<template>
  <div
    class="modal set-integrations-add-panel set-integrations-add-panel-button right-sliding-panel right-sliding-panel-xs"
    :class="[
      { 'show d-block': show },
      { 'd-none': !show },
      { 'modal-mini': type === 'mini' },
    ]"
    v-show="show"
    tabindex="-1"
    role="dialog"
    :aria-hidden="!show"
  >
    <div class="modal-dialog mb-0">
      <div class="modal-content">
        <modal-topbar>
          <template v-if="!currentIntegrationId">
            <a slot="left" @click="closePanel">
              <i class="fas fa-chevron-left mr-1"></i>
              <span>Settings</span>
            </a>
            <div slot="center">Add new integration</div>
          </template>
          <template v-else>
            <a slot="left" @click="closePanel()">
              <i class="fas fa-chevron-left mr-1"></i>
              <span>Back</span>
            </a>
            <div slot="center">
              {{ availableIntegrations[currentIntegrationId].name }}
            </div>
          </template>
        </modal-topbar>

        <div v-if="currentIntegrationId" class="modal-body">
          <div class="d-flex align-items-baseline mt-5">
            <div class="card-icon">
              <img :src="availableIntegrations[currentIntegrationId].image" />
            </div>
            <h2
              class="ml-3"
              :id="availableIntegrations[currentIntegrationId].id"
            >
              {{ availableIntegrations[currentIntegrationId].name }}
            </h2>
          </div>
          <div class="flex-center my-4">
            <base-button
              v-if="!currentIntegrationId"
              type="primary"
              class="btn-w-25"
              v-on:click="closePanel"
            >
              Close
            </base-button>
            <base-button
              v-else
              type="primary"
              class="btn-w-25"
              :disabled="loading.connect || loading.request"
              @click="connect()"
            >
              Connect
            </base-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* the user clicks a button (Ecwid, Big Cartel) */
import { AVAILABLE_INTEGRATIONS, MESSAGES } from "@/constants";
import { mapState } from "vuex";
import configs from "../configs";

export default {
  name: "set-integrations-add-panel-button",
  components: {},
  props: {
    currenciesList: {
      type: Array,
    },
    show: Boolean,
    type: {
      type: String,
      default: "",
      validator(value) {
        let acceptedValues = ["", "notice", "mini"];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Modal type (notice|mini|"") ',
    },
    selectedIntegrationId: {
      type: String,
    },
    animationDuration: {
      type: Number,
      default: 500,
      description: "Modal transition duration",
    },
  },
  data() {
    return {
      availableIntegrations: AVAILABLE_INTEGRATIONS,
      loading: {
        request: false,
        connect: false,
      },
      currentIntegrationId: null,
      localError: false,
    };
  },
  computed: {
    ...mapState({
      authUser: (state) => state.user.authUser,
      showAlert: (state) => state.alert.showAlert,
    }),
  },
  methods: {
    closePanel() {
      /* slide out panel */
      document.querySelector(
        ".set-integrations-add-panel-button .modal-dialog"
      ).style.right = `-100%`;
      /* reset panel */
      setTimeout(() => {
        this.$emit("update:show", false);
        this.$emit("close");
      }, this.animationDuration / 2);
    },
    selectIntegration(id) {
      this.currentIntegrationId = id;
      setTimeout(() => {
        document.getElementById("integration_url_input_" + id).focus();
      }, 200);
    },
    redirectToSite(data) {
      let url;
      this.loading.connect = true;
      if (data.name === AVAILABLE_INTEGRATIONS.ecwid.id) {
        url = `https://my.ecwid.com/api/oauth/authorize?client_id=${configs.services.ecwid.client_id}&response_type=code&scope=read_orders%20read_discount_coupons&redirect_uri=${configs.site.url}/api/webhooks/ecwid/connect?nonce=${data.nonce}`;
       }else if (data.name === AVAILABLE_INTEGRATIONS.bigcartel.id) {
        url = `https://my.bigcartel.com/oauth/authorize?client_id=${configs.services.bigcartel.client_id}&redirect_uri=${configs.site.url}/api/webhooks/bigcartel/connect&state=${data.nonce}&response_type=code`;
      }
      const win = window.open(url, "_blank");
      if (win == null || typeof(win) == 'undefined') {
        // Turn off the pop-up blocker
        this.$store.dispatch("setAlert", {
          showAlert: true,
          content: MESSAGES["user__store_popup-failed"],
          alertClass: "danger",
        });
      } else {
        win.focus();
      }
    },
    async connect() {
      this.loading.request = true;
      try {
        const { data } = await this.$store.dispatch(
          "integration/requestIntegration",
          {
            site: this.currentIntegrationId,
            url: "",
          }
        );
        this.redirectToSite(data);
        this.loading.request = false;
      } catch (error) {
        this.loading.request = false;
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["common-500"],
            alertClass: "danger",
          });
        }
      }
    },
  },
  watch: {
    show(val) {
      const documentClasses = document.body.classList;
      if (val) {
        this.localError = false;
        this.currentIntegrationId = this.selectedIntegrationId;

        this.loading.request = false;
        this.loading.connect = false;
        /* slide in panel*/
        documentClasses.add("modal-open");
        setTimeout(() => {
          document.querySelector(
            ".set-integrations-add-panel-button .modal-dialog"
          ).style.right = `0px`;
        }, 0);
      } else {
        /* reset panel */
        documentClasses.remove("modal-open");
        document.querySelector(".set-integrations-add-panel-button").scrollTop = 0;
      }
    },
  },
};
</script>
<style>
.modal.show {
  background-color: rgba(255, 255, 255, 0.7);
}
</style>
