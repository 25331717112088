<template>
  <div class="root">
     <div class="container">
        <div class="w-25 d-flex">
          {{label}}
          <div class="question-mark"
              v-b-tooltip.hover.html="description"
          >
            <i class="fas fa-question-circle"></i>
          </div>
        </div>
        <ul class="w-75 progressbar">
          <li
            v-for="i in 3"
            :key="i"
            :class="{
              active: i <= active + 1,
              hidden: step.indexes.indexOf(i - 1) === -1,
              isDisabled: !hasAccessByIndex(i - 1),
            }"
            @click="onClickStep(i - 1)"
            v-b-tooltip.hover
            :title="getTooltipMessage(i - 1)"
          >
            <span v-if="step.showLabels">{{step.labels[i - 1]}}</span>
          </li>
        </ul>
      </div>
  </div>
</template>
<script>
import { getPermissionErrorMessage } from "@/utils";
import { mapGetters } from "vuex";
export default {
  props:['id', 'active', 'label', 'description', 'step'],
  computed: {
    ...mapGetters({
      hasAccess: 'user/hasAccess',
    })
  },
  data () {
    return {
      getPermissionErrorMessage: getPermissionErrorMessage,
    }
  },
  methods: {
    onClickStep(index) {
      if (this.step.indexes.indexOf(index) > -1 && this.hasAccessByIndex(index)) {
        this.$emit('select', index);
      }
    },
    hasAccessByIndex(index) {
      if (index && !this.hasAccess(`${this.id}:${["view", "full"][index - 1]}`)) {
        return false;
      }
      return true;
    },
    getTooltipMessage(index) {
      if (this.hasAccessByIndex(index)) return undefined;
      return this.getPermissionErrorMessage(`${this.id}:${["view", "full"][index - 1]}`, "user__permission_account-error");
    }
  }
}
</script>
<style lang="scss" scoped>
div {
  color: gray;
  text-transform: initial;
  font-size: 14px;
}
.container {
  width: 100%;
  position: absolute;
  z-index: 1;
  margin-top: 20px;
  display: flex;
  align-items: center;
  .question-mark {
    display: block;
    margin-left: 5px;
    cursor: pointer;

  }
}
.progressbar {
  counter-reset: step;
  padding: 0;
  padding-top: 10px;
}
.progressbar li {
  float: left;
  width: 30%;
  position: relative;
  text-align: center;
  list-style: none;
  cursor: pointer;
  &:hover:before {
    border-color: #3aac5d !important;
    background: #5faf6f !important;
  }
  span {
    position: absolute;
    bottom: 45px;
    width: 100%;
    text-align: center;
    left: 0;
  }
  &.isDisabled {
    &:hover:before {
      border-color: inherit !important;
      background: #bebebe !important;
    }
  }
}
.progressbar li:before {
  content: "";
  counter-increment: step;
  width: 25px;
  height: 25px;
  border: 2px solid #bebebe;
  display: block;
  margin: 3px auto 10px auto;
  border-radius: 50%;
  line-height: 27px;
  background: white;
  color: #bebebe;
  text-align: center;
  font-weight: bold;

}
.progressbar li:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  background: #979797;
  top: 15px;
  left: -50%;
  z-index: -1;
}
.progressbar li.active:before {
  border-color: #3aac5d;
  background: #30a050;
  color: white;
}
.progressbar li.active:after {
  background: #3aac5d;
}
.progressbar li.hidden::before {
  opacity: 0;
}

.progressbar li:first-child:after {
  content: none;
}
</style>
