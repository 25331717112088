<template>
  <div class="admin">
    <div class="topbar navbar-mobile flex-between d-md-none">
      <div class="d-flex flex-row w-100">
        <a
          @click="showFilterbar(true)"
          class="topbar-menu flex-middle-center mr-3"
        >
          <i class="fas fa-bars"></i>
        </a>
        <div class="topbar-info flex-middle w-100" @click="showFilterbar(true)">
          Admin
        </div>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center p-3 mt-5">
      <div class="form-group admin__form">
        <label class="typo__label" for="user-search"
          >Please select an user to login as</label
        >
        <multiselect
          v-model="selectedUser"
          id="user-search"
          label="email"
          track-by="id"
          placeholder="Input at least two character to search the user emails"
          open-direction="bottom"
          :options="users"
          :multiple="false"
          :searchable="true"
          :loading="loading.users"
          :internal-search="false"
          :options-limit="20"
          :max-height="600"
          :hide-selected="true"
          @search-change="searchUsers"
        >
        </multiselect>
        <div v-if="selectedUserDetail" class="row w-100 mt-4">
          <div class="col-6 user-label">Email</div>
          <div class="col-6 user-value">
            <span>{{selectedUserDetail.email}}</span>
          </div>

          <div class="col-6 user-label">Credits</div>
          <div class="col-6 user-value">
            <base-input
              v-if="isEditMode"
              class="input-group-alternative mb-3"
              placeholder="Credits"
              v-model="edit.credits"
              type="input"
              min="0"
              step="1"
              autofocus
            ></base-input>
            <span v-else>{{selectedUserDetail.credits / 100 | NormalNumber(2)}}</span>
          </div>

          <div class="col-6 user-label">Currency</div>
          <div class="col-6 user-value">
            <span>{{selectedUserDetail.default_currency}}</span>
          </div>

          <div class="col-6 user-label">Max active campaigns</div>
          <div class="col-6 user-value">
            <base-input
              v-if="isEditMode"
              class="input-group-alternative mb-3"
              placeholder="Max active campaigns"
              v-model="edit.max_active_campaigns"
              type="text"
              autofocus
            ></base-input>
            <span v-else>{{selectedUserDetail.max_active_campaigns}}</span>
          </div>

          <div class="col-6 user-label">Active campaigns</div>
          <div class="col-6 user-value">
            <span>{{selectedUserDetail.active_campaigns}}</span>
          </div>

          <div class="col-6 user-label">Plan</div>
          <div class="col-6 user-value">
            <base-input
              v-if="isEditMode"
              class="input-group-alternative mb-3"
              placeholder="Plan"
              v-model="edit.plan_id"
              type="text"
              autofocus
            ></base-input>
            <span v-else>{{selectedUserDetail.plan_id}}</span>
          </div>



          <div v-if="isEditMode" class="col-12 d-flex justify-content-center mt-5">
            <base-button
              type="danger"
              class="input-group-alternative d-none d-md-block mr-3"
              @click="saveUpdates"
              :disabled="loading.save"
            >
              Save
            </base-button>
            <base-button
              type="primary"
              class="input-group-alternative d-none d-md-block"
              @click="isEditMode=false"
              :disabled="loading.save"
            >
              Cancel
            </base-button>
          </div>
          <div v-else class="col-12 d-flex justify-content-center mt-3">
            <base-button
              type="primary"
              class="input-group-alternative d-none d-md-block mr-3"
              @click="emulateAsUser"
            >
              Emulate
            </base-button>
            <base-button
              type="danger"
              class="input-group-alternative d-none d-md-block"
              @click="onEditMode"
            >
              Update
            </base-button>
          </div>

        </div>
      </div>
    </div>
    <filter-bar
      :background-color="filterbarBackground"
      :short-title="configs.site.name"
      :title="configs.site.name"
      class="d-block d-md-none"
    >
      <left-nav slot="filters" />
    </filter-bar>
  </div>
</template>

<script>
import Configs from "../configs";
import { MESSAGES } from "@/constants";

import Multiselect from "vue-multiselect";

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      filterbarBackground: "vue", //vue|blue|orange|green|red|primary
      configs: Configs,
      selectedUser: null,
      selectedUserDetail: null,
      users: [],
      isEditMode: false,
      edit: {},
      loading: {
        users: false,
        userDetail: false,
        save: false,
      }
    };
  },
  computed: {},
  watch: {
    selectedUser(user) {
      if (user) {
        this.getUserDetail();
      }
    }
  },

  mounted() {
    this.$filterbar.displayFilterbar(false);
  },

  beforeDestroy() {},
  methods: {
    filterChanged(milliseconds = 500) {
      if (this.searchThread) {
        clearTimeout(this.searchThread);
        this.searchThread = null;
      }
      this.searchThread = setTimeout(() => {
        this.getCampaigns();
      }, milliseconds);
      this.isQuery = false;
    },
    hideFilterbar() {
      if (this.$filterbar.showFilterbar) {
        this.$filterbar.displayFilterbar(false);
      }
    },
    showFilterbar() {
      setTimeout(() => {
        this.$filterbar.displayFilterbar(true);
      }, 0);
    },
    async searchUsers(query) {
      if (!query || query.length < 2) return;
      this.loading.users = true;
      try {
        const response = await this.$store.dispatch("user/searchUsers", query);
        this.users = response;
        this.loading.users = false;
      } catch {
        this.loading.users = false;
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["common-500"],
            alertClass: "danger",
          });
        }
      }
    },
    async getUserDetail () {
      if (!this.selectedUser) return;
      this.loading.userDetail = true;
      try {
        const response = await this.$store.dispatch("user/getUserDetail", this.selectedUser.id);
        this.selectedUserDetail = response;
        this.loading.userDetail = false;
      } catch {
        this.loading.userDetail = false;
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["common-500"],
            alertClass: "danger",
          });
        }
      }
    },
    emulateAsUser() {
      this.$store.dispatch("user/startEmulatingUser", this.selectedUserDetail);
    },
    async saveUpdates() {
      this.loading.save = true;
      try {
        const response = await this.$store.dispatch("user/setUserDetail", {
          id: this.selectedUser.id,
          updates: this.edit
        });
        if (response) {
          this.selectedUserDetail = {...this.selectedUserDetail, ...this.edit};
          this.isEditMode = false;
        }
        this.loading.save = false;
      } catch {
        this.loading.save = false;
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["common-500"],
            alertClass: "danger",
          });
        }
      }
    },
    onEditMode() {
      this.isEditMode = true;
      this.edit = {
        credits: this.selectedUserDetail.credits,
        max_active_campaigns: this.selectedUserDetail.max_active_campaigns,
        plan_id: this.selectedUserDetail.plan_id,
      }
    }
  },
};
</script>

<style lang="scss">
@import "../assets/scss/pages/admin";
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
