<template>
  <div
    class="modal set-plans-update-panel right-sliding-panel"
    :class="[
      { 'show d-block': show },
      { 'd-none': !show },
      { 'modal-mini': type === 'mini' }
    ]"
    v-show="show"
    tabindex="-1"
    role="dialog"
    :aria-hidden="!show"
  >
    <div class="modal-dialog mb-0">
      <div class="modal-content">
        <modal-topbar>
          <a v-if="showSelectPaymentMethod" slot="left" @click="back">Back</a>
          <a v-else slot="left" @click="closePanel">Cancel</a>
          <div slot="center">Select plans</div>
        </modal-topbar>

        <div
          class="modal-body"
          :class="!showStripeForm & showSelectPaymentMethod ? 'px-0' : ''"
        >
          <stripe-form
            v-if="showStripeForm"
            :plan="currentPlan"
            :paymentMethod="currentPaymentMethod"
            @startSubscription="startSubscription"
            @removePaymentMethod="removePaymentMethod"
          ></stripe-form>
          <div v-else-if="showSelectPaymentMethod" class="mt-5">
            <div v-if="paymentMethods.length" class="setting-label px-4">
              Payment Methods
            </div>
            <ul class="main-nav">
              <li
                v-for="method in paymentMethods"
                :key="method.card_id"
                class="p-3 my-1 flex-middle-between"
                @click="setCard(method)"
              >
                <div class="d-flex">
                  <div class="card-icon">
                    <svg
                      class="ccicon"
                      width="750"
                      height="471"
                      viewBox="0 0 750 471"
                      version="1.1"
                      v-html="getCardIcon(method.brand)"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                    />
                  </div>
                  <span class="ml-3 mt-2">{{ method.last4 }}</span>
                </div>
              </li>
            </ul>
            <div class="w-100 text-center mt-3">
              <a class="btn-link" @click="setCard(null)"
                >Pay with another card</a
              >
            </div>
          </div>
          <div v-else class="row">
            <div
              v-for="plan in plans"
              :key="plan.id"
              class="col-lg-4 col-md-6 col-sm-12"
              @click="selectPlan(plan)"
            >
              <card :hover="true" class="plan-card mt-5">
                <h3 class="m-0" slot="header">
                  {{ plan.name }}
                </h3>
                <div class="d-flex">
                  <h3>
                    ${{ plan.cost / 100 }}/{{ plan.interval_count === 1 ?  plan.interval : `${plan.interval_count} ${plan.interval}s` }}
                  </h3>

                </div>
                <p class="plan-card__description">{{ plan.description }}</p>
                <ul>
                  <li>
                    <i class="fas fa-check mr-2"></i>
                    {{plan.credits_amount / 100 | NormalNumber(0)}}
                    credits
                  </li>
                  <li>
                    <i class="fas fa-check mr-2"></i>
                    {{ plan.max_active_campaigns }} active campaign{{
                      plan.max_active_campaigns > 1 ? "s" : ""
                    }}
                  </li>
                </ul>
                <base-button type="primary" class="w-100">Select</base-button>
              </card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as ICONS from "../constants/icons";

/* components */
import StripeForm from "./StripeForm";

export default {
  name: "set-plans-update-panel",
  components: {
    StripeForm
  },
  props: {
    show: Boolean,
    type: {
      type: String,
      default: "",
      validator(value) {
        let acceptedValues = ["", "notice", "mini"];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Modal type (notice|mini|"") '
    },
    selectedPaymentMethod: {
      type: Object,
      default: null
    },
    animationDuration: {
      type: Number,
      default: 500,
      description: "Modal transition duration"
    }
  },
  data() {
    return {
      icons: ICONS,
      loading: {
        profile: false,
        payments: false
      },
      paymentMethods: [],
      plans: [],
      currentPlan: {},
      currentPaymentMethod: null,
      showSelectPaymentMethod: false,
      showStripeForm: false,
      localError: false
    };
  },
  methods: {
    closePanel() {
      /* slide out panel */
      document.querySelector(
        ".set-plans-update-panel .modal-dialog"
      ).style.right = `-100%`;
      /* reset panel */
      setTimeout(() => {
        this.$emit("update:show", false);
        this.$emit("close");
      }, this.animationDuration / 2);
    },
    getCardIcon(brand) {
      if (brand) {
        return this.icons[brand] ? this.icons[brand] : this.icons["undefined"];
      }
      return this.icons["unknown"];
    },
    selectPlan(plan) {
      this.showSelectPaymentMethod = this.selectedPaymentMethod ? false : true;
      this.showStripeForm = this.selectedPaymentMethod ? true : false;
      this.currentPlan = plan;
      this.currentPaymentMethod = this.selectedPaymentMethod;
      if (!this.paymentMethods || !this.paymentMethods.length) {
        this.setCard(null);
      }
    },
    startSubscription(result) {
      if (result) {
        this.showSelectPaymentMethod = false;
        this.showStripeForm = false;
        this.closePanel();
      }
    },
    setCard(paymentMethod) {
      this.showStripeForm = true;
      this.currentPaymentMethod = paymentMethod;
    },
    back() {
      if (this.showStripeForm) {
        this.showStripeForm = false;
        if (!this.paymentMethods || !this.paymentMethods.length) {
          this.showSelectPaymentMethod = null;
        } else {
          this.showSelectPaymentMethod = !this.selectedPaymentMethod;
        }
      } else {
        this.showSelectPaymentMethod = false;
      }
    },
    async getPlans() {
      this.loading.plans = true;
      try {
        const { data } = await this.$store.dispatch("billing/getBillingPlans");
        this.plans = data;
        this.loading.plans = false;
      } catch {
        this.loading.plans = false;
        this.localError = "Getting plans failed";
      }
    },
    removePaymentMethod(cardId) {
      const index = this.paymentMethods.map(e => e.card_id).indexOf(cardId);
      if (index >= 0) {
        this.paymentMethods.splice(index, 1);
      }
      this.showStripeForm = false;
    },
    async getPaymentMethods() {
      this.loading.payments = true;
      try {
        const { data } = await this.$store.dispatch("billing/getPaymentMethods");
        this.paymentMethods = data;
        this.loading.payments = false;
      } catch {
        this.loading.payments = false;
        this.localError = "Getting billing data failed";
      }
    }
  },

  watch: {
    show(val) {
      this.showSelectPaymentMethod = false;
      this.showStripeForm = false;
      const documentClasses = document.body.classList;

      if (val) {
        this.getPlans();
        this.getPaymentMethods();

        /* slide in panel*/
        documentClasses.add("modal-open");
        setTimeout(() => {
          document.querySelector(
            ".set-plans-update-panel .modal-dialog"
          ).style.right = `0px`;
        }, 0);
      } else {
        /* reset panel */
        documentClasses.remove("modal-open");
        document.querySelector(".set-plans-update-panel").scrollTop = 0;
      }
    }
  }
};
</script>
<style>
.modal.show {
  background-color: rgba(255, 255, 255, 0.7);
}
</style>
