var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab-pane fade set-integrations-panel",class:[{ 'modal-mini': _vm.type === 'mini' }],attrs:{"id":_vm.tabId},on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.closePanel.apply(null, arguments)}}},[_c('div',{staticClass:"main-content"},[(_vm.integrations && _vm.integrations.length)?[_c('div',{staticClass:"panel-block"},[_c('div',{staticClass:"panel-item"},[_vm._m(0),_c('ul',{staticClass:"main-nav-integrations"},_vm._l((_vm.integrations),function(integration,index){return _c('li',{key:index,staticClass:"integration-item flex-middle-between flex-wrap"},[_c('div',{staticClass:"integration-card flex-middle flex-wrap"},[_c('div',{staticClass:"integration-icon"},[_c('img',{attrs:{"src":_vm.availableIntegrations[integration.name].image}})]),_c('div',{staticClass:"ml-3"},[_vm._v("\n                  "+_vm._s(integration.store_name ||
                    (integration.url
                      ? integration.url.replace(/(^\w+:|^)\/\//, "")
                      : ""))+"\n                ")]),_c('div',{staticClass:"flex-middle ml-auto"},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"status-badge",class:integration.webhook_active ? 'active' : '',attrs:{"title":integration.webhook_active
                        ? 'Ninja Influence will receive the events from your store'
                        : 'Ninja Influence could not create the webhooks to receive the events from your store'}},[_vm._v("\n                    "+_vm._s(integration.webhook_active
                        ? "Connected"
                        : "Partially connected")+"\n                  ")])])]),(_vm.hasFullAccess('integrations'))?_c('div',{staticClass:"ml-2"},[_c('base-button',{staticClass:"btn-light",attrs:{"isBusy":integration.id === _vm.selectedId && _vm.disconnecting,"type":"danger"},on:{"click":function($event){return _vm.disconnect(integration.id)}}},[_vm._v("\n                  Disconnect\n                ")])],1):_vm._e()])}),0)])])]:_vm._e(),(_vm.hasFullAccess('integrations'))?[_c('div',{staticClass:"panel-item"},[_vm._m(1),_c('ul',{staticClass:"main-nav-integrations"},[_vm._l((_vm.availableIntegrations),function(integration,id,index){return [_c('li',{key:index,staticClass:"integration-item available flex-middle-between",on:{"click":function($event){return _vm.addNewIntegration(id)}}},[_c('div',{staticClass:"integration-card flex-middle"},[_c('div',{staticClass:"integration-icon"},[_c('img',{attrs:{"src":integration.image}})]),_c('span',{staticClass:"ml-3 flex-fill"},[_vm._v(_vm._s(integration.name))]),_c('i',{staticClass:"fas fa-chevron-right float-right"})])])]})],2)])]:_vm._e(),_c('div',{staticClass:"panel-item"},[_vm._m(2),_c('ul',{staticClass:"main-nav-integrations"},[_vm._l((_vm.comingSoonIntegrations),function(integration,id,index){return [_c('li',{key:index,staticClass:"integration-item flex-middle-between"},[_c('div',{staticClass:"integration-card flex-middle"},[_c('div',{staticClass:"integration-icon"},[_c('img',{attrs:{"src":integration.image}})]),_c('span',{staticClass:"ml-3"},[_vm._v(_vm._s(integration.name))])])])]})],2)])],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-2 setting-label mt-1"},[_c('span',{staticClass:"nav-link-text"},[_vm._v("Active Integrations")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-2 setting-label mt-1"},[_c('span',{staticClass:"nav-link-text"},[_vm._v("Available Integrations")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-2 setting-label mt-1"},[_c('span',{staticClass:"nav-link-text"},[_vm._v("Coming Soon")])])
}]

export { render, staticRenderFns }