<template>
  <div
    class="tab-pane fade set-billing-panel"
    :class="[
      { 'modal-mini': type === 'mini' }
    ]"
    v-bind:id="tabId"
  >
    <div class="main-content">
      <div v-if="paymentMethods.length" class="setting-label px-4">Payment Methods</div>
          <ul class="main-nav">
            <li
              v-for="method in paymentMethods"
              :key="method.card_id"
              class="p-3 my-1 flex-middle-between"
              @click="buyCredits(method)"
            >
              <div class="d-flex">
                <div class="card-icon">
                  <svg
                    class="ccicon"
                    width="750"
                    height="471"
                    viewBox="0 0 750 471"
                    version="1.1"
                    v-html="getCardIcon(method.brand)"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  />
                </div>
                <span class="ml-3 mt-2">{{ method.last4 }} </span>
              </div>
              <i class="fas fa-chevron-right"></i>
            </li>
          </ul>

          <div class="w-100 text-center mt-3">
            <a class="btn-link" @click="addNewPaymentMethod">Add new card</a>
          </div>
          <!-- <div class="setting-label mt-3 px-4">Billing History</div>
          <div class="w-100">
            <vue-scrolling-table
              class="billing-table"
              :scroll-horizontal="true"
              :scroll-vertical="true"
              :sync-header-scroll="true"
              :dead-area-color="'transparent'"
              :class="{freezeFirstColumn: true}"
            >
              <template slot="thead">
                <tr>
                  <th>Date</th>
                  <th class="description-column">Description</th>
                  <th>Amount</th>
                  <th class="extra-column"></th>
                </tr>
              </template>
              <template slot="tbody">
                <tr class="d-flex">
                  <td class="flex-middle">Jun 29, 2019</td>
                  <td class="description-column flex-middle">
                    Invoice for May 2019
                  </td>
                  <td class="flex-middle">$350</td>
                  <td class="extra-column flex-middle">Download&nbsp;<a>PDF</a>&nbsp;or&nbsp;<a>CSV</a></td>
                </tr>
              </template>
            </vue-scrolling-table>
          </div> -->
        </div>
      </div>
    <!-- </div>
  </div> -->
</template>
<script>
import { mapState } from "vuex";
import * as ICONS from "../constants/icons";
import { MESSAGES } from "@/constants";

// /* components */
// import VueScrollableTable from "vue-scrolling-table";

export default {
  name: "set-billing-panel",
  // components: {
  //   "vue-scrolling-table": VueScrollableTable
  // },
  props: {
    show: Boolean,
    type: {
      type: String,
      default: "",
      validator(value) {
        let acceptedValues = ["", "notice", "mini"];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Modal type (notice|mini|"") '
    },
    animationDuration: {
      type: Number,
      default: 500,
      description: "Modal transition duration"
    },
    username: {
      type: String
    },
    tabId: String
  },
  data() {
    return {
      icons: ICONS,
      paymentMethods: [],
      loading: {
        payments: false,
        auth: false
      },
      localError: false
    };
  },
  mounted() {
    this.$nextTick(function () {
      this.getPaymentMethods();
      this.getUserData();
    });
  },
  methods: {
    closePanel() {
      /* slide out panel */
      document.querySelector(
        ".set-billing-panel .modal-dialog"
      ).style.right = `-100%`;
      /* reset panel */
      setTimeout(() => {
        this.$emit("update:show", false);
        this.$emit("close");
      }, this.animationDuration / 2);
    },
    getCardIcon(brand) {
      if (brand) {
        return this.icons[brand] ? this.icons[brand] : this.icons["undefined"];
      }
      return this.icons["unknown"];
    },
    buyCredits(paymentMethod) {
      this.$emit("buyCredits", paymentMethod);
    },
    addNewPaymentMethod() {
      this.$emit("addNewPaymentMethod");
    },
    async getUserData() {
      this.loading.auth = true;
      try {
        await this.$store.dispatch("user/checkAuthMe");
        this.loading.auth = false;
      } catch {
        this.loading.auth = false;
        this.localError = "Getting user data faild";
      }
    },
    async getPaymentMethods() {
      this.loading.payments = true;
      try {
        const { data } = await this.$store.dispatch("billing/getPaymentMethods");
        this.paymentMethods = data;
        this.loading.payments = false;
      } catch {
        this.loading.payments = false;
        this.localError = MESSAGES["user__get_billing_plans-failed"];
      }
    }
  },
  computed: {
    ...mapState({
      credits: state => state.user.authUser.credits,
      emulatingCredits: state => state.user.emulatingUser.credits,
    })
  },
  watch: {
    show(val) {
      const documentClasses = document.body.classList;
      if (val) {
        this.getPaymentMethods();
        this.getUserData();
        /* slide in panel*/
        documentClasses.add("modal-open");
        setTimeout(() => {
          document.querySelector(
            ".set-billing-panel .modal-dialog"
          ).style.right = `0px`;
        }, 0);
      } else {
        /* reset panel */
        documentClasses.remove("modal-open");
        document.querySelector(".set-billing-panel").scrollTop = 0;
      }
    }
  }
};
</script>
<style>
.modal.show {
  background-color: rgba(255, 255, 255, 0.7);
}
</style>
