<template>
  <div class="campaign-search d-flex flex-column">
    <!-- Menu for mobile version (not used for now) -->
    <!-- <div class="topbar navbar-mobile flex-between d-md-none">
        <div class="d-flex flex-row w-100">
          <a @click="showFilterbar(true)" class="topbar-menu flex-middle-center mr-3">
            <i class="fas fa-bars"></i>
          </a>
          <div class="topbar-info flex-middle w-100" @click="showFilterbar(true)">Campaigns</div>
        </div>
        <a
          v-if="hasFullAccess('campaigns')"
          class="topbar-button flex-middle-center topbar-button-wide p-2"
          @click="showEditCampaign = true"
        >
          <i class="fas fa-plus mr-1"></i>
          Campaigns
        </a>
        <a
          v-else
          class="topbar-button topbar-button-disabled flex-middle-center topbar-button-wide p-2"
          v-b-tooltip.hover
          :title="getPermissionErrorMessage('campaigns:full')"
        >
          <i class="fas fa-plus mr-1"></i>
          Campaigns
        </a>
    </div>-->

    <!-- Top menu header -->
    <div
      class="campaigns-search-header d-flex flex-wrap justify-content-end p-2"
    >
      <!-- <div class="flex-between filter-item search-form">
                  <base-input
                    v-model="query"
                    class="input-group-alternative search-input w-100"
                    placeholder="Search Campaigns..."
                    autofocus
                  ></base-input>
                  <a type="success" class="search-icon ml-3" @click="filterChanged">
                    <i class="fas fa-search"></i>
                  </a>
      </div>-->
      <base-checkbox class="filter-archived" v-model="showArchived"
        >Show archived</base-checkbox
      >
      <base-dropdown class="m-1" position="right">
        <a
          slot="title"
          class="nav-link nav-link-icon"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i class="fas fa-filter"></i> Sort
          <i class="fas fa-angle-down"></i>
        </a>
        <template v-for="(orderByItem, index) in orderByList">
          <a
            :key="orderByItem.value"
            @click="setOrderBy(orderByItem)"
            class="dropdown-item px-3 py-1"
          >
            <i class="fas fa-sort-amount-down"></i>
            {{ orderByItem.label }}
            <i
              v-show="orderBy.value === orderByItem.value"
              class="fas fa-check mr-0 ml-2"
            ></i>
          </a>
          <div
            v-if="orderByList.length > index + 1"
            class="dropdown-divider"
            :key="index"
          ></div>
        </template>
      </base-dropdown>
      <base-dropdown class="m-1" position="right">
        <a
          slot="title"
          class="nav-link nav-link-icon"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i
            v-if="orderDirection.value === 'ascending'"
            class="fas fa-sort-amount-up"
          ></i>
          <i v-else class="fas fa-sort-amount-down mx-2"></i>
          <span class="d-none d-md-inline">{{ orderDirection.label }}</span>
          <i class="fas fa-angle-down mx-1"></i>
        </a>
        <template v-for="(orderDirectionItem, index) in orderDirectionsList">
          <a
            :key="orderDirectionItem.value"
            @click="setOrderDirection(orderDirectionItem)"
            class="dropdown-item px-3 py-1"
          >
            <i
              v-if="orderDirectionItem.value === 'ascending'"
              class="fas fa-sort-amount-up"
            ></i>
            <i v-else class="fas fa-sort-amount-down"></i>
            {{ orderDirectionItem.label }}
            <i
              v-show="orderDirection.value === orderDirectionItem.value"
              class="fas fa-check mr-0 ml-2"
            ></i>
          </a>
          <div
            v-if="orderDirectionsList.length > index + 1"
            class="dropdown-divider"
            :key="index"
          ></div>
        </template>
      </base-dropdown>

      <!-- Pagnination parameters (not used) -->
      <!-- <base-dropdown class="m-1" position="right">
        <a
          slot="title"
          class="nav-link nav-link-icon"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {{ pageSize.label }}
          <i class="fas fa-angle-down"></i>
        </a>
        <template v-for="(pageSizeItem, index) in pageSizesList">
          <a :key="pageSizeItem.value" @click="setPageSizeItem(pageSizeItem)" class="dropdown-item px-3 py-1">
            {{ pageSizeItem.label }}
            <i v-show="pageSize.value === pageSizeItem.value" class="fas fa-check mr-0 ml-2"></i>
          </a>
          <div v-if="pageSizesList.length > index + 1" class="dropdown-divider" :key="index"></div>
        </template>
      </base-dropdown>-->

      <base-button
        :disabled="!hasFullAccess('campaigns')"
        :disabledTooltip="getPermissionErrorMessage('campaigns:full')"
        icon="fas fa-plus"
        class="btn-black"
        @click="showEditCampaign = true"
      >
        <span>Create campaign</span>
      </base-button>
    </div>

    <!-- Page basic content -->
    <div class="search-content flex-fill">
      <div class="campaigns-container d-flex flex-wrap w-100" v-if="campaignsList.length">
        <div
          v-for="(campaign, index) in campaignsList"
          :key="index"
          class="campaign-wrapper px-2 py-2"
          @click="gotoPipeLine(campaign.id)"
        >
          <div
            class="campaign-card d-flex flex-column"
            :class="campaign.archived ? 'archived' : ''"
          >
            <div class="campaign-header d-flex">
              <div class="campaign-name">
                <h3>{{ campaign.name }}</h3>
                <h4 class="ml-2">
                  <span v-if="campaign.active" class="campaign-active"
                    >Active</span
                  >
                  <span v-else class="campaign-inactive">Inactive</span>
                </h4>
              </div>
              <div
                v-if="campaign.unread_messages"
                class="campaigns-icons"
                v-b-tooltip.hover
                title="You have unread messages"
              >
                <i class="fas fa-envelope" @click="gotoInbox(campaign.id)"></i>
              </div>

              <div
                class="campaigns-icons"
                v-b-tooltip.hover
                :title="campaign.archived ? 'Unarchive' : 'Archive'"
                v-if="hasFullAccess('campaigns')"
                @click="archiveCampaign($event, campaign.id)"
              >
                <a
                >
                  <img v-if="!campaign.archived"
                    src="/app/img/icons/common/archive-white-24dp.svg"
                    class
                  />
                  <img v-if="campaign.archived"
                    src="/app/img/icons/common/unarchive-white-24dp.svg"
                    class
                  />
                </a>
              </div>
            </div>
            <div class="campaign-body">
              <div class="d-flex campaign-params-card">
                <div class>
                  <h3>Budget and success keys</h3>
                  <div class="campaign-params">
                    <div class>
                      <div class="campaign-label">Budget</div>
                      <div class="campaign-currency">
                        {{ campaign.budget | BigNumber | Currency }}
                      </div>
                    </div>
                    <div class>
                      <div class="campaign-label">Spend</div>
                      <div class="campaign-currency">
                        {{ campaign.spend | BigNumber | Currency }}
                      </div>
                    </div>
                    <div class>
                      <div class="campaign-label">IMV</div>
                      <div class="campaign-currency">
                        {{ campaign.imv | BigNumber | Currency }}
                      </div>
                    </div>
                    <div class>
                      <div class="campaign-label">ROI</div>
                      <div class="campaign-currency">
                        {{ getCampaignROI(campaign) | Percent(1, false) }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="progress-block">
                  <h3>Progress</h3>
                  <div class="campaign-params">
                    <div
                      v-for="stage_id in campaign.stages_order"
                      class
                      :key="stage_id"
                    >
                      <div class>
                        <div class="campaign-label">
                          {{ campaign.stage_names[stage_id] }}
                        </div>
                        <div class="campaign-currency">
                          {{ campaign.stage_sizes[stage_id] }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="campaign-bottom">
              <div class="campaign-profiles" v-if="campaign.influencers > 0">
                <div class="campaign-influencers">
                  <template v-if="campaign.profileImages">
                    <div
                      v-for="(profileImage, index) in campaign.profileImages"
                      :key="`index-${index}`"
                      class="campaign-influencer"
                    >
                      <span class="campaigns-icons"
                        ><img
                          class="avatar avatar-sm"
                          :src="profileImage"
                          alt=""
                      /></span>
                    </div>
                  </template>
                  <template v-else>
                    <div class="campaign-influencer">
                      <span class="campaigns-icons"></span>
                    </div>
                    <div class="campaign-influencer">
                      <span class="campaigns-icons"></span>
                    </div>
                    <div class="campaign-influencer">
                      <span class="campaigns-icons"></span>
                    </div>
                  </template>
                  <div class="campaign-influencer influencers-count">
                    <span class="campaigns-icons"
                      >+ {{ campaign.influencers }}</span
                    >
                  </div>
                </div>
                <div
                  class="campaign-plus show-influencers-button"
                  ref="show-influencers-button"
                  @click="showInfluencers($event, campaign.id)"
                >
                  <i class="fas fa-plus"></i>
                </div>
              </div>
              <div class="campaign-profiles" v-else>
                <div class="campaign-influencers">
                  <div
                    class="campaign-influencer influencers-count"
                    @click="gotoSearch"
                  >
                    <span class="campaigns-icons">{{
                      campaign.influencers
                    }}</span>
                  </div>
                </div>
              </div>

              <div class="campaign-date">
                <span class>{{ campaign.created_at | FullDate(true) }}</span>
              </div>

              <div class="campaign-view">
                <button
                  class="btn btn-black"
                  @click="gotoPipeLine(campaign.id)"
                >
                  View
                </button>
              </div>
            </div>
            <!-- 
                  <div class="campaign-card__menu">
                    <base-dropdown class="nav-item" position="right" :disabled="!hasFullAccess('campaigns')">
                      <base-button :disabled="!hasFullAccess('campaigns')" :disabledTooltip="
                          getPermissionErrorMessage('campaigns:full')
                        " slot="title" role="button" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="hasFullAccess('campaigns') && false" type="transparent" icon="fas fa-ellipsis-v"
                        :iconOnly="true" :rounded="true" />
                      <a v-if="hasFullAccess('campaigns')"
                        class="dropdown-item dropdown-item--primary px-3 py-1 d-flex justify-content-between align-items-center"
                        @click="archiveCampaign(campaign.id)">
                        <span class="mr-1">{{
                          campaign.archived ? "Unarchive" : "Archive"
                        }}</span>
                      </a>
                    </base-dropdown>
                  </div>
            -->
          </div>
        </div>
        <div class="campaign-wrapper px-2 py-2"></div>
      </div>

      <div v-else-if="loading.search" class="d-flex flex-center loading-data">
        <base-spinner></base-spinner>
        <div class="pl-2 mt-2">loading...</div>
      </div>
      <div v-else class="no-data text-center">
        <h3>You have not created any campaigns yet.</h3>
      </div>
    </div>

    <!-- Pagination (it is not used now) -->
    <!-- <div v-if="totalCampaigns / pageSize.value > 1" class="container-fluid mt-2">
      <nav aria-label="Page navigation example">
        <ul class="pagination justify-content-center">
          <li :class="'page-item ' + (pageNumber > 1 ? '' : 'disabled')">
            <a class="page-link" tabindex="-2" @click="pageNumber = 1">
              <i class="fas fa-fast-backward"></i>
            </a>
          </li>
          <li :class="'page-item ' + (pageNumber > 1 ? '' : 'disabled')">
            <a class="page-link" tabindex="-1" @click="pageNumber = pageNumber - 1">
              <i class="fas fa-step-backward"></i>
            </a>
          </li>
          <li class="page-item" v-if="pageNumber > 1">
            <a class="page-link" @click="pageNumber = pageNumber - 1">
              {{
              pageNumber - 1
              }}
            </a>
          </li>
          <li class="page-item active">
            <a class="page-link">{{ pageNumber }}</a>
          </li>
          <li class="page-item" v-if="pageNumber < Math.ceil(totalCampaigns / pageSize.value)">
            <a class="page-link" @click="pageNumber = pageNumber + 1">
              {{
              pageNumber + 1
              }}
            </a>
          </li>
          <li
            :class="
                'page-item ' +
                (pageNumber < Math.ceil(totalCampaigns / pageSize.value)
                  ? ''
                  : 'disabled')
              "
          >
            <a class="page-link" tabindex="1" @click="pageNumber = pageNumber + 1">
              <i class="fas fa-step-forward"></i>
            </a>
          </li>
          <li
            :class="
                'page-item ' +
                (pageNumber < Math.ceil(totalCampaigns / pageSize.value)
                  ? ''
                  : 'disabled')
              "
          >
            <a class="page-link" tabindex="2" @click="pageNumber = Math.ceil(totalCampaigns / pageSize.value)">
              <i class="fas fa-fast-forward"></i>
            </a>
          </li>
        </ul>
      </nav>
    </div>-->
    <filter-bar
      :background-color="filterbarBackground"
      :short-title="configs.site.name"
      :title="configs.site.name"
      class="d-block d-md-none"
    >
      <left-nav slot="filters" />
    </filter-bar>
    <edit-campaign-panel
      :show="showEditCampaign"
      :loading="loading.create"
      :currenciesList="currencies"
      @close="showEditCampaign = false"
      @save="createCampaign"
      :animationDuration="500"
    />

    <div
      class="popover-custom influencers-popover"
      role="tooltip"
      ref="show-influencers-popup"
    >
      <button
        type="button"
        class="close close-influencers-popup"
        @click="closeInfluencersPopup"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="influencers-popover-content">
        <div
          class="influencer-item"
          v-for="(influencer, index) in campaignInfluencers"
          :key="influencer.id"
        >
          <img
            :src="influencer.profilePic"
            :alt="influencer.username"
            class="avatar avatar-sm"
          />
          {{ influencer.fullName }}
        </div>
      </div>
      <div class="popover-arrow" data-popper-arrow></div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import {
  getCampaignROI,
  equalObjects,
  getPermissionErrorMessage
} from "@/utils";
import Configs from "../configs";
import {
  ORDER_BY_ITEMS,
  ORDER_DIRECTIONS,
  PAGE_SIZES,
  COLORS_LIST,
  MESSAGES
} from "@/constants";

import { createPopper } from "@popperjs/core";

/* components */
const EditCampaignPanel = () => import("../components/EditCampaignPanel");

export default {
  components: {
    EditCampaignPanel
  },
  data() {
    return {
      filterbarBackground: "vue", //vue|blue|orange|green|red|primary
      getCampaignROI: getCampaignROI,
      getPermissionErrorMessage: getPermissionErrorMessage,
      configs: Configs,
      showEditCampaign: false,
      pageSizesList: PAGE_SIZES,
      orderByList: ORDER_BY_ITEMS,
      orderDirectionsList: ORDER_DIRECTIONS,
      totalCampaigns: 0,

      campaignsList: [],
      colorsList: COLORS_LIST,

      searchThread: null,
      isQuery: false,

      loading: {
        search: false,
        create: false,
        currencies: false
      },
      platformOffsetY: 0,
      influencersPopupVisible: true,

      // Influencers propover
      campaignsInfluencers: [],
      campaignInfluencers: [],
      popperInstance: undefined
    };
  },
  computed: {
    ...mapState({
      filter: (state) => state.campaign.filter,
      currencies: (state) => state.user.currencies,
      showAlert: (state) => state.alert.showAlert
    }),
    ...mapGetters({
      hasFullAccess: "user/hasFullAccess",
      hasViewAccess: "user/hasViewAccess"
    }),
    query: {
      set(value) {
        this.$store.dispatch("campaign/setQuery", value.trim());
      },
      get() {
        return this.filter.query;
      }
    },
    orderBy: {
      set(value) {
        this.$store.dispatch("campaign/setOrderyBy", value);
      },
      get() {
        return this.filter.order_by;
      }
    },
    orderDirection: {
      set(value) {
        this.$store.dispatch("campaign/setOrderDirection", value);
      },
      get() {
        return this.filter.order_dir;
      }
    },
    pageSize: {
      set(value) {
        this.$store.dispatch("campaign/setPageSize", value);
      },
      get() {
        return this.filter.page_size;
      }
    },
    pageNumber: {
      set(value) {
        this.$store.dispatch("campaign/setPageNumber", value * 1);
      },
      get() {
        return this.filter.page * 1;
      }
    },
    showArchived: {
      set(value) {
        this.$store.dispatch("campaign/setShowArchived", value);
      },
      get() {
        return this.filter.archived;
      }
    }
  },
  watch: {
    query: function () {
      this.isQuery = true;
      this.setURL();
    },
    orderBy: function () {
      this.isQuery = false;
      this.setURL();
    },
    orderDirection: function () {
      this.isQuery = false;
      this.setURL();
    },
    pageSize: function () {
      this.isQuery = false;
      this.setURL();
    },
    showArchived: function () {
      this.isQuery = false;
      this.setURL();
    },
    pageNumber: function () {
      this.isQuery = false;
      this.setURL();
    },
    $route: function () {
      this.setRouter();
    }
  },

  mounted() {
    this.setRouter();
    this.getCurrenciesList();
    this.$filterbar.displayFilterbar(false);

    this.$nextTick(function () {});
  },

  beforeDestroy() {},
  methods: {
    async showInfluencers(event, campaignId) {
      event.stopPropagation()
      const showInfluencersPopup = this.$refs["show-influencers-popup"];

      if (this.popperInstance) this.closeInfluencersPopup();

      const campaignData = await this.$store.dispatch(
        "campaign/getCampaignLeadsPreview",
        {
          campaign_id: campaignId
        }
      );

      this.campaignInfluencers = campaignData.map((c) => {
        return {
          id: c.id,
          username: c.username,
          fullName:
            c.data.full_name || c.data.title || c.data.nickname || c.username,
          profilePic: c.profile_pic
        };
      });

      this.popperInstance = createPopper(event.target, showInfluencersPopup, {
        placement: "top",
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 18]
            }
          }
        ]
      });

      showInfluencersPopup.setAttribute("data-show", "");
      this.$nextTick(function () {
        this.popperInstance.update();
      });
      this.influencersPopupVisible = true;
    },
    closeInfluencersPopup() {
      const showInfluencersPopup = this.$refs["show-influencers-popup"];
      showInfluencersPopup.removeAttribute("data-show");

      if (this.popperInstance) {
        this.popperInstance.destroy();
        this.popperInstance = undefined;
      }

      this.influencersPopupVisible = false;
    },
    filterChanged(milliseconds = 500) {
      this.loading.search = true;
      if (this.searchThread) {
        clearTimeout(this.searchThread);
        this.searchThread = null;
      }
      this.searchThread = setTimeout(() => {
        this.getCampaigns();
      }, milliseconds);
      this.isQuery = false;
    },
    hideFilterbar() {
      if (this.$filterbar.showFilterbar) {
        this.$filterbar.displayFilterbar(false);
      }
    },
    showFilterbar() {
      setTimeout(() => {
        this.$filterbar.displayFilterbar(true);
      }, 0);
    },
    gotoPipeLine(campaignId) {
      this.$router.push("/app/campaigns/" + campaignId + "/pipeline");
    },
    gotoInbox(campaignId) {
      this.$router.push("/app/inbox/");
    },
    gotoSearch() {
      this.$router.push("/app/influencers/");
    },

    setRouter() {
      const indexOfPageSizes = PAGE_SIZES.map((el) => el.value).indexOf(
        this.$route.query.page_size * 1
      );
      const indexOfOrderByItems = ORDER_BY_ITEMS.map((el) => el.value).indexOf(
        this.$route.query.order_by
      );
      const indexOfOrderDirections = ORDER_DIRECTIONS.map(
        (el) => el.value
      ).indexOf(this.$route.query.order_dir);

      this.showArchived = this.$route.query["archived"] ? true : false;

      if (
        indexOfPageSizes < 0 ||
        indexOfOrderByItems < 0 ||
        indexOfOrderByItems < 0
      ) {
        this.setURL();
        return;
      }

      const newFilter = {
        query: this.$route.query.query || this.query,
        page: this.$route.query.page || this.pageNumber,
        order_by:
          ORDER_BY_ITEMS[indexOfOrderByItems < 0 ? 0 : indexOfOrderByItems],
        order_dir:
          ORDER_DIRECTIONS[
            indexOfOrderDirections < 0 ? 0 : indexOfOrderDirections
          ],
        page_size: PAGE_SIZES[indexOfPageSizes < 0 ? 0 : indexOfPageSizes]
      };

      if (equalObjects(newFilter, this.filter)) {
        this.$store.dispatch("campaign/setFilter", newFilter);
      }
      this.filterChanged(this.isQuery ? 1000 : 500);
    },
    setURL() {
      this.$router.replace({
        name: "campaigns",
        query: {
          query: this.query,
          order_by: this.orderBy.value,
          order_dir: this.orderDirection.value,
          page_size: this.pageSize.value,
          archived: this.showArchived ? true : undefined,
          page: this.pageNumber
        }
      });
      // to reload userpilot
      this.$store.dispatch("user/setRoute", this.$route.path);
    },
    setOrderBy(orderByItem) {
      this.orderBy = JSON.parse(JSON.stringify(orderByItem));
    },
    setOrderDirection(orderDirectionItem) {
      this.orderDirection = JSON.parse(JSON.stringify(orderDirectionItem));
    },
    setPageSizeItem(pageSizeItem) {
      this.pageSize = JSON.parse(JSON.stringify(pageSizeItem));
    },
    async archiveCampaign(event, campaignId) {
      event.stopPropagation()
      
      try {
        const index = this.campaignsList.map((e) => e.id).indexOf(campaignId);
        const result = await this.$store.dispatch(
          this.campaignsList[index].archived
            ? "campaign/unarchiveCampaign"
            : "campaign/archiveCampaign",
          {
            id: campaignId
          }
        );
        if (index > -1 && result) {
          this.campaignsList[index].archived =
            !this.campaignsList[index].archived;
          if (this.campaignsList[index].archived && !this.showArchived) {
            this.getCampaigns();
          }
        }
      } catch {
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["user__get_currencies-failed"],
            alertClass: "danger"
          });
        }
      }
    },
    async getCurrenciesList() {
      if (this.currencies.length > 0) return;
      this.loading.currencies = true;
      try {
        await this.$store.dispatch("user/getCurrenciesList");
        this.loading.currencies = false;
      } catch {
        this.loading.currencies = false;
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["user__get_currencies-failed"],
            alertClass: "danger"
          });
        }
      }
    },
    async getCampaigns() {
      this.loading.search = true;
      this.totalCampaigns = 0;
      try {
        const { data } = await this.$store.dispatch("campaign/getCampaigns", {
          order_by: this.orderBy.value,
          order_dir: this.orderDirection.value,
          page_size: this.pageSize.value,
          page: this.pageNumber,
          query: this.query,
          archived: this.showArchived
        });
        this.campaignsList = data.campaigns;
        let campaignInfluencersPics = data.profile_pics;
        this.totalCampaigns = data.total;
        this.campaignsList.forEach((campaign) => {
          campaign.imv = (campaign.imv_coef * campaign.impressions) / 1000;

          let influencersPic = campaignInfluencersPics[campaign.id];
          if (influencersPic) campaign.profileImages = influencersPic;
        });
        this.loading.search = false;
      } catch (err) {
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["campaign__get_campaigns-failed"],
            alertClass: "danger"
          });
        }
        this.loading.search = false;
      }

      if (
        this.totalCampaigns &&
        Math.ceil(this.totalCampaigns / this.pageSize.value) < this.pageNumber
      ) {
        this.pageNumber = 1;
      }
    },

    async createCampaign(campaign) {
      this.loading.create = true;
      try {
        const result = await this.$store.dispatch(
          "campaign/createCampaign",
          campaign
        );
        this.loading.create = false;
        this.showEditCampaign = false;
        if (result) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["campaign__create_campaign-success"],
            alertClass: "success"
          });
          this.getCampaigns();
        }
      } catch {
        this.loading.create = false;
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["campaign__create_campaign-failed"],
            alertClass: "danger"
          });
        }
      }
    }
  }
};
</script>
<style lang="scss">
@import "../assets/scss/pages/campaigns";
</style>
