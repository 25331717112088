<template>
  <div
    class="tab-pane fade set-team-panel show"
    :class="[{ 'modal-mini': type === 'mini' }]"
    v-bind:id="tabId"
  >
    <div class="main-content">
      <!-- Old way for adding users -->
      <div class="w-100 text-center mb-3" v-if="false">
        <a v-if="hasFullAccess('team')" class="btn-link" @click="inviteTeam"
          >Invite new team member...</a
        >
      </div>

      <!-- Create sub-user form -->
      <form
        class="invite-user-form"
        @submit.prevent="submitSubUser"
        v-if="hasFullAccess('team')"
      >
        <div class="panel-blocks">
          <div class="panel-item">
            <div class="mb-2 setting-label mt-1">
              <span class="nav-link-text">To add a new team member</span>
            </div>
            <div class="sub-user-input-group">
              <div class="sub-user-input">
                <base-input
                  id="new_user_email"
                  class="input-group-alternative mb-3"
                  placeholder="Type an email"
                  v-model="newUser.email"
                  type="email"
                  autofocus
                ></base-input>
              </div>
              <div class="sub-user-action"></div>
            </div>
          </div>
          <div class="save-block">
            <!-- <div class="sub-user-input">
                <base-input
                  id="new_user_password"
                  class="input-group-alternative mb-3"
                  placeholder="Choose a password"
                  v-model="newUser.password"
                  type="password"
                  autofocus
                ></base-input>
              </div> -->
            <div class="sub-user-action">
              <base-button
                :isBusy="loading.submit"
                type="primary"
                nativeType="submit"
                :class="'btn-black'"
              >
                <i class="fas fa-user-plus"></i> Invite user
              </base-button>
            </div>
          </div>
        </div>
      </form>
      <!-- New list of sub-users -->
      <div class="sub-users-list">
        <div class="mb-2 setting-label mt-1">
          <span class="nav-link-text">Your team</span>
        </div>
        <ul class="action-items-list" v-if="allSubUsers.length">
          <li
            class="action-list-item"
            v-for="(subUser, index) in allSubUsers"
            :key="index"
          >
            <div class="action-item-card">
              <span class="material-icons"> person </span>
              {{ subUser.email }}
              <!-- <div class="subuser-email">
              <div class="mb-2 setting-label mt-1">
                <span class="nav-link-text">Active team member</span>
              </div>
            </div>
            <div class="subuser-permission">
              <div class="mb-2 setting-label mt-1">
                <span class="nav-link-text">Permission</span>
              </div>
            </div>
            <div class="subuser-action"></div> -->
            </div>
            <div class="action-item-actions" v-if="hasFullAccess('team')">
              <div class="subuser-permission">
                <!-- {{subUser.role}} -->
                <!-- :value="subUser.role" -->
                <multiselect
                  v-model="userRoles[index].role"
                  placeholder="Permissions"
                  :searchable="false"
                  :show-labels="true"
                  selectLabel=" "
                  selectedLabel=" "
                  deselectLabel=" "
                  :options="ACCOUNT_ROLES"
                  @input="changeUserRole(userRoles[index])"
                />
              </div>
              <div class="subuser-action">
                <base-button
                  :disabled="loading.del"
                  @click="deleteSubUser(subUser)"
                  :class="'btn-light'"
                >
                  <i class="fas fa-trash"></i>
                </base-button>
              </div>
              <!-- <div class="subuser-item panel-item">
              <div class="subuser-email">
                <base-input class="input-group-alternative mb-3" placeholder="Type an email" type="text" autofocus></base-input>
              </div>
              <div class="subuser-permission">
                <multiselect
                  placeholder="Permissions"
                  :searchable="false"
                  :allow-empty="false"
                  :hide-selected="true"
                  selectLabel
                  selectedLabel="?"
                  deselectLabel="?"
                  label="label"
                  track-by="id"
                  :options="ACCOUNT_ROLES"
                />
              </div>
              <div class="subuser-action">
                <base-button :isBusy="loading.submit" type="primary" nativeType="submit" :class="'btn-light btn-icon'">
                  <i class="fas fa-trash"></i>
                </base-button>
              </div>
            </div>
            <div class="subuser-item panel-item">
              <div class="subuser-email">
                <base-input class="input-group-alternative mb-3" placeholder="Type an email" type="text" autofocus></base-input>
              </div>
              <div class="subuser-permission">
                <multiselect
                  placeholder="Permissions"
                  :searchable="false"
                  :allow-empty="false"
                  :hide-selected="true"
                  selectLabel
                  selectedLabel="?"
                  deselectLabel="?"
                  label="label"
                  track-by="id"
                  :options="ACCOUNT_ROLES"
                />
              </div>
              <div class="subuser-action">
                <base-button :isBusy="loading.submit" type="primary" nativeType="submit" :class="'btn-light btn-icon'">
                  <i class="fas fa-trash"></i>
                </base-button>
              </div>
            </div> -->
            </div>
          </li>
        </ul>
        <div v-else class="text-center mt-5">
          You don't have any team members yet!
        </div>
      </div>
      <!-- List of sub-users -->
      <template v-if="activeSubUsers && activeSubUsers.length && false">
        <div class="setting-label mt-3 px-3">ACTIVE TEAM MEMBERS</div>
        <ul class="main-nav">
          <li
            v-for="(subUser, index) in activeSubUsers"
            :key="index"
            class="p-3 my-1 flex-middle-between flex-wrap"
          >
            <div class="flex-middle flex-wrap">
              <div
                class="status-icon"
                :class="subUser.accepted_at ? 'active' : 'pending'"
              >
                <i class="fas fa-user-alt"></i>
              </div>
              <div class="user-email ml-3">
                {{ subUser.email }}
              </div>
            </div>
            <div class="flex-middle ml-auto">
              <base-button
                v-if="hasFullAccess('team')"
                type="primary"
                class="w-100 flex-middle"
                :disabled="loading.del"
                v-b-tooltip.hover
                title="Update permissions"
                @click="editSubUser(subUser)"
                icon="fas fa-user-edit"
              >
              </base-button>
              <base-button
                v-if="hasFullAccess('team')"
                type="danger"
                class="w-100 flex-middle"
                :disabled="loading.del"
                v-b-tooltip.hover
                title="Remove access"
                @click="deleteSubUser(subUser)"
                :isBusy="loading.del"
              >
                <i class="fa fa-trash" aria-hidden="true"></i>
              </base-button>
            </div>
          </li>
        </ul>
      </template>
      <template v-if="pendingSubUsers && pendingSubUsers.length && false">
        <div class="setting-label mt-3 px-4">PENDING INVITES</div>
        <ul class="main-nav">
          <li
            v-for="(subUser, index) in pendingSubUsers"
            :key="index"
            class="p-3 my-1 flex-middle-between flex-wrap"
          >
            <div class="flex-middle flex-wrap">
              <div class="status-icon pending">
                <i class="fas fa-user-clock"></i>
              </div>
              <div class="user-email ml-3">
                {{ subUser.email }}
              </div>
            </div>
            <div class="flex-middle ml-auto">
              <base-button
                v-if="hasFullAccess('team')"
                type="primary"
                class="w-100 flex-middle"
                :disabled="loading.del"
                v-b-tooltip.hover
                title="Update permissions"
                @click="editSubUser(subUser)"
                icon="fas fa-user-edit"
              >
              </base-button>
              <base-button
                v-if="hasFullAccess('team')"
                type="danger"
                class="w-100 flex-middle"
                :disabled="loading.del"
                v-b-tooltip.hover
                title="Remove access"
                @click="deleteSubUser(subUser)"
                :isBusy="loading.del"
              >
                <i class="fa fa-trash" aria-hidden="true"></i>
              </base-button>
            </div>
          </li>
        </ul>
      </template>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import { getPermissionErrorMessage } from "@/utils";

import Multiselect from "vue-multiselect";

import { MESSAGES, ACCOUNT_ROLES } from "../constants";

export default {
  name: "set-team-panel",
  components: {
    Multiselect
  },
  props: {
    show: Boolean,
    type: {
      type: String,
      default: "",
      validator(value) {
        let acceptedValues = ["", "notice", "mini"];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Modal type (notice|mini|"") '
    },
    animationDuration: {
      type: Number,
      default: 500,
      description: "Modal transition duration"
    },
    tabId: String
  },
  computed: {
    ...mapState({
      allSubUsers: (state) => state.user.subUsers,
      activeSubUsers: (state) =>
        (state.user.subUsers || []).filter((e) => !!e.accepted_at),
      pendingSubUsers: (state) =>
        (state.user.subUsers || []).filter((e) => !e.accepted_at),
      authUser: (state) => state.user.authUser,
      showAlert: (state) => state.alert.showAlert
    }),
    ...mapGetters({
      hasFullAccess: "user/hasFullAccess"
    })
  },
  data() {
    return {
      ACCOUNT_ROLES: ACCOUNT_ROLES,
      getPermissionErrorMessage: getPermissionErrorMessage,
      loading: {
        get: false,
        del: false,
        permission: false,
        addUser: false,
        updateUser: false
      },
      currentId: null,
      newUser: {
        email: "",
        password: "",
        scopes: {}
      },
      localError: false,
      userRoles: []
    };
  },
  mounted() {
    this.$nextTick(function () {
      this.getSubUsers();
    });
  },
  methods: {
    closePanel() {
      /* slide out panel */
      document.querySelector(
        ".set-team-panel .modal-dialog"
      ).style.right = `-100%`;
      /* reset panel */
      setTimeout(() => {
        this.$emit("update:show", false);
        this.$emit("close");
      }, this.animationDuration / 2);
    },
    inviteTeam() {
      this.$emit("inviteTeamMember");
    },
    editSubUser(subUser) {
      this.$emit("editTeamMember", subUser);
    },

    async getSubUsers() {
      this.loading.get = true;
      try {
        await this.$store.dispatch("user/getSubUsers");
        this.loading.get = false;
      } catch {
        this.loading.get = false;
        this.localError = MESSAGES["common-500"];
      }
    },

    async deleteSubUser(subUser) {
      if (!this.hasFullAccess("team")) {
        this.$store.dispatch("setAlert", {
          showAlert: true,
          content: this.getPermissionErrorMessage("team:full"),
          alertClass: "danger"
        });
        return;
      }

      this.loading.del = true;
      try {
        await this.$store.dispatch("user/deleteSubUser", subUser.id);
        this.loading.del = false;
      } catch {
        this.loading.del = false;
        this.localError = MESSAGES["common-500"];
      }
    },

    async saveSubUsers() {},

    async submitSubUser() {
      if (!this.hasFullAccess("team")) {
        this.$store.dispatch("setAlert", {
          showAlert: true,
          content: this.getPermissionErrorMessage("team:full"),
          alertClass: "danger"
        });
        return;
      }

      this.loading.addUser = true;
      try {
        await this.$store.dispatch("user/inviteSubUser", {
          email: this.newUser.email,
          role: "Viewer"
        });

        this.$store.dispatch("setAlert", {
          showAlert: true,
          content: MESSAGES["user__team-invite-success"],
          alertClass: "success"
        });
        this.newUser.email = "";
        this.newUser.password = "";
        this.loading.addUser = false;
      } catch {
        this.loading.addUser = false;
        this.localError = MESSAGES["common-500"];
      }
    },
    async changeUserRole(subuserInfo) {
      try {
        this.loading.updateUser = true;
        await this.$store.dispatch("user/updateSubUserNew", {
          id: subuserInfo.id,
          email: subuserInfo.email,
          role: subuserInfo.role
        });

        this.$store.dispatch("setAlert", {
          showAlert: true,
          content: MESSAGES["user__team-update-success"],
          alertClass: "success"
        });

        this.loading.updateUser = false;
      } finally {
        this.loading.updateUser = false;
      }
    }
  },

  watch: {
    show(val) {
      const documentClasses = document.body.classList;
      if (val) {
        /* reset panel */
        documentClasses.remove("modal-open");
        document.querySelector(".set-team-panel").scrollTop = 0;
      }
    },
    allSubUsers(val) {
      this.userRoles = val.map((x) => {
        return {
          id: x.id,
          email: x.email,
          role: x.role
        };
      });
    }
  }
};
</script>
<style lang="scss">
.invite-user-form {
  margin-top: 10px;
  margin-bottom: 20px;
}

.subuser-permission {
  width: 150px;
  padding-right: 10px;
}

.modal.show {
  background-color: rgba(255, 255, 255, 0.7);
}
</style>
