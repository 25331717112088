<template>
  <div
    class="
      modal
      set-integrations-add-panel set-integrations-add-panel-values2
      right-sliding-panel right-sliding-panel-xs
    "
    :class="[
      { 'show d-block': show },
      { 'd-none': !show },
      { 'modal-mini': type === 'mini' }
    ]"
    v-show="show"
    tabindex="-1"
    role="dialog"
    :aria-hidden="!show"
  >
    <div class="modal-dialog mb-0">
      <div class="modal-content">
        <modal-topbar>
          <template v-if="!currentIntegrationId">
            <a slot="left" @click="closePanel">
              <i class="fas fa-chevron-left mr-1"></i>
              <span>Settings</span>
            </a>
            <div slot="center">Add new integration</div>
          </template>
          <template v-else>
            <a slot="left" @click="closePanel()">
              <i class="fas fa-chevron-left mr-1"></i>
              <span>Back</span>
            </a>
            <div slot="center">
              {{ availableIntegrations[currentIntegrationId].name }}
            </div>
          </template>
        </modal-topbar>

        <div v-if="currentIntegrationId" class="modal-body">
          <div class="d-flex align-items-baseline mt-5">
            <div class="card-icon">
              <img :src="availableIntegrations[currentIntegrationId].image" />
            </div>
            <h2
              class="ml-3"
              :id="availableIntegrations[currentIntegrationId].id"
            >
              {{ availableIntegrations[currentIntegrationId].name }}
            </h2>
          </div>
          <p class="mt-3">
            <ol>
              <li>Go to your Shopify store</li>
              <li>Go to Menu -> apps -> Develop apps</li>
              <li>Create an app</li>
              <li>Give the app suitable name + choose the developer for this app from the drop down</li>
              <li>Go to "Configuration" tab -> under "Admin API integration" press on "Edit" </li>
              <li>There search for the following permission and check box the following for each permission: <br/>
                Orders-> <strong>"Read_orders"</strong> permission<br/>
                Discounts -> <strong>"Read_discounts"</strong> permission<br/>
                then press "Save".
              </li>
              <li>Press "Install app" from the right top side button</li>
              <li>Go to "API credentials" tab(on Shopify) -> copy "API secret key" (under "API key and secret key" -> to the "API secret key" field on NinjaInfluence/Settings.</li>
              <li>Copy "Admin API access token" to the the field -> NinjaInfluence/Settings -> "Admin API access token"</li>
              <li>Copy the full Shopify URL (the one you are in at the moment) to the field- >"Example URL" under NinjaInfluence/Settings.</li>
              <li>Press "Connect" on Ninja settings.</li>
            </ol>
          </p>
          <ul class="panel-blocks">
            <li class="panel-item mt-3">
              <div class="mb-2 setting-label">
                <span class="nav-link-text"> Example URL </span>
              </div>
              <base-input
                :id="'integration_url_input_' + currentIntegrationId"
                class="input-group-alternative mb-4"
                placeholder="https://..."
                :valid="!urlHasError"
                v-on:keydown="urlHasError = false"
                v-model="currentUrl"
                v-on:keyup.enter="connect()"
                type="text"
                autocomplete="shopify-url"
              >
              </base-input>
            </li>
            <li class="panel-item mt-3">
              <div class="mb-2 setting-label">
                <span class="nav-link-text">Admin API access token</span>
              </div>
              <base-input
                placeholder="shpss_..."
                :valid="!tokenHasError"
                v-model="token"
                v-on:keydown="tokenHasError = false"
                v-on:keyup.enter="connect()"
                type="text"
                autocomplete="shopify-token"
              ></base-input>
            </li>
            <li class="panel-item mt-3">
              <div class="mb-2 setting-label">
                <span class="nav-link-text">API secret key</span>
              </div>
              <base-input
                placeholder="b8c15..."
                :valid="!apiSecretHasError"
                v-model="apiSecret"
                v-on:keydown="apiSecretHasError = false"
                v-on:keyup.enter="connect()"
                type="text"
                autocomplete="shopify-api-secret"
              ></base-input>
            </li>
          </ul>
          <div class="flex-center my-4">
            <base-button
              v-if="!currentIntegrationId"
              type="primary"
              class="btn-w-25"
              v-on:click="closePanel"
            >
              Close
            </base-button>
            <base-button
              v-else
              type="primary"
              class="btn-w-25"
              :disabled="loading.connect || loading.request"
              @click="connect()"
            >
              Connect
            </base-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* the user inputs some values (only Shopify) */

import { AVAILABLE_INTEGRATIONS, MESSAGES } from "@/constants";
import { mapState } from "vuex";
import configs from "../configs";

export default {
  name: "set-integrations-add-panel-values2",
  components: {},
  props: {
    currenciesList: {
      type: Array
    },
    show: Boolean,
    type: {
      type: String,
      default: "",
      validator(value) {
        let acceptedValues = ["", "notice", "mini"];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Modal type (notice|mini|"") '
    },
    shop: {
      type: String,
      default: "",
      description: "Shopify store"
    },
    state: {
      type: String,
      default: "",
      description: "state"
    },
    selectedIntegrationId: {
      type: String
    },
    animationDuration: {
      type: Number,
      default: 500,
      description: "Modal transition duration"
    }
  },
  data() {
    return {
      availableIntegrations: AVAILABLE_INTEGRATIONS,
      loading: {
        request: false,
        connect: false
      },
      currentIntegrationId: null,
      currentUrl: "",
      currentProtocol: "",
      currentDomain: "",
      apiSecret: "", // for shopify
      token: "", // for shopify
      urlHasError: false,
      apiSecretHasError: false, // for shopify
      tokenHasError: false, // for shopify
      localError: false
    };
  },
  computed: {
    ...mapState({
      authUser: (state) => state.user.authUser,
      showAlert: (state) => state.alert.showAlert
    })
  },
  methods: {
    closePanel() {
      /* slide out panel */
      document.querySelector(
        ".set-integrations-add-panel-values2 .modal-dialog"
      ).style.right = `-100%`;
      /* reset panel */
      setTimeout(() => {
        this.$emit("update:show", false);
        this.$emit("close");
      }, this.animationDuration / 2);
    },
    selectIntegration(id) {
      this.currentIntegrationId = id;
      setTimeout(() => {
        document.getElementById("integration_url_input_" + id).focus();
      }, 200);
    },
    redirectToSite(data) {
      let url, baseUrl;
      this.loading.connect = true;
      baseUrl = `${this.currentUrl}${
        this.currentDomain ? "." + this.currentDomain : ""
      }`;
      url = `https://${baseUrl}/admin/oauth/authorize?client_id=${configs.services.shopify.client_id}&redirect_uri=${configs.site.url}/api/webhooks/shopify/simple_connect&response_type=code&scope=read_orders,read_discounts&state=${data.nonce}`;
      const win = window.open(url, "_blank");
      if (win == null || typeof win == "undefined") {
        // Turn off the pop-up blocker
        this.$store.dispatch("setAlert", {
          showAlert: true,
          content: MESSAGES["user__store_popup-failed"],
          alertClass: "danger"
        });
      } else {
        win.focus();
      }
    },
    async connect() {
      if (!this.currentUrl.trim()) {
        this.urlHasError = true;
        return;
      }
      if (!this.apiSecret.trim()) {
        this.apiSecretHasError = true;
        return;
      }
      this.currentDomain = this.currentDomain.trim();
      this.loading.request = true;

      try {
        if (this.shop && this.state) {
          const result = await this.$store.dispatch(
            "integration/checkShopifyIntegration",
            {
              shop: this.shop,
              state: this.state
            }
          );
          if (result) {
            this.loading.request = false;
            this.$emit("close");
            return;
          }
        }
        const { data } = await this.$store.dispatch(
          "integration/integrateShopify",
          {
            example_url: this.currentUrl,
            api_secret: this.apiSecret,
            token: this.token
          }
        );
        this.loading.request = false;
        if (data) {
          this.$emit("close");
        }
      } catch (error) {
        this.loading.request = false;
        if (!this.showAlert) {
          /* need to upate the message text */
          if (error.response.status === 422) {
            this.$store.dispatch("setAlert", {
              showAlert: true,
              content: error.response.statusText,
              alertClass: "danger"
            });
          } else {
            this.$store.dispatch("setAlert", {
              showAlert: true,
              content: MESSAGES["common-500"],
              alertClass: "danger"
            });
          }
        }
      }
    }
  },
  watch: {
    show(val) {
      const documentClasses = document.body.classList;
      if (val) {
        this.localError = false;
        this.currentIntegrationId = this.selectedIntegrationId;
        this.currentUrl = this.shop ? this.shop : "";
        this.currentProtocol = "https://";
        if (this.currentIntegrationId) {
          this.currentDomain = AVAILABLE_INTEGRATIONS.shopify.domains.length
            ? AVAILABLE_INTEGRATIONS.shopify.domains[0]
            : "";
        }
        this.loading.request = false;
        this.loading.connect = false;
        this.urlHasError = false;

        /* slide in panel*/
        documentClasses.add("modal-open");
        setTimeout(() => {
          document.querySelector(
            ".set-integrations-add-panel-values2 .modal-dialog"
          ).style.right = `0px`;
        }, 0);
      } else {
        /* reset panel */
        documentClasses.remove("modal-open");
        document.querySelector(
          ".set-integrations-add-panel-values2"
        ).scrollTop = 0;
      }
    },
    currentUrl() {
      this.urlHasError = false;
      this.loading.connect = false;
      this.loading.request = false;
      if (this.currentDomain) {
        this.currentUrl = this.currentUrl
          .replace(/(^\w+:|^)\/\//, "")
          .replace(new RegExp(`.${this.currentDomain}$`, "g"), "");
      }
    }
  }
};
</script>
<style>
.modal.show {
  background-color: rgba(255, 255, 255, 0.7);
}
</style>
