<template>
  <div
    class="modal inbox-send-email-panel right-sliding-panel"
    :class="[
      { 'show d-block': show },
      { 'd-none': !show },
      { 'modal-mini': type === 'mini' },
    ]"
    v-show="show"
    tabindex="-1"
    role="dialog"
    :aria-hidden="!show"
  >
    <div class="modal-dialog mb-0">
      <div class="modal-content">
        <modal-topbar>
          <a slot="left" @click="closePanel">
            <i class="fas fa-chevron-left mr-1"></i>
            <span v-if="lead">Back</span>
          </a>
          <div slot="center">{{ threadId ? "Reply" : "New conversation" }}</div>
        </modal-topbar>

        <div v-if="lead" class="modal-body">
          <div class="mt-5 flex-middle tags">
            <label class="mr-2 mb-0">To</label>
            <multiselect
              v-if="lead"
              placeholder="Input influencer email(s)"
              v-model="to"
              :multiple="true"
              :taggable="true"
              track-by="address"
              label="address"
              :options="toOptions"
              :disabled="isReply"
              @tag="addTOOption"
              tagPlaceholder="Press enter to select"
            />
          </div>
          <div class="mt-2 flex-middle tags">
            <label class="mr-2 mb-0">Cc</label>
            <multiselect
              v-if="lead"
              placeholder="Input Cc email(s)"
              v-model="cc"
              :multiple="true"
              :taggable="true"
              track-by="address"
              label="address"
              :options="ccOptions"
              @tag="addCCOption"
              tagPlaceholder="Press enter to select"
            />
          </div>
          <div class="mt-2 flex-middle">
            <label class="mr-2 mb-0">Subject</label>
            <base-input class="mb-0 w-100" placeholder="No subject" v-model="subject"></base-input>
          </div>
          <div class="mt-2 flex-middle user-email">
            <label class="mr-2 mb-0">From</label>
            <multiselect
              v-if="fromOptions"
              placeholder="Select your email"
              v-model="from"
              label="address"
              :allow-empty="false"
              :hide-selected="true"
              :disabled="isReply"
              track-by="address"
              :multiple="false"
              :options="fromOptions"
            >
              <template slot="singleLabel" slot-scope="props"
                ><img
                  class="icon-size16 mr-2"
                  :src="availableEmails[props.option.name].image"
                  :alt="availableEmails[props.option.name].name"
                /><span class="option__desc"
                  ><span class="option__title">{{
                    props.option.address
                  }}</span></span
                ></template
              >
              <template slot="option" slot-scope="props">
                <div class="d-flex">
                  <img
                    class="icon-size16 mr-2"
                    :src="availableEmails[props.option.name].image"
                    :alt="availableEmails[props.option.name].name"
                  />
                  <div class="option__desc">
                    <span class="option__title">{{
                      props.option.address
                    }}</span>
                  </div>
                </div>
              </template>
            </multiselect>
          </div>
          <div class="mt-2 w-100">
            <quill-editor
              class="mt-3"
              ref="myQuillEditor"
              v-model="content"
              :option="editorOption"
            ></quill-editor>
          </div>
          <div class="flex-center mt-3">
            <div class="flex-center">
              <base-button
                :disabled="!hasFullAccess('emails')"
                :disabledTooltip="getPermissionErrorMessage('emails:full')"
                class="btn-send btn-w-25 flex-middle-center"
                type="primary"
                @click="send"
                :isBusy="loading.send"
              >

                {{ isReply ? "Reply" : "Send" }}
              </base-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { MESSAGES, AVAILABLE_EMAILS } from "@/constants";
import { getPermissionErrorMessage } from "@/utils";
import { mapState, mapGetters } from "vuex";

/* components */
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import Multiselect from "vue-multiselect";

export default {
  name: "inbox-send-email-panel",
  components: {
    quillEditor,
    Multiselect,
  },
  props: {
    show: Boolean,
    type: {
      type: String,
      default: "",
      validator(value) {
        let acceptedValues = ["", "notice", "mini"];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Modal type (notice|mini|"") ',
    },
    animationDuration: {
      type: Number,
      default: 500,
      description: "Modal transition duration",
    },
    editorOption: {
      theme: "snow",
      placeholder: "Your new placeholder!",
      modules: {},
    },
  },
  data() {
    return {
      getPermissionErrorMessage: getPermissionErrorMessage,
      from: null,
      to: [],
      cc: [],
      subject: "",
      content: "",
      toOptions: [],
      ccOptions: [],
      isReply: false,

      threadId: null,
      conversationIndex: null,
      messageId: null,

      availableEmails: AVAILABLE_EMAILS,

      loading: {
        send: false,
      },
    };
  },
  computed: {
    ...mapState({
      lead: (state) => state.inbox.currentLead,
      threads: (state) => state.inbox.messages,
      emails: (state) => state.integration.emails,
      fromOptions: (state) => {
        if (!state.integration.emails) return [];
        return [
          ...(state.integration.emails.outlook
            ? state.integration.emails.outlook.map((e) => {
                return { address: e.address, name: "outlook" };
              })
            : []),
          ...(state.integration.emails.gmail
            ? state.integration.emails.gmail.map((e) => {
                return { address: e.address, name: "gmail" };
              })
            : []),
        ];
      },
      conversation: (state) => state.inbox.conversation,
      showAlert: (state) => state.alert.showAlert,
    }),
    ...mapGetters({
      hasFullAccess: 'user/hasFullAccess',
      hasViewAccess: 'user/hasViewAccess',
    }),
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
  },
  methods: {
    closePanel() {
      /* slide out panel */
      document.querySelector(
        ".inbox-send-email-panel .modal-dialog"
      ).style.right = `-100%`;
      /* reset panel */
      setTimeout(() => {
        this.$emit("update:show", false);
        this.$emit("close");
      }, this.animationDuration / 2);
    },
    onEditorChange({ html }) {
      this.content = html;
    },
    addTOOption(newEmail) {
      this.toOptions.push({ address: newEmail, name: newEmail });
      this.to.push({ address: newEmail, name: newEmail });
    },
    addCCOption(newEmail) {
      this.ccOptions.push({ address: newEmail, name: newEmail });
      this.cc.push({ address: newEmail, name: newEmail });
    },
    async send() {
      this.$set(this.loading, "send", true);
      try {
        let result;
        if (this.isReply) {
          result = await this.$store.dispatch("inbox/replyEmail", {
            name: this.from.name,
            address: this.from.address,
            message_id: this.messageId,
            subject: this.subject,
            lead_id: this.lead.id,
            reply_meta: {
              body: this.content,
              to_recipients: this.to,
              cc_recipients: this.cc,
            },
          });
        } else {
          result = await this.$store.dispatch("inbox/sendEmail", {
            name: this.from.name,
            address: this.from.address,
            lead_id: this.lead.id,
            send_meta: {
              subject: this.subject,
              body: this.content,
              to_recipients: this.to,
              cc_recipients: this.cc,
            },
          });
        }
        this.$set(this.loading, "send", false);

        // Clean up the form
        this.subject = "";
        this.content = "";

        // update threads list
        if (result && result.data && result.data.sent && result.data.message) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["email__send_message-success"],
            alertClass: "success",
          });
          this.$store.dispatch("inbox/setMessage", {
            reply_id: this.isReply ? this.messageId : null,
            message: result.data.message,
          });
          this.closePanel();
        }
      } catch (error) {
        this.$set(this.loading, "send", false);
        if (
          error.response.status === 409 &&
          error.response.data.hasOwnProperty("user_message_id")
        ) {
          const email = error.response.data
            ? error.response.data.metadata
            : null;
          const messageId = error.response.data.user_message_id;
          if (messageId && messageId[1] === "email__invalid-auth") {
            this.$emit("invalidEmailToken", email);
          } else if (!this.showAlert) {
            const message = (
              MESSAGES[messageId && messageId[1] ? messageId[1] : messageId] ||
              MESSAGES["common-500"]
            ).replace(
              "{{email_provider}}",
              email && email.name
                ? AVAILABLE_EMAILS[email.name].name
                : "The email provider"
            );
            this.$store.dispatch("setAlert", {
              showAlert: true,
              content: message,
              alertClass: "danger",
            });
          }
        } else if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["common-500"],
            alertClass: "danger",
          });
        }
      }
    },
  },

  watch: {
    show(val) {
      const documentClasses = document.body.classList;
      this.editor.root.setAttribute("data-placeholder", "");
      if (val) {
        const {
          from,
          to,
          subject,
          thread_id,
          conversation_index,
          is_reply,
        } = this.conversation;
        this.isReply = is_reply;
        this.from = from;
        this.to = JSON.parse(JSON.stringify(to));
        this.subject = subject;
        this.toOptions = this.lead.data.emails
          ? this.lead.data.emails.map((e) => {
              return { address: e, name: e };
            })
          : [];
        this.ccOptions = this.lead.data.emails
          ? this.lead.data.emails.map((e) => {
              return { address: e, name: e };
            })
          : [];
        this.threadId = thread_id;
        this.conversationIndex = conversation_index;
        this.content = "";
        if (this.threadId) {
          this.subject = "Re: " + this.subject;
          this.messageId = this.threadId;
          // need to update content like mailbox
          if (this.conversationIndex) {
            this.content = `<br><blockquote>${
              this.threads[this.threadId][this.conversationIndex].body
            }</blockquote>`;
            this.messageId = this.threads[this.threadId][
              this.conversationIndex
            ].id;
          }
        }

        /* slide in panel*/
        documentClasses.add("modal-open");
        setTimeout(() => {
          document.querySelector(
            ".inbox-send-email-panel .modal-dialog"
          ).style.right = `0px`;
          if (
            document.querySelector(".inbox-send-zemail-panel button.ql-image")
          ) {
            document.querySelector(
              ".inbox-send-email-panel button.ql-image"
            ).style.display = "none";
            document.querySelector(
              ".inbox-send-email-panel button.ql-video"
            ).style.display = "none";
          }
        }, 0);
      } else {
        /* reset panel */
        documentClasses.remove("modal-open");
        document.querySelector(".inbox-send-email-panel").scrollTop = 0;
      }
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.modal.show {
  background-color: rgba(255, 255, 255, 0.7);
}
.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select {
  background: transparent !important;
}
</style>
