<template>
  <div
    class="modal edit-campaign-panel right-sliding-panel right-sliding-panel-xs"
    :class="[
      { 'show d-block': show },
      { 'd-none': !show },
      { 'modal-mini': type === 'mini' }
    ]"
    v-show="show"
    tabindex="-1"
    role="dialog"
    :aria-hidden="!show"
  >
    <div class="modal-dialog mb-0">
      <div class="modal-content">
        <modal-topbar>
          <a slot="left" @click="closePanel">
            <i class="fas fa-chevron-left mr-1"></i>
            <span>Back</span>
          </a>
          <div slot="center">
            {{ campaign ? "Edit properties" : "Create campaign" }}
          </div>
        </modal-topbar>

        <div class="modal-body">
          <ul class="panel-blocks mt-5">
            <li class="panel-item">
              <div class="mb-2 campaign-label mt-1">
                <span class=" nav-link-text">Campaign Name</span>
              </div>
              <base-input
                id="input_campaign_name"
                class="input-group-alternative mb-3"
                :class="!isValidCampaignName && localError ? 'error' : ''"
                placeholder="Campaign Name"
                v-model="campaignName"
                type="text"
                autofocus
              ></base-input>
            </li>
            <li class="panel-item">
              <div class="mb-2 campaign-label">
                <span class="nav-link-text">Budget</span>
                <span class="ml-2" v-if="campaign && currency">({{ this.currency.code }})</span>
              </div>
              <base-input
                class="input-group-alternative mb-3"
                :class="!isValidBudget && localError ? 'error' : ''"
                placeholder="Budget"
                v-model="budget"
                type="number"
              ></base-input>
            </li>
            <li class="panel-item" v-if="campaign">
              <div class="mb-2 campaign-label">
                <span class=" nav-link-tex">IMV coefficient</span>
              </div>
              <p>
                The IMV (Impressions Money Value) is the monetary value
                associated to 1,000 impressions of a campaign. This varies from
                industry to industry and also depends on what you promote.
              </p>
              <base-input
                class="input-group-alternative mb-3"
                :class="!isValidImvCoef && localError ? 'error' : ''"
                placeholder="IMV coefficient"
                v-model="imvCoef"
                type="number"
              ></base-input>
            </li>
            <li v-if="!campaign" class="panel-item">
              <div class="mb-2 campaign-label ">
                <span class="nav-link-text">Currency</span>
              </div>
              <div class="select-container d-flex">
                <multiselect
                  v-if="currenciesListForDropdown"
                  placeholder="Currency"
                  :searchable="true"
                  selectLabel
                  deselectLabel
                  selectedLabel
                  :allow-empty="false"
                  label="description"
                  track-by="code"
                  :hide-selected="true"
                  v-model="currency"
                  :options="currenciesListForDropdown"
                  :class="!isValidCurrency && localError ? 'error' : ''"
                />
              </div>
            </li>
            <li class="panel-item mt-3">
              <div class="mb-2">
                <span class="campaign-label nav-link-text">Color</span>
              </div>
              <div class="colors-container">
                <div
                  v-for="colorItem in colorsList"
                  :style="{ backgroundColor: colorItem.value }"
                  :key="colorItem.id"
                  class="color-option m-1 flex-middle-center"
                  @click="color = colorItem.id"
                >
                  <div
                    v-if="colorItem.id === color"
                    class="color-selected"
                  ></div>
                </div>
              </div>
            </li>
          </ul>
          <div class="flex-center mt-4">
            <base-button
              type="primary"
              :class="'input-group-alternative' + loading ? ' ' : ' disabled'"
              @click="saveCampaign(true)"
            >
              <span v-if="campaign">Save campaign</span>
              <span v-else>Create campaign</span>
            </base-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { COLORS_LIST } from "@/constants";
import { mapState } from "vuex";
import Multiselect from "vue-multiselect";

export default {
  name: "edit-campaign-panel",
  components: {
    Multiselect
  },
  data() {
    return {
      budget: 0,
      campaignName: "",
      currency: null,
      color: null,
      imvCoef: 0,
      colorsList: COLORS_LIST,
      localError: false
    };
  },
  props: {
    show: Boolean,
    loading: Boolean,
    type: {
      type: String,
      default: "",
      validator(value) {
        let acceptedValues = ["", "notice", "mini"];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Modal type (notice|mini|"") '
    },
    animationDuration: {
      type: Number,
      default: 500,
      description: "Modal transition duration"
    },
    campaign: {
      type: Object
    },
    currenciesList: {
      type: Array
    }
  },
  computed: {
    ...mapState({
      defaultCurrency: state => state.user.authUser.default_currency
    }),
    isValidCampaignName: {
      get() {
        return this.campaignName.trim().length ? true : false;
      }
    },
    isValidBudget: {
      get() {
        return this.budget < 0 ? false : true;
      }
    },
    isValidCurrency: {
      get() {
        return this.currency ? true : false;
      }
    },
    isValidImvCoef: {
      get() {
        if (!this.campaign) return true;
        return this.imvCoef > -1 && this.imvCoef !== "" ? true : false;
      }
    },
    currenciesListForDropdown: {
      get() {
        return this.currenciesList.map(e => {
          return { code: e.code, description: e.code + " - " + e.description };
        });
      }
    }
  },
  methods: {
    closePanel() {
      document.querySelector(
        ".edit-campaign-panel .modal-dialog"
      ).style.right = `-100%`;

      setTimeout(() => {
        this.$emit("update:show", false);
        this.$emit("close");
      }, this.animationDuration / 2);
    },

    saveCampaign() {
      if (!this.loading) {
        if (
          !this.isValidCampaignName ||
          !this.isValidBudget ||
          !this.isValidImvCoef ||
          !this.isValidCurrency
        ) {
          this.localError = true;
          return;
        }
        const colorIndex = COLORS_LIST.map(e => e.id).indexOf(this.color);
        const colorValue =
          colorIndex > -1 ? COLORS_LIST[colorIndex].value : "transparent";
        this.$emit("save", {
          name: this.campaignName,
          budget: this.budget >= 0 ? this.budget : 'none',
          currency: this.currency.code,
          color: colorValue,
          imv_coef: this.imvCoef
        });
      }
    }
  },
  watch: {
    show(val) {
      let documentClasses = document.body.classList;
      this.localError = false;
      const defaultCurrencyIndex = this.currenciesList
        .map(e => e.code)
        .indexOf(this.defaultCurrency);

      if (this.campaign) {
        const currentCampaignCurrencyIndex = this.currenciesList
          .map(e => e.code)
          .indexOf(this.campaign.currency);
        this.currency = this.currenciesListForDropdown[currentCampaignCurrencyIndex > -1 ? currentCampaignCurrencyIndex : defaultCurrencyIndex];
        this.campaignName = this.campaign.name;
        this.budget = this.campaign.budget;
        const colorIndex = COLORS_LIST.map(c => c.value).indexOf(this.campaign.color);
        this.color = colorIndex > -1 ? COLORS_LIST[colorIndex].id : null;
        this.imvCoef = this.campaign.imv_coef;
      } else {
        this.currency = this.currenciesListForDropdown[defaultCurrencyIndex];
        this.campaignName = "";
        this.budget = 0;
        this.imvCoef = 0;
      }

      if (val) {
        /* slide in panel*/
        documentClasses.add("modal-open");

        setTimeout(() => {
          document.querySelector(
            ".edit-campaign-panel .modal-dialog"
          ).style.transition = `0s ease-out`;
          document.querySelector(
            ".edit-campaign-panel .modal-dialog"
          ).style.right = `0px`;
          document.querySelector("#input_campaign_name").focus();
        }, 10);
      } else {
        documentClasses.remove("modal-open");
        document.querySelector(".edit-campaign-panel").scrollTop = 0;
      }
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.modal.show {
  background-color: rgba(255, 255, 255, 0.7);
}

/* Hide arrows in input budget field */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}
</style>
