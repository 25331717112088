<template>
  <div id="chart">
    <apexchart
      width="150"
      height="150"
      type="radialBar"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import chart from "vue-apexcharts";

export default {
  name: "budget-spend",
  components: { apexchart: chart },
  props: {
    graphOptions: {
      type: Array,
      default: () => ({})
    }
  },

  data: function() {
    return {
      series: this.graphOptions,
      chartOptions: {
        chart: {
          height: 180,
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            offsetY: 0,
            startAngle: 0,
            endAngle: 360,
            hollow: {
              margin: 1,
              size: "50%",
              background: "transparent",
              image: undefined,
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                show: false,
              },
            },
          },
        },
        colors: ["#FF5722", "#9C27B0", "#39539E", "#0077B5"],
        labels: [],
        legend: {
          show: false,
          floating: true,
          fontSize: "16px",
          position: "left",
          offsetX: 160,
          offsetY: 15,
          labels: {
            useSeriesColors: true,
          },
          markers: {
            size: 1,
          },
          formatter: function(seriesName, opts) {
            return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex];
          },
          itemMargin: {
            vertical: 5,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                show: false,
              },
            },
          },
        ],
      },
    };
  },
   mounted(){
    console.log('props- Budget',this.graphOptions)
  }
};
</script>
<style></style>
