<template>
  <ul class="filter-nav my-2 p-3">
    <li class="flex-between filter-item mt-3 search-form">
      <base-input
        id="input_search--youtube"
        class="input-group-alternative search-input w-100"
        placeholder="Search YouTube..."
        autofocus
        v-on:keyup="searchInputChanged($event)"
        v-model="searchInput"
      ></base-input>
      <base-button
        type="primary"
        class="search-button input-group-alternative ml-0 mb-4"
        @click="search(true)"
      >
        <i class="fas fa-search"></i>
      </base-button>
      <div class="search-message">@Channel-ID for specific channels or Keyword for general search</div>
    </li>
    <li class="flex-column filter-item mt-5">
      <div class="mb-2">
        <span class="nav-link-text ml-2">Contact details</span>
      </div>
      <div class="filter-item-wrapper">
        <multiselect
          placeholder="Contact details"
          :searchable="true"
          :allow-empty="false"
          :hide-selected="true"
          selectLabel
          selectedLabel="✓"
          deselectLabel="✕"
          label="label"
          track-by="id"
          v-model="hasEmails"
          :options="profileTypes.contact"
        />
        <a
          v-if="hasEmails && hasEmails.id !== profileTypes.contact[0].id"
          class="btn-select-reset btn-select-reset--contact"
          @click="resetFilter('has_emails')"
        >
          <i class="fas fa-times-circle"></i>
        </a>
      </div>
    </li>
    <li class="flex-column filter-item mt-3">
      <div class="mb-2">
        <span class="nav-link-text ml-2">Engagement Rate (%)</span>
      </div>
      <div class="flex-middle-between filter-item-wrapper">
        <base-input
          class="input-group-alternative mb-3"
          placeholder="Min"
          type="number"
          :step="INPUT_ENGAGEMENT_RATE_STEP"
          :min="INPUT_ENGAGEMENT_RATE_MIN"
          :max="
            engagementRateLTE == null
              ? INPUT_ENGAGEMENT_RATE_MAX
              : Math.min(INPUT_ENGAGEMENT_RATE_MAX, engagementRateLTE)
          "
          v-on:blur="validateEngagementRateGTE"
          v-on:keyup.enter="validateEngagementRateGTE"
          v-model="engagementRateGTE"
        ></base-input>
        <base-input
          class="input-group-alternative mb-3 ml-3"
          placeholder="Max"
          type="number"
          :step="INPUT_ENGAGEMENT_RATE_STEP"
          :min="
            Math.max(INPUT_ENGAGEMENT_RATE_MIN, engagementRateGTE)
          "
          :max="INPUT_ENGAGEMENT_RATE_MAX"
          v-on:blur="validateEngagementRateLTE"
          v-on:keyup.enter="validateEngagementRateLTE"
          v-model="engagementRateLTE"
        ></base-input>
        <a
          v-if="engagementRateGTE || engagementRateLTE"
          class="btn-select-reset"
          @click="resetFilter('engagement_rate')"
        >
          <i class="fas fa-times-circle"></i>
        </a>
      </div>
      <div class="mb-2">
        <span class="nav-link-text ml-2">Subscribers</span>
      </div>
      <div class="flex-middle-between filter-item-wrapper">
        <base-input
          class="input-group-alternative mb-3"
          placeholder="Min"
          type="number"
          :step="INPUT_SUBSCRIBERS_STEP"
          :min="INPUT_SUBSCRIBERS_MIN"
          :max="
            subscribersLTE == null
              ? INPUT_SUBSCRIBERS_MAX
              : Math.min(INPUT_SUBSCRIBERS_MAX, subscribersLTE)
          "
          v-on:blur="validateSubscribersGTE"
          v-on:keyup.enter="validateSubscribersGTE"
          v-model="subscribersGTE"
        ></base-input>
        <base-input
          class="input-group-alternative mb-3 ml-3"
          placeholder="Max"
          type="number"
          :step="INPUT_SUBSCRIBERS_STEP"
          :min="Math.max(INPUT_SUBSCRIBERS_MIN, subscribersGTE)"
          :max="INPUT_SUBSCRIBERS_MAX"
          v-on:blur="validateSubscribersLTE"
          v-on:keyup.enter="validateSubscribersLTE"
          v-model="subscribersLTE"
        ></base-input>
        <a
          v-if="subscribersGTE || subscribersLTE"
          class="btn-select-reset"
          @click="resetFilter('subscribers')"
        >
          <i class="fas fa-times-circle"></i>
        </a>
      </div>

      <div class="mb-2">
        <span class="nav-link-text ml-2">Videos per week</span>
      </div>
      <div class="flex-middle-between filter-item-wrapper">
        <base-input
          class="input-group-alternative mb-3"
          placeholder="Min"
          type="number"
          :step="INPUT_VIDEOS_PER_WEEK_STEP"
          :min="INPUT_VIDEOS_PER_WEEK_MIN"
          :max="
            videosPerWeekLTE == null
              ? INPUT_VIDEOS_PER_WEEK_MAX
              : Math.min(INPUT_VIDEOS_PER_WEEK_MAX, videosPerWeekLTE)
          "
          v-on:blur="validateVideosPerWeekGTE"
          v-on:keyup.enter="validateVideosPerWeekGTE"
          v-model="videosPerWeekGTE"
        ></base-input>
        <base-input
          class="input-group-alternative mb-3 ml-3"
          placeholder="Max"
          type="number"
          :step="INPUT_VIDEOS_PER_WEEK_STEP"
          :min="Math.max(INPUT_VIDEOS_PER_WEEK_MIN, videosPerWeekGTE)"
          :max="INPUT_SUBSCRIBERS_MAX"
          v-on:blur="validateVideosPerWeekLTE"
          v-on:keyup.enter="validateVideosPerWeekLTE"
          v-model="videosPerWeekLTE"
        ></base-input>
        <a
          v-if="videosPerWeekGTE || videosPerWeekLTE"
          class="btn-select-reset"
          @click="resetFilter('vides_per_week')"
        >
          <i class="fas fa-times-circle"></i>
        </a>
      </div>
    </li>
    <li class="flex-column filter-item mt-3">
      <span class="nav-link-text ml-2">Social Media</span>
      <div class="has-social row">
        <div class="col-6 my-2">
          <base-checkbox v-model="hasInstagram">
            <div class="flex-middle-center">
              <img src="/app/img/icons/svg/instagram-round.svg" />
              <div class="ml-1">Instagram</div>
            </div>
          </base-checkbox>
        </div>
        <div class="col-6 my-2">
          <base-checkbox v-model="hasTwitch">
            <div class="flex-middle-center">
              <img src="/app/img/icons/svg/twitch-round.svg" />
              <div class="ml-1">Twitch</div>
            </div>
          </base-checkbox>
        </div>
        <div class="col-6 my-2">
          <base-checkbox v-model="hasFacebook">
            <div class="flex-middle-center">
              <img src="/app/img/icons/svg/facebook-round.svg" />
              <div class="ml-1">Facebook</div>
            </div>
          </base-checkbox>
        </div>
        <div class="col-6 my-2">
          <base-checkbox v-model="hasTwitter">
            <div class="flex-middle-center">
              <img src="/app/img/icons/svg/twitter-round.svg" />
              <div class="ml-1">Twitter</div>
            </div>
          </base-checkbox>
        </div>
        <div class="col-6 my-2">
          <base-checkbox v-model="hasPatreon">
            <div class="flex-middle-center">
              <img src="/app/img/icons/svg/patreon-round.svg" />
              <div class="ml-1">Patreon</div>
            </div>
          </base-checkbox>
        </div>
        <div class="col-6 my-2">
          <base-checkbox v-model="hasPinterest">
            <div class="flex-middle-center">
              <img src="/app/img/icons/svg/pinterest-round.svg" />
              <div class="ml-1">Pinterest</div>
            </div>
          </base-checkbox>
        </div>
      </div>
    </li>
    <li class="flex-column filter-item mt-3">
      <div class="mb-2">
        <span class="nav-link-text ml-2">Location</span>
      </div>
      <div class="mb-3 filter-item-wrapper">
        <multiselect
          placeholder="Country"
          :searchable="true"
          selectLabel
          selectedLabel="✓"
          deselectLabel="✕"
          v-model="country"
          :options="countries"
        />
        <a
          v-if="country"
          class="btn-select-reset"
          @click="resetFilter('country')"
        >
          <i class="fas fa-times-circle"></i>
        </a>
      </div>
    </li>
    <li class="flex-column mt-3 mb-5">
      <div class="mb-3 flex-center">
        <a class="btn-reset-all" @click="resetFilter()">
          <i class="fas fa-sync"></i>
          <span class="nav-link-text ml-2">Reset filters</span>
        </a>
      </div>
    </li>
  </ul>
</template>
<script>
import { mapState } from "vuex";

/* components */
import Multiselect from "vue-multiselect";
import {
  YOUTUBE_COUNTRIES,
  SOCIALS,
  PROFILE_TYPES,
  INPUT_ENGAGEMENT_RATE_MAX,
  INPUT_ENGAGEMENT_RATE_MIN,
  INPUT_ENGAGEMENT_RATE_STEP,
  INPUT_SUBSCRIBERS_MAX,
  INPUT_SUBSCRIBERS_MIN,
  INPUT_SUBSCRIBERS_STEP,
  INPUT_VIDEOS_PER_WEEK_MAX,
  INPUT_VIDEOS_PER_WEEK_MIN,
  INPUT_VIDEOS_PER_WEEK_STEP
} from "@/constants";

export default {
  name: "youtube-search-filter",
  components: {
    Multiselect
  },
  props: {
    logo: {
      type: String,
      default: "/app/img/brand/green.png",
      description: "filterbar app logo"
    },
    autoClose: {
      type: Boolean,
      default: true,
      description:
        "Whether filterbar should autoclose on mobile when clicking an item"
    }
  },
  provide() {
    return {
      autoClose: this.autoClose
    };
  },
  computed: {
    ...mapState({
      filter: state => state.influencer.filter[SOCIALS.youtube.id],
      exportList: state => state.influencer.exportList
    }),
    searchInput: {
      set(value) {
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.youtube.id,
          name: "keyword",
          value: value
        });
      },
      get() {
        return this.filter.keyword;
      }
    },
    engagementRateLTE: {
      set(value) {
        const realValue = value
          ? Math.min(INPUT_ENGAGEMENT_RATE_MAX, value)
          : value;
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.youtube.id,
          name: "avg_engagement_rate_lte",
          value: realValue
        });
      },
      get() {
        return this.filter.avg_engagement_rate_lte;
      }
    },
    engagementRateGTE: {
      set(value) {
        const realValue = value
          ? Math.max(INPUT_ENGAGEMENT_RATE_MIN, value)
          : value;
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.youtube.id,
          name: "avg_engagement_rate_gte",
          value: realValue
        });
      },
      get() {
        return this.filter.avg_engagement_rate_gte;
      }
    },
    subscribersLTE: {
      set(value) {
        const realValue = value
          ? Math.min(INPUT_SUBSCRIBERS_MAX, value)
          : value;
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.youtube.id,
          name: "subscribers_lte",
          value: realValue
        });
      },
      get() {
        return this.filter.subscribers_lte;
      }
    },
    subscribersGTE: {
      set(value) {
        const realValue = value
          ? Math.max(INPUT_SUBSCRIBERS_MIN, value)
          : value;
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.youtube.id,
          name: "subscribers_gte",
          value: realValue
        });
      },
      get() {
        return this.filter.subscribers_gte;
      }
    },
    videosPerWeekLTE: {
      set(value) {
        const realValue = value
          ? Math.min(INPUT_VIDEOS_PER_WEEK_MAX, value)
          : value;
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.youtube.id,
          name: "videos_per_week_lte",
          value: realValue
        });
      },
      get() {
        return this.filter.videos_per_week_lte;
      }
    },
    videosPerWeekGTE: {
      set(value) {
        const realValue = value
          ? Math.max(INPUT_VIDEOS_PER_WEEK_MIN, value)
          : value;
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.youtube.id,
          name: "videos_per_week_gte",
          value: realValue
        });
      },
      get() {
        return this.filter.videos_per_week_gte;
      }
    },
    country: {
      set(value) {
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.youtube.id,
          name: "country",
          value: value
        });
      },
      get() {
        return this.filter.country;
      }
    },
    hasEmails: {
      set(value) {
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.youtube.id,
          name: "has_emails",
          value: value.id === "all" ? false : true
        });
      },
      get() {
        return this.filter.has_emails
          ? PROFILE_TYPES.contact[0]
          : PROFILE_TYPES.contact[1];
      }
    },
    hasInstagram: {
      set(value) {
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.youtube.id,
          name: "has_instagram",
          value: value
        });
      },
      get() {
        return this.filter.has_instagram;
      }
    },
    hasTwitch: {
      set(value) {
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.youtube.id,
          name: "has_twitch",
          value: value
        });
      },
      get() {
        return this.filter.has_twitch;
      }
    },
    hasFacebook: {
      set(value) {
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.youtube.id,
          name: "has_facebook",
          value: value
        });
      },
      get() {
        return this.filter.has_facebook;
      }
    },
    hasTwitter: {
      set(value) {
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.youtube.id,
          name: "has_twitter",
          value: value
        });
      },
      get() {
        return this.filter.has_twitter;
      }
    },
    hasPatreon: {
      set(value) {
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.youtube.id,
          name: "has_patreon",
          value: value
        });
      },
      get() {
        return this.filter.has_patreon;
      }
    },
    hasPinterest: {
      set(value) {
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.youtube.id,
          name: "has_pinterest",
          value: value
        });
      },
      get() {
        return this.filter.has_pinterest;
      }
    }
  },
  watch: {

    // searchInput: function(value) {
    //   this.$emit("change", {
    //     social: SOCIALS.youtube.id,
    //     name: "keyword",
    //     value: value,
    //     query: this.buildQuery()
    //   });
    // },
    
    engagementRateLTE: function(value) {
      this.$emit("change", {
        social: SOCIALS.youtube.id,
        name: "avg_engagement_rate_lte",
        value: value,
        query: this.buildQuery()
      });
    },
    engagementRateGTE: function(value) {
      this.$emit("change", {
        social: SOCIALS.youtube.id,
        name: "avg_engagement_rate_gte",
        value: value,
        query: this.buildQuery()
      });
    },
    subscribersLTE: function(value) {
      this.$emit("change", {
        social: SOCIALS.youtube.id,
        name: "subscribers_lte",
        value: value,
        query: this.buildQuery()
      });
    },
    subscribersGTE: function(value) {
      this.$emit("change", {
        social: SOCIALS.youtube.id,
        name: "subscribers_gte",
        value: value,
        query: this.buildQuery()
      });
    },
    videosPerWeekLTE: function(value) {
      this.$emit("change", {
        social: SOCIALS.youtube.id,
        name: "videos_per_week_lte",
        value: value,
        query: this.buildQuery()
      });
    },
    videosPerWeekGTE: function(value) {
      this.$emit("change", {
        social: SOCIALS.youtube.id,
        name: "videos_per_week_gte",
        value: value,
        query: this.buildQuery()
      });
    },
    country: function(country) {
      this.$emit("change", {
        social: SOCIALS.youtube.id,
        name: "country",
        value: country,
        query: this.buildQuery()
      });
    },
    hasEmails: function(value) {
      this.$emit("change", {
        social: SOCIALS.youtube.id,
        name: "has_emails",
        value: value.id === "all" ? null : true,
        query: this.buildQuery()
      });
    },
    hasInstagram: function(has) {
      this.$emit("change", {
        social: SOCIALS.youtube.id,
        name: "has_instagram",
        value: has,
        query: this.buildQuery()
      });
    },
    hasTwitch: function(has) {
      this.$emit("change", {
        social: SOCIALS.youtube.id,
        name: "has_twitch",
        value: has,
        query: this.buildQuery()
      });
    },
    hasFacebook: function(has) {
      this.$emit("change", {
        social: SOCIALS.youtube.id,
        name: "has_facebook",
        value: has,
        query: this.buildQuery()
      });
    },
    hasTwitter: function(has) {
      this.$emit("change", {
        social: SOCIALS.youtube.id,
        name: "has_twitter",
        value: has,
        query: this.buildQuery()
      });
    },
    hasPatreon: function(has) {
      this.$emit("change", {
        social: SOCIALS.youtube.id,
        name: "has_patreon",
        value: has,
        query: this.buildQuery()
      });
    },
    hasPinterest: function(has) {
      this.$emit("change", {
        social: SOCIALS.youtube.id,
        name: "has_pinterest",
        value: has,
        query: this.buildQuery()
      });
    }
  },

  data() {
    return {
      INPUT_ENGAGEMENT_RATE_MAX: INPUT_ENGAGEMENT_RATE_MAX,
      INPUT_ENGAGEMENT_RATE_MIN: INPUT_ENGAGEMENT_RATE_MIN,
      INPUT_ENGAGEMENT_RATE_STEP: INPUT_ENGAGEMENT_RATE_STEP,
      INPUT_SUBSCRIBERS_MAX: INPUT_SUBSCRIBERS_MAX,
      INPUT_SUBSCRIBERS_MIN: INPUT_SUBSCRIBERS_MIN,
      INPUT_SUBSCRIBERS_STEP: INPUT_SUBSCRIBERS_STEP,
      INPUT_VIDEOS_PER_WEEK_MAX: INPUT_VIDEOS_PER_WEEK_MAX,
      INPUT_VIDEOS_PER_WEEK_MIN: INPUT_VIDEOS_PER_WEEK_MIN,
      INPUT_VIDEOS_PER_WEEK_STEP: INPUT_VIDEOS_PER_WEEK_STEP,
      profileTypes: PROFILE_TYPES,
      countries: YOUTUBE_COUNTRIES
    };
  },
  mounted() {
    document.querySelector("#input_search--youtube").focus();
  },
  methods: {
    resetFilter(which) {
      switch (which) {
        case "engagement_rate":
          this.$store.dispatch("influencer/setFilterValue", {
            social: SOCIALS.youtube.id,
            name: "avg_engagement_rate_lte",
            value: null
          });
          this.$store.dispatch("influencer/setFilterValue", {
            social: SOCIALS.youtube.id,
            name: "avg_engagement_rate_gte",
            value: null
          });
          break;
        case "subscribers":
          this.$store.dispatch("influencer/setFilterValue", {
            social: SOCIALS.youtube.id,
            name: "subscribers_lte",
            value: null
          });
          this.$store.dispatch("influencer/setFilterValue", {
            social: SOCIALS.youtube.id,
            name: "subscribers_gte",
            value: null
          });
          break;
        case "videos_per_week":
          this.$store.dispatch("influencer/setFilterValue", {
            social: SOCIALS.youtube.id,
            name: "videos_per_week_lte",
            value: null
          });
          this.$store.dispatch("influencer/setFilterValue", {
            social: SOCIALS.youtube.id,
            name: "videos_per_week_gte",
            value: null
          });
          break;
        case "country":
          this.$store.dispatch("influencer/setFilterValue", {
            social: SOCIALS.youtube.id,
            name: which,
            value: null
          });
          break;
        case "has_emails":
          this.$store.dispatch("influencer/setFilterValue", {
            social: SOCIALS.youtube.id,
            name: "has_emails",
            value: true
          });
          break;
        default:
          [
            "avg_engagement_rate_lte",
            "avg_engagement_rate_gte",
            "subscribers_lte",
            "subscribers_gte",
            "videos_per_week_lte",
            "videos_per_week_gte",
            "has_emails",
            "country"
          ].forEach(key => {
            this.$store.dispatch("influencer/setFilterValue", {
              social: SOCIALS.youtube.id,
              name: key,
              value: key === "has_emails" ? true : null
            });
          });

          [
            "has_instagram",
            "has_twitch",
            "has_facebook",
            "has_patreon",
            "has_pinterest"
          ].forEach(key => {
            this.$store.dispatch("influencer/setFilterValue", {
              social: SOCIALS.youtube.id,
              name: key,
              value: false
            });
          });
      }
    },
    searchInputChanged(e) {
      if (e.keyCode === 13) {
        this.search(true);
      }
    },
    getSearchInput() {
      return this.searchInput;
    },
    search() {
      this.$emit("search", {
        social: SOCIALS.youtube.id,
        input: this.searchInput,
        query: this.buildQuery(),
        username: this.searchInput
      });
    },
    validateEngagementRateGTE() {
      if (!this.engagementRateGTE) return;
      if (
        this.engagementRateLTE !== null &&
        this.engagementRateLTE < this.engagementRateGTE
      ) {
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.youtube.id,
          name: "avg_engagement_rate_gte",
          value: this.engagementRateLTE
        });
      }
    },
    validateEngagementRateLTE() {
      if (!this.engagementRateLTE) return;
      if (
        this.engagementRateGTE !== null &&
        this.engagementRateLTE < this.engagementRateGTE
      ) {
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.youtube.id,
          name: "avg_engagement_rate_lte",
          value: this.engagementRateGTE
        });
      }
    },
    validateSubscribersGTE() {
      if (!this.subscribersGTE) return;
      if (
        this.subscribersLTE !== null &&
        this.subscribersLTE < this.subscribersGTE
      ) {
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.youtube.id,
          name: "subscribers_gte",
          value: this.subscribersLTE
        });
      }
    },
    validateSubscribersLTE() {
      if (!this.subscribersLTE) return;
      if (
        this.subscribersGTE !== null &&
        this.subscribersLTE < this.subscribersGTE
      ) {
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.youtube.id,
          name: "subscribers_lte",
          value: this.subscribersGTE
        });
      }
    },
    validateVideosPerWeekGTE() {
      if (!this.videosPerWeekGTE) return;
      if (
        this.videosPerWeekLTE !== null &&
        this.videosPerWeekLTE < this.videosPerWeekGTE
      ) {
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.youtube.id,
          name: "videos_per_week_gte",
          value: this.videosPerWeekLTE
        });
      }
    },
    validateVideosPerWeekLTE() {
      if (!this.videosPerWeekLTE) return;
      if (
        this.videosPerWeekGTE !== null &&
        this.videosPerWeekLTE < this.videosPerWeekGTE
      ) {
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.youtube.id,
          name: "videos_per_week_lte",
          value: this.videosPerWeekGTE
        });
      }
    },
    buildQuery() {
      /* inputed string */
      let query = `query=${this.searchInput}`;

      /* has_emails */
      if (this.hasEmails.id !== "all") {
        query += `&has_emails=true`;
      }

      /* engagement and subscribers and videos per week */
      if (this.engagementRateLTE !== null && this.engagementRateLTE !== "") {
        query += `&avg_engagement_rate_lte=${this.engagementRateLTE / 100}`;
      }
      if (this.engagementRateGTE !== null && this.engagementRateGTE !== "") {
        query += `&avg_engagement_rate_gte=${this.engagementRateGTE / 100}`;
      }
      if (this.subscribersLTE !== null && this.subscribersLTE !== "") {
        query += `&subscribers_lte=${this.subscribersLTE}`;
      }
      if (this.subscribersGTE !== null && this.subscribersGTE !== "") {
        query += `&subscribers_gte=${this.subscribersGTE}`;
      }
      if (this.videosPerWeekLTE !== null && this.videosPerWeekLTE !== "") {
        query += `&videos_per_week_lte=${this.videosPerWeekLTE}`;
      }
      if (this.videosPerWeekGTE !== null && this.videosPerWeekGTE !== "") {
        query += `&videos_per_week_gte=${this.videosPerWeekGTE}`;
      }
      /* has social accounts */

      if (this.hasInstagram) {
        query += `&has_instagram=true`;
      }
      if (this.hasTwitch) {
        query += `&has_twitch=true`;
      }
      if (this.hasFacebook) {
        query += `&has_facebook=true`;
      }
      if (this.hasTwitter) {
        query += `&has_twitter=true`;
      }
      if (this.hasPatreon) {
        query += `&has_patreon=true`;
      }
      if (this.hasPinterest) {
        query += `&has_pinterest=true`;
      }

      /* location */
      if (this.country) {
        query += `&country=${this.country}`;
      }
      return query;
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
