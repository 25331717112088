var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticClass:"modal set-variables-panel right-sliding-panel right-sliding-panel-xs",class:[
    { 'show d-block': _vm.show },
    { 'd-none': !_vm.show },
    { 'modal-mini': _vm.type === 'mini' }
  ],attrs:{"tabindex":"-1","role":"dialog","aria-hidden":!_vm.show}},[_c('div',{staticClass:"modal-dialog mb-0"},[_c('div',{staticClass:"modal-content"},[_c('modal-topbar',[_c('a',{attrs:{"slot":"left"},on:{"click":_vm.closePanel},slot:"left"},[_c('i',{staticClass:"fas fa-chevron-left mr-1"}),_c('span',[_vm._v("Settings")])]),_c('div',{attrs:{"slot":"center"},slot:"center"},[_vm._v("Variables")])]),_c('div',{staticClass:"modal-body main-content--pricing"},[_c('ul',{staticClass:"panel-blocks mt-5"},[_vm._m(0),_c('li',{staticClass:"panel-item"},[_vm._m(1),_c('div',{staticClass:"select-container d-flex"},[(_vm.currenciesListForDropdown)?_c('multiselect',{class:!_vm.isValidCurrency && _vm.localError ? 'error' : '',attrs:{"placeholder":"Currency","searchable":true,"selectLabel":"","deselectLabel":"","selectedLabel":"","allow-empty":false,"label":"description","track-by":"code","hide-selected":true,"options":_vm.currenciesListForDropdown},model:{value:(_vm.currency),callback:function ($$v) {_vm.currency=$$v},expression:"currency"}}):_vm._e()],1)]),_c('li',{staticClass:"panel-item mt-3"},[_vm._m(2),_c('base-input',{staticClass:"input-group-alternative mb-4",attrs:{"placeholder":"0","type":"text"},model:{value:(_vm.imvCoef),callback:function ($$v) {_vm.imvCoef=$$v},expression:"imvCoef"}})],1)]),_c('div',{staticClass:"flex-middle-center"},[_c('base-button',{staticClass:"btn-w-25 mr-4",attrs:{"type":"primary"},on:{"click":_vm.closePanel}},[_vm._v("Close")]),_c('base-button',{staticClass:"btn-w-25",attrs:{"type":"primary"},on:{"click":_vm.setVariables}},[_vm._v("Save")])],1)])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"panel-item"},[_c('p',[_vm._v("\n              The IMV (Impressions Money Value) is the monetary value\n              associated to 1,000 impressions of a campaign. This varies from\n              industry to industry and also depends on what you promote.\n            ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-2 setting-label mt-1"},[_c('span',{staticClass:"nav-link-text"},[_vm._v("Currency")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-2 setting-label"},[_c('span',{staticClass:"nav-link-text"},[_vm._v("IMV")])])
}]

export { render, staticRenderFns }