<template>
  <div>
    <div v-if="showScript">
      <script
        type="application/javascript"
        src="https://cashier-sandbox.bridgerpay.dev/loader"
        :data-cashier-key="CASHIER_KEY"
        :data-cashier-token="CASHIER_TOKEN"
        data-direct-payment-method=""
        data-single-payment-method=""
        data-single-payment-provider=""
        data-dont-skip-single-payment-box=""
        data-theme="black"
        data-deposit-button-text="Pay"
      ></script>
    </div>
    <div class="text-right mt-2">
      <base-button type="primary" v-if="showScript" @click="closeModal"
        >Close</base-button
      >
    </div>
    <div class="bridgerpay-loading" v-if="loading.payment">
      <base-spinner></base-spinner>
      <div class="pl-2 mt-2">loading...</div>
    </div>
  </div>
</template>


<script>
const BRIDGERPAY_CASHIER_KEY = process.env.VUE_APP_BRIDGERPAY_CASHIER_KEY;

import { MESSAGES } from "@/constants";

export default {
  name: "bridgerpay-modal",
  props: {
    show: Boolean,
    paymentAmount: [String, Number],
    paymentCurrency: String,
    leadId: Number,
    campaignId: Number,
    userCountry: String,
    userState: String
  },
  data() {
    return {
      CASHIER_KEY: BRIDGERPAY_CASHIER_KEY,
      CASHIER_TOKEN: "",
      canClose: false,
      showScript: false,
      loading: {
        payment: false
      }
    };
  },
  methods: {
    cashierEventHandler({ detail }) {
      console.log("[bp]:all", detail);
    },
    cashierDepositEventHandler({ detail }) {
      console.log("[bp]:deposit", detail);
      //debugger;
      this.canClose = true;
    },
    closeModal() {
      this.$emit("close");
      this.showScript = false;
    }
  },
  watch: {
    async show(val) {
      if (val) {
        this.loading.payment = true;
        var result = await this.$store.dispatch("workspace/getCashierSession", {
          currency: this.paymentCurrency,
          amount: Number(this.paymentAmount),
          country: this.userCountry,
          state: this.userState,
          campaign_id: this.campaignId,
          lead_id: this.leadId
        });

        if (result && result.result) {
          this.CASHIER_TOKEN = result.result.cashier_token;

          // Show script
          this.showScript = true;

          window.addEventListener("[bp]:all", this.cashierEventHandler);
          window.addEventListener(
            "[bp]:deposit",
            this.cashierDepositEventHandler
          );
        } else {
           this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["common-500"],
            alertClass: "danger"
          });
        }

        this.loading.payment = false;
      } else {
        // cleanup
        this.showScript = false;

        // emmit event for hiding component by parent element
        window.removeEventListener("[bp]:all", this.cashierEventHandler);
        window.removeEventListener(
          "[bp]:deposit",
          this.cashierDepositEventHandler
        );
      }
    }
  }
};
</script>

<style lang="scss">
.bridgerpay-loading {
  text-align: center;
}
</style>