<template>
  <div
    class="modal set-team-invite-panel right-sliding-panel right-sliding-panel-xs"
    @click.self="closePanel"
    :class="[
      { 'show d-block': show },
      { 'd-none': !show },
      { 'modal-mini': type === 'mini' }
    ]"
    v-show="show"
    tabindex="-1"
    role="dialog"
    :aria-hidden="!show"
  >
    <div class="modal-dialog mb-0">
      <div class="modal-content">
        <modal-topbar>
          <a slot="left" @click="closePanel">Cancel</a>
          <div slot="center">
            <template v-if="selected">
              Edit team member
            </template>
            <template v-else>
              Invite a new team member
            </template>
          </div>
        </modal-topbar>

        <div class="modal-body main-content">
          <form @submit.prevent="submitSubUser">
            <ul class="panel-blocks mt-5 pb-3">
              <li class="panel-item">
                <div class="mb-2 setting-label mt-1">
                  <span class="nav-link-text">Email</span>
                </div>
                <base-input
                  class="input-group-alternative mb-3"
                  placeholder="team@mail.com"
                  type="email"
                  v-model="subUser.email"
                  autofocus
                  :disabled="loading.submit || selected"
                ></base-input>
              </li>
              <li class="panel-item">
                <div class="mb-2 setting-label pt-2">
                  <span class="nav-link-text">Presets</span>
                  <div class="d-flex mt-1">
                    <base-button
                      v-for="preset in pressets"
                      :key="preset.id"
                      type="primary"
                      class="'btn-w-25 flex-middle-center'"
                      @click="setSubUserWithPreset(preset)"
                    >
                      {{preset.label}}
                    </base-button>
                  </div>
                </div>
              </li>
              <li class="panel-item">
                <div class="mb-2 setting-label pt-3">
                  <span class="nav-link-text">Permissions</span>
                  <div
                    v-for="(scope, index) in accountScopes"
                    :class="index ? 'py-4' : 'pb-4 pt-3'"
                    :key="scope.id"
                  >
                    <PermissionSelector
                      :label="scope.label"
                      :id="scope.id"
                      :description="getDescription(scope)"
                      :step="{
                        indexes: scope.access,
                        labels: accountAccess.map(e => e.label),
                        showLabels: !index,
                      }"
                      :active="getActiveAccessIndex(scope.id)"
                      @select="onSelectAccess(scope.id, $event)"
                    />
                  </div>
                </div>
              </li>
            </ul>

            <div class="flex-center my-5">
              <base-button
                :disabled="!hasFullAccess('team') || !subUser.email"
                :disabledTooltip="subUser.email && getPermissionErrorMessage('team:full')"
                :isBusy="loading.submit"
                type="primary"
                nativeType="submit"
                :class="loading.submit ? 'btn-w-50 flex-middle-center' : 'btn-w-25 flex-middle-center'"
              >
                {{ selected ? "Update" : "Invite" }}
              </base-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PermissionSelector from "../components/PermissionSelector";
import { mapGetters } from "vuex";
import { getPermissionErrorMessage } from "@/utils";
import {
  MESSAGES,
  ACCOUNT_SCOPES,
  ACCOUNT_ACCESS,
  ACCOUNT_PERMISSION_PRESETS,
} from '../constants';

export default {
  name: "set-team-invite-panel",
  components: {
    PermissionSelector,
  },
  props: {
    show: Boolean,
    type: {
      type: String,
      default: "",
      validator(value) {
        let acceptedValues = ["", "notice", "mini"];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Modal type (notice|mini|"") '
    },
    animationDuration: {
      type: Number,
      default: 500,
      description: "Modal transition duration"
    },
    selected: {
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      hasFullAccess: 'user/hasFullAccess',
      hasAccess: 'user/hasAccess',
    }),
  },
  data() {
    return {
      accountAccess: ACCOUNT_ACCESS,
      accountScopes: ACCOUNT_SCOPES,
      pressets: ACCOUNT_PERMISSION_PRESETS,
      getPermissionErrorMessage: getPermissionErrorMessage,

      loading: {
        submit: false
      },
      subUser: {
        email: "",
        scopes: {},
      },
      localError: false
    };
  },
  methods: {
    closePanel() {
      /* slide out panel */
      document.querySelector(
        ".set-team-invite-panel .modal-dialog"
      ).style.right = `-100%`;
      /* reset panel */
      setTimeout(() => {
        this.$emit("update:show", false);
        this.$emit("close");
      }, this.animationDuration / 2);
    },

    getDescription(scope) {
      let description = "";
      scope.access.forEach((accessIndex, index) => {
        if (accessIndex) {
          description += `<strong>${this.accountAccess[accessIndex].label}</strong>:
            ${scope.description[index - 1]}
            ${scope.access.length - 1 !== accessIndex ? "<br/>" : ""}`;
        }
      });
      return description;
    },

    getActiveAccessIndex(scopeId) {
      const index = ACCOUNT_ACCESS.findIndex(e => e.id  === this.subUser.scopes[scopeId]);
      return index > -1 ? index : 0;
    },

    onSelectAccess(scopeId, index) {
      this.subUser.scopes[scopeId] = ACCOUNT_ACCESS[index].id;
      this.subUser.scopes = {...this.subUser.scopes};
    },

    setSubUserWithPreset(preset) {
      const scopes = {};
      preset.scopes.forEach(e => {
        const item = Array.isArray(e) ? e : e.split(":");
        if (item.length < 2) return;
        if (this.hasAccess(e)) {
          scopes[item[0]] = item[1];
        } else if (this.hasAccess(`${item[0]}:view`)){
          scopes[item[0]] = "view";
        }
      })
      this.subUser.scopes = scopes;
    },

    async submitSubUser() {
      if (!this.hasFullAccess("team")) {
        this.$store.dispatch("setAlert", {
          showAlert: true,
          content: this.getPermissionErrorMessage("team:full"),
          alertClass: "danger"
        });
        return;
      }
      this.loading.submit = true;
      try {
        if (this.selected) {
          await this.$store.dispatch("user/updateSubUser", {
            id: this.subUser.id,
            scopes: Object.keys(this.subUser.scopes).filter(e => this.subUser.scopes[e] !== "no").map(e => [e, this.subUser.scopes[e]])
          });
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["user__team-permissions-updated"],
            alertClass: "success"
          });
        } else {
          await this.$store.dispatch("user/inviteSubUser", {
            email: this.subUser.email,
            scopes: Object.keys(this.subUser.scopes).filter(e => this.subUser.scopes[e] !== "no").map(e => [e, this.subUser.scopes[e]])
          });
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["user__team-invite-success"],
            alertClass: "success"
          });
        }
        this.closePanel();
        this.loading.submit = false;
      } catch {
        this.loading.submit = false;
        this.localError = MESSAGES['common-500'];
      }
    }
  },

  watch: {
    show(val) {
      const documentClasses = document.body.classList;
      if (val) {
        if (this.selected) {
          this.subUser.id = this.selected.id;
          this.subUser.email = this.selected.email;
          this.setSubUserWithPreset(this.selected)
        }
        /* slide in panel*/
        documentClasses.add("modal-open");
        setTimeout(() => {
          document.querySelector(
            ".set-team-invite-panel  .modal-dialog"
          ).style.right = `0px`;
        }, 0);
      } else {
        /* reset panel */
        documentClasses.remove("modal-open");
        document.querySelector(".set-team-invite-panel  ").scrollTop = 0;
      }
    },
  }
};
</script>
<style>
.modal.show {
  background-color: rgba(255, 255, 255, 0.7);
}
</style>
