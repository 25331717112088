<template>
  <div
    class="tab-pane fade set-integrations-panel"
    :class="[{ 'modal-mini': type === 'mini' }]"
    @click.self="closePanel"
    v-bind:id="tabId"
  >
    <div class="main-content">
      <template v-if="integrations && integrations.length">
        <div class="panel-block">
          <div class="panel-item">
            <div class="mb-2 setting-label mt-1">
              <span class="nav-link-text">Active Integrations</span>
            </div>
            <ul class="main-nav-integrations">
              <li
                v-for="(integration, index) in integrations"
                :key="index"
                class="integration-item flex-middle-between flex-wrap"
              >
                <div class="integration-card flex-middle flex-wrap">
                  <div class="integration-icon">
                    <img :src="availableIntegrations[integration.name].image" />
                  </div>
                  <div class="ml-3">
                    {{
                      integration.store_name ||
                      (integration.url
                        ? integration.url.replace(/(^\w+:|^)\/\//, "")
                        : "")
                    }}
                  </div>
                  <div class="flex-middle ml-auto">
                    <div
                      class="status-badge"
                      v-b-tooltip.hover
                      :title="
                        integration.webhook_active
                          ? 'Ninja Influence will receive the events from your store'
                          : 'Ninja Influence could not create the webhooks to receive the events from your store'
                      "
                      :class="integration.webhook_active ? 'active' : ''"
                    >
                      {{
                        integration.webhook_active
                          ? "Connected"
                          : "Partially connected"
                      }}
                    </div>
                  </div>
                </div>

                <div class="ml-2" v-if="hasFullAccess('integrations')">
                  <base-button
                    :isBusy="integration.id === selectedId && disconnecting"
                    type="danger"
                    class="btn-light"
                    v-on:click="disconnect(integration.id)"
                  >
                    Disconnect
                  </base-button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </template>

      <template v-if="hasFullAccess('integrations')">
        <div class="panel-item">
          <div class="mb-2 setting-label mt-1">
            <span class="nav-link-text">Available Integrations</span>
          </div>
          <ul class="main-nav-integrations">
            <template v-for="(integration, id, index) in availableIntegrations">
              <li
                :key="index"
                @click="addNewIntegration(id)"
                class="integration-item available flex-middle-between"
              >
                <div class="integration-card flex-middle">
                  <div class="integration-icon">
                    <img :src="integration.image" />
                  </div>
                  <span class="ml-3 flex-fill">{{ integration.name }}</span>
                  <i class="fas fa-chevron-right float-right"></i>
                </div>
              </li>
            </template>
          </ul>
        </div>
      </template>

      <div class="panel-item">
        <div class="mb-2 setting-label mt-1">
          <span class="nav-link-text">Coming Soon</span>
        </div>
        <ul class="main-nav-integrations">
          <template v-for="(integration, id, index) in comingSoonIntegrations">
            <li
              :key="index"
              class="integration-item flex-middle-between"
            >
              <div class="integration-card flex-middle">
                <div class="integration-icon">
                  <img :src="integration.image" />
                </div>
                <span class="ml-3">{{ integration.name }}</span>
              </div>
            </li>
          </template>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import { AVAILABLE_INTEGRATIONS, COMING_SOON_INTEGRATIONS } from "@/constants";

export default {
  name: "set-integrations-panel",
  components: {},
  props: {
    integrations: {
      type: Array
    },
    show: Boolean,
    type: {
      type: String,
      default: "",
      validator(value) {
        let acceptedValues = ["", "notice", "mini"];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Modal type (notice|mini|"") '
    },
    animationDuration: {
      type: Number,
      default: 500,
      description: "Modal transition duration"
    },
    disconnecting: {
      type: Boolean
    },
    username: {
      type: String
    },
    tabId: String
  },
  data() {
    return {
      availableIntegrations: AVAILABLE_INTEGRATIONS,
      comingSoonIntegrations: COMING_SOON_INTEGRATIONS,
      selectedId: null,
      localError: false
    };
  },
  computed: {
    ...mapState({
      showAlert: (state) => state.alert.showAlert
    }),
    ...mapGetters({
      hasViewAccess: "user/hasViewAccess",
      hasFullAccess: "user/hasFullAccess"
    })
  },
  methods: {
    closePanel() {
      /* reset panel */
      setTimeout(() => {
        this.$emit("update:show", false);
        this.$emit("close");
      }, this.animationDuration / 2);
    },
    addNewIntegration(integration) {
      this.$emit("addNewIntegration", integration);
    },
    disconnect(id) {
      this.selectedId = id;
      this.$emit("disconnect", id);
    }
  },

  watch: {
    show(val) {
      const documentClasses = document.body.classList;
      if (val) {
        this.localError = false;
      } else {
        /* reset panel */
        documentClasses.remove("modal-open");
        document.querySelector(".set-integrations-panel").scrollTop = 0;
      }
    }
  }
};
</script>
<style>
.modal.show {
  background-color: rgba(255, 255, 255, 0.7);
}
</style>
