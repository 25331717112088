<template>
  <!-- <ul class="nav campaign-navs">
    <li class="nav-item" v-for="router in routers" :key="router.suffix">
      <router-link :to="routerPrefix + id + router.suffix" class="nav-link flex-middle-center">{{router.label}}</router-link>
    </li>
  </ul>-->
  <div class="campaign-navigation">
    <div class="back-to-campaign">

      <button class="btn btn-light btn-icon" @click="$router.push('../../campaigns')"><i class="fas fa-chevron-left"></i></button>
      <!-- <router-link :to="'../../campaigns'" tag="button" custom v-slot="{ href, route, navigate, isActive, isExactActive }">
        <button class="btn btn-icon" @click="navigate">
          <i class="fas fa-chevron-left"></i>
        </button>
      </router-link> -->
    </div>

    <div class="campaign-nav-item" v-for="router in routers" v-bind:key="router.label">
      <router-link :to="routerPrefix + id + router.suffix" class="nav-link flex-middle-center">{{router.label}}</router-link>
    </div>
  </div>
</template>
<script>

export default {
  name: "campaign-nav",
  props: {
    id: {
      type: String,
      default: "",
      description: "Campaign ID"
    }
  },
  data() {
    return {
      routerPrefix: "../../campaigns/",
      routers: [
        {
          suffix: "/pipeline",
          label: "Pipeline"
        },
        {
          suffix: "/tracking",
          label: "Dashboard"
        }
      ]
    };
  }
};
</script>

<style lang="scss">
@import "../assets/scss/custom/campaign-nav";
</style>
