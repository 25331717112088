<template>
  <ul class="filter-nav my-2 p-3">
    <li>
      <influencers-search-switch v-model="tiktokSearchType">
      </influencers-search-switch>
    </li>
    <li class="flex-between filter-item mt-3 search-form">
      <base-input
        id="input_search--tiktok"
        class="input-group-alternative search-input w-100"
        :placeholder="tiktokSearchType == 'keyword' ? 'Search TikTok...' : 'Search TikTok username...'"
        autofocus
        v-on:keyup="searchInputChanged($event)"
        v-model="searchInput"
      ></base-input>
      <base-button type="primary" class="search-button input-group-alternative ml-0 mb-4" @click="search(true)">
        <i class="fas fa-search"></i>
      </base-button>
    </li>

    <li class="flex-column filter-item mt-2">
      <div class="mb-2">
        <span class="nav-link-text">Hashtags</span>
      </div>
      <div class="flex-column">
        <div class="mb-3 filter-item-wrapper">
          <multiselect
            placeholder="Add one or more hashtags"
            tagPlaceholder="Press enter to insert"
            :multiple="true"
            :taggable="true"
            :options="hashtags"
            label="label"
            track-by="hashtag"
            @tag="addHashtag"
            @search-change="getHashtagsList"
            @open="hashtags = []"
            :loading="loading.hashtags"
            :internal-search="false"
            selectLabel
            deselectLabel="✕"
            :hideSelected="true"
            v-model="currentHashtags"
            ref="hashtagsMultiselect"
          >
            <span slot="noOptions">Type at least two characters.</span>
            <span slot="noResult">No matching hashtags.</span>
          </multiselect>
          <a v-if="hashtags" class="btn-select-reset" @click="resetFilter('hashtags')">
            <i class="fas fa-times-circle" v-if="currentHashtags.length"></i>
          </a>
        </div>
      </div>
    </li>

    <li class="flex-column filter-item mt-3">
      <div class="mb-2">
        <span class="nav-link-text ml-2">Engagement Rate (%)</span>
      </div>
      <div class="flex-middle-between filter-item-wrapper">
        <base-input
          class="input-group-alternative mb-3"
          placeholder="Min"
          type="number"
          :step="INPUT_ENGAGEMENT_RATE_STEP"
          :min="INPUT_ENGAGEMENT_RATE_MIN"
          :max="
            engagementRateLTE == null
              ? INPUT_ENGAGEMENT_RATE_MAX
              : Math.min(INPUT_ENGAGEMENT_RATE_MAX, engagementRateLTE)
          "
          v-on:blur="validateEngagementRateGTE"
          v-on:keyup.enter="validateEngagementRateGTE"
          v-model="engagementRateGTE"
        ></base-input>
        <base-input
          class="input-group-alternative mb-3 ml-3"
          placeholder="Max"
          type="number"
          :step="INPUT_ENGAGEMENT_RATE_STEP"
          :min="Math.max(INPUT_ENGAGEMENT_RATE_MIN, engagementRateGTE)"
          :max="INPUT_ENGAGEMENT_RATE_MAX"
          v-on:blur="validateEngagementRateLTE"
          v-on:keyup.enter="validateEngagementRateLTE"
          v-model="engagementRateLTE"
        ></base-input>
        <a
          v-if="engagementRateGTE || engagementRateLTE"
          class="btn-select-reset"
          @click="resetFilter('engagement_rate')"
        >
          <i class="fas fa-times-circle"></i>
        </a>
      </div>
      <div class="mb-2">
        <span class="nav-link-text ml-2">Fans</span>
      </div>
      <div class="flex-middle-between filter-item-wrapper">
        <base-input
          class="input-group-alternative mb-3"
          placeholder="Min"
          type="number"
          :step="INPUT_FANS_STEP"
          :min="INPUT_FANS_MIN"
          :max="
            fansLTE == null ? INPUT_FANS_MAX : Math.min(INPUT_FANS_MAX, fansLTE)
          "
          v-on:blur="validateFansGTE"
          v-on:keyup.enter="validateFansGTE"
          v-model="fansGTE"
        ></base-input>
        <base-input
          class="input-group-alternative mb-3 ml-3"
          placeholder="Max"
          type="number"
          :step="INPUT_FANS_STEP"
          :min="Math.max(INPUT_FANS_MIN, fansGTE)"
          :max="INPUT_FANS_MAX"
          v-on:blur="validateFansLTE"
          v-on:keyup.enter="validateFansLTE"
          v-model="fansLTE"
        ></base-input>
        <a v-if="fansGTE || fansLTE" class="btn-select-reset" @click="resetFilter('fans')">
          <i class="fas fa-times-circle"></i>
        </a>
      </div>
    </li>
    <li class="flex-column filter-item mt-3">
      <!-- <div class="mb-2">
        <span class="nav-link-text ml-2">Location</span>
      </div>
      <div class="mb-3 filter-item-wrapper">
        <multiselect
          placeholder="Country"
          :searchable="true"
          selectLabel
          selectedLabel="✓"
          deselectLabel="✕"
          v-model="country"
          :options="countries"
        />
        <a
          v-if="country"
          class="btn-select-reset"
          @click="resetFilter('country')"
        >
          <i class="fas fa-times-circle"></i>
        </a>
      </div>-->
      <span class="nav-link-text ml-2">Language</span>
      <div class="mb-3 filter-item-wrapper">
        <multiselect
          placeholder="Language"
          :searchable="true"
          :multiple="true"
          :taggable="true"
          selectLabel
          selectedLabel="✓"
          deselectLabel="✕"
          label="name"
          track-by="code"
          v-model="language"
          :options="languages"
        />
        <a v-if="language" class="btn-select-reset" @click="resetFilter('language')">
          <i class="fas fa-times-circle"></i>
        </a>
      </div>
    </li>
    <li class="flex-column mt-3 mb-5">
      <div class="mb-3 flex-center">
        <a class="btn-reset-all" @click="resetFilter()">
          <i class="fas fa-sync"></i>
          <span class="nav-link-text ml-2">Reset filters</span>
        </a>
      </div>
    </li>
  </ul>
</template>
<script>
import { mapState } from "vuex";

/* components */
import Multiselect from "vue-multiselect";
const InfluencersSearchSwitch = () => import("./Influencers/InfluencersSearchSwitch");
import {
  MESSAGES,
  TIKTOK_COUNTRIES,
  TIKTOK_LANGUAGES,
  SOCIALS,
  INPUT_ENGAGEMENT_RATE_MAX,
  INPUT_ENGAGEMENT_RATE_MIN,
  INPUT_ENGAGEMENT_RATE_STEP,
  INPUT_FANS_MAX,
  INPUT_FANS_MIN,
  INPUT_FANS_STEP
} from "@/constants";

export default {
  name: "tiktok-search-filter",
  components: {
    Multiselect,
    InfluencersSearchSwitch
  },
  props: {
    logo: {
      type: String,
      default: "/app/img/brand/green.png",
      description: "filterbar app logo"
    },
    autoClose: {
      type: Boolean,
      default: true,
      description:
        "Whether filterbar should autoclose on mobile when clicking an item"
    }
  },
  provide() {
    return {
      autoClose: this.autoClose
    };
  },
  computed: {
    ...mapState({
      filter: (state) => state.influencer.filter[SOCIALS.tiktok.id],
      exportList: (state) => state.influencer.exportList
    }),
    tiktokSearchType: {
      set(value) {
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.tiktok.id,
          name: "searchType",
          value: value
        });
      },
      get() {
        return this.filter.searchType;
      }
    },
    searchInput: {
      set(value) {
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.tiktok.id,
          name: "keyword",
          value: value
        });
      },
      get() {
        return this.filter.keyword;
      }
    },
    engagementRateLTE: {
      set(value) {
        const realValue = value
          ? Math.min(INPUT_ENGAGEMENT_RATE_MAX, value)
          : value;
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.tiktok.id,
          name: "avg_engagement_rate_lte",
          value: realValue
        });
      },
      get() {
        return this.filter.avg_engagement_rate_lte;
      }
    },
    engagementRateGTE: {
      set(value) {
        const realValue = value
          ? Math.max(INPUT_ENGAGEMENT_RATE_MIN, value)
          : value;
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.tiktok.id,
          name: "avg_engagement_rate_gte",
          value: realValue
        });
      },
      get() {
        return this.filter.avg_engagement_rate_gte;
      }
    },
    fansLTE: {
      set(value) {
        const realValue = value ? Math.min(INPUT_FANS_MAX, value) : value;
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.tiktok.id,
          name: "fans_lte",
          value: realValue
        });
      },
      get() {
        return this.filter.fans_lte;
      }
    },
    fansGTE: {
      set(value) {
        const realValue = value ? Math.max(INPUT_FANS_MIN, value) : value;
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.tiktok.id,
          name: "fans_gte",
          value: realValue
        });
      },
      get() {
        return this.filter.fans_gte;
      }
    },
    categoryAndOr: {
      set(value) {
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.tiktok.id,
          name: "category_and_or",
          value: value
        });
      },
      get() {
        return this.filter.category_and_or;
      }
    },
    country: {
      set(value) {
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.tiktok.id,
          name: "country",
          value: value
        });
      },
      get() {
        return this.filter.country;
      }
    },
    language: {
      set(value) {
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.tiktok.id,
          name: "language",
          value: value
        });
      },
      get() {
        return this.filter.language;
      }
    },
    currentHashtags: {
      set(value) {
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.tiktok.id,
          name: "hashtags",
          value: value
        });
      },
      get() {
        return this.filter.hashtags;
      }
    }
  },
  watch: {
    searchInput: function(value) {
      this.$emit("change", {
        social: SOCIALS.tiktok.id,
        name: "keyword",
        value: value,
        query: this.buildQuery()
      });
    },
    tiktokSearchType: function(value) {
      this.$emit("change", {
        social: SOCIALS.tiktok.id,
        name: "searchType",
        value: value,
        query: this.buildQuery()
      });
    },
    engagementRateLTE: function (value) {
      this.$emit("change", {
        social: SOCIALS.tiktok.id,
        name: "avg_engagement_rate_lte",
        value: value,
        query: this.buildQuery()
      });
    },
    engagementRateGTE: function (value) {
      this.$emit("change", {
        social: SOCIALS.tiktok.id,
        name: "avg_engagement_rate_gte",
        value: value,
        query: this.buildQuery()
      });
    },
    fansLTE: function (value) {
      this.$emit("change", {
        social: SOCIALS.tiktok.id,
        name: "fans_lte",
        value: value,
        query: this.buildQuery()
      });
    },
    fansGTE: function (value) {
      this.$emit("change", {
        social: SOCIALS.tiktok.id,
        name: "fans_gte",
        value: value,
        query: this.buildQuery()
      });
    },
    country: function (country) {
      this.$emit("change", {
        social: SOCIALS.tiktok.id,
        name: "country",
        value: country,
        query: this.buildQuery()
      });
    },
    language: function (language) {
      this.$emit("change", {
        social: SOCIALS.tiktok.id,
        name: "language",
        value: language,
        query: this.buildQuery()
      });
    },
    currentHashtags: function () {
      this.search(true);
      // this.$emit("change", {
      //   social: SOCIALS.instagram.id,
      //   name: "hashtags",
      //   value: value,
      //   query: this.buildQuery()
      // });
    }
  },

  data() {
    return {
      INPUT_ENGAGEMENT_RATE_MAX: INPUT_ENGAGEMENT_RATE_MAX,
      INPUT_ENGAGEMENT_RATE_MIN: INPUT_ENGAGEMENT_RATE_MIN,
      INPUT_ENGAGEMENT_RATE_STEP: INPUT_ENGAGEMENT_RATE_STEP,
      INPUT_FANS_MAX: INPUT_FANS_MAX,
      INPUT_FANS_MIN: INPUT_FANS_MIN,
      INPUT_FANS_STEP: INPUT_FANS_STEP,
      countries: TIKTOK_COUNTRIES,
      hashtags: [],
      languages: TIKTOK_LANGUAGES,
      loading: {
        hashtags: false
      },
      showHashtagPopup: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      //document.querySelector("#input_search--tiktok").focus();
    });
  },
  methods: {
    resetFilter(which) {
      switch (which) {
        case "engagement_rate":
          this.$store.dispatch("influencer/setFilterValue", {
            social: SOCIALS.tiktok.id,
            name: "avg_engagement_rate_lte",
            value: null
          });
          this.$store.dispatch("influencer/setFilterValue", {
            social: SOCIALS.tiktok.id,
            name: "avg_engagement_rate_gte",
            value: null
          });
          break;
        case "fans":
          this.$store.dispatch("influencer/setFilterValue", {
            social: SOCIALS.tiktok.id,
            name: "fans_lte",
            value: null
          });
          this.$store.dispatch("influencer/setFilterValue", {
            social: SOCIALS.tiktok.id,
            name: "fans_gte",
            value: null
          });
          break;
        case "country":
        case "language":
          this.$store.dispatch("influencer/setFilterValue", {
            social: SOCIALS.tiktok.id,
            name: which,
            value: null
          });
          break;
        case "hashtags":
          this.$store.dispatch("influencer/setFilterValue", {
            social: SOCIALS.tiktok.id,
            name: which,
            value: []
          });
          break;
        default:
          [
            "engagement_rate",
            "fans",
            "language",
            "country",
            "hashtags"
          ].forEach((key) => {
            this.resetFilter(key)
          });
      }
    },
    searchInputChanged(e) {
      if (e.keyCode === 13) {
        this.search(true);
      }
    },
    search() {
      this.$emit("search", {
        social: SOCIALS.tiktok.id,
        input: this.searchInput,
        query: this.buildQuery()
      });
    },
    getSearchInput() {
      return this.searchInput;
    },
    validateEngagementRateGTE() {
      if (!this.engagementRateGTE) return;
      if (
        this.engagementRateLTE !== null &&
        this.engagementRateLTE < this.engagementRateGTE
      ) {
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.tiktok.id,
          name: "avg_engagement_rate_gte",
          value: this.engagementRateLTE
        });
      }
    },
    validateEngagementRateLTE() {
      if (!this.engagementRateLTE) return;
      if (
        this.engagementRateGTE !== null &&
        this.engagementRateLTE < this.engagementRateGTE
      ) {
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.tiktok.id,
          name: "avg_engagement_rate_lte",
          value: this.engagementRateGTE
        });
      }
    },
    validateFansGTE() {
      if (!this.fansGTE) return;
      if (this.fansLTE !== null && this.fansLTE < this.fansGTE) {
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.tiktok.id,
          name: "fans_gte",
          value: this.fansLTE
        });
      }
    },
    validateFansLTE() {
      if (!this.fansLTE) return;
      if (this.fansGTE !== null && this.fansLTE < this.fansGTE) {
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.tiktok.id,
          name: "fans_lte",
          value: this.fansGTE
        });
      }
    },
    buildQuery() {
      /* inputed string */
      let query = `query=${this.searchInput}`;

      /* engagement and fans */
      if (this.engagementRateLTE !== null && this.engagementRateLTE !== "") {
        query += `&avg_engagement_rate_lte=${this.engagementRateLTE / 100}`;
      }
      if (this.engagementRateGTE !== null && this.engagementRateGTE !== "") {
        query += `&avg_engagement_rate_gte=${this.engagementRateGTE / 100}`;
      }
      if (this.fansLTE !== null && this.fansLTE !== "") {
        query += `&fans_lte=${this.fansLTE}`;
      }
      if (this.fansGTE !== null && this.fansGTE !== "") {
        query += `&fans_gte=${this.fansGTE}`;
      }
      /* language */
      if (this.language) {
        let languages = [];
        this.language.forEach((l) => languages.push(l.code));
        languages = languages.join();
        query += `&language=${languages}`;
      }
      /* location */
      if (this.country) {
        query += `&country=${this.country}`;
      }

      /* hashtags */
      if (this.currentHashtags && this.currentHashtags.length) {
        const hashtagsList = this.currentHashtags.map((obj) =>
          encodeURIComponent(obj.hashtag)
        );
        query += `&hashtags_and=${hashtagsList.toString()}`;
      }
      return query;
    },

    addHashtag(newTag) {
      if (!newTag || newTag.trim() === "#") return;
      const tag = newTag.toLowerCase().trim();
      const tagWithSharp = tag.charAt(0) === "#" ? tag : `#${tag}`;

      const tagObj = {
        hashtag: tag,
        label: tagWithSharp
      };
      this.hashtags.push(tagObj);
      this.currentHashtags = this.currentHashtags.concat([tagObj]);
    },
    async getHashtagsList(tagKey) {
      if (tagKey && tagKey.length > 1) {
        try {
          this.loading.hashtags = true;
          const data = await this.$store.dispatch(
            "influencer/getHashtagsList",
            {
              social: SOCIALS.instagram.id,
              prefix: tagKey.toLowerCase()
            }
          );
          if (data.hits && data.hits.length) {
            this.hashtags = data.hits.map((e) => {
              return {
                hashtag: e,
                label: `#${e}`
              };
            });
          } else {
            this.hashtags = [];
          }
          this.loading.hashtags = false;
        } catch {
          this.loading.hashtags = false;
          if (!this.showAlert) {
            this.$store.dispatch("setAlert", {
              showAlert: true,
              content: MESSAGES["common-500"],
              alertClass: "danger"
            });
          }
        }
      }
    },
    hashtagPopupSearch() {
      this.hashtagPopupDeclined = true;
      this.search(true);
    },
    focusHastags() {
      this.$refs.hashtagsMultiselect.activate();
    },
    closeHashtagPopup() {
      this.showHashtagPopup = false;
      this.hashtagPopupDeclined = true;
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
