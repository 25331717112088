<template>
  <div v-if="campaign" class="campaign-tracking">
    <div class="topbar navbar-mobile flex-between d-md-none">
      <div class="d-flex flex-row w-100">
        <router-link
          :to="'../../campaigns'"
          class="topbar-menu topbar-link flex-middle-center mr-3"
        >
          <i class="fas fa-chevron-left"></i>
        </router-link>

        <a
          class="flex-middle cursor-pointer"
          v-b-tooltip.hover
          :title="
            hasFullAccess('campaigns')
              ? campaign.active
                ? 'Click to deactivate'
                : 'Click to activate'
              : ''
          "
          @click="hasFullAccess('campaigns') ? toggleCampaign() : undefined"
        >
          <div
            class="topbar-info flex-middle"
            :style="{ color: campaign.color }"
          >
            {{ campaign.name }}
          </div>
          <h5 class="mt-2 ml-2">
            <span v-if="campaign.active" class="badge badge-success"
              >Active</span
            >
            <span v-else class="badge badge-danger">Inactive</span>
          </h5>
        </a>
      </div>
      <div class="d-flex">
        <a
          class="topbar-button tracking-button flex-middle-center topbar-button-wide p-2"
          :class="hasFullAccess('campaigns') ? '' : 'topbar-button-disabled'"
          @click="hasFullAccess('campaigns') ? toggleCampaign() : undefined"
          v-b-tooltip.hover
          :title="
            hasFullAccess('campaigns')
              ? undefined
              : getPermissionErrorMessage('campaigns:full')
          "
        >
          <span v-if="campaign.active">Pause tracking</span>
          <span v-else>Start tracking</span>
        </a>
        <a
          class="topbar-button edit-button flex-middle-center topbar-button-wide p-2"
          :class="hasFullAccess('campaigns') ? '' : 'topbar-button-disabled'"
          @click="
            hasFullAccess('campaigns') ? (showEditCampaign = true) : undefined
          "
          v-b-tooltip.hover
          :title="
            hasFullAccess('campaigns')
              ? undefined
              : getPermissionErrorMessage('campaigns:full')
          "
        >
          Edit
        </a>
      </div>
    </div>

    <!-- Top menu header -->
    <campaign-nav :id="id"></campaign-nav>

    <div
      class="campaign-tracking-content container-fluid"
      :style="{ height: contentHeight }"
    >
      <div class="campaign-title-block flex-between flex-wrap">
        <div
          class="title-container d-none d-md-flex flex-row my-3 align-items-center"
        >
          <a
            class="campaign-status flex-middle cursor-pointer mr-4"
            v-b-tooltip.hover
            :title="
              hasFullAccess('campaigns')
                ? campaign.active
                  ? 'Click to deactivate'
                  : 'Click to activate'
                : undefined
            "
            @click="hasFullAccess('campaigns') ? toggleCampaign() : undefined"
          >
            <h1 class="mr-2 mb-0">{{ campaign.name }}</h1>
            <h4 class="mb-0">
              <span v-if="campaign.active" class="badge badge-success"
                >Active</span
              >
              <span v-else class="badge badge-danger">Inactive</span>
            </h4>
          </a>
          <base-button
            :disabled="!hasFullAccess('campaigns')"
            :disabledTooltip="getPermissionErrorMessage('campaigns:full')"
            :type="campaign.active ? 'danger' : 'success'"
            class="mx-2"
            @click="toggleCampaign"
            :isBusy="loading.active"
            >{{
              campaign.active ? "Pause tracking" : "Start tracking"
            }}</base-button
          >
        </div>
      </div>

      <div class="row mt-3">
        <h2 class="ml-3">Key performance indicators</h2>
        <div class="col-12">
          <p class="description mb-0">{{ keyDescription }}</p>
        </div>
        <div class="col-12 mt-3">
          <div class="row">
            <div class="flex-between flex-wrap w-100">
              <div class="campaign-value-container kpi1">
                <div class="kpi-icons">
                  <i
                    v-b-tooltip.hover
                    :title="'The return on investment. It\'s calculated from the revenue if an e-commerce integration is activated, from the IMV otherwise.'"
                    class="card-tooltip fas fa-question-circle"
                    src="/app/img/icons/common/tooltip-card.svg"
                  >
                  </i>
                  <img
                    class="card-kpi-icon"
                    src="/app/img/icons/common/KPI1.svg"
                  />
                </div>

                <div class="kpi-stats">
                  <div class="campaign-label campaign-label--large">ROI</div>
                  <div
                    class="campaign-value campaign-value--green campaign-value--large"
                  >
                    {{ getCampaignROI(campaign) | Percent(1, false) }}
                  </div>
                </div>
              </div>

              <div class="campaign-value-container kpi2">
                <div class="kpi-icons">
                  <i
                    v-b-tooltip.hover
                    :title="'The total (estimated) number of impressions generated by the campaign.'"
                    class="card-tooltip fas fa-question-circle"
                    src="/app/img/icons/common/tooltip-card.svg"
                  >
                  </i>
                  <img
                    class="card-kpi-icon"
                    src="/app/img/icons/common/KPI2.svg"
                  />
                </div>

                <div class="kpi-stats">
                  <div class="campaign-label campaign-label--large">IMP</div>
                  <div
                    class="campaign-value campaign-value--green campaign-value--large"
                  >
                    {{ campaign.impressions | BigNumber }}
                  </div>
                </div>
              </div>
              <div class="campaign-value-container kpi3">
                <div class="kpi-icons">
                  <i
                    v-b-tooltip.hover
                    :title="'Cost per 1,000 impressions, calculated from the spend.'"
                    class="card-tooltip fas fa-question-circle"
                    src="/app/img/icons/common/tooltip-card.svg"
                  >
                  </i>
                  <img
                    class="card-kpi-icon"
                    src="/app/img/icons/common/KPI3.svg"
                  />
                </div>

                <div class="kpi-stats">
                  <div class="campaign-label campaign-label--large">eCPM</div>
                  <div
                    class="campaign-value campaign-value--green campaign-value--large"
                  >
                    {{
                      campaign.ecpm
                        | NormalNumber(2)
                        | Currency(currencySymbols[campaign.currency])
                    }}
                  </div>
                </div>
              </div>
              <div class="campaign-value-container kpi4">
                <div class="kpi-icons">
                  <i
                    v-b-tooltip.hover
                    :title="'The total number of engagements generated by the campaign.'"
                    class="card-tooltip fas fa-question-circle"
                    src="/app/img/icons/common/tooltip-card.svg"
                  >
                  </i>
                  <img
                    class="card-kpi-icon"
                    src="/app/img/icons/common/KPI4.svg"
                  />
                </div>

                <div class="kpi-stats">
                  <div class="campaign-label campaign-label--large">ENG</div>
                  <div
                    class="campaign-value campaign-value--green campaign-value--large"
                  >
                    {{ campaign.engagements | BigNumber }}
                  </div>
                </div>
              </div>
              <div class="campaign-value-container kpi5">
                <div class="kpi-icons">
                  <i
                    v-b-tooltip.hover
                    :title="'Cost per 1,000 engagements, calculated from the spend.'"
                    class="card-tooltip fas fa-question-circle"
                    src="/app/img/icons/common/tooltip-card.svg"
                  >
                  </i>
                  <img
                    class="card-kpi-icon"
                    src="/app/img/icons/common/KPI5.svg"
                  />
                </div>

                <div class="kpi-stats">
                  <div class="campaign-label campaign-label--large">CPE</div>
                  <div
                    class="campaign-value campaign-value--green campaign-value--large"
                  >
                    {{
                      campaign.cpe
                        | NormalNumber(2)
                        | Currency(currencySymbols[campaign.currency])
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mb-4 mt-3 kpi-bottom">
          <div class="flex-between flex-wrap w-100">
            <div class="campaign-value-container kpi6">
              <div class="kpi-top">
                <div class="kpi-icons"></div>
                <div class="kpi-stats">
                  <div class="campaign-label campaign-label--perday">
                    Per Budget
                  </div>
                  <div class="campaign-label campaign-label--large">
                    Budget
                    {{
                      campaign.budget
                        | BigNumber
                        | Currency(currencySymbols[campaign.currency])
                    }}
                  </div>
                  <div class="campaign-label campaign-label--purple">
                    Spend
                    {{
                      campaign.spend
                        | BigNumber
                        | Currency(currencySymbols[campaign.currency])
                    }}
                  </div>
                </div>
              </div>
              <div class="kpi-graph">
                <div class="" v-if="campaign.budget > 0">
                  <BudgetSpend
                    :graphOptions="[
                      100,
                      (100 * campaign.spend) / campaign.budget,
                    ]"
                  />
                </div>
              </div>
            </div>
            <div class="campaign-value-container kpi7">
              <div class="kpi-top">
                <div class="kpi-icons">
                  <i
                    v-b-tooltip.hover
                    :title="''"
                    class="card-tooltip fas fa-question-circle"
                    src="/app/img/icons/common/tooltip-card.svg"
                  >
                  </i>
                  <img
                    class="card-kpi-icon"
                    src="/app/img/icons/common/KPI7.svg"
                  />
                </div>

                <div class="kpi-stats">
                  <div class="campaign-label campaign-label--perday">
                    Per day
                  </div>
                  <div class="campaign-label campaign-label--large">
                    Orders {{ campaign.sales | BigNumber }}
                  </div>
                  <div class="campaign-label campaign-label--purple">
                    Returns {{ campaign.refunds | BigNumber }}
                  </div>
                </div>
              </div>
              <div class="kpi-graph">
                <div v-if="salesHistoryChart.chartData.labels.length">
                  <OrdersReturns :graphOptions="salesHistoryChart" />
                </div>
                <div class="chart-container col-12 mt-3" v-else>
                  <div class="no-data">
                    <div
                      v-if="loading.salesHistory"
                      class="flex-middle-center flex-column"
                    >
                      <base-spinner></base-spinner>
                      <p class="mt-1">Loading...</p>
                    </div>
                    <div v-else class="text-center">
                      <h5>No data yet</h5>
                      <p>
                        <router-link to="/app/settings/integrations"
                          >Connect</router-link
                        >
                        your e-commerce platform to track sales if you haven't
                        done it already
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="campaign-value-container kpi8">
              <div class="kpi-top">
                <div class="kpi-icons">
                  <i
                    v-b-tooltip.hover
                    :title="''"
                    class="card-tooltip fas fa-question-circle"
                    src="/app/img/icons/common/tooltip-card.svg"
                  >
                  </i>
                  <img
                    class="card-kpi-icon"
                    src="/app/img/icons/common/KPI8.svg"
                  />
                </div>

                <div class="kpi-stats">
                  <div class="campaign-label campaign-label--perday">
                    Per day
                  </div>
                  <div class="campaign-label campaign-label--large">
                    Revenue
                    {{
                      campaign.gross_revenue
                        | NormalNumber(2)
                        | Currency(currencySymbols[campaign.currency])
                    }}
                  </div>
                  <div class="campaign-label campaign-label--purple">
                    Refunds
                    {{
                      campaign.total_refunded
                        | BigNumber(2)
                        | Currency(currencySymbols[campaign.currency])
                    }}
                  </div>
                </div>
              </div>
              <div class="kpi-graph">
                <div v-if="salesHistoryChart.chartData.labels.length">
                  <RevenueRefunds :graphOptions="salesHistoryChart" />
                </div>
                <div class="chart-container col-12 mt-3" v-else>
                  <div class="no-data">
                    <div
                      v-if="loading.salesHistory"
                      class="flex-middle-center flex-column"
                    >
                      <base-spinner></base-spinner>
                      <p class="mt-1">Loading...</p>
                    </div>
                    <div v-else class="text-center">
                      <h5>No data yet</h5>
                      <p>
                        <router-link to="/app/settings/integrations"
                          >Connect</router-link
                        >
                        your e-commerce platform to track sales if you haven't
                        done it already
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="campaign-value-container kpi9">
              <div class="kpi-top">
                <div class="kpi-icons">
                  <i
                    v-b-tooltip.hover
                    :title="''"
                    class="card-tooltip fas fa-question-circle"
                    src="/app/img/icons/common/tooltip-card.svg"
                  >
                  </i>
                  <img
                    class="card-kpi-icon"
                    src="/app/img/icons/common/KPI9.svg"
                  />
                </div>

                <div class="kpi-stats">
                  <div class="campaign-label campaign-label--perday">
                    Per day
                  </div>
                  <div class="campaign-label campaign-label--large">
                    Influencers {{ influencersHistoryChart.totalInfluencers }}
                  </div>
                </div>
              </div>
              <div class="kpi-graph">
                <div
                  id="chart-container"
                  class="chart-container col-12 mt-3"
                  v-if="influencersHistoryChart.chartData.labels.length"
                >
                  <div class="">
                    <line-chart
                      :height="80"
                      ref="cumulativeChart"
                      :chart-data="influencersHistoryChart.chartData"
                      :extra-options="influencersHistoryChart.extraOptions"
                    ></line-chart>
                  </div>
                </div>
                <div class="chart-container col-12 mt-3" v-else>
                  <div class="no-data">
                    <div
                      v-if="loading.influencersHistory"
                      class="flex-middle-center flex-column"
                    >
                      <base-spinner></base-spinner>
                      <p class="mt-1">Loading...</p>
                    </div>
                    <div v-else class="text-center">
                      <h5>No data yet</h5>
                      <p>Click on the campaign name to activate tracking</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="campaign-value-container kpi10">
              <div class="kpi-top">
                <div class="kpi-icons">
                  <i
                    v-b-tooltip.hover
                    :title="''"
                    class="card-tooltip fas fa-question-circle"
                    src="/app/img/icons/common/tooltip-card.svg"
                  >
                  </i>
                  <img
                    class="card-kpi-icon"
                    src="/app/img/icons/common/KPI10.svg"
                  />
                </div>

                <div class="kpi-stats">
                  <div class="campaign-label campaign-label--perday">
                    Per day
                  </div>
                  <div class="campaign-label campaign-label--large">
                    Posts {{ postsHistoryChart.allData.length }}
                  </div>
                </div>
              </div>
              <div class="kpi-graph">
                <div
                  id="chart-container"
                  class="chart-container col-12 mt-3"
                  v-if="postsHistoryChart.chartData.labels.length"
                >
                  <div class="">
                    <line-chart
                      :height="80"
                      ref="cumulativeChart"
                      :chart-data="postsHistoryChart.chartData"
                      :extra-options="postsHistoryChart.extraOptions"
                    ></line-chart>
                  </div>
                </div>
                <div class="chart-container col-12 mt-3" v-else>
                  <div class="no-data">
                    <div
                      v-if="loading.postsHistoryChart"
                      class="flex-middle-center flex-column"
                    >
                      <base-spinner></base-spinner>
                      <p class="mt-1">Loading...</p>
                    </div>
                    <div v-else class="text-center">
                      <h5>No data yet</h5>
                      <p>Click on the campaign name to activate tracking</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column">
        <div class="row" :style="{ order: 2 }">
          <h2 class="ml-3">Social metrics</h2>
          <div class="col-12 mb-4">
            <div class="row">
              <div class="col-12 mb-4">
                <div class="graph-sale row">
                  <div class="col-12 d-flex flex-wrap graph-header">
                    <div class="flex-wrap">
                      <h3>Overall performance over time</h3>
                    </div>
                    <div class="checkbox-container">
                      <div class="checkbox-box checkbox-eng">
                        <input
                          type="checkbox"
                          id="checkbox"
                          v-model="checkboxTracking[0].show"
                          @change="checkTrackingChart()"
                        />
                        <label for="checkbox">Engagements</label>
                      </div>
                      <div class="checkbox-box checkbox-likes">
                        <input
                          type="checkbox"
                          id="checkbox"
                          v-model="checkboxTracking[1].show"
                          @change="checkTrackingChart()"
                        />
                        <label for="checkbox">Likes</label>
                      </div>
                      <div class="checkbox-box checkbox-views">
                        <input
                          type="checkbox"
                          id="checkbox"
                          v-model="checkboxTracking[2].show"
                          @change="checkTrackingChart()"
                        />
                        <label for="checkbox">Video views</label>
                      </div>
                    </div>

                    <!-- graph tpye -->
                    <div class="select-container d-flex select-sale">
                      <multiselect
                        placeholder="Cumulative"
                        :searchable="false"
                        selectLabel
                        deselectLabel
                        selectedLabel
                        :allow-empty="false"
                        label="label"
                        track-by="name"
                        :hide-selected="true"
                        :options="chartTypes"
                        v-model="selectedTrackingChartType"
                        class="my-2"
                      />
                    </div>
                  </div>

                  <div
                    id="chart-container"
                    class="chart-container col-12 mt-3"
                    v-if="trackingHistoryChart.chartData.labels.length"
                  >
                    <bar-chart
                      v-if="selectedTrackingChartType.name == 'instant'"
                      :height="250"
                      ref="instantChartRS"
                      :chart-data="trackingHistoryChart.chartData"
                      :extra-options="trackingHistoryChart.extraOptions"
                    ></bar-chart>
                    <line-chart
                      v-else
                      :height="250"
                      ref="cumulativeChartRS"
                      :chart-data="trackingHistoryChart.chartData"
                      :extra-options="trackingHistoryChart.extraOptions"
                    ></line-chart>
                  </div>
                  <div class="chart-container col-12 mt-3" v-else>
                    <div class="no-data">
                      <div
                        v-if="loading.trackingHistory"
                        class="flex-middle-center flex-column"
                      >
                        <base-spinner></base-spinner>
                        <p class="mt-1">Loading...</p>
                      </div>
                      <div v-else class="text-center">
                        <h5>No data yet</h5>
                        <p>
                          <router-link to="/app/settings/integrations"
                            >Connect</router-link
                          >
                          your e-commerce platform to track sales if you haven't
                          done it already
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="chart-period-container col-12 flex-center">
                    <ul class="flex-center w-100">
                      <li
                        v-for="(period, index) in chartPeriods"
                        :key="period.value"
                        class="period-item"
                        :class="selectedPeriodIndexRS == index ? 'active' : ''"
                        @click="setPeriodRS(index)"
                      >
                        <span>{{ period.label }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6 mb-4">
                <div class="row">
                  <div class="col-12">
                    <h3>Performance by influencer</h3>
                  </div>
                  <div class="col-12">
                    <VueScrollableTable
                      v-if="leads.length && leadPerformanceList.length"
                      class="performance-table"
                      :dead-area-color="'#FFF3E0'"
                      :scroll-horizontal="false"
                      :scroll-vertical="true"
                      :class="{ freezeFirstColumn: true }"
                    >
                      <template slot="thead">
                        <tr class="d-flex table-influencer">
                          <th class="name-column name-column--shallow">
                            {{ leadPerformanceList.length }} INFLUENCERS
                          </th>
                        </tr>
                        <tr class="d-flex table-influencer">
                          <th class="name-column name-column--shallow">
                            INFLUENCER
                          </th>
                          <th>Followers</th>
                          <th>Engagements</th>
                          <th>Video views</th>
                          <th>Likes</th>
                        </tr>
                      </template>

                      <template slot="tbody">
                        <tr
                          v-for="lead in leadPerformanceList"
                          :key="lead.id"
                          class="d-flex table-influencer"
                        >
                          <td
                            class="name-column name-column--shallow flex-between"
                          >
                            <div class="d-flex flex-middle">
                              <div class="avatar ml-2">
                                <base-avatar
                                  :username="getFullName(lead.username)"
                                  :src="lead.profile_pic"
                                >
                                </base-avatar>
                              </div>
                              <div class="flex-column ml-2">
                                <div class="profile-name">
                                  {{ lead.username }}
                                </div>
                                <div class="profile-username">
                                  @
                                  {{ lead.username }}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td class="flex-middle">
                            {{ (lead.data.followers || lead.data.subscribers) | BigNumber }}
                          </td>
                          <td class="flex-middle">
                            {{ lead.engagements | BigNumber }}
                          </td>
                          <td class="flex-middle">
                            {{ lead.video_views | BigNumber }}
                          </td>
                          <td class="flex-middle">
                            {{ lead.likes | BigNumber }}
                          </td>
                        </tr>
                      </template>
                    </VueScrollableTable>
                    <div v-else class="no-data">
                      <div
                        v-if="loading.leadPerformanceList"
                        class="flex-middle-center flex-column"
                      >
                        <base-spinner></base-spinner>
                        <p class="mt-1">Loading...</p>
                      </div>
                      <div v-else class="text-center">
                        <h5>No data yet</h5>
                        <p>Click on the campaign name to activate tracking</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6 mb-4">
                <div class="row">
                  <div class="col-12">
                    <h3>Performance by post</h3>
                  </div>
                  <div class="col-12">
                    <VueScrollableTable
                      v-if="leads.length && contentPerformanceList.length"
                      class="performance-table"
                      :scroll-horizontal="true"
                      :scroll-vertical="true"
                      :sync-header-scroll="true"
                      :dead-area-color="'#FFF8E1;'"
                      :class="{ freezeFirstColumn: true }"
                    >
                      <template slot="thead">
                        <tr class="d-flex table-count table-post">
                          <th class="name-column name-column--shallow">
                            {{ contentPerformanceList.length }} POSTS
                          </th>
                        </tr>
                        <tr class="d-flex table-post">
                          <th class="name-column name-column--shallow">
                            INFLUENCER
                          </th>
                          <th>LINK TO POST</th>
                          <th>Engagements</th>
                          <th>Video views</th>
                          <th>Likes</th>
                        </tr>
                      </template>

                      <template slot="tbody">
                        <tr
                          v-for="content in contentPerformanceList"
                          :key="content.id"
                          class="d-flex table-post"
                        >
                          <td
                            class="name-column name-column--shallow flex-between"
                          >
                            <div class="d-flex flex-middle">
                              <div class="avatar ml-2">
                                <base-avatar
                                  :username="
                                    getFullName(getLeadInfo(content.lead_id))
                                  "
                                  :src="
                                    getLeadInfo(content.lead_id).profile_pic
                                  "
                                >
                                </base-avatar>
                              </div>
                              <div class="flex-column ml-2">
                                <div class="profile-name">
                                  {{
                                    getFullName(getLeadInfo(content.lead_id))
                                  }}
                                </div>
                                <div class="profile-username">
                                  @{{
                                    getUsername(getLeadInfo(content.lead_id))
                                  }}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td class="flex-middle">
                            <a
                              :href="content.url"
                              target="_blank"
                              class="text-overflow-ellipsis w-100"
                            >
                              {{ content.url | NoDomain }}
                            </a>
                          </td>
                          <td class="flex-middle">
                            {{ content.engagements | BigNumber }}
                          </td>
                          <td class="flex-middle">
                            {{ content.video_views | BigNumber }}
                          </td>
                          <td class="flex-middle">
                            {{ content.likes | BigNumber }}
                          </td>
                        </tr>
                      </template>
                    </VueScrollableTable>
                    <div v-else class="no-data">
                      <div
                        v-if="loading.contentPerformanceList"
                        class="flex-middle-center flex-column"
                      >
                        <base-spinner></base-spinner>
                        <p class="mt-1">Loading...</p>
                      </div>
                      <div v-else class="text-center">
                        <h5>No data yet</h5>
                        <p>Click on the campaign name to activate tracking</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row" :style="{ order: 1 }">
          <h2 class="">Revenue and sales</h2>
          <div class="col-12 mb-4">
            <div class="row">
              <div class="col-12 mb-4">
                <div class="graph-sale row">
                  <div class="col-12 d-flex flex-wrap graph-header">
                    <div class="flex-wrap">
                      <h3>Overall revenue and sales over time</h3>
                    </div>
                    <div class="checkbox-container">
                      <div class="checkbox-box checkbox-revenue">
                        <input
                          type="checkbox"
                          id="checkbox"
                          v-model="checkboxSales[0].show"
                          @change="checkHistoryChart()"
                        />
                        <label for="checkbox">revenue</label>
                      </div>
                      <div class="checkbox-box checkbox-refunds">
                        <input
                          type="checkbox"
                          id="checkbox"
                          v-model="checkboxSales[2].show"
                          @change="checkHistoryChart()"
                        />
                        <label for="checkbox">refunds</label>
                      </div>
                      <div class="checkbox-box checkbox-orders">
                        <input
                          type="checkbox"
                          id="checkbox"
                          v-model="checkboxSales[1].show"
                          @change="checkHistoryChart()"
                        />
                        <label for="checkbox">orders</label>
                      </div>
                      <div class="checkbox-box checkbox-returns">
                        <input
                          type="checkbox"
                          id="checkbox"
                          v-model="checkboxSales[3].show"
                          @change="checkHistoryChart()"
                        />
                        <label for="checkbox">returns</label>
                      </div>
                    </div>

                    <!-- graph tpye -->
                    <div class="select-container d-flex select-sale">
                      <multiselect
                        placeholder="Cumulative"
                        :searchable="false"
                        selectLabel
                        deselectLabel
                        selectedLabel
                        :allow-empty="false"
                        label="label"
                        track-by="name"
                        :hide-selected="true"
                        :options="chartTypes"
                        v-model="selectedSalesChartType"
                        class="my-2"
                      />
                    </div>
                  </div>

                  <div
                    id="chart-container"
                    class="chart-container col-12 mt-3"
                    v-if="salesHistoryChart.chartData.labels.length"
                  >
                    <bar-chart
                      v-if="selectedSalesChartType.name == 'instant'"
                      :height="250"
                      ref="instantChartRS"
                      :chart-data="salesHistoryChart.chartData"
                      :extra-options="salesHistoryChart.extraOptions"
                    ></bar-chart>
                    <line-chart
                      v-else
                      :height="250"
                      ref="cumulativeChartRS"
                      :chart-data="salesHistoryChart.chartData"
                      :extra-options="salesHistoryChart.extraOptions"
                    ></line-chart>
                  </div>
                  <div class="chart-container col-12 mt-3" v-else>
                    <div class="no-data">
                      <div
                        v-if="loading.salesHistory"
                        class="flex-middle-center flex-column"
                      >
                        <base-spinner></base-spinner>
                        <p class="mt-1">Loading...</p>
                      </div>
                      <div v-else class="text-center">
                        <h5>No data yet</h5>
                        <p>
                          <router-link to="/app/settings/integrations"
                            >Connect</router-link
                          >
                          your e-commerce platform to track sales if you haven't
                          done it already
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="chart-period-container col-12 flex-center">
                    <ul class="flex-center w-100">
                      <li
                        v-for="(period, index) in chartPeriods"
                        :key="period.value"
                        class="period-item"
                        :class="selectedPeriodIndexRS == index ? 'active' : ''"
                        @click="setPeriodRS(index)"
                      >
                        <span>{{ period.label }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 mb-4">
                <div class="row">
                  <div class="col-12">
                    <h3>Revenue and sales by influencer</h3>
                  </div>
                  <div class="col-12">
                    <VueScrollableTable
                      v-if="leadPerformanceListRS.length"
                      class="performance-table"
                      :dead-area-color="'#F9FBE7'"
                      :scroll-horizontal="false"
                      :scroll-vertical="true"
                      :class="{ freezeFirstColumn: true }"
                    >
                      <template slot="thead">
                        <tr class="d-flex table-revenue">
                          <th class="name-column name-column--shallow">
                            {{ leadPerformanceListRS.length }} INFLUENCERS
                          </th>
                        </tr>
                        <tr class="d-flex table-revenue">
                          <th class="name-column name-column--shallow">
                            INFLUENCER
                          </th>
                          <th>Revenue</th>
                          <th>Refunds</th>
                          <th>Orders</th>
                          <th>Returns</th>
                        </tr>
                      </template>

                      <template slot="tbody">
                        <tr
                          v-for="lead in leadPerformanceListRS"
                          :key="lead.id"
                          class="d-flex table-revenue"
                        >
                          <td
                            class="name-column name-column--shallow flex-between"
                          >
                            <div class="d-flex flex-middle">
                              <div class="avatar ml-2">
                                <base-avatar
                                  :username="getFullName(lead.username)"
                                  :src="lead.profile_pic"
                                >
                                </base-avatar>
                              </div>
                              <div class="flex-column ml-2">
                                <div class="profile-name">
                                  {{ lead.username }}
                                </div>
                                <div class="profile-username">
                                  @
                                  {{ lead.username }}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td class="flex-middle">
                            {{
                              lead.gross_revenue
                                | NormalNumber(2)
                                | Currency(currencySymbols[campaign.currency])
                            }}
                          </td>
                          <td class="flex-middle">
                            {{
                              lead.total_refunded
                                | NormalNumber(2)
                                | Currency(currencySymbols[campaign.currency])
                            }}
                          </td>
                          <td class="flex-middle">
                            {{ lead.sales | BigNumber }}
                          </td>
                          <td class="flex-middle">
                            {{ lead.refunds | BigNumber }}
                          </td>
                        </tr>
                      </template>
                    </VueScrollableTable>
                    <div v-else class="no-data">
                      <div
                        v-if="loading.leadPerformanceListRS"
                        class="flex-middle-center flex-column"
                      >
                        <base-spinner></base-spinner>
                        <p class="mt-1">Loading...</p>
                      </div>
                      <div v-else class="text-center">
                        <h5>No data yet</h5>
                        <p>Click on the campaign name to activate tracking</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6 mb-4">
                <div class="row">
                  <div class="col-12">
                    <h3>Revenue and sales by coupon</h3>
                  </div>

                  <div class="col-12">
                    <VueScrollableTable
                      v-if="couponsPerformanceList.length"
                      class="performance-table"
                      :scroll-horizontal="true"
                      :scroll-vertical="true"
                      :sync-header-scroll="true"
                      :dead-area-color="'#F1F8E9'"
                      :class="{ freezeFirstColumn: true }"
                    >
                      <template slot="thead">
                        <tr class="d-flex table-count table-coupon">
                          <th class="name-column name-column--shallow">
                            {{ couponsPerformanceList.length }} COUPONS
                          </th>
                        </tr>
                        <tr class="table-count table-coupon">
                          <th class="name-column name-column--shallow">
                            COUPON
                          </th>
                          <th>Revenue</th>
                          <th>Refunds</th>
                          <th>Orders</th>
                          <th>Returns</th>
                          <th class="name-date">DATE ADDED</th>
                        </tr>
                      </template>
                      <template slot="tbody">
                        <tr
                          v-for="(coupon, index) in couponsPerformanceList"
                          :key="index"
                          class="d-flex table-coupon"
                          style="background: #8bc34a"
                        >
                          <td
                            class="name-column name-column--shallow flex-between"
                          >
                            <div class="flex-middle">
                              <img
                                class="integration-icon"
                                :src="
                                  availableIntegrations[coupon.integration_name]
                                    .image
                                "
                                :alt="
                                  coupon.store_name ||
                                  coupon.url ||
                                  availableIntegrations[coupon.integration]
                                "
                              />
                              <div class="coupon-name">
                                <a :href="coupon.integration_url">{{
                                  coupon.code
                                }}</a>
                              </div>
                            </div>
                          </td>
                          <td class="flex-middle">
                            {{
                              coupon.gross_revenue
                                | NormalNumber(2)
                                | Currency(currencySymbols[campaign.currency])
                            }}
                          </td>
                          <td class="flex-middle">
                            {{
                              coupon.total_refunded
                                | NormalNumber(2)
                                | Currency(currencySymbols[campaign.currency])
                            }}
                          </td>
                          <td class="flex-middle">
                            {{ coupon.sales | BigNumber }}
                          </td>
                          <td class="flex-middle">
                            {{ coupon.refunds | BigNumber }}
                          </td>
                          <td class="name-date">
                            {{ coupon.added_at | ShortDate(true) }}
                          </td>
                        </tr>
                      </template>
                    </VueScrollableTable>
                    <div v-else class="no-data">
                      <div
                        v-if="loading.couponsPerformanceList"
                        class="flex-middle-center flex-column"
                      >
                        <base-spinner></base-spinner>
                        <p class="mt-1">Loading...</p>
                      </div>
                      <div v-else class="text-center">
                        <h5>No data yet</h5>
                        <p>Click on the campaign name to activate tracking</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <SetPlansUpdatePanel
      :show="showSetPlansUpdate"
      @close="showSetPlansUpdate = false"
      :animationDuration="500"
    />
    <EditCampaignPanel
      :show="showEditCampaign"
      :currenciesList="currencies"
      :campaign="campaign"
      :loading="loading.save"
      @close="showEditCampaign = false"
      @save="saveCampaign"
      :animationDuration="500"
    />
  </div>
</template>
<script>
import {
  getTimeObject,
  getCampaignROI,
  getPermissionErrorMessage,
} from "@/utils";
import {
  CAMPAIGN_TRACKING_METRICS,
  CAMPAIGN_SALES_METRICS,
  CAMPAIGN_CHART_PERIODS,
  CAMPAIGN_CHART_TYPES,
  CURRENCY_SYMBOLS,
  MESSAGES,
  SOCIALS,
  AVAILABLE_INTEGRATIONS,
} from "@/constants";

/* components */
const SetPlansUpdatePanel = () => import("../components/SetPlansUpdatePanel");
const EditCampaignPanel = () => import("../components/EditCampaignPanel");
const VueScrollableTable = () => import("vue-scrolling-table");

import Multiselect from "vue-multiselect";
import CampaignNav from "../components/CampaignNav";

import * as chartConfigs from "@/components/Charts/config";
import LineChart from "@/components/Charts/LineChart";
import BarChart from "@/components/Charts/BarChart";
import RevenueRefunds from "@/components/Charts/RevenueRefunds";
import OrdersReturns from "@/components/Charts/OrdersReturns";
import BudgetSpend from "@/components/Charts/BudgetSpend";

import { mapState, mapGetters } from "vuex";

import { isMobile } from "mobile-device-detect";

export default {
  components: {
    SetPlansUpdatePanel,
    EditCampaignPanel,
    VueScrollableTable,
    CampaignNav,
    Multiselect,
    LineChart,
    BarChart,
    RevenueRefunds,
    OrdersReturns,
    BudgetSpend,
  },
  data() {
    return {
      filterbarBackground: "vue", //vue|blue|orange|green|red|primary
      getCampaignROI: getCampaignROI,
      getPermissionErrorMessage: getPermissionErrorMessage,
      socials: SOCIALS,
      availableIntegrations: AVAILABLE_INTEGRATIONS,

      trackingMetrics: CAMPAIGN_TRACKING_METRICS,
      selectedMetricLeads: CAMPAIGN_TRACKING_METRICS[0],
      selectedMetricContent: CAMPAIGN_TRACKING_METRICS[0],
      selectedMetricChart: CAMPAIGN_TRACKING_METRICS[0],

      salesMetrics: CAMPAIGN_SALES_METRICS,
      selectedMetricLeadsRS: CAMPAIGN_SALES_METRICS[0],
      selectedMetricCoupons: CAMPAIGN_SALES_METRICS[0],
      selectedSalesChart: CAMPAIGN_SALES_METRICS[0],

      checkboxSales: [
        { name: "revenue", show: true },
        { name: "refunds", show: false },
        { name: "orders", show: false },
        { name: "returns", show: false },
      ],

      checkboxTracking: [
        { name: "engagements", show: true },
        { name: "ikes", show: false },
        { name: "video-views", show: false },
      ],

      chartTypes: CAMPAIGN_CHART_TYPES,
      selectedTrackingChartType: CAMPAIGN_CHART_TYPES[0],
      selectedSalesChartType: CAMPAIGN_CHART_TYPES[0],

      chartPeriods: CAMPAIGN_CHART_PERIODS,
      currencySymbols: CURRENCY_SYMBOLS,
      selectedPeriodIndex: 2,
      selectedPeriodIndexRS: 2,
      leadPerformanceList: [],
      leadPerformanceListRS: [],
      contentPerformanceList: [],
      couponsPerformanceList: [],
      campaign: null,
      leads: [],
      trackingHistoryChart: {
        allData: [[]],
        activeIndex: 0,
        chartData: {
          datasets: [],
          labels: [],
        },
        extraOptions: chartConfigs.areaChartOptions,
      },
      salesHistoryChart: {
        allData: [[]],
        activeIndex: 0,
        chartData: {
          datasets: [],
          labels: [],
        },
        extraOptions: chartConfigs.areaChartOptions,
      },
      influencersHistoryChart: {
        totalInfluencers: 0,
        allData: [],
        activeIndex: 0,
        chartData: {
          datasets: [],
          labels: [],
        },
        extraOptions: chartConfigs.kpiChartOptions,
      },
      postsHistoryChart: {
        allData: [],
        activeIndex: 0,
        chartData: {
          datasets: [],
          labels: [],
        },
        extraOptions: chartConfigs.kpiChartOptions,
      },
      keyDescription: isMobile
        ? "Tap on each one to learn more about it"
        : "Hover your mouse on each one to learn more about it",
      showSetPlansUpdate: false,
      showEditCampaign: false,
      loading: {
        campaign: false,
        save: false,
        leadPerformance: false,
        leadPerformanceListRS: false,
        contentPerformance: false,
        couponsPerformance: false,
        trackingHistory: false,
        salesHistory: false,
        influencersHistory: false,
        postsHistory: false,
        active: false,
        postsHistoryChart: false,
      },
      headerHeight: 0,
      platformOffsetY: 0,
      resizeHandler: null,
    };
  },
  props: {
    id: {
      type: String,
      description: "Campaign ID",
    },
  },
  computed: {
    ...mapState({
      currencies: (state) => state.user.currencies,
      filter: (state) => state.campaign.filter,
      alert: (state) => state.alert,
      showAlert: (state) => state.alert.showAlert,
    }),
    ...mapGetters({
      hasFullAccess: "user/hasFullAccess",
      hasViewAccess: "user/hasViewAccess",
    }),
    RSisFirst: {
      get() {
        if (
          this.campaign.sales * 1 > 0 ||
          this.campaign.refunds * 1 > 0 ||
          this.campaign.gross_revenue * 1 > 0
        ) {
          return true;
        } else if (this.campaign.engagements * 1 > 0) {
          return false;
        } else {
          return true;
        }
      },
    },
    contentHeight: {
      get() {
        return `calc(100vh - ${this.headerHeight}px - ${
          this.platformOffsetY + 30
        }px)`;
      },
    },
  },
  watch: {
    $route: function (to) {
      this.id = to.params.id;
      this.getSingleCampaign();
      // to reload userpilot
      this.$store.dispatch("user/setRoute", to.path);
    },
    selectedMetricLeads: function () {
      this.getCampaignLeadsPerformance();
    },
    selectedMetricLeadsRS: function () {
      this.getCampaignLeadsPerformanceRS();
    },
    selectedMetricContent: function () {
      this.getCampaignContentPerformance();
    },
    selectedMetricCoupons: function () {
      this.getCampaignCouponsPerformance();
    },
    selectedMetricChart: function (obj) {
      this.trackingHistoryChart.activeIndex = CAMPAIGN_TRACKING_METRICS.map(
        (e) => e.name
      ).indexOf(obj.name);
      this.selectTrackingHistoryChart();
    },
    selectedSalesChart: function (obj) {
      this.salesHistoryChart.activeIndex = CAMPAIGN_SALES_METRICS.map(
        (e) => e.name
      ).indexOf(obj.name);
      this.selectSalesHistoryChart();
    },
    selectedTrackingChartType: function () {
      this.getCampaignTrackingHistory();
    },
    selectedSalesChartType: function () {
      this.getSalesHistory();
    },
    showAlert: function (show) {
      if (!show) return;
      if (this.alert.content === MESSAGES["campaigns__max-active-reached"]) {
        this.showSetPlansUpdate = true;
      }
    },
  },
  async mounted() {
    // to reload userpilot
    this.$store.dispatch("user/setRoute", this.$route.path);

    if (!this.resizeHandler) {
      this.resizeHandler = window.addEventListener("resize", this.resizeWindow);
    }

    await this.getSingleCampaign();
    await this.getInfluencersHistory();
    await this.getPostsHistory();
    if (this.RSisFirst) {
      await this.getSalesHistory();
      await this.getCampaignLeadsPreview();
      await this.getCampaignLeadsPerformanceRS();
      await this.getCampaignCouponsPerformance();
      await this.getCampaignTrackingHistory();
      await this.getCampaignLeadsPerformance();
      await this.getCampaignContentPerformance();
    } else {
      await this.getCampaignTrackingHistory();
      await this.getCampaignLeadsPreview();
      await this.getCampaignLeadsPerformance();
      await this.getCampaignContentPerformance();
      await this.getSalesHistory();
      await this.getCampaignLeadsPerformanceRS();
      await this.getCampaignCouponsPerformance();
    }

    this.getCurrenciesList();

    setTimeout(() => {
      this.resizeWindow();
    }, 100);
  },
  beforeDestroy() {
    if (this.resizeHandler) {
      window.removeEventListener("resize", this.resizeWindow);
      this.resizeHandler = null;
    }
  },
  methods: {
    resizeWindow() {
      const isMobileSafari =
        navigator.userAgent.match(/(iPod|iPhone|iPad)/) &&
        navigator.userAgent.match(/AppleWebKit/);
      if (isMobileSafari) {
        this.platformOffsetY = 114;
      }
      const searchTopbars = document.querySelectorAll(".topbar");
      this.headerHeight = 0;//document.querySelector(".navbar-top").clientHeight;
      if (searchTopbars.length) {
        searchTopbars.forEach((el) => {
          this.headerHeight += el.clientHeight;
        });
      }
    },
    toggleCampaign() {
      if (this.campaign.active) {
        this.setCampaignInactive();
      } else {
        this.setCampaignActive();
      }
    },
    getLeadInfo(leadId) {
      const leadIndex = this.leads.map((e) => e.id).indexOf(leadId);
      return this.leads[leadIndex] || {};
    },
    getUsername(obj) {
      if (obj.social === SOCIALS.youtube.id) {
        return obj.id;
      }
      return obj.username;
    },
    getFullName(obj) {
      if (obj.social === SOCIALS.instagram.id) {
        return obj.data.full_name ? obj.data.full_name : obj.username;
      } else if (obj.social === SOCIALS.tiktok.id) {
        return obj.data.nickname ? obj.data.nickname : obj.username;
      } else if (obj.social === SOCIALS.youtube.id) {
        return obj.data.title ? obj.data.title : obj.id;
      }
      return obj.full_name ? obj.full_name : obj.username;
    },
    async setCampaignActive() {
      this.loading.active = true;
      try {
        const result = await this.$store.dispatch(
          "campaign/setCampaignActive",
          {
            campaign_id: this.id,
          }
        );
        if (result) {
          this.campaign.active = true;
        }
        this.loading.active = false;
      } catch {
        this.loading.active = false;
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["common-500"],
            alertClass: "danger",
          });
        }
      }
    },
    async setCampaignInactive() {
      this.loading.active = true;
      try {
        const result = await this.$store.dispatch(
          "campaign/setCampaignInactive",
          {
            campaign_id: this.id,
          }
        );
        this.loading.active = false;
        if (result) {
          this.campaign.active = false;
        }
      } catch {
        this.loading.active = false;
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["common-500"],
            alertClass: "danger",
          });
        }
      }
    },
    async getSingleCampaign() {
      this.loading.campaign = true;
      try {
        const { data } = await this.$store.dispatch(
          "campaign/getSingleCampaign",
          this.id
        );
        this.campaign = data;
        this.campaign.imv =
          (this.campaign.imv_coef * this.campaign.impressions) / 1000;

        this.campaign.ecpm =
          this.campaign.impressions * 1 === 0
            ? 0
            : this.campaign.spend / (this.campaign.impressions / 1000);
        this.campaign.cpe =
          this.campaign.engagements * 1 === 0
            ? 0
            : this.campaign.spend / (this.campaign.engagements / 1000);
        this.loading.campaign = false;
      } catch {
        this.campaign = null;
        this.loading.campaign = false;

        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["campaign__get_single_campaign-failed"],
            alertClass: "danger",
          });
        }
      }
    },
    async getCampaignLeadsPerformance() {
      this.loading.leadPerformance = true;
      try {
        const { data } = await this.$store.dispatch(
          "campaign/getCampaignLeadsPerformance",
          {
            campaign_id: this.id,
            metric: this.selectedMetricLeads.name,
          }
        );
        this.leadPerformanceList = data.leads;
        this.loading.leadPerformance = false;
      } catch {
        this.loading.leadPerformance = false;
        this.leadPerformanceList = [];
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["common-500"],
            alertClass: "danger",
          });
        }
      }
    },
    async getCampaignLeadsPerformanceRS() {
      this.loading.leadPerformanceListRS = true;
      try {
        const { data } = await this.$store.dispatch(
          "campaign/getCampaignLeadsPerformance",
          {
            campaign_id: this.id,
            metric: this.selectedMetricLeadsRS.name,
          }
        );
        this.leadPerformanceListRS = data.leads;
        this.loading.leadPerformanceListRS = false;
      } catch {
        this.leadPerformanceListRS = [];
        this.loading.leadPerformanceListRS = false;
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["common-500"],
            alertClass: "danger",
          });
        }
      }
    },
    async getCampaignContentPerformance() {
      this.loading.contentPerformance = true;
      try {
        const { data } = await this.$store.dispatch(
          "campaign/getCampaignContentPerformance",
          {
            campaign_id: this.id,
            metric: this.selectedMetricContent.name,
          }
        );
        this.contentPerformanceList = data.content_pieces;

        this.loading.contentPerformance = false;
      } catch {
        this.loading.contentPerformance = false;
        this.contentPerformanceList = [];
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["common-500"],
            alertClass: "danger",
          });
        }
      }
    },
    async getCampaignCouponsPerformance() {
      this.loading.couponsPerformance = true;
      try {
        const { data } = await this.$store.dispatch(
          "campaign/getCampaignCouponsPerformance",
          {
            campaign_id: this.id,
            metric: this.selectedMetricCoupons.name,
          }
        );
        this.couponsPerformanceList = data.coupons;

        this.loading.couponsPerformance = false;
      } catch {
        this.loading.couponsPerformance = false;
        this.couponsPerformanceList = [];
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["common-500"],
            alertClass: "danger",
          });
        }
      }
    },
    async getCampaignLeadsPreview() {
      this.leads = [];
      this.loading.leads = true;
      try {
        const data = await this.$store.dispatch(
          "campaign/getCampaignLeadsPreview",
          {
            campaign_id: this.id,
          }
        );
        this.leads = data;
        this.loading.leads = false;
      } catch {
        this.loading.leads = false;
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["campaign__get_leads_preview-failed"],
            alertClass: "danger",
          });
        }
      }
    },
    async getCampaignTrackingHistory() {
      this.loading.trackingHistory = true;
      const currentTimestamp = Math.round(new Date().getTime() / 1000);
      const startingFrom = CAMPAIGN_CHART_PERIODS[this.selectedPeriodIndex]
        .value
        ? currentTimestamp -
          CAMPAIGN_CHART_PERIODS[this.selectedPeriodIndex].value
        : null;
      const { resolution } = CAMPAIGN_CHART_PERIODS[this.selectedPeriodIndex];
      try {
        const { data } = await this.$store.dispatch(
          "campaign/getCampaignTrackingHistory",
          {
            campaign_id: this.id,
            type: this.selectedTrackingChartType.name,
            starting_from: startingFrom,
            resolution: resolution,
          }
        );
        this.loading.trackingHistory = false;
        const { tracking_history } = data;
        this.buildTrackingHistoryChart(tracking_history);
      } catch {
        this.loading.trackingHistory = false;

        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["common-500"],
            alertClass: "danger",
          });
        }
      }
    },
    async getSalesHistory() {
      this.loading.salesHistory = true;
      const currentTimestamp = Math.round(new Date().getTime() / 1000);
      const startingFrom = CAMPAIGN_CHART_PERIODS[this.selectedPeriodIndexRS]
        .value
        ? currentTimestamp -
          CAMPAIGN_CHART_PERIODS[this.selectedPeriodIndexRS].value
        : null;
      const { resolution } = CAMPAIGN_CHART_PERIODS[this.selectedPeriodIndexRS];
      try {
        const { data } = await this.$store.dispatch(
          "campaign/getSalesHistory",
          {
            campaign_id: this.id,
            type: this.selectedSalesChartType.name,
            starting_from: startingFrom,
            resolution: resolution,
          }
        );
        this.loading.salesHistory = false;
        const { sales_history } = data;
        this.buildSalesHistoryChart(sales_history);
      } catch {
        this.loading.salesHistory = false;

        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["common-500"],
            alertClass: "danger",
          });
        }
      }
    },

    async getInfluencersHistory() {
      this.loading.influencersHistory = true;
      try {
        const { data } = await this.$store.dispatch(
          "campaign/getInfluencersHistory",
          {
            campaign_id: this.id,
          }
        );
        this.loading.influencersHistory = false;
        const influencers_history = data;
        this.buildInfluencersHistory(influencers_history);
      } catch {
        this.loading.influencersHistory = false;
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["common-500"],
            alertClass: "danger",
          });
        }
      }
    },
    async getPostsHistory() {
      this.loading.postsHistory = true;

      try {
        const { data } = await this.$store.dispatch(
          "campaign/getPostsHistory",
          { campaign_id: this.id }
        );
        this.loading.postsHistory = false;
        const posts_history = data;
        // console.log('posts_history',posts_history);

        this.buildPostsHistory(posts_history);
      } catch {
        this.loading.postsHistory = false;
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["common-500"],
            alertClass: "danger",
          });
        }
      }
    },

    async getCurrenciesList() {
      if (this.currencies.length > 0) return;
      this.loading.currencies = true;
      try {
        await this.$store.dispatch("user/getCurrenciesList");
        this.loading.currencies = false;
      } catch {
        this.loading.currencies = false;
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["user__get_currencies-failed"],
            alertClass: "danger",
          });
        }
      }
    },
    async saveCampaign(campaign) {
      this.loading.save = true;
      try {
        const result = await this.$store.dispatch("campaign/saveCampaign", {
          id: this.campaign.id,
          name: campaign.name,
          imv_coef: campaign.imv_coef,
          budget: campaign.budget,
          currency: campaign.currency,
          color: campaign.color,
        });
        this.loading.save = false;
        if (result.status === 201) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["campaign__update_campaign-success"],
            alertClass: "success",
          });
          this.getSingleCampaign();
          this.showEditCampaign = false;
        }
      } catch {
        this.loading.save = false;
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["campaign__update_campaign-failed"],
            alertClass: "danger",
          });
        }
      }
    },
    setPeriod(index) {
      this.selectedPeriodIndex = index;
      this.getCampaignTrackingHistory();
    },
    setPeriodRS(index) {
      this.selectedPeriodIndexRS = index;
      this.getSalesHistory();
    },
    buildTrackingHistoryChart(rawData) {
      let allData = [];
      const selectedChartPeriod =
        CAMPAIGN_CHART_PERIODS[this.selectedPeriodIndex];
      CAMPAIGN_TRACKING_METRICS.forEach((metric) => {
        const data = rawData.map((e) => e[metric.name]);
        allData.push(data);
      });
      const labels = rawData.map((e) => {
        const t = getTimeObject(e.ts);
        switch (selectedChartPeriod.label) {
          case "1D":
            return `${t.hour}:${t.minute}`;
          case "1W":
            return `${t.month} ${t.day} ${t.hour}:${t.minute}`;
          case "1M":
          case "3M":
          case "6M":
            return `${t.month} ${t.day}`;
          case "1Y":
            return `${t.month}`;
          default:
            return `${t.month} ${t.day}`;
        }
      });

      this.trackingHistoryChart.allData = allData;
      this.trackingHistoryChart.chartData.labels = labels;
      this.checkTrackingChart();
      // this.selectTrackingHistoryChart();
    },
    buildSalesHistoryChart(rawData) {
      let allData = [];
      const selectedChartPeriod =
        CAMPAIGN_CHART_PERIODS[this.selectedPeriodIndex];
      CAMPAIGN_SALES_METRICS.forEach((metric) => {
        const data = rawData.map((e) => e[metric.name]);
        allData.push(data);
      });
      const labels = rawData.map((e) => {
        const t = getTimeObject(e.ts);
        switch (selectedChartPeriod.label) {
          case "1D":
            return `${t.hour}:${t.minute}`;
          case "1W":
            return `${t.month} ${t.day} ${t.hour}:${t.minute}`;
          case "1M":
          case "3M":
          case "6M":
            return `${t.month} ${t.day}`;
          case "1Y":
            return `${t.month}`;
          default:
            return `${t.month} ${t.day}`;
        }
      });

      this.salesHistoryChart.allData = allData;
      this.salesHistoryChart.chartData.labels = labels;
      //this.selectSalesHistoryChart();
      this.checkHistoryChart();
    },
    buildInfluencersHistory(rawData) {
      const data = [];

      for (let i = 0; i < rawData.length; i++) {
        data.push(rawData[i].count);
      }


      this.influencersHistoryChart.totalInfluencers = rawData.length == 0 ? 0 :
        rawData[rawData.length - 1].count;

      const labels = rawData.map((e) => {
        const t = getTimeObject(e.ts);
        const timeScale = "6M";

        switch (timeScale) {
          case "1D":
            return `${t.hour}:${t.minute}`;
          case "1W":
            return `${t.month} ${t.day} ${t.hour}:${t.minute}`;
          case "1M":
          case "3M":
          case "6M":
            return `${t.month} ${t.day}`;
          case "1Y":
            return `${t.month}`;
          default:
            return `${t.month} ${t.day}`;
        }
      });

      this.influencersHistoryChart.allData = data;
      this.influencersHistoryChart.chartData.labels = labels;

      this.selectInfluencersHistoryChart();
    },
    buildPostsHistory(rawData) {
      const data = [];

      for (let i = 0; i < rawData.length; i++) {
        data.push(rawData[i].count);
      }
      const labels = rawData.map((e) => {
        const t = getTimeObject(e.ts);
        const timeScale = "6M";

        switch (timeScale) {
          case "1D":
            return `${t.hour}:${t.minute}`;
          case "1W":
            return `${t.month} ${t.day} ${t.hour}:${t.minute}`;
          case "1M":
          case "3M":
          case "6M":
            return `${t.month} ${t.day}`;
          case "1Y":
            return `${t.month}`;
          default:
            return `${t.month} ${t.day}`;
        }
      });

      this.postsHistoryChart.allData = data;
      this.postsHistoryChart.chartData.labels = labels;
      this.selectPostsHistoryChart();
    },

    selectTrackingHistoryChart() {
      const index = this.trackingHistoryChart.activeIndex;
      const chartData = {
        datasets: [
          {
            backgroundColor:
              this.selectedTrackingChartType.name === "instant"
                ? CAMPAIGN_TRACKING_METRICS[index].primary_color
                : CAMPAIGN_TRACKING_METRICS[index].background_color,
            borderColor: CAMPAIGN_TRACKING_METRICS[index].primary_color,
            pointBackgroundColor: "#fff",
            pointBorderWidth: 1,
            lineTension: 0.6,
            label: CAMPAIGN_TRACKING_METRICS[index].label,
            data: this.trackingHistoryChart.allData[index],
            pointHoverRadius: 1,
            pointHoverBorderWidth: 1,
          },
        ],
        labels: this.trackingHistoryChart.chartData.labels, //["May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
      };
      this.trackingHistoryChart.chartData = chartData;
      this.loading.trackingHistory = true;
      setTimeout(() => {
        this.loading.trackingHistory = false;
      }, 100);
    },
    selectSalesHistoryChart() {
      // const options = [];
      // for (let index = 0; index <= 3; index++) {
      //   console.log("index", CAMPAIGN_SALES_METRICS[index].show);
      //   options.push({
      //     backgroundColor:
      //       this.selectedSalesChartType.name === "instant"
      //         ? CAMPAIGN_SALES_METRICS[index].primary_color
      //         : CAMPAIGN_SALES_METRICS[index].background_color,
      //     borderColor: CAMPAIGN_SALES_METRICS[index].primary_color,
      //     pointBackgroundColor: "#fff",
      //     pointBorderWidth: 1,
      //     lineTension: 0.3,
      //     label: CAMPAIGN_SALES_METRICS[index].label,
      //     prefix:
      //       CAMPAIGN_SALES_METRICS[index].name === "gross_revenue" ||
      //       CAMPAIGN_SALES_METRICS[index].name === "total_refunded"
      //         ? CURRENCY_SYMBOLS[this.campaign.currency]
      //         : null,
      //     data: this.salesHistoryChart.allData[index],
      //     pointHoverRadius: 2,
      //     pointHoverBorderWidth: 2,
      //   });
      // }
      // if (this.checkboxSales.revenue) {
      //   console.log("tomy", this.checkboxSales.revenue);
      // } else {
      //   console.log("noa", this.checkboxSales.revenue);
      // }
      // console.log("this.checkboxSales", this.checkboxSales);
      // console.log("options", options);
      // const chartData = {
      //   datasets: [options[0], options[1], options[2], options[3]],
      //   labels: this.salesHistoryChart.chartData.labels,
      // };
      // this.salesHistoryChart.chartData = chartData;
      // console.log(" this.salesHistoryChart.chartData", this.salesHistoryChart);
      // this.loading.salesHistory = true;
      // setTimeout(() => {
      //   this.loading.salesHistory = false;
      // }, 100);
    },
    checkHistoryChart() {
      const options = [];
      for (let index = 0; index <= 3; index++) {
        if (this.checkboxSales[index].show) {
          options.push({
            backgroundColor:
              this.selectedSalesChartType.name === "instant"
                ? CAMPAIGN_SALES_METRICS[index].primary_color
                : CAMPAIGN_SALES_METRICS[index].background_color,
            borderColor: CAMPAIGN_SALES_METRICS[index].primary_color,
            pointBackgroundColor: "#fff",
            pointBorderWidth: 1,
            lineTension: 0.3,
            label: CAMPAIGN_SALES_METRICS[index].label,
            prefix:
              this.checkboxSales[index].name == "revenue" ||
              this.checkboxSales[index].name == "refunds"
                ? null
                : CURRENCY_SYMBOLS[this.campaign.currency],
            data: this.salesHistoryChart.allData[index],
            pointHoverRadius: 2,
            pointHoverBorderWidth: 2,
          });
        } else {
          options.push({});
        }
      }

      var total_options = [options[0], options[1], options[2], options[3]];

      const chartData = {
        datasets: total_options,
        labels: this.salesHistoryChart.chartData.labels,
      };
      this.salesHistoryChart.chartData = chartData;
      this.loading.salesHistory = true;
      setTimeout(() => {
        this.loading.salesHistory = false;
      }, 100);
    },
    checkTrackingChart() {
      const options = [];
      for (let index = 0; index <= 2; index++) {
        if (this.checkboxTracking[index].show) {
          options.push({
            backgroundColor:
              this.selectedTrackingChartType.name === "instant"
                ? CAMPAIGN_TRACKING_METRICS[index].primary_color
                : CAMPAIGN_TRACKING_METRICS[index].background_color,
            borderColor: CAMPAIGN_TRACKING_METRICS[index].primary_color,
            pointBackgroundColor: "#fff",
            pointBorderWidth: 1,
            lineTension: 0.3,
            label: CAMPAIGN_TRACKING_METRICS[index].label,
            prefix:
              this.checkboxTracking[index].name == "revenue" ||
              this.checkboxTracking[index].name == "refunds"
                ? null
                : null,
            data: this.trackingHistoryChart.allData[index],
            pointHoverRadius: 2,
            pointHoverBorderWidth: 2,
          });
        } else {
          options.push({});
        }
      }

      var total_options = [options[0], options[1], options[2]];

      const chartData = {
        datasets: total_options,
        labels: this.trackingHistoryChart.chartData.labels,
      };
      this.trackingHistoryChart.chartData = chartData;
      this.loading.trackingHistory = true;
      setTimeout(() => {
        this.loading.trackingHistory = false;
      }, 100);
    },
    selectInfluencersHistoryChart() {
      const chartData = {
        datasets: [
          {
            backgroundColor: "#F1F8E9",
            borderColor: "#CDDC39",
            pointBackgroundColor: "#fff",
            pointBorderWidth: 1,
            lineTension: 0.5,
            label: "Influencers",
            data: this.influencersHistoryChart.allData,
            pointHoverRadius: 1,
            pointHoverBorderWidth: 1,
          },
        ],
        labels: this.influencersHistoryChart.chartData.labels, //["May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
      };

      this.influencersHistoryChart.chartData = chartData;

      this.loading.influencersHistory = true;
      setTimeout(() => {
        this.loading.influencersHistory = false;
      }, 100);
    },
    selectPostsHistoryChart() {
      const chartData = {
        datasets: [
          {
            backgroundColor: "#E0F2F1",
            borderColor: "#8BC34A",
            pointBackgroundColor: "#fff",
            pointBorderWidth: 1,
            lineTension: 0.5,
            label: "Posts",
            data: this.postsHistoryChart.allData,
            pointHoverRadius: 1,
            pointHoverBorderWidth: 1,
          },
        ],
        labels: this.postsHistoryChart.chartData.labels, //["May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
      };

      this.postsHistoryChart.chartData = chartData;

      this.loading.postsHistoryChart = true;
      setTimeout(() => {
        this.loading.postsHistoryChart = false;
      }, 100);
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import "../assets/scss/pages/campaigns";
</style>
