<template>
  <div
    class="tab-pane fade set-emails-panel"
    :class="[{ 'modal-mini': type === 'mini' }]"
    v-bind:id="tabId"
  >
    <div class="main-content">
      <!-- The list of the Outlook email accounts -->
      <template v-if="emails.outlook && emails.outlook.length">
        <div class="setting-label px-4">Outlook:</div>
        <ul class="email-accounts-list">
          <li
            v-for="(item, index) in emails.outlook"
            :key="index"
            class="email-account"
          >
            <div class="email-account-card">
              <a
                v-if="item.token_expired"
                class="expired-icon"
                v-b-tooltip.hover
                title="Outlook revoked the authorization for this email account. You need to connect it again to allow Ninja Influence to work properly again."
              >
                <i class="fas fa-exclamation-triangle"></i>
              </a>
              <div class="email-account-icon">
                <img :src="`/app/img/icons/common/outlook.svg`" />
              </div>
              <span class="ml-3 text-overflow-ellipsis item-email">{{
                item.address
              }}</span>
            </div>
            <div class="email-account-actions">
              <div class="btn-group" role="group" aria-label="Email action">
                <base-button
                  v-if="item.token_expired"
                  :disabled="!hasFullAccess('integrations')"
                  :disabledTooltip="
                    getPermissionErrorMessage('integrations:full')
                  "
                  title="Reconnect"
                  type="success"
                  :isBusy="disconnecting"
                  icon="fas fa-sync"
                  class="btn-light"
                  @click="reconnectEmail('outlook', item)"
                >
                </base-button>
                <base-button
                  :disabled="!hasFullAccess('integrations')"
                  :disabledTooltip="
                    getPermissionErrorMessage('integrations:full')
                  "
                  type="danger"
                  v-b-tooltip.hover
                  title="Delete permanently"
                  :isBusy="disconnecting"
                  icon="far fa-trash-alt"
                  class="btn-light"
                  @click="disconnectEmail('outlook', item)"
                >
                </base-button>
              </div>
            </div>
          </li>
        </ul>
      </template>
      <!-- The list of the Gmail email accounts (native integrations, disabled for now) -->
      <template v-if="emails.gmailNative && emails.gmailNative.length && false">
        <div class="setting-label mt-3 px-4">Gmail:</div>
        <ul class="email-accounts">
          <li
            v-for="(item, index) in emails.gmail"
            :key="index"
            class="p-3 my-1 flex-middle-between flex-wrap"
          >
            <div class="flex-middle">
              <a
                v-if="item.token_expired"
                class="expired-icon"
                v-b-tooltip.hover
                title="Gmail revoked the authorization for this email account. You need to connect it again to allow Ninja Influence to work properly again."
              >
                <i class="fas fa-exclamation-triangle"></i>
              </a>
              <div class="card-icon">
                <img :src="`/app/img/icons/common/gmail.svg`" />
              </div>
              <span class="ml-3 text-overflow-ellipsis item-email">{{
                item.address
              }}</span>
            </div>
            <div class="btn-group" role="group" aria-label="Email action">
              <base-button
                v-if="item.token_expired"
                v-b-tooltip.hover
                title="Reconnect"
                type="success"
                :disabled="disconnecting"
                icon="fas fa-sync"
                class="reconnect-email"
                @click="reconnectEmail('gmail', item)"
              >
              </base-button>
              <base-button
                type="danger"
                v-b-tooltip.hover
                title="Delete permanently"
                :disabled="disconnecting"
                icon="far fa-trash-alt"
                class="remove-email"
                @click="disconnectEmail('gmail', item)"
              >
              </base-button>
            </div>
          </li>
        </ul>
      </template>
      <!-- The list of the Gmail email accounts -->
      <template v-if="emails.gmail && emails.gmail.length">
        <div class="setting-label mt-3 px-4">Gmail:</div>
        <ul class="email-accounts-list">
          <li
            v-for="(item, index) in emails.gmail"
            :key="index"
            class="email-account"
          >
            <div class="email-account-card">
              <div class="email-account-icon">
                <img :src="`/app/img/icons/common/gmail.svg`" />
              </div>
              <span class="ml-3 text-overflow-ellipsis item-email">{{
                item.address
              }}</span>
            </div>

            <div class="email-account-actions" aria-label="Email action">
              <base-button
                type="danger"
                v-b-tooltip.hover
                title="Delete permanently"
                :disabled="disconnecting"
                icon="far fa-trash-alt"
                class="btn-light"
                @click="disconnectEmail('gmail', item)"
              >
              </base-button>
            </div>
          </li>
        </ul>
      </template>
      <div class="text-divider full"></div>
      <!-- Add Outlook account button -->
      <div
        class="flex-middle-center w-100 flex-column"
        :class="emails.outlook && emails.outlook.length ? '' : ''"
      >
        <base-button
          v-if="hasFullAccess('integrations')"
          class="btn-black"
          type="primary"
          @click="requestEmail({ name: 'outlook' })"
        >
          <img
            class=""
            src="/app/img/icons/common/outlook-white.svg"
            slot="icon"
          />
          Sign in with Outlook
        </base-button>
        <a
          v-else
          v-b-tooltip.hover
          :title="getPermissionErrorMessage('integrations:full')"
        >
          <base-button class="btn-black" type="primary" disabled>
            <img
              class=""
              src="/app/img/icons/common/outlook-white.svg"
              slot="icon"
            />
            Sign in with Outlook
          </base-button>
        </a>
      </div>

      <div class="text-divider">or</div>
      <!-- Add Gmail account button (Native gmail integration) -->
      <!--<div class="flex-middle-center w-100 flex-column">
         <p class="setting-label mb-1 mt-3">Connect Gmail Address:</p>
        <base-button
          class="my-b"
          type="primary"
          icon="fab fa-google"
          @click="requestEmail({ name: 'gmail' })"
        >
          Sign in with Gmail
        </base-button> 
      </div>-->
      <!-- Add Gmail account button (smtp/imap integration) -->
      <div class="flex-middle-center w-100">
        <span
          ref="connect-gmail-help-button"
          class="help-mark material-icons-outlined"
          @click="showGmailHelpPopup = true"
        >
          info
        </span>

        <base-button
          class="btn-light connect-gmail-button"
          type="primary"
          icon="fab fa-google"
          @click="openConnectGmailForm"
        >
          Signing up with Gmail
        </base-button>
      </div>

      <!-- Tooltip with a help text for adding Gmail (smtp/imap) account -->
      <!-- popover-custom -->
      <div
        v-show="showGmailHelpPopup"
        ref="connect-gmail-help-popup"
        class="gmail-help-popup"
        role="tooltip"
      >
        <button
          type="button"
          class="close close-popover"
          @click="showGmailHelpPopup = false"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>

        <ul>
          <li>
            Check that IMAP is enabled via this link:
            <a
              href="https://mail.google.com/mail/u/0/#settings/fwdandpop"
              target="_blank"
              >https://mail.google.com/mail/u/0/#settings/fwdandpop</a
            >. Screenshot:
            <a href="https://prnt.sc/t8zayp" target="_blank">
              https://prnt.sc/t8zayp</a
            >
          </li>
          <li>
            Turn on the 2-step verification via this link:
            <a href="https://myaccount.google.com/u/0/security-checkup/1?continue=https%3A%2F%2Fmyaccount.google.com%2Fu%2F1%2Fsecurity" target="_blank"
              >https://myaccount.google.com/u/0/security-checkup/1?continue=https%3A%2F%2Fmyaccount.google.com%2Fu%2F1%2Fsecurity</a
            >. Screenshot:
            <a href="https://prnt.sc/pKaxB2yQhOQ_" target="_blank"
              >https://prnt.sc/pKaxB2yQhOQ_</a
            >
          </li>
          <li>
            Create application password via link:
            <a
              href="https://myaccount.google.com/security?hl=en"
              target="_blank"
              >https://myaccount.google.com/security?hl=en</a>
            (this this option is only available if you have turned 2-step verification on the previous point)
            Screenshot:
            <a href="https://prnt.sc/RJzXu-ufGzeK" target="_blank"
              >https://prnt.sc/RJzXu-ufGzeK</a>
          </li>
          <li>
            Choose «Custom name» option (Screenshot:
            <a href="https://prnt.sc/9gpnxzrWwAIN" target="_blank">https://prnt.sc/9gpnxzrWwAIN</a>) and enter any name you can think of, for example: Ninja Influence, NI, etc.
          </li>
          <li>
            Use the password from the form instead of the regular one to connect account to the NI. Screenshot:
            <a href="https://prnt.sc/HBGlQP7gc1aC" target="_blank">https://prnt.sc/HBGlQP7gc1aC</a>
          </li>

        </ul>

        <!-- <div class="popover-arrow" data-popper-arrow></div> -->
      </div>

      <!-- Add Gmail (smtp/imap) account -->
      <form @submit.prevent="submitGmailForm" v-if="showConnectGmailForm">
        <div class="panel-block">
          <div class="panel-item">
            <div class="mb-2 setting-label mt-1">
              <span class="nav-link-text">Email</span>
            </div>
            <base-input
              id="input_gmail_email"
              class="input-group-alternative mb-3"
              placeholder="Email"
              v-model="gmailAccount.email"
              :error="gmailAccountErrors.email"
              type="email"
              autofocus
            ></base-input>
          </div>
          <div class="panel-item">
            <div class="mb-2 setting-label mt-1">
              <span class="nav-link-text">From name</span>
            </div>
            <base-input
              id="input_gmail_from_name"
              class="input-group-alternative mb-3"
              placeholder="From Name"
              v-model="gmailAccount.fromName"
              :error="gmailAccountErrors.fromName"
              type="text"
              autofocus
            ></base-input>
          </div>
          <div class="panel-item">
            <div class="mb-2 setting-label mt-1">
              <span class="nav-link-text">Password</span>
            </div>
            <base-input
              id="input_gmail_password"
              class="input-group-alternative mb-3"
              placeholder="Password"
              v-model="gmailAccount.password"
              :error="gmailAccountErrors.password"
              type="password"
              autofocus
            ></base-input>
          </div>
          <div class="save-block">
            <base-button
              type="primary"
              nativeType="submit"
              :class="'btn btn-light mw-150'"
              @click="hideConnectGmailForm"
            >
              Cancel
            </base-button>
            <base-button
              :isBusy="loading.submitGmailForm"
              type="primary"
              nativeType="submit"
              :class="'btn btn-black mw-150'"
            >
              Connect
            </base-button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import { createPopper } from "@popperjs/core";

import { getPermissionErrorMessage } from "@/utils";
import { MESSAGES } from "@/constants";
import * as Configs from "../configs";
import GoogleSignInButton from "vue-google-signin-button-directive";

export default {
  name: "set-emails-panel",
  directives: {
    GoogleSignInButton
  },
  props: {
    currenciesList: {
      type: Array
    },
    show: Boolean,
    type: {
      type: String,
      default: "",
      validator(value) {
        let acceptedValues = ["", "notice", "mini"];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Modal type (notice|mini|"") '
    },
    animationDuration: {
      type: Number,
      default: 500,
      description: "Modal transition duration"
    },
    disconnecting: {
      type: Boolean,
      default: false
    },
    tabId: String
  },
  data() {
    return {
      getPermissionErrorMessage: getPermissionErrorMessage,
      loading: {
        connect: false,
        request: false,
        submitGmailForm: false
      },
      googleClientId: Configs.services.google.client_id,
      currency: null,
      imvCoef: 0,
      localError: false,
      showConnectGmailForm: false,
      gmailAccount: {
        email: null,
        fromName: null,
        password: null
      },
      gmailAccountErrors: {
        email: null,
        fromName: null,
        password: null
      },
      showGmailHelpPopup: false
    };
  },
  computed: {
    ...mapState({
      showAlert: (state) => state.alert.showAlert,
      emails: (state) => state.integration.emails,
      connectGmailRequestInfo: (state) => state.integration.connectGmailRequestInfo
    }),
    ...mapGetters({
      hasFullAccess: "user/hasFullAccess",
      hasViewAccess: "user/hasViewAccess"
    })
  },
  async mounted() {
    this.$nextTick(async function () {
      await this.getConnectedEmails();

      // createPopper(
      //   this.$refs["connect-gmail-help-button"],
      //   this.$refs["connect-gmail-help-popup"],
      //   {
      //     placement: "bottom",
      //     modifiers: [
      //       {
      //         name: "offset",
      //         options: {
      //           offset: [0, 8]
      //         }
      //       }
      //       // {
      //       //   name: "preventOverflow",
      //       //   options: {
      //       //     rootBoundary: "document",
      //       //     altBoundary: false
      //       //   }
      //       // }
      //     ]
      //   }
      // );
    });
  },
  methods: {
    closePanel() {
      /* slide out panel */
      document.querySelector(
        ".set-emails-panel .modal-dialog"
      ).style.right = `-100%`;
      /* reset panel */
      setTimeout(() => {
        this.$emit("update:show", false);
        this.$emit("close");
      }, this.animationDuration / 2);
    },
    redirectToSite(data) {
      const { name, nonce } = data;
      if (name === "outlook") {
        const scopes = [
          "offline_access",
          "https://graph.microsoft.com/Mail.ReadWrite",
          "https://graph.microsoft.com/Mail.Send",
          "https://graph.microsoft.com/User.Read"
        ].join(" ");
        const url = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${Configs.services.outlook.client_id}&response_type=code&redirect_uri=${Configs.site.url}/api/webhooks/outlook/connect&response_mode=form_post&scope=${scopes}&state=${nonce}`;
        const win = window.open(url, "_blank");
        if (win == null || typeof win == "undefined") {
          // Turn off the pop-up blocker
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["user__email_popup-failed"],
            alertClass: "danger"
          });
        } else {
          win.focus();
        }
      }
      if (name === "gmail") {
        let scopes = ["https://mail.google.com"].join(" ");
        // url ='https://accounts.google.com/o/oauth2/v2/auth?scope=https://mail.google.com&access_type=offline&redirect_uri=https://test.ninjainfluence.com/api/webhooks/gmail/connect&response_type=code&client_id=630847289118-v1i0g98lqe22rlfg6qgfp5f2bfm7u7p0.apps.googleusercontent.com&state=ydtqffavb4viajxy-dpmbk4os0lujfep&response_mode=form_post'

        const url = `https://accounts.google.com/o/oauth2/v2/auth?
          scope=${scopes}&
          access_type=offline&
          include_granted_scopes=true&
          redirect_uri=${Configs.site.url}/api/webhooks/gmail/connect&
          response_type=code&
          client_id=${Configs.services.google.client_id}&
          state=${nonce}&response_mode=form_post`;

        // eslint-disable-next-line no-console
        console.log("url final", url);
        const win = window.open(url, "_blank");
        if (win == null || typeof win == "undefined") {
          // Turn off the pop-up blocker
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["user__email_popup-failed"],
            alertClass: "danger"
          });
        } else {
          win.focus();
        }
      }
    },
    async getConnectedEmails() {
      this.loading.emails = true;
      try {
        await this.$store.dispatch("integration/getConnectedEmails");
        this.loading.emails = false;
      } catch ({ response }) {
        if (
          response.status === 409 &&
          response.data &&
          response.data.metadata
        ) {
          this.showConfirmReconnectEmail = true;
          this.selectedEmail = response.data.metadata;
        } else {
          if (!this.showAlert) {
            this.$store.dispatch("setAlert", {
              showAlert: true,
              content: MESSAGES["common-500"],
              alertClass: "danger"
            });
          }
        }
      }
    },
    disconnectEmail(name, item) {
      this.$emit("disconnectEmail", { ...item, name });
    },
    reconnectEmail(name) {
      if (name === "gmail") {
        document
          .getElementById("btn-google-login")
          .dispatchEvent(new Event("click"));
      } else {
        this.requestEmail({ name });
      }
    },
    async requestEmail(payload) {
      this.loading.request = true;
      try {
        const data = await this.$store.dispatch(
          "integration/requestEmail",
          payload
        );
        this.loading.request = false;
        this.redirectToSite(data);
      } catch {
        this.loading.request = false;
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["common-500"],
            alertClass: "danger"
          });
        }
      }
    },

    OnGoogleAuthSuccess(idToken) {
      // eslint-disable-next-line no-console
      console.log("idToken", idToken);
      this.requestEmail({
        token: idToken,
        name: "gmail"
      });
    },
    OnGoogleAuthFail(error) {
      if (error.error !== "popup_closed_by_user") {
        this.$store.dispatch("setAlert", {
          showAlert: true,
          content: error.error,
          alertClass: "danger"
        });
      }
    },
    openConnectGmailForm() {
      this.showGmailHelpPopup = false;

      this.gmailAccount.email = null;
      this.gmailAccount.fromName = null;
      this.gmailAccount.password = null;

      this.showConnectGmailForm = true;
    },
    hideConnectGmailForm() {
      this.showConnectGmailForm = false;
    },
    async submitGmailForm() {
      // todo: gmail form validation
      if (!this.gmailAccount.email) {
        this.gmailAccountErrors.email = "Email is required.";
        return;
      }
      if (!this.gmailAccount.fromName) {
        this.gmailAccountErrors.fromName = "From name is required.";
        return;
      }
      if (!this.gmailAccount.password) {
        this.gmailAccountErrors.password = "Password is required.";
        return;
      }

      this.loading.submitGmailForm = true;
      try{
        await this.$store.dispatch("integration/addGmailIntegration", {
          email: this.gmailAccount.email,
          fromName: this.gmailAccount.fromName,
          password: this.gmailAccount.password
        });

        this.loading.submitGmailForm = false;

        this.showConnectGmailForm = false;
        await this.getConnectedEmails();
      } finally {
        this.loading.submitGmailForm = false;
      }
    }
  },

  watch: {
    show(val) {
      const documentClasses = document.body.classList;
      if (val) {
        this.localError = false;
        /* slide in panel*/
        documentClasses.add("modal-open");
        setTimeout(() => {
          document.querySelector(
            ".set-emails-panel .modal-dialog"
          ).style.right = `0px`;
          this.imvCoef = this.defaultImvCoefficient;
        }, 0);
      } else {
        /* reset panel */
        documentClasses.remove("modal-open");
        document.querySelector(".set-emails-panel").scrollTop = 0;
      }
    },
    "gmailAccount.email": {
      handler() {
        this.gmailAccountErrors.email = null;
      }
    },
    "gmailAccount.fromName": {
      handler() {
        this.gmailAccountErrors.fromName = null;
      }
    },
    "gmailAccount.password": {
      handler() {
        this.gmailAccountErrors.password = null;
      }
    }
  }
};
</script>
<style lang="scss">
@import "../assets/scss/custom/functions";
@import "../assets/scss/custom/variables";

.text-divider {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  height: 20px;

  &.full {
    &:after,
    &:before {
      margin: 0 0;
    }
  }

  &:after,
  &:before {
    content: "";
    display: block;
    background: $black;
    width: 100%;
    height: 1px;
  }

  &:before {
    margin-right: 10px;
  }
  &:after {
    margin-left: 10px;
  }
}

.email-accounts-list {
  padding: 0;
  list-style-type: none;
  .email-account {
    margin-bottom: 20px;
    display: flex;

    .email-account-card {
      flex: 1 1 auto;
      padding: 10px;
      margin-right: 20px;
      background-color: $white;

      border-radius: 5px;

      display: flex;

      .email-account-icon {
        img {
          width: 32px;
          height: 32px;
        }
      }
    }

    .email-account-actions {
      align-items: center;
      display: flex;
    }
  }
}

.help-mark {
  cursor: pointer;
}

.connect-gmail-button {
  background-color: #2196f3;
  color: $white;
  margin: 0 10px;
}

.gmail-help-popup {
  text-align: left;
  margin-top: 10px;
  padding: 10px;
  height: 300px;
  overflow-y: auto;

  background: $white;
  color: $black;

  font-size: 13px;

  box-shadow: $form-input-shadow;

  .close-popover {
    color: $black;
  }

  .popover-arrow {
    background: $white;
  }
}

.modal.show {
  background-color: rgba(255, 255, 255, 0.7);
}
.google-signin-button {
  color: white;
  background-color: red;
  height: 50px;
  font-size: 16px;
  border-radius: 10px;
  padding: 10px 20px 25px 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
</style>
