<template>
  <div
    class="tab-pane set-account-panel show active"
    :class="[{ 'modal-mini': type === 'mini' }]"
    v-bind:id="tabId"
  >
    <div class="main-content">
      <form @submit.prevent="setAccount">
        <div class="panel-blocks">
          <div class="panel-item">
            <div class="mb-2 setting-label mt-1">
              <span class="nav-link-text">My full name</span>
            </div>
            <base-input
              id="input_name"
              class="input-group-alternative mb-3"
              placeholder="Full Name"
              v-model="userAccount.fullName"
              type="text"
              autofocus
            ></base-input>
          </div>
          <div class="panel-item">
            <div class="mb-2 setting-label mt-1">
              <span class="nav-link-text">Email accounts</span>
            </div>
            <base-input
              id="input_email"
              class="input-group-alternative mb-3"
              :value="userEmail"
              type="email"
              disabled="disabled"
              autofocus
            ></base-input>
          </div>
          <div class="panel-item">
            <div class="mb-2 setting-label mt-1">
              <span class="nav-link-text">My phone num</span>
            </div>
            <base-input
              id="input_phone"
              class="input-group-alternative mb-3"
              placeholder="01-1234567"
              v-model="userAccount.phone"
              type="phone"
              autofocus
            ></base-input>
          </div>
          <div class="panel-item">
            <div class="mb-2 setting-label mt-1">
              <span class="nav-link-text">My company</span>
            </div>
            <base-input
              id="input_company"
              class="input-group-alternative mb-3"
              placeholder="My Company Ltd."
              v-model="userAccount.company"
              type="text"
              autofocus
            ></base-input>
          </div>
          <div class="panel-item">
            <div class="mb-2 setting-label mt-1">
              <span class="nav-link-text">Country</span>
            </div>
            <multiselect
              placeholder="Country"
              v-model="userAccount.country"
              label="country"
              track-by="alpha2"
              :options="countries"
            >
            </multiselect>
          </div>
          <div class="panel-item" v-if="showCountryState">
            <div class="mb-2 setting-label mt-1">
              <span class="nav-link-text">State</span>
            </div>
            <multiselect
              placeholder="State"
              v-model="userAccount.state"
              label="state"
              track-by="code"
              :options="selectedCountryStates"
            >
            </multiselect>
          </div>
        </div>
        <div class="save-block">
          <base-button
            :isBusy="loading.submit"
            type="primary"
            nativeType="submit"
            :class="'btn btn-black mw-150'"
          >
            Save
          </base-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Multiselect from "vue-multiselect";

export default {
  name: "set-account-panel",
  components: {
    Multiselect
  },
  props: {
    show: Boolean,
    type: {
      type: String,
      default: "",
      validator(value) {
        let acceptedValues = ["", "notice", "mini"];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Modal type (notice|mini|"") '
    },
    animationDuration: {
      type: Number,
      default: 500,
      description: "Modal transition duration"
    },
    username: {
      type: String
    },
    tabId: String
  },
  data() {
    return {
      loading: {
        profile: false,
        submit: false
      },
      userAccount: {
        fullName: "",
        email: "",
        phone: "",
        company: "",
        country: null,
        state: null
      },

      localError: false
    };
  },
  computed: {
    ...mapState({
      userEmail: (state) => state.user.authUser.email,
      stateUserAccount: (state) => state.user.authUser.userAccount,
      countries: (state) => state.user.countries,
      countryStates: (state) => state.user.states
    }),
    showCountryState: {
      get() {
        const countryCode =
          this.userAccount.country && this.userAccount.country.alpha2;

        return (
          countryCode == "US" || countryCode == "CA" || countryCode == "AU"
        );
      }
    },
    selectedCountryStates: {
      get() {
        const countryCode =
          this.userAccount.country && this.userAccount.country.alpha2;

        if (countryCode == "US") return this.countryStates.usStates;
        else if (countryCode == "CA")
          return this.countryStates.canadaProvincies;
        else if (countryCode == "AU") return this.countryStates.australiaStates;
        else return [];
      }
    }
  },
  mounted() {
    this.$nextTick(function () {
      this.loadCountries();
    });
  },
  methods: {
    closePanel() {
      /* slide out panel */
      document.querySelector(
        ".set-account-panel .modal-dialog"
      ).style.right = `-100%`;
      /* reset panel */
      setTimeout(() => {
        this.$emit("update:show", false);
        this.$emit("close");
      }, this.animationDuration / 2);
    },
    selectCard(type, param) {
      this.$emit("selectCard", { type: type, param: param });
    },
    async getAccount() {
      this.loading.profile = true;
      try {
        await this.$store.dispatch("user/getAccount");
        this.loading.profile = false;
      } catch {
        this.loading.profile = false;
        this.localError = "Getting account failed";
      }
    },
    async setAccount() {
      this.loading.submit = true;
      try {
        await this.$store.dispatch("user/setAccount", this.userAccount);
        this.loading.submit = false;
      } catch {
        this.loading.submit = false;
        this.localError = "Updating account failed";
      }
    },
    async loadCountries() {
      this.loading.countries = true;
      try {
        await this.$store.dispatch("user/loadCountries");
        this.loading.countries = false;
      } catch {
        this.loading.countries = false;
        this.localError = "Getting countries and states failed";
      }
    }
  },

  watch: {
    show(val) {
      //const documentClasses = document.body.classList;
      if (val) {
        /* slide in panel*/
        // documentClasses.add("modal-open");
        // setTimeout(() => {
        //   document.querySelector(
        //     ".set-account-panel .modal-dialog"
        //   ).style.right = `0px`;
        // }, 0);
        //this.getAccount();
      } else {
        /* reset panel */
        //documentClasses.remove("modal-open");
        //document.querySelector(".set-account-panel").scrollTop = 0;
      }
    },
    stateUserAccount: {
      immediate: true,
      handler(val) {
        // eslint-disable-next-line no-console
        console.log("Watch stateUserAccount", val);

        this.userAccount.fullName = val.fullName;
        this.userAccount.phone = val.phone;
        this.userAccount.company = val.company;

        this.$nextTick(function () {
          if (this.stateUserAccount && this.stateUserAccount.country) {
            const index = this.countries.findIndex(
              (s) => s.alpha2 === this.stateUserAccount.country
            );
            this.userAccount.country = this.countries[index];
            console.log("Set country", this.userAccount.country);
          }

          if (this.stateUserAccount && this.stateUserAccount.state) {
            const index = this.selectedCountryStates.findIndex(
              (s) => s.code === this.stateUserAccount.state
            );
            this.userAccount.state = this.selectedCountryStates[index];
            console.log("Set state", this.userAccount.state);
          }
        });
      },
      deep: true
    },
    countries(val) {
      if (val.length !== 0) 
        this.getAccount();
    }
  }
};
</script>
<style>

</style>
