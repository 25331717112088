<template>
  <div class="home-page">
    <div class="page-title">Home</div>
    <div class="block-title">User</div>

    <div class="page-block user-block">
      <div class="greetings-card">
        <span>
          Hello {{ authUser.email }}, Welcome back!<b>
            {{ home.Influencer_stats.profiles_added_today }} new influencers
            have been added today </b
          >, and are waiting to launch your product!
        </span>
      </div>
      <div class="help-card">
        <router-link class="link-black" to="/app/help"
          ><span class="material-icons-outlined"> update </span> Learn What's
          New In Ninjainfluence</router-link
        >
      </div>
    </div>

    <div class="block-title">Information for you</div>

    <div class="row d-flex">
      <div class="col-4">
        <div class="kpi-info">
          <span class="title-kpi col-12 mb-2">Influencers overview</span>

          <div class="kpi-section">
            <div class="w-50">
              <div class="kpi-card">
                <div class="kpi-card-left">
                  <img
                    src="/app/img/icons/svg/instagram_icon.svg"
                    class="card-icon"
                  />
                  <span>Influencers on Instagram</span>
                </div>
                <div
                  class="kpi-card-right spinner-border text-primary"
                  v-if="home.loading_influencer_stats"
                ></div>
                <div
                  class="kpi-card-right"
                  v-if="!home.loading_influencer_stats"
                >
                  {{
                    home.Influencer_stats.instagram_profile_count | BigNumber
                  }}
                </div>
              </div>
            </div>
            <div class="w-50">
              <div class="kpi-card">
                <div class="kpi-card-left">
                  <img
                    src="/app/img/icons/svg/youtubeicon.svg"
                    class="card-icon"
                  />
                  <span>Influencers on youtube</span>
                </div>
                <div
                  class="kpi-card-right spinner-border text-primary"
                  v-if="home.loading_influencer_stats"
                ></div>
                <div
                  class="kpi-card-right"
                  v-if="!home.loading_influencer_stats"
                >
                  {{ home.Influencer_stats.youtube_profile_count | BigNumber }}
                </div>
              </div>
            </div>
          </div>
          <div class="kpi-section">
            <div class="w-50">
              <div class="kpi-card">
                <div class="kpi-card-left">
                  <img
                    src="/app/img/icons/svg/tiktokicon.svg"
                    class="card-icon"
                  />
                  <span>Influencers on TikTok</span>
                </div>
                <div
                  class="kpi-card-right spinner-border text-primary"
                  v-if="home.loading_influencer_stats"
                ></div>
                <div
                  class="kpi-card-right"
                  v-if="!home.loading_influencer_stats"
                >
                  {{ home.Influencer_stats.tiktok_profile_count | BigNumber }}
                </div>
              </div>
            </div>
            <div class="w-50">
              <div class="kpi-card">
                <div class="kpi-card-left">
                  <img
                    src="/app/img/icons/svg/Followers.svg"
                    class="card-icon"
                  />
                  <span>Influencers reached so far</span>
                </div>
                <div
                  class="kpi-card-right spinner-border text-primary"
                  v-if="home.loading_influencers_reached"
                ></div>
                <div
                  class="kpi-card-right"
                  v-if="!home.loading_influencers_reached"
                >
                  {{ home.influencers_reached.count | BigNumber }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="kpi-info">
          <span class="title-kpi col-12 mb-2">Outreach overview</span>

          <div class="kpi-section">
            <div class="w-50">
              <div class="kpi-card">
                <div class="kpi-card-left">
                  <img src="/app/img/icons/svg/Posts.svg" class="card-icon" />
                  <span>Number of posts so far</span>
                </div>
                <div
                  class="kpi-card-right spinner-border text-primary"
                  v-if="home.loading_user_campaigns_stats"
                ></div>
                <div
                  class="kpi-card-right"
                  v-if="!home.loading_user_campaigns_stats"
                >
                  {{
                    home.user_campains_stats.total_content_pieces | BigNumber
                  }}
                </div>
              </div>
            </div>
            <div class="w-50">
              <div class="kpi-card">
                <div class="kpi-card-left">
                  <img
                    src="/app/img/icons/svg/Images-engagement.svg"
                    class="card-icon"
                  />
                  <span>Post engagements</span>
                </div>
                <div
                  class="kpi-card-right spinner-border text-primary"
                  v-if="home.loading_user_campaigns_stats"
                ></div>
                <div
                  class="kpi-card-right"
                  v-if="!home.loading_user_campaigns_stats"
                >
                  {{ home.user_campains_stats.total_engagements | BigNumber }}
                </div>
              </div>
            </div>
          </div>
          <div class="kpi-section">
            <div class="w-50">
              <div class="kpi-card">
                <div class="kpi-card-left">
                  <img
                    src="/app/img/icons/svg/Likes-per-post.svg"
                    class="card-icon"
                  />
                  <span>Number of likes overall</span>
                </div>
                <div
                  class="kpi-card-right spinner-border text-primary"
                  v-if="home.loading_user_campaigns_stats"
                ></div>
                <div
                  class="kpi-card-right"
                  v-if="!home.loading_user_campaigns_stats"
                >
                  {{ home.user_campains_stats.total_likes | BigNumber }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="kpi-info">
          <span class="title-kpi col-12 mb-2">Campaigns overview</span>

          <div class="kpi-section">
            <div class="w-50">
              <div class="kpi-card">
                <div class="kpi-card-left">
                  <img src="/app/img/icons/svg/stats.svg" class="card-icon" />
                  <span>Total revenue</span>
                </div>
                <div
                  class="kpi-card-right spinner-border text-primary"
                  v-if="home.loading_user_campaigns_stats"
                ></div>
                <div
                  class="kpi-card-right"
                  v-if="!home.loading_user_campaigns_stats"
                >
                  {{ home.user_campains_stats.total_revenue | BigNumber }}
                </div>
              </div>
            </div>
            <div class="w-50">
              <div class="kpi-card">
                <div class="kpi-card-left">
                  <img src="/app/img/icons/svg/cart.svg" class="card-icon" />
                  <span>Purchased items</span>
                </div>
                <div
                  class="kpi-card-right spinner-border text-primary"
                  v-if="home.loading_user_campaigns_stats"
                ></div>
                <div
                  class="kpi-card-right"
                  v-if="!home.loading_user_campaigns_stats"
                >
                  {{ home.user_campains_stats.total_sales | BigNumber }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row table-stats d-flex">
      <div v-if="home.leads_overview" class="col-4">
        <span class="table-title">Influencers information</span>
        <VueScrollableTable
          class="influencers-info-table"
          :scroll-horizontal="false"
          :scroll-vertical="false"
          :sync-header-scroll="false"
          :dead-area-color="'transparent'"
        >
          <template slot="thead">
            <tr>
              <th class="w-name">Name</th>
              <th class="w-total-posts">Total posts followers</th>
              <th class="w-orders-quantity">Orders quantity</th>
              <th class="w-total-sales">Total sales</th>
            </tr>
          </template>
          <template slot="tbody">
            <tr
              v-for="(item, index) in home.leads_overview"
              :key="index"
              class=""
            >
              <td class="w-name text-truncate">
                <img
                  v-if="item.profile_pic"
                  :src="item.profile_pic"
                  @error="onLogoImageError($event)"
                />
                {{
                  item.data.full_name ||
                  item.data.title ||
                  item.data.nickname ||
                  item.username
                }}
              </td>
              <td class="w-total-posts">
                {{ item.data.followers || item.data.subscribers | BigNumber }}
              </td>
              <td class="w-orders-quantity">
                {{ item.orders_quantity | BigNumber }}
              </td>
              <td class="w-total-sales">
                {{ item.total_sales | BigNumber }}
              </td>
            </tr>
          </template>
        </VueScrollableTable>
      </div>
      <div v-if="home.campaigns" class="col-4">
        <span class="table-title">Your campaigns</span>
        <VueScrollableTable
          class="your-campaigns-table"
          :scroll-horizontal="false"
          :scroll-vertical="false"
          :sync-header-scroll="false"
          :dead-area-color="'transparent'"
        >
          <template slot="thead">
            <tr>
              <th class="w-campaign-name">Campaign</th>
              <th class="w-orders-quantity">Orders quantity</th>
              <th class="w-revenue">Revenue</th>
              <th class="w-roi">ROI</th>
              <th class="w-engagement">Engagement</th>
            </tr>
          </template>
          <template slot="tbody">
            <tr v-for="(item, index) in home.campaigns" :key="index" class="">
              <td class="w-campaign-name">
                {{ item.name }}
              </td>
              <td class="w-orders-quantity">
                {{ item.content_pieces }}
              </td>
              <td class="w-revenue">
                {{ item.gross_revenue | Currency }}
              </td>
              <td class="w-roi">{{ item.roi | fixedToDecimal }}%</td>
              <td class="w-engagement">
                {{ item.engagements | BigNumber }}
              </td>
            </tr>
          </template>
        </VueScrollableTable>

        <div class="btn-more">
          <button class="btn-view" @click="gotoCampaign()" type="button">
            View All
          </button>
        </div>
      </div>
      <div v-if="home.latest_communictions" class="col-4">
        <span class="table-title">Latest's communications</span>
        <VueScrollableTable
          class="latest-communications-table"
          :scroll-horizontal="false"
          :scroll-vertical="false"
          :sync-header-scroll="false"
          :dead-area-color="'transparent'"
        >
          <template slot="thead">
            <tr>
              <th class="w-name">Name</th>
              <th class="w-date">By date</th>
              <th class="w-message">Messages</th>
            </tr>
          </template>
          <template slot="tbody">
            <tr
              v-for="(item, index) in home.latest_communictions"
              :key="index"
              class=""
            >
              <td class="w-name">
                {{ item.from_name }}
              </td>
              <td class="w-date">
                {{ item.created_at | ShortDate }}
              </td>
              <td class="w-message">
                {{ item.body_preview | ShortInboxMsg }} ..
              </td>
            </tr>
          </template>
        </VueScrollableTable>
        <div class="btn-more">
          <button class="btn-view" @click="gotoInbox()" type="button">
            View All
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import {
  getCampaignROI,
  //equalObjects,
  //getPermissionErrorMessage,
  onLogoImageError
} from "@/utils";
//import Configs from "../configs";

/* components */
const VueScrollableTable = () => import("vue-scrolling-table");

export default {
  components: {
    VueScrollableTable
  },
  data() {
    return {
      getCampaignROI: getCampaignROI
    };
  },
  computed: {
    ...mapState({
      currencies: (state) => state.user.currencies,
      authUser: (state) => state.user.authUser,
      home: (state) => state.home,
      showAlert: (state) => state.alert.showAlert
    }),
    ...mapGetters({
      hasFullAccess: "user/hasFullAccess",
      hasViewAccess: "user/hasViewAccess"
    })
  },
  watch: {},

  mounted() {
    this.$store.dispatch("home/getNewInfluencerCount");
    this.$store.dispatch("home/InfluencersReached");
    this.$store.dispatch("home/userCampainsStats");
    this.$store.dispatch("home/leadsOverview");
    this.$store.dispatch("home/latestCommunictions");
    this.$store.dispatch("home/getCampaigns");
  },

  beforeDestroy() {
    if (this.resizeHandler) {
      window.removeEventListener("resize", this.resizeWindow);
      this.resizeHandler = null;
    }
  },
  methods: {
    onLogoImageError: onLogoImageError,
    resizeWindow() {
      const isMobileSafari =
        navigator.userAgent.match(/(iPod|iPhone|iPad)/) &&
        navigator.userAgent.match(/AppleWebKit/);
      if (isMobileSafari) {
        this.platformOffsetY = 114;
      }
    },
    gotoCampaign() {
      this.$router.push("/app/campaigns/");
    },
    gotoInbox() {
      this.$router.push("/app/inbox/");
    }
  }
};
</script>
<style lang="scss">
@import "../assets/scss/pages/home";
</style>
