<template>
  <div class="influencer-search" id="influencer_search">
    <!-- <div @click="hideFilterbar"> -->
    <!-- Topbar of the search results area - Searching and results count label, sorting -->
    <div v-if="loading.search || !emptyView" class="topbar">
      <div class="flex-between flex-wrap py-md-2 px-3">
        <div v-if="loading.search" class="my-2 d-none d-md-flex">
          <span>
            Searching...
          </span>
        </div>
        <div v-else class="align-items-center my-2 d-none d-md-flex">
          <span v-if="searchResult.hits.length > 1">
            Displaying
            <strong>{{ searchResult.hits.length }}</strong
            >&nbsp;of
            <strong>
              {{ this.searchResult.total.value | NormalNumber(0) }}
            </strong>
            {{ socials[social].name }} influencers
            <template v-if="!isNaN(this.searchResult.took)"
              >({{
                (this.searchResult.took / 1000).toFixed(1)
              }}
              seconds)</template
            >
            <span class="custom-gallery-view">
              <base-checkbox
                v-if="viewIndex == 0"
                v-model="enabledCheckAllProfiles"
                class="custom-control-alternative-gallery"
                @change="checkAllProfiles"
                >Select all</base-checkbox
              >
            </span>
          </span>
          <span
            v-if="
              searchResult.hits.length == 1 &&
              !isFakeObjectHits(searchResult.hits)
            "
          >
            Displaying
            <strong>1</strong>
            {{ socials[social].name }} influencer
            <template v-if="!isNaN(this.searchResult.took)"
              >({{
                (this.searchResult.took / 1000).toFixed(1)
              }}
              seconds)</template
            >
            <span class="custom-gallery-view">
              <base-checkbox
                v-if="viewIndex == 0"
                v-model="enabledCheckAllProfiles"
                class="custom-control-alternative-gallery"
                @change="checkAllProfiles"
                >Select all</base-checkbox
              >
            </span>
          </span>
        </div>

        <div class="topbar-sort flex-wrap justify-content-end my-2">
          <!-- Gallery/Table View dropdown -->
          <base-dropdown class="nav-item" position="right">
            <a
              slot="title"
              class="nav-link nav-link-icon"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i :class="viewList[viewIndex].class"></i>
              {{ viewList[viewIndex].label }}
              <i class="fas fa-angle-down"></i>
            </a>
            <!-- Gallery/Table View dropdown options -->
            <a
              v-for="(viewItem, index) in viewList"
              :key="viewItem.value"
              @click="setViewIndex(index)"
              class="dropdown-item px-3 py-1"
            >
              <i :class="viewItem.class"></i>
              {{ viewItem.label }}
            </a>
          </base-dropdown>

          <!-- Sort dropdown -->
          <base-dropdown class="nav-item" position="right">
            <a
              slot="title"
              class="nav-link nav-link-icon"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="fas fa-filter"></i> Sort
              <i class="fas fa-angle-down"></i>
            </a>
            <!-- Sort dropdown options -->
            <template v-for="(sortItem, index) in socialSorts[social]">
              <a
                :key="sortItem.value"
                @click="setSortIndex(index)"
                class="dropdown-item px-3 py-1"
              >
                <i class="fas fa-sort-amount-down"></i>
                {{ sortItem.label }}
                <i
                  v-show="filter[social].sort_index === index"
                  class="fas fa-check mr-0 ml-2"
                ></i>
              </a>
              <div
                v-if="socialSorts[social].length > index + 1"
                class="dropdown-divider"
                :key="index"
              ></div>
            </template>
          </base-dropdown>
          <div v-if="exportList.length > 0" class="d-flex">
            <base-dropdown
              :disabled="!hasFullAccess('campaigns')"
              class="nav-item"
              position="right"
            >
              <base-button
                :disabled="!hasFullAccess('campaigns')"
                :disabledTooltip="getPermissionErrorMessage('campaigns:full')"
                slot="title"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                type="primary"
                :icon="'fas fa-plus'"
                :isBusy="loading.addLeads"
                class="input-group-alternative m-1 d-none d-md-flex"
              >
                Add to Campaign
              </base-button>
              <a
                @click="displayEditCampaignPanel(false)"
                class="dropdown-item dropdown-item--primary px-3 py-1"
                >Create New...</a
              >
              <div class="dropdown-divider"></div>
              <div class="campaigns-list">
                <template v-for="campaign in campaignsList">
                  <a
                    :key="campaign.name"
                    class="dropdown-item px-3 py-1"
                    @click="addLeadsToExistingCampaign(campaign, false)"
                    >{{ campaign.name }}</a
                  >
                </template>
              </div>
            </base-dropdown>

            <!-- 
                <base-button
                id="btn_export_profiles"
                :disabled="!hasFullAccess('exports')"
                :disabledTooltip="getPermissionErrorMessage('exports:full')"
                type="primary"
                icon="fas fa-file-download"
                class="input-group-alternative m-1 d-none d-md-block"
                @click="showExportProfiles = true"
              >
                <span>Export ({{ exportList.length }})</span>
              </base-button> 
              -->
          </div>
        </div>
      </div>
    </div>

    <!-- Initial empty view -->
    <div v-if="emptyView && !loading.search" class="search-tips p-4 mt-9">
      <h1>
        Find the best {{ socials[filter.social].name }} influencers for your
        business
      </h1>
      <p v-if="emptyView" class="order-3">
        Search by <strong>@username</strong>, by <strong>keywords</strong>. To
        narrow your search further (e.g., Hashtags, Engagement Rate, Location
        etc) use <strong>Filters</strong>.
      </p>
    </div>

    <!-- No results empty view -->
    <div
      v-if="searchResult.total.value === 0 && !emptyView"
      class="search-tips p-4 mt-5"
    >
      <h1>Wow, you've got something really specific in mind.</h1>
      <h3>Try changing the search criteria to see better results.</h3>
    </div>

    <!-- Search results list -->
    <div
      v-if="!emptyView && !isFakeObjectHits(searchResult.hits)"
      class="search-results flex-column align-items-center"
    >
      <div
        v-if="viewIndex == 0"
        v-on:scroll="scrollGallery"
        class="profile-gallery-panel mx-sm-0"
      >
        <div class="cards-container row" :key="profilesKey">
          <!-- render ProfileCard with searchResults -->
          <template v-for="(profile, index) in searchResult.hits">
            <div class="col-12 flex-center" :key="index">
              <profile-card
                :social="social"
                :profile="profile"
                :selected="inExportList(profile)"
                @clickProfile="showProfile(profile)"
                @checkBoxToggle="checkProfile($event, profile)"
                class="my-2"
              />
            </div>
          </template>
        </div>
      </div>
      <div
        v-else-if="searchResult.total.value"
        class="profile-table-container px-md-3 w-100"
      >
        <div class="profile-table-container--select_all p-2">
          <base-checkbox
            v-model="enabledCheckAllProfiles"
            class="custom-control-alternative"
            @change="checkAllProfiles"
            >Select all</base-checkbox
          >
        </div>
        <VueScrollableTable
          class="profile-table"
          :scroll-horizontal="true"
          :scroll-vertical="true"
          :sync-header-scroll="true"
          :dead-area-color="'transparent'"
          :class="{
            freezeFirstColumn: true,
            hasHashtagsColumn: social == socials.instagram.id
          }"
          @scroll="scrollTable"
        >
          <template slot="thead">
            <tr class="mb-sm-2">
              <th
                :class="
                  'checkbox-column ' +
                  (enabledCheckAllProfiles ? 'checkbox-column-checked' : '')
                "
              >
                <base-checkbox
                  v-model="enabledCheckAllProfiles"
                  class="custom-control-alternative"
                  @change="checkAllProfiles"
                ></base-checkbox>
              </th>
              <!-- instagram header -->
              <template v-if="social == socials.instagram.id">
                <th class="name-column name-column--instagram">NAME</th>
                <th class="hashtag-column">HASHTAGS</th>
                <th>FOLLOWERS</th>
                <th>ENGAGEMENT</th>
                <th>AVG LIKES PER POST</th>
                <th>AVG REPLIES PER POST</th>
                <th>AVG VIEWS PER VIDEO</th>
                <th>SOCIAL LINKS</th>
              </template>
              <!-- tiktok header -->
              <template v-else-if="social == socials.tiktok.id">
                <th class="name-column">NAME</th>
                <th>FANS</th>
                <th>ENGAGEMENT</th>
                <th>HEARTS</th>
                <th>VIDEOS</th>
                <th>SOCIAL LINKS</th>
              </template>
              <!-- youtube header -->
              <template v-else-if="social == socials.youtube.id">
                <th class="name-column">NAME</th>
                <th>SUBSCRIBERS</th>
                <th>ENGAGEMENT</th>
                <th>POST LIKES</th>
                <th>VIDEOS</th>
                <th>SOCIAL LINKS</th>
              </template>
            </tr>
          </template>
          <template slot="tbody">
            <tr
              v-for="(row, index) in searchResult.hits"
              :key="index"
              class="d-flex"
            >
              <th
                :class="
                  'checkbox-column ' +
                  (inExportList(row) ? 'checkbox-column-checked' : '')
                "
              >
                <base-checkbox
                  :checked="inExportList(row)"
                  class="custom-control-alternative"
                  @change="checkProfile($event, row)"
                ></base-checkbox>
              </th>
              <!-- instagram row -->
              <template v-if="social == socials.instagram.id">
                <td
                  @click="showProfile(row)"
                  class="name-column name-column--instagram flex-middle-between"
                >
                  <div class="avatar ml-2">
                    <img
                      :src="row.profile_pic_url"
                      itemprop="image"
                      :alt="row.first_name"
                      :title="row.first_name"
                      @error="onLogoImageError($event)"
                    />
                  </div>
                  <div class="flex-column ml-2">
                    <div class="profile-name">
                      {{ getFullName(row) }}
                    </div>
                    <div class="profile-username">@{{ row.username }}</div>
                  </div>
                </td>
                <td
                  @click="showProfile(row)"
                  class="space-column text-left p-2"
                ></td>
                <td
                  class="hashtag-column flex-middle"
                  @click="showProfile(row)"
                >
                  <template v-if="row.all_hashtags && row.all_hashtags.length">
                    <div
                      class="text-overflow-ellipsis"
                      @click="showProfile(row)"
                    >
                      <b>{{ formatHashtags(row.all_hashtags) }}</b>
                    </div>
                    <a
                      class="btn-see-all"
                      @click.self.stop="setSelectedHashtags(row.all_hashtags)"
                    >
                      See all
                    </a>
                  </template>
                </td>
                <td @click="showProfile(row)" class="flex-middle-center">
                  {{ row.followers | BigNumber }}
                </td>
                <td @click="showProfile(row)">
                  <div class="flex-column align-items-center">
                    <div class="completion">
                      {{ row.engagement_rate | Percent }}
                    </div>
                    <div class="w-100 px-1">
                      <base-progress
                        type="primary"
                        :show-percentage="false"
                        :class="'pt-0 pt-' + getEngagementRateClass(row)"
                        :value="
                          engagementRateChartList[getEngagementRateClass(row)]
                            .percent
                        "
                      />
                    </div>
                  </div>
                </td>
                <td @click="showProfile(row)" class="flex-middle-center">
                  {{ row.likes_per_post | BigNumber }}
                </td>
                <td @click="showProfile(row)" class="flex-middle-center">
                  {{ row.replies_per_post | BigNumber }}
                </td>
                <td @click="showProfile(row)" class="flex-middle-center">
                  {{ row.views_per_video | BigNumber }}
                </td>
                <td @click="showProfile(row)" class="flex-middle-center">
                  <div class="d-flex mt-2">
                    <div v-if="row.tiktok_username" class="flex-column">
                      <img
                        src="/app/img/icons/svg/tiktok_icon_sm.svg"
                        alt="TickTok logo"
                        class="mr-3"
                      />
                      <small class="mr-3">{{ row.fans | BigNumber }}</small>
                    </div>
                    <div v-if="row.youtube_channel" class="flex-column">
                      <img
                        src="/app/img/icons/svg/youtube_icon_sm.svg"
                        height="22.5px"
                        alt="Youtube logo"
                      />
                      <small class="pt-1">{{
                        row.subscribers | BigNumber
                      }}</small>
                    </div>
                  </div>
                </td>
              </template>
              <!-- tiktok row -->
              <template v-else-if="social == socials.tiktok.id">
                <td @click="showProfile(row)" class="name-column flex-middle">
                  <div class="avatar ml-2">
                    <img
                      :src="row.covers_small"
                      itemprop="image"
                      :alt="row.first_name"
                      :title="row.first_name"
                      @error="onLogoImageError($event)"
                    />
                  </div>
                  <div class="flex-column ml-2">
                    <div class="profile-name">{{ row.nickname }}</div>
                    <div class="profile-username">@{{ row.username }}</div>
                  </div>
                </td>
                <td @click="showProfile(row)" class="space-column"></td>
                <td @click="showProfile(row)" class="flex-middle-center">
                  {{ row.fans | BigNumber }}
                </td>

                <td @click="showProfile(row)" class="flex-middle-center">
                  {{ row.avg_engagement_rate | Percent }}
                </td>
                <td @click="showProfile(row)" class="flex-middle-center">
                  {{ row.hearts | BigNumber }}
                </td>
                <td @click="showProfile(row)" class="flex-middle-center">
                  {{ row.videos | BigNumber }}
                </td>
                <td @click="showProfile(row)" class="flex-middle-center">
                  <div class="d-flex mt-2">
                    <div v-if="row.instagram_username" class="flex-column mr-3">
                      <img
                        src="/app/img/icons/svg/instagram_icon_sm.svg"
                        alt="Instagram logo"
                      />
                      <small>{{ row.followers | BigNumber }}</small>
                    </div>
                    <div v-if="row.youtube_channel" class="flex-column">
                      <img
                        src="/app/img/icons/svg/youtube_icon_sm.svg"
                        height="22.5px"
                        alt="Youtube logo"
                      />
                      <small class="pt-1">{{
                        row.subscribers | BigNumber
                      }}</small>
                    </div>
                  </div>
                </td>
              </template>
              <!-- youtube row -->
              <template v-else-if="social == socials.youtube.id">
                <td @click="showProfile(row)" class="name-column flex-middle">
                  <div class="avatar ml-2">
                    <img
                      :src="row.avatar_url"
                      itemprop="image"
                      :alt="row.title"
                      :title="row.title"
                      @error="onLogoImageError($event)"
                    />
                  </div>
                  <div class="flex-column ml-2">
                    <div class="profile-name">{{ row.title }}</div>
                    <div class="profile-username">@{{ row.id }}</div>
                  </div>
                </td>
                <td @click="showProfile(row)" class="space-column"></td>
                <td @click="showProfile(row)" class="flex-middle-center">
                  {{ row.subscribers | BigNumber }}
                </td>

                <td @click="showProfile(row)" class="flex-middle-center">
                  {{ row.avg_engagement_rate | Percent }}
                </td>
                <td @click="showProfile(row)" class="flex-middle-center">
                  {{ row.avg_likes_pct | Percent }}
                </td>
                <td @click="showProfile(row)" class="flex-middle-center">
                  {{ row.videos | BigNumber }}
                </td>
                <td @click="showProfile(row)" class="flex-middle-center">
                  <div class="d-flex mt-2">
                    <div v-if="row.instagram_username" class="flex-column">
                      <img
                        src="/app/img/icons/svg/instagram_icon_sm.svg"
                        alt="Instagram logo"
                      />
                      <small>{{ row.followers | BigNumber }}</small>
                    </div>
                    <div v-if="row.tiktok_username" class="flex-column">
                      <img
                        src="/app/img/icons/svg/tiktok_icon_sm.svg"
                        alt="TickTok logo"
                        class="ml-3"
                      />
                      <small class="ml-3">{{ row.fans | BigNumber }}</small>
                    </div>
                  </div>
                </td>
              </template>
            </tr>
          </template>
        </VueScrollableTable>
      </div>

      <div class="search-loading-more" v-if="loading.search">
        <base-spinner></base-spinner>
      </div>
    </div>
    <div
      v-else-if="!emptyView && isFakeObjectHits(searchResult.hits)"
      class="search-tips p-4 mt-5"
    >
      <h1>Looks like it is a new influencer for us!</h1>
      <h3>Please re-search in a few minutes...</h3>
    </div>
    <!-- </div> -->
    <filter-bar :background-color="filterbarBackground">
      <left-nav v-if="showLeftNav" slot="filters" />
      <div v-else slot="filters">
        <div role="tablist">
          <card
            v-for="id in sortedSocialIds"
            :key="id"
            no-body
            class="search-filter"
          >
            <a
              v-if="id == filter.social"
              class="search-filter__header selected"
              v-b-tooltip.hover
              :title="socials[id].name"
            >
              <div class="social-icon mr-2">
                <img :src="socials[id].image" :alt="socials[id].name" />
              </div>
              <strong>
                {{ socials[id].name }}
              </strong>
            </a>
            <div
              v-else
              class="search-filter__header"
              block
              v-b-toggle="'accordion-' + id"
              variant="info"
              v-b-tooltip.hover
              :title="socials[id].name"
            >
              <div class="social-icon mr-2">
                <img :src="socials[id].image" :alt="socials[id].name" />
              </div>
              {{ socials[id].name }}
            </div>
            <b-collapse
              :id="'accordion-' + id"
              :visible="filter.social == id"
              accordion="my-accordion"
              role="tabpanel"
              @show="setFilterSocial(id)"
            >
              <div
                v-if="socials[id].enabled"
                :style="{
                  height: `calc(100vh - ${
                    headerHeight > 40 ? headerHeight : 0
                  }px - ${
                    Object.keys(socials).length * 45
                  }px - ${platformOffsetY}px)`
                }"
                class="d-flex"
              >
                <component
                  :is="id + '-search-filter'"
                  :ref="id + 'SearchFilter'"
                  @search="searchNew"
                  @change="filterChanged"
                />
              </div>
              <div v-else>
                <!-- TODO -->
              </div>
            </b-collapse>
          </card>
        </div>
      </div>
    </filter-bar>
    <a
      v-if="$filterbar.showFilterbar"
      @click="toggleLeftNavbar"
      class="navbar-switch flex-middle-center d-md-none"
    >
      <span :class="showLeftNav ? 'active' : ''"></span>
      <span :class="showLeftNav ? '' : 'active'"></span>
    </a>
    <export-panel
      :show="showExportProfiles"
      @close="showExportProfiles = false"
      @showProfile="showProfile"
    ></export-panel>
    <profile-panel
      :show="showSingleProfile"
      :campaignsList="campaignsList"
      @close="showSingleProfile = false"
      @displayEditCampaignPanel="displayEditCampaignPanel(true)"
      @addLeadsToExistingCampaign="addLeadsToExistingCampaign($event, true)"
      :social="social"
      :username="selectedProfile.username"
      :id="selectedProfile.id"
      :animationDuration="500"
    />
    <edit-campaign-panel
      :show="showEditCampaign"
      :loading="loading.createCampaign"
      :currenciesList="currencies"
      @close="showEditCampaign = false"
      @save="addLeadsToNewCampaign"
      :animationDuration="500"
    />
    <set-billing-package-panel
      :show="showBillingPackage"
      @close="showBillingPackage = false"
      :animationDuration="500"
    />
    <modal :show="progress.show" type="mini" :showClose="false">
      <base-progress
        :striped="true"
        :animated="true"
        :value="progress.percent"
        type="primary"
      ></base-progress>
      <p>
        {{ progress.description }}
      </p>
    </modal>

    <modal :show="showAllHashtags" @close="showAllHashtags = false" type="mini">
      <h3>{{ selectedHashtags && selectedHashtags.length }} Hashtags</h3>
      {{
        selectedHashtags &&
        selectedHashtags.length &&
        selectedHashtags.join(", ")
      }}
    </modal>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Configs from "../configs";
import {
  SOCIALS,
  SOCIAL_INFLUENCERS_SORTS,
  NOKEYWORDS_INFLUENCERS_SORTS,
  ENGAGEMENT_CHART_DATA,
  INFLUENCERS_VIEWS_LIST,
  ACCOUNT_SCOPES,
  ACCOUNT_ACCESS,
  MESSAGES
} from "@/constants";
import {
  getEngagementRateClass,
  getFullName,
  getPermissionErrorMessage,
  isFakeObjectHits,
  onLogoImageError
} from "@/utils";

/* components */
const ProfileCard = () => import("../components/ProfileCard");
const ProfilePanel = () => import("../components/ProfilePanel");
const ExportPanel = () => import("../components/ExportPanel");
const EditCampaignPanel = () => import("../components/EditCampaignPanel");
const SetBillingPackagePanel = () =>
  import("../components/SetBillingPackagePanel");
const InstagramSearchFilter = () =>
  import("../components/InstagramSearchFilter");
const TiktokSearchFilter = () => import("../components/TiktokSearchFilter");
const YoutubeSearchFilter = () => import("../components/YoutubeSearchFilter");
const VueScrollableTable = () => import("vue-scrolling-table");

export default {
  components: {
    InstagramSearchFilter,
    TiktokSearchFilter,
    YoutubeSearchFilter,

    ProfileCard,
    ProfilePanel,
    ExportPanel,
    EditCampaignPanel,
    SetBillingPackagePanel,
    VueScrollableTable
  },

  data() {
    return {
      filterbarBackground: "vue", //vue|blue|orange|green|red|primary
      getEngagementRateClass: getEngagementRateClass,
      getFullName: getFullName,
      getPermissionErrorMessage: getPermissionErrorMessage,
      isFakeObjectHits: isFakeObjectHits,
      configs: Configs,
      emptyView: true,
      socials: SOCIALS,
      sortedSocialIds: Object.keys(SOCIALS).sort(
        (a, b) => SOCIALS[b].order - SOCIALS[a].order
      ),
      searchQuery: "",
      searchInput: "",
      socialSorts: SOCIAL_INFLUENCERS_SORTS,
      viewList: INFLUENCERS_VIEWS_LIST,
      engagementRateChartList: ENGAGEMENT_CHART_DATA,
      campaignsList: [],
      selectedHashtags: [],
      loading: {
        search: false,
        export: false,
        campaigns: false,
        addLeads: false,
        createCampaign: false,
        currencies: false
      },
      progress: {
        show: false,
        percent: 0,
        description: ""
      },
      showSingleProfile: false,
      showExportProfiles: false,
      showEditCampaign: false,
      showLeftNav: false,
      showCreditCard: false,
      showBillingPackage: false,
      showAllHashtags: false,
      selectedProfile: {},
      enabledCheckAllProfiles: false,
      searchThread: null,
      headerHeight: 0,
      resizeHandler: null,
      keyupHandler: null,
      platformOffsetY: 0,
      fromProfilePanel: false,
      selectedCampaignId: null,
      profilesKey: 0,
    };
  },
  computed: {
    ...mapState({
      social: (state) => state.influencer.social,
      searchResult: (state) => state.influencer.searchResult,
      filter: (state) => state.influencer.filter,
      currentFilter: (state) =>
        state.influencer.filter[state.influencer.filter.social],
      campaignFilter: (state) => state.campaign.filter,
      // exportsAllowed: state => state.user.authUser.exports_allowed,
      exportList: (state) => state.influencer.exportList,
      viewIndex: (state) => state.influencer.viewIndex,
      currencies: (state) => state.user.currencies,
      alert: (state) => state.alert,
      showAlert: (state) => state.alert.showAlert
    }),
    ...mapGetters({
      hasFullAccess: "user/hasFullAccess",
      hasViewAccess: "user/hasViewAccess"
    }),
    searchInputValue: {
      get() {
        var currentFilterComponent =
          this.$refs[`${this.socials[this.social].id}SearchFilter`][0];
        return currentFilterComponent.getSearchInput();
      }
    },
    instagramSortings: {
      get() {
        return SOCIAL_INFLUENCERS_SORTS["instagram"];
      }
    }
  },
  watch: {
    showExportProfiles: function (val) {
      if (val) {
        this.showSingleProfile = false;
        this.$filterbar.showFilterbar = false;
      }
    },
    showSingleProfile: function (val) {
      if (val) {
        this.$filterbar.showFilterbar = false;
      }
    },
    showAlert: function (show) {
      if (!show) return;
      if (
        this.alert.content === MESSAGES["user__no-credits"] ||
        this.alert.content === MESSAGES["user__no-credits-after-free-quota"]
      ) {
        if (!this.hasFullAccess("billing")) {
          setTimeout(() => {
            this.$store.dispatch("setAlert", {
              showAlert: true,
              content: getPermissionErrorMessage("billing:full"),
              alertClass: "danger"
            });
          }, 1500);
          return;
        }
        this.showBillingPackage = true;
      }
    }
  },

  mounted() {
    // to reload userpilot
    this.$store.dispatch("user/setRoute", this.$route.path);

    document.querySelector("#influencer_search");
    if (!this.keyupHandler) {
      this.keyupHandler = window.addEventListener("keyup", this.keyup);
    }
    if (!this.resizeHandler) {
      this.resizeHandler = window.addEventListener("resize", this.resizeWindow);
    }
    this.$filterbar.displayFilterbar(false);

    this.getCurrenciesList();
    this.getCampaignsList();
    if (this.searchResult.hits.length) {
      this.emptyView = false;
      const currentFilter =
        this.$refs[`${this.socials[this.social].id}SearchFilter`];
      if (currentFilter && currentFilter[0]) {
        this.searchQuery = currentFilter[0].buildQuery();
      }
    }
    setTimeout(() => {
      this.resizeWindow();
    }, 100);

    // document.ontouchmove = function(event){
    //   event.preventDefault();
    // }
    document.ontouchmove = function () {
      return true;
    };
  },
  beforeDestroy() {
    if (this.resizeHandler) {
      window.removeEventListener("resize", this.resizeWindow);
      this.resizeHandler = null;
    }
    if (this.keyupHandler) {
      window.removeEventListener("keyup", this.keyup);
      this.keyupHandler = null;
    }
  },
  methods: {
    onLogoImageError: onLogoImageError,
    hideFilterbar() {
      if (this.$filterbar.showFilterbar) {
        this.$filterbar.displayFilterbar(false);
      }
    },
    showFilterbar() {
      setTimeout(() => {
        this.$filterbar.displayFilterbar(true);
      }, 0);
    },
    setSocial(social) {
      this.$store.dispatch("influencer/setSocial", social);
    },
    setFilterSocial(social) {
      this.$store.dispatch("influencer/setFilterSocial", social);
    },
    toggleLeftNavbar() {
      this.$filterbar.displayFilterbar(false);
      this.showLeftNav = !this.showLeftNav;
      setTimeout(() => {
        this.$filterbar.displayFilterbar(true);
      }, 300);
    },
    keyup(e) {
      if (e.key === "Escape") {
        this.showSingleProfile = false;
        this.showExportProfiles = false;
        this.hideFilterbar();
      }
    },
    resizeWindow() {
      const isMobileSafari =
        navigator.userAgent.match(/(iPod|iPhone|iPad)/) &&
        navigator.userAgent.match(/AppleWebKit/);
      if (isMobileSafari) {
        this.platformOffsetY = 114;
      }
      this.headerHeight = 0; //document.querySelector(".navbar-top").clientHeight;
    },
    scrollTable() {
      const tBodyElement = document.querySelector(".profile-table tbody");
      if (
        tBodyElement.offsetHeight + tBodyElement.scrollTop >=
          tBodyElement.scrollHeight &&
        this.searchResult.hits.length < this.searchResult.total.value &&
        !this.loading.search
      ) {
        this.search(false);
      }
    },
    scrollGallery(event) {
      const element = event.target;
      const scrollTop = element.scrollTop;
      const scrollHeight = document.querySelector(
        ".profile-gallery-panel .cards-container"
      ).clientHeight;

      const scrollTriger = 1500;
      if (
        scrollHeight - scrollTop < scrollTriger &&
        this.searchResult.hits.length < this.searchResult.total.value &&
        !this.loading.search
      ) {
        this.search(false);
      }
    },
    setSortIndex(index) {
      this.$store.dispatch("influencer/setSortIndex", {
        social: this.social,
        sort_index: index
      });
      if (this.searchThread) {
        clearTimeout(this.searchThread);
        this.searchThread = null;
      }

      const currentFilter =
        this.$refs[`${this.socials[this.social].id}SearchFilter`];
      if (currentFilter && currentFilter[0]) {
        this.searchInput = currentFilter[0].getSearchInput();
      }

      this.searchThread = setTimeout(() => {
        this.search(true);
      }, 1000);
    },
    setViewIndex(index) {
      this.$store.dispatch("influencer/setViewIndex", index);
      this.resizeWindow();
    },
    setSelectedHashtags(hashtags) {
      this.showAllHashtags = true;
      if (typeof hashtags === "string") {
        const hashtagsObj = JSON.parse(hashtags);
        this.selectedHashtags = Object.keys(hashtagsObj)
          .map((tag) => `#${tag}`)
          .sort();
      } else if (hashtags && hashtags.length) {
        this.selectedHashtags = hashtags.map((tag) => `#${tag}`).sort();
      }
    },
    formatHashtags(hashtags) {
      if (typeof hashtags === "string") {
        const hashtagsObj = JSON.parse(hashtags);
        return Object.keys(hashtagsObj)
          .map((tag) => `#${tag}`)
          .sort()
          .join(", ");
      } else if (hashtags && hashtags.length) {
        return hashtags
          .map((tag) => `#${tag}`)
          .sort()
          .join(", ");
      }
      return "";
    },
    async getCurrenciesList() {
      if (this.currencies.length > 0) return;
      this.loading.currencies = true;
      try {
        await this.$store.dispatch("user/getCurrenciesList");
        this.loading.currencies = false;
      } catch {
        this.loading.currencies = false;
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["user__get_currencies-failed"],
            alertClass: "danger"
          });
        }
      }
    },

    async getCampaignsList() {
      this.loading.campaigns = true;
      try {
        const { data } = await this.$store.dispatch(
          "campaign/getCampaignNames"
        );
        if (data) {
          this.campaignsList = data;
        } else {
          this.campaignsList = [];
        }
        this.loading.campaigns = false;
      } catch {
        this.loading.cities = false;
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["campaign__get_campaigns-failed"],
            alertClass: "danger"
          });
        }
      }
    },

    generateInitialLead(profile) {
      let data;
      switch (profile.social) {
        case SOCIALS.instagram.id:
          data = {
            social: profile.social,
            username: profile.username,
            profile_pic: profile.profile_pic_url,
            source: "search",
            data: {
              full_name: profile.full_name,
              followers: profile.followers,
              posts: profile.posts,
              following: profile.following,
              engagement_rate: profile.engagement_rate,
              biography: profile.biography,
              website: profile.website,
              emails: profile.emails,
              business_email: profile.business_email
            }
          };
          break;
        case SOCIALS.tiktok.id:
          // need to update the data structure
          data = {
            social: profile.social,
            username: profile.username,
            profile_pic: profile.covers_small[0],
            source: "search",
            data: {
              nickname: profile.nickname,
              signature: profile.signature,
              fans: profile.fans,
              hearts: profile.hearts,
              digg: profile.digg,
              videos: profile.videos,
              following: profile.following,
              avg_engagement_rate: profile.avg_engagement_rate,
              main_language: profile.main_language
            }
          };
          break;
        case SOCIALS.youtube.id:
          // need to update the data structure
          data = {
            social: profile.social,
            username: profile.id,
            profile_pic: profile.avatar_url,
            source: "search",
            data: {
              title: profile.title,
              description: profile.description,
              subscribers: profile.subscribers,
              views: profile.views,
              videos: profile.videos,
              avg_engagement_rate: profile.avg_engagement_rate,
              videos_per_week: profile.videos_per_week,
              avg_comments: profile.avg_comments,
              avg_views: profile.avg_views,
              avg_likes_pct: profile.avg_likes_pct,
              avg_duration: profile.avg_duration,
              avg_views_per_reaction: profile.avg_views_per_reaction,
              avg_views_per_comment: profile.avg_views_per_comment,
              tv_banner_url: profile.tv_banner_url,
              mobile_banner_url: profile.mobile_banner_url,
              facebook_id: profile.facebook_id,
              instagram_id: profile.instagram_id,
              twitter_id: profile.twitter_id,
              twitch_id: profile.twitch_id,
              patreon_id: profile.patreon_id,
              pinterest_id: profile.pinterest_id,
              emails: profile.emails,
              languages: profile.languages
            }
          };
          break;
        default:
      }
      return data;
    },

    async addLeadsToExistingCampaign(campaign, fromProfilePanel) {
      this.selectedCampaignId = campaign.id;
      const batchSize = 200;
      const profiles = fromProfilePanel
        ? [{ social: this.social, ...this.selectedProfile }]
        : this.exportList;
      const showProgressModal = profiles && profiles.length > batchSize;
      this.progress = {
        title: "Add influencers to existing campaign",
        show: showProgressModal,
        percent: 0,
        description: "Generating influencers..."
      };

      const leadsPromise = profiles.map(async (profile) => {
        return this.generateInitialLead(profile);
      });

      Promise.all(leadsPromise).then(async (leads) => {
        if (!leads || !leads.length) {
          this.progress.show = false;
          return;
        }
        this.progress = {
          title: "Add influencers to existing campaign",
          show: showProgressModal,
          percent: 0,
          description: "Adding influencers to campaign... (0/0)"
        };
        this.loading.addLeads = true;

        try {
          for (
            let i = 0, done = 0;
            i < Math.ceil(leads.length / batchSize);
            i++
          ) {
            const batchLeads = leads.slice(
              i * batchSize,
              i * batchSize + batchSize
            );
            await this.$store.dispatch("campaign/addLeadToExistingCampaign", {
              campaign_id: campaign.id,
              leads: batchLeads.filter((e) => !!e)
            });
            done += batchLeads.length;
            this.progress = {
              title: "Add influencers to existing campaign",
              show: showProgressModal,
              percent: Math.ceil((done / leads.length) * 100.0),
              description: `Adding influencers to campaign... (${done}/${leads.length})`
            };
          }
          setTimeout(() => {
            this.progress.show = false;
          }, 1500);
          this.loading.addLeads = false;
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["campaign__add_lead-success"],
            alertClass: "success",
            customButton: {
              text: "Visit campaign to contact influencer",
              callback: () => {
                this.$router.push(
                  `campaigns/${this.selectedCampaignId}/pipeline`
                );
              }
            }
          });
        } catch (err) {
          if (!this.showAlert) {
            this.$store.dispatch("setAlert", {
              showAlert: true,
              content: MESSAGES["campaign__add_lead-failed"],
              alertClass: "danger"
            });
          }
          this.loading.addLeads = false;
          setTimeout(() => {
            this.progress.show = false;
          }, 500);
        }
      });
    },
    displayEditCampaignPanel(fromProfilePanel) {
      this.fromProfilePanel = fromProfilePanel;
      this.showEditCampaign = true;
    },

    async addLeadsToNewCampaign(campaign) {
      const batchSize = 200;
      const profiles = this.fromProfilePanel
        ? [{ social: this.social, ...this.selectedProfile }]
        : this.exportList;
      const showProgressModal = profiles && profiles.length > batchSize;
      this.progress = {
        title: "Add influencers to new campaign",
        show: showProgressModal,
        percent: 0,
        description: "Generating influencers..."
      };
      const leadsPromise = profiles.map(async (profile) => {
        return this.generateInitialLead(profile);
      });

      Promise.all(leadsPromise).then(async (leads) => {
        if (!leads || !leads.length) {
          this.progress.show = false;
          return;
        }
        this.progress = {
          title: "Add influencers to existing campaign",
          show: showProgressModal,
          percent: 0,
          description: "Adding influencers to campaign... (0/0)"
        };
        this.loading.addLeads = true;
        try {
          let campaignId = null;
          for (
            let i = 0, done = 0;
            i < Math.ceil(leads.length / batchSize);
            i++
          ) {
            const batchLeads = leads.slice(
              i * batchSize,
              i * batchSize + batchSize
            );
            if (i === 0) {
              const { data } = await this.$store.dispatch(
                "campaign/addLeadToNewCampaign",
                {
                  campaign_name: campaign.name,
                  budget: campaign.budget,
                  currency: campaign.currency,
                  color: campaign.color,
                  leads: batchLeads.filter((e) => !!e)
                }
              );
              campaignId = data.campaign_id;
              this.campaignsList.push({ name: campaign.name, id: campaignId });
              this.campaignsList.sort((a, b) => {
                return a.name > b.name ? 1 : -1;
              });
            } else {
              await this.$store.dispatch("campaign/addLeadToExistingCampaign", {
                campaign_id: campaignId,
                leads: batchLeads.filter((e) => !!e)
              });
            }
            done += batchLeads.length;
            this.progress = {
              title: "Add influencers to new campaign",
              show: showProgressModal,
              percent: Math.ceil((done / leads.length) * 100.0),
              description: `Adding influencers to campaign... (${done}/${leads.length})`
            };
          }
          setTimeout(() => {
            this.progress.show = false;
          }, 1500);
          this.loading.addLeads = false;
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["campaign__add_lead-success"],
            alertClass: "success"
          });
        } catch (err) {
          if (!this.showAlert) {
            this.$store.dispatch("setAlert", {
              showAlert: true,
              content: MESSAGES["campaign__add_lead-failed"],
              alertClass: "danger"
            });
          }
          this.loading.addLeads = false;
          setTimeout(() => {
            this.progress.show = false;
          }, 500);
        }
        this.showEditCampaign = false;
      });
    },

    async searchNew(data) {
      this.hideFilterbar();
      this.searchQuery = data.query;
      this.searchInput = data.username;
      const social = this.filter.social;
      await this.setSocial(social);
      await this.$store.dispatch("influencer/cleartSearchResult");
      this.emptyView = true;
      this.search(true);
    },
    async search(isNewSearch) {
      if (!this.hasFullAccess("search")) {
        const readableScope = ACCOUNT_SCOPES.find((e) => e.id === "search");
        const readablePermission = ACCOUNT_ACCESS.find((e) => e.id === "full");
        const message = MESSAGES["user__permission-error"]
          .replace(
            "{{permission}}",
            readablePermission && readablePermission.label
          )
          .replace("{{scope}}", readableScope && readableScope.label);
        this.$store.dispatch("setAlert", {
          showAlert: true,
          content: message,
          alertClass: "danger"
        });
        return;
      }

      this.enabledCheckAllProfiles = false;
      this.loading.search = true;
      this.searchResult.page = isNewSearch ? 1 : this.searchResult.page + 1;

      const social = this.social;

      try {
        let currentFilter = this.filter[social];

        if (
          /@[A-Za-z0-9._-]{3,30}/.test(this.searchInput) ||
          (currentFilter.searchType && currentFilter.searchType === "username")
        ) {
          const username =
            social == SOCIALS.tiktok.id
              ? currentFilter.keyword.replace("@", "")
              : this.searchInput.replace("@", "");

          await this.$store.dispatch(
            "influencer/getSingleProfile",
            social === SOCIALS.youtube.id
              ? {
                  social: social,
                  id: username,
                  is_search: true
                }
              : {
                  social: social,
                  username: username,
                  is_search: true
                }
          );
        } else {
          const query = this.buildQuery(isNewSearch);
          const result = await this.$store.dispatch(
            "influencer/getSearchResult",
            {
              social: social,
              query: query,
              is_new: isNewSearch
            }
          );
          result.hits.forEach((profile) => {
            if (this.enabledCheckAllProfiles) {
              this.$store.dispatch(
                "influencer/addProfileToExportList",
                profile
              );
            }
          });
        }

        this.resizeWindow();
        this.emptyView = false;
        this.loading.search = false;
      } catch {
        this.loading.search = false;
        this.emptyView = true;
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["influencer__search-failed"],
            alertClass: "danger"
          });
        }
      }
    },
    filterChanged(data) {
      if (this.emptyView) return;

      if (this.searchThread) {
        clearTimeout(this.searchThread);
        this.searchThread = null;
      }
      this.searchThread = setTimeout(() => {
        this.searchQuery = data.query;
        this.searchInput = data.username;
        this.search(true);
      }, 1000);
    },
    buildQuery(social, isNewSearch) {
      let query = this.searchQuery;

      let resetToDefaultSortOrder = false;
      let emptySearchString = !this.currentFilter.keyword;

      if (this.social === "instagram") {
        let emptyHashtags =
          this.currentFilter.hashtags &&
          this.currentFilter.hashtags.length === 0;
        if (
          this.currentFilter.sort_index === 2 &&
          emptySearchString &&
          emptyHashtags
        )
          resetToDefaultSortOrder = true;
      } else if (this.social === "youtube") {
        if (this.currentFilter.sort_index === 2 && emptySearchString)
          resetToDefaultSortOrder = true;
      } else if (this.social === "tiktok") {
        let emptyHashtags =
          this.currentFilter.hashtags &&
          this.currentFilter.hashtags.length === 0;
        if (
          this.currentFilter.sort_index === 3 &&
          emptySearchString &&
          emptyHashtags
        ) {
          resetToDefaultSortOrder = true;
        }
      }

      if (resetToDefaultSortOrder)
        query += `&order_by=${NOKEYWORDS_INFLUENCERS_SORTS[this.social].value}`;
      else
        query += `&order_by=${
          SOCIAL_INFLUENCERS_SORTS[this.social][
            this.filter[this.social].sort_index
          ].value
        }`;

      if (!isNewSearch) {
        query += `&page=${this.searchResult.page}`;
      }

      // eslint-disable-next-line no-console
      console.log("[Search query]: " + query);

      return query;
    },
    checkProfile(e, profile) {
      let isChecked = e;
      if (e.type === "change") {
        isChecked = e.srcElement.checked;
      }
      const profileWithSocial = {
        social: this.social,
        ...profile
      };
      if (isChecked) {
        this.$store.dispatch(
          "influencer/addProfileToExportList",
          profileWithSocial
        );
      } else {
        this.$store.dispatch(
          "influencer/removeProfileToExportList",
          profileWithSocial
        );
        this.enabledCheckAllProfiles = false;
      }
      this.resizeWindow();
    },
    checkAllProfiles() {
      if (this.enabledCheckAllProfiles) {
        this.searchResult.hits.forEach((profile) => {
          const profileWithSocial = {
            social: this.social,
            ...profile
          };
          this.$store.dispatch(
            "influencer/addProfileToExportList",
            profileWithSocial
          );
        });
      } else {
        this.$store.dispatch("influencer/clearExportList");
      }
      this.profilesKey += 1;
    },
    inExportList(activeProfile) {
      const activeProfileId =
        this.social === SOCIALS.youtube.id
          ? activeProfile.id
          : activeProfile.username;
      const index = this.exportList
        .map((profile) => {
          const profileId =
            profile.social === SOCIALS.youtube.id
              ? profile.id
              : profile.username;
          return `${profile.social}@${profileId}`;
        })
        .indexOf(`${this.social}@${activeProfileId}`);
      return index < 0 ? false : true;
    },
    showProfile(profile) {
      this.showSingleProfile = true;
      this.selectedProfile = profile;
    }
  }
};
</script>

<style lang="scss">
@import "../assets/scss/pages/influencers";

</style>
