import { render, staticRenderFns } from "./EditCampaignPanel.vue?vue&type=template&id=2841d375"
import script from "./EditCampaignPanel.vue?vue&type=script&lang=js"
export * from "./EditCampaignPanel.vue?vue&type=script&lang=js"
import style0 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./EditCampaignPanel.vue?vue&type=style&index=1&id=2841d375&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports