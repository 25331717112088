<template>
  <div
    class="tab-pane fade set-password-panel"
    :class="[
      { 'modal-mini': type === 'mini' }
    ]"
    v-bind:id="tabId"
  >


    <div class="main-content">
      <ul class="panel-blocks">
        <li class="panel-item">
          <div class="mb-2 setting-label">
            <span class=" nav-link-text">Current password</span>
          </div>
          <base-input
            class="input-group-alternative mb-4"
            placeholder="Current password"
            v-model="password"
            type="password"
            :valid="!error.password"
          ></base-input>
        </li>
        <li class="panel-item mt-3">
          <div class="mb-2 setting-label">
            <span class=" nav-link-text">New password</span>
          </div>
          <base-input
            class="input-group-alternative mb-4"
            placeholder="New password"
            v-model="passwordNew"
            type="password"
            :valid="!error.passwordNew"
          ></base-input>
        </li>
        <li class="panel-item mt-3">
          <div class="mb-2 setting-label">
            <span class=" nav-link-text">Password confirmation</span>
          </div>
          <base-input
            class="input-group-alternative mb-4"
            placeholder="Password confirmation"
            v-model="passwordConfirm"
            :valid="!error.passwordConfirm"
            type="password"
          ></base-input>
        </li>
      </ul>

      <div class="save-block ">
        <base-button
          type="primary"
          class="btn-black"
          v-on:click="submit"
          :isBusy="loading"
        >
          Update
        </base-button>
      </div>
    </div>

  </div>
</template>
<script>
import { mapState } from "vuex";
import { MESSAGES } from "@/constants";

export default {
  name: "set-password-panel",
  components: {
  },
  props: {
    currenciesList: {
      type: Array
    },
    show: Boolean,
    type: {
      type: String,
      default: "",
      validator(value) {
        let acceptedValues = ["", "notice", "mini"];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Modal type (notice|mini|"") '
    },
    animationDuration: {
      type: Number,
      default: 500,
      description: "Modal transition duration"
    },
    tabId: String
  },
  data() {
    return {
      loading: false,

      password: "",
      passwordNew: "",
      passwordConfirm: "",
      error: {
        password: "",
        passwordNew: false,
        passwordConfirm: false,
      },
      localError: ""
    };
  },
  computed: {
    ...mapState({
      showAlert: state => state.alert.showAlert,
    }),
  },
  methods: {
    closePanel() {
      /* slide out panel */
      document.querySelector(
        ".set-password-panel .modal-dialog"
      ).style.right = `-100%`;
      /* reset panel */
      setTimeout(() => {
        this.$emit("update:show", false);
        this.$emit("close");
      }, this.animationDuration / 2);
    },
    isValidPassword() {
      if (!this.password) {
        this.error.password = true;
        this.localError = MESSAGES["user__update_password-missing"];
        return false;
      } else if (!this.passwordNew) {
        this.error.passwordNew = true;
        this.localError = MESSAGES["user__update_password-missing"];
        return false;
      } else if (this.passwordNew.length < 4) {
        this.error.passwordNew = true;
        this.localError = MESSAGES["user__update_password-length"];
        return false;
      } else if (this.passwordNew !== this.passwordConfirm) {
        this.error.passwordConfirm = true;
        this.localError = MESSAGES["user__update_password-no-match"];
        return false;
      }
      return true;
    },
    async submit() {
      this.error = {
        password: false,
        passwordNew: false,
        passwordConfirm: false,
      }
      this.localError = "";
      if (!this.isValidPassword()) return;

      this.loading = true;
      try {
        await this.$store.dispatch(
          "user/updatePassword",
          {
            current: this.password,
            "new": this.passwordNew,
            new_confirmation: this.passwordConfirm,
          }
        );
        this.loading = false;
        this.$store.dispatch("setAlert", {
          showAlert: true,
          content: "Updated successfully!",
          alertClass: "success",
        });
      } catch (error) {
        this.loading = false;
        let message = MESSAGES[error.response.data.user_message_id[1]];
        window.msg = message;
        if (!message) {
          message = error.response.status === 409 ?  MESSAGES["user__current-password-wrong"] : MESSAGES["common-500"];
        }
        this.$store.dispatch("setAlert", {
          showAlert: true,
          content: error && error.response && message,
          alertClass: "danger",
        });
      }
    }


  },

  watch: {
    show(val) {
      const documentClasses = document.body.classList;
      if (val) {
        this.error = {
          password: false,
          passwordNew: false,
          passwordConfirm: false,
        }
        this.password = "",
        this.passwordNew = "";
        this.passwordConfirm = "";
        /* slide in panel*/
        documentClasses.add("modal-open");
        setTimeout(() => {
          document.querySelector(
            ".set-password-panel .modal-dialog"
          ).style.right = `0px`;
          this.imvCoef = this.defaultImvCoefficient;
        }, 0);
      } else {
        /* reset panel */
        documentClasses.remove("modal-open");
        document.querySelector(".set-password-panel").scrollTop = 0;
      }
    },
    localError(val) {
      if (!this.showAlert && val) {
        this.$store.dispatch("setAlert", {
          showAlert: true,
          content: val,
          alertClass: "danger"
        });
      }
    }
  }
};
</script>
<style>
.modal.show {
  background-color: rgba(255, 255, 255, 0.7);
}
</style>
