<template>
  <div class="signup-section row">
    <div class="card-body">
      <div class="signup-steps">
        <div
          class="signup-step pricing-plan-step"
          v-if="signupStep == SignUpSteps.PricingPlan"
        >
          <div class="page-title">NinjaInfluence</div>
          <div class="step-title">Pricing table</div>

          <span class="switch">
            <label for="switch-id">Billed Monthly</label>
            <toggle-button
              v-model="isYearly"
              :width="65"
              :height="30"
              color="#5fd185"
              :sync="true"
              class="mb-3 ml-3 switch-category toggle-plan"
            />
            <label for="switch-id">Annually</label>
          </span>

          <div class="monthl-card-m-row">
            <div class="monthly-card-col">
              <div class="monthly-card-content">
                <h2>GOLD NINJA</h2>

                <p>
                  Ideal for small brands planning small number of campaigns and
                  Influencers
                </p>
                <p class="price" v-if="isYearly">
                  $199
                  <span>/ per annually</span>
                </p>
                <p class="price" v-if="!isYearly">
                  $499
                  <span>/ per month</span>
                </p>
                <div class="mains button-m-div mt-3">
                  <a
                    class="bubbly-button"
                    @click="choosePlan('gold_450_yearly')"
                    v-if="isYearly"
                    >Test 14 days for free</a
                  >
                  <a
                    class="bubbly-button"
                    @click="choosePlan('gold_850_monthly')"
                    v-if="!isYearly"
                    >Test 14 days for free</a
                  >
                  <div class="bubbles"></div>
                </div>
              </div>
            </div>
            <div class="monthly-card-col">
              <div class="monthly-card-content">
                <h2>PLATINUM NINJA</h2>
                <p>
                  Fit most medium size brands with multiple campaigns running
                  with many Influencers
                </p>
                <p class="price" v-if="isYearly">
                  $399
                  <span>/ per annually</span>
                </p>
                <p class="price" v-if="!isYearly">
                  $899
                  <span>/ per month</span>
                </p>
                <div class="mains button-m-div mt-3">
                  <a
                    class="bubbly-button"
                    @click="choosePlan('platinum_650_yearly')"
                    v-if="isYearly"
                    >Test 14 days for free</a
                  >
                  <a
                    class="bubbly-button"
                    @click="choosePlan('platinum_1050_monthly')"
                    v-if="!isYearly"
                    >Test 14 days for free</a
                  >
                  <div class="bubbles"></div>
                </div>
              </div>
            </div>
            <div class="monthly-card-col">
              <div class="monthly-card-content">
                <h2>DIAMOND NINJA</h2>
                <p>
                  Suited for large brands or agencies running many campaigns
                  with hundred of Influencers
                </p>
                <p class="price" v-if="isYearly">
                  $699
                  <span>/ per annually</span>
                </p>
                <p class="price" v-if="!isYearly">
                  $1399
                  <span>/ per month</span>
                </p>
                <div class="mains button-m-div mt-3">
                  <a
                    class="bubbly-button"
                    @click="choosePlan('diamond_950_yearly')"
                    v-if="isYearly"
                    >Test 14 days for free</a
                  >
                  <a
                    class="bubbly-button"
                    @click="choosePlan('diamond_1450_monthly')"
                    v-if="!isYearly"
                    >Test 14 days for free</a
                  >
                  <div class="bubbles"></div>
                </div>
              </div>
            </div>
          </div>

          <div class="month-table">
            <table class="pricing-table desktop-pricing-table">
              <thead>
                <tr>
                  <th></th>
                  <th>
                    <div class="pric-head-content">
                      <h2>GOLD NINJA</h2>
                    </div>
                  </th>
                  <th>
                    <div class="pric-head-content">
                      <h2>PLATINUM NINJA</h2>
                    </div>
                  </th>
                  <th>
                    <div class="pric-head-content">
                      <h2>DIAMOND NINJAS</h2>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>135M Influencers Advanced Search</td>
                  <td><img src="/app/img/icons/svg/Check.svg" /></td>
                  <td><img src="/app/img/icons/svg/Check.svg" /></td>
                  <td><img src="/app/img/icons/svg/Check.svg" /></td>
                </tr>
                <tr>
                  <td>Support</td>
                  <td>Chat/ Live</td>
                  <td>Account manager</td>
                  <td>Account manager</td>
                </tr>
                <tr>
                  <td>Influencer Strategist</td>
                  <td><img src="/app/img/icons/svg/Cross.svg" /></td>
                  <td>3 hrs p/m</td>
                  <td>6 hrs p/m</td>
                </tr>
                <tr>
                  <td>Onboarding Influencers</td>
                  <td>Your first 2 Influencers guaranteed</td>
                  <td>Your first 5 Influencers guaranteed</td>
                  <td>Your first 10 Influencers guaranteed</td>
                </tr>
                <tr>
                  <td>On boarding</td>
                  <td>Basic</td>
                  <td>Professional</td>
                  <td>Professional</td>
                </tr>
                <tr>
                  <td>Number of seats</td>
                  <td>2</td>
                  <td>5</td>
                  <td>10</td>
                </tr>
                <tr>
                  <td>Connected email</td>
                  <td>3</td>
                  <td>3</td>
                  <td>5</td>
                </tr>
                <tr>
                  <td>Number of influencers contacted per month</td>
                  <td>500</td>
                  <td>1,000</td>
                  <td>1,500</td>
                </tr>
                <tr>
                  <td>Number or emails sent</td>
                  <td>4,000</td>
                  <td>20,000</td>
                  <td>UL</td>
                </tr>
                <tr>
                  <td>Number of campaigns</td>
                  <td>20</td>
                  <td>50</td>
                  <td>UL</td>
                </tr>
                <tr>
                  <td>eCommerce Integrations</td>
                  <td>1</td>
                  <td>2</td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>Social and eCommerce Tracking</td>
                  <td><img src="/app/img/icons/svg/Check.svg" /></td>
                  <td><img src="/app/img/icons/svg/Check.svg" /></td>
                  <td><img src="/app/img/icons/svg/Check.svg" /></td>
                </tr>
                <tr>
                  <td>Tracking links</td>
                  <td>50</td>
                  <td>100</td>
                  <td>1,000</td>
                </tr>
                <tr>
                  <td>Influencer Payments</td>
                  <td><img src="/app/img/icons/svg/Cross.svg" /></td>
                  <td><img src="/app/img/icons/svg/Check.svg" /></td>
                  <td><img src="/app/img/icons/svg/Check.svg" /></td>
                </tr>
                <tr>
                  <td>API</td>
                  <td><img src="/app/img/icons/svg/Check.svg" /></td>
                  <td><img src="/app/img/icons/svg/Check.svg" /></td>
                  <td><img src="/app/img/icons/svg/Check.svg" /></td>
                </tr>
                <tr>
                  <td>Advance Audience Analytics</td>
                  <td>500</td>
                  <td>1000</td>
                  <td>1,500</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td></td>
                  <td>
                    <div class="mains button-m-div mt-3">
                      <a
                        class="bubbly-button"
                        @click="choosePlan('gold_450_yearly')"
                        v-if="isYearly"
                        >Free Trial</a
                      >
                      <a
                        class="bubbly-button"
                        @click="choosePlan('gold_850_monthly')"
                        v-if="!isYearly"
                        >Free Trial</a
                      >
                      <div class="bubbles"></div>
                    </div>
                  </td>
                  <td>
                    <div class="mains button-m-div mt-3">
                      <a
                        class="bubbly-button"
                        @click="choosePlan('platinum_650_yearly')"
                        v-if="isYearly"
                        >Free Trial</a
                      >
                      <a
                        class="bubbly-button"
                        @click="choosePlan('platinum_1050_monthly')"
                        v-if="!isYearly"
                        >Free Trial</a
                      >
                      <div class="bubbles"></div>
                    </div>
                  </td>
                  <td>
                    <div class="mains button-m-div mt-3">
                      <a
                        class="bubbly-button"
                        @click="choosePlan('diamond_950_yearly')"
                        v-if="isYearly"
                        >Free Trial</a
                      >
                      <a
                        class="bubbly-button"
                        @click="choosePlan('diamond_1450_monthly')"
                        v-if="!isYearly"
                        >Free Trial</a
                      >
                      <div class="bubbles"></div>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
        <div
          class="signup-step credit-card-step"
          v-if="signupStep == SignUpSteps.CreditCard"
        >
          <div class="page-title">NinjaInfluence</div>
          <div class="step-title">Payment detail</div>

          <!-- http://localhost:8080/app/payment?plan=gold_850_monthly -->
          <iframe
            id="ppg-iframe"
            frameborder="0"
            scrolling="auto"
            onload="resizeIframe(this)"
            :src="ppg_ifram_src"
          >
          </iframe>
        </div>
      </div>

      <div class="auth-form">
        <form role="form">
          <div class="col">
            <div class="text-danger text-center">
              <!-- <small>{{ localError }}</small> -->
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-6">
        <!-- <a href="#" class="text-light">
                        <small>Forgot password?</small>
          </a>-->
      </div>
      <div class="col-6 text-right">
        <!-- <router-link to="/app/login" class="text-black">
          <small>Login into your account</small>
        </router-link> -->
      </div>

      <div class="under-actions">
        <a
          class="nav-link nav-link-icon py-3 px-4"
          @click="backToPlans"
          v-if="signupStep == SignUpSteps.CreditCard"
        >
          <i class="fas fa-arrow-left mr-2"></i>
          <span class="">Back to plans</span>
        </a>

        <a href="#" class="nav-link nav-link-icon py-3 px-4" @click="logOut">
          <i class="fas fa-sign-out-alt mr-2"></i>
          <span class="nav-link-inner--text">Sign out</span>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import { StripeElementCard } from "@vue-stripe/vue-stripe";
import Configs from "../configs";

import { ToggleButton } from "vue-js-toggle-button";

let predefinedPlans = {
  test_product: "88126",
  gold_450_yearly: "84752",
  gold_850_monthly: "84747",
  platinum_650_yearly: "84753",
  platinum_1050_monthly: "84749",
  diamond_950_yearly: "84755",
  diamond_1450_monthly: "84750"
};

let SignUpSteps = {
  PricingPlan: 1,
  CreditCard: 2
};

export default {
  name: "signup",
  components: {
    ToggleButton,
    StripeElementCard
  },
  data() {
    this.publishableKey = process.env.VUE_APP_STRIPE_PUBLIC_KEY;
    return {
      user_id: null,
      showMore: true,
      showAdditionalCosts: false,
      yearlySelected: false,
      SignUpSteps: SignUpSteps,
      configs: Configs,
      pricingPlan: null,
      user_email: null,
      signupStep: SignUpSteps.PricingPlan,
      is_test_mode: process.env.NODE_ENV !== "production",
      isYearly: true
    };
  },
  props: {},
  computed: {
    ppg_ifram_src() {
      if (!this.user_email) {
        this.user_email = localStorage.getItem("email");
      }
      return (this.ppg_ifram_src = `https://store.payproglobal.com/checkout?products[1][id]=${
        predefinedPlans[this.pricingPlan]
      }&page-template=17240&exfo=742&secret-key=pppg6cdA6V&language=en&currency=USD&x-user=${
        this.user_id
      }&x-plan=${this.pricingPlan}&billing-email=${
        this.user_email
      }&use-test-mode=${this.is_test_mode}`);
    }
  },
  async mounted() {
    console.log(this.$route);
    if (this.$route.query.plan) {
      if (
        Object.keys(predefinedPlans).includes(
          this.$route.query.plan.toLowerCase()
        )
      ) {
        this.pricingPlan = this.$route.query.plan;
        this.signupStep = SignUpSteps.CreditCard;
      }
    } else {
      const plan = await this.$store.dispatch("user/getCurrentPlan");
      this.pricingPlan = plan.data.plan.id;
      this.signupStep = SignUpSteps.CreditCard;
    }

    if (this.is_test_mode) {
      this.pricingPlan = "test_product";
      this.signupStep = SignUpSteps.CreditCard;
    }

    this.user_email = localStorage.getItem("email");
    this.user_id = localStorage.getItem("user_id");
  },
  methods: {
    logOut() {
      this.$store.dispatch("user/signOut");
      setTimeout(() => {
        window.location.href = "/app";
      }, 100);
    },
    backToPlans() {
      this.pricingPlan = null;
      this.signupStep = SignUpSteps.PricingPlan;
    },
    expandAdditionalCosts() {
      this.showMore = !this.showMore;
      this.showAdditionalCosts = !this.showAdditionalCosts;
    },
    togglePrice() {
      this.yearlySelected = !this.yearlySelected;
    },
    backToPricingPlan() {
      this.signupStep = SignUpSteps.PricingPlan;
    },
    choosePlan(plan) {
      this.pricingPlan = plan;

      this.signupStep = SignUpSteps.CreditCard;
    }
  },
  watch: {}
};
</script>
<style lang="scss">
//@import "../assets/scss/pages/register";
@import "../assets/scss/pages/pricing";
</style>
