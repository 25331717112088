<template>
  <div
    v-if="lead && lead.data"
    class="
      flex-column
      align-items-baseline
      justify-content-between
      flex-wrap
      w-100
      my-2
    "
  >
    <div class="flex-middle-between flex-wrap w-100">
      <div class="profile-avatar" v-if="lead.data" @click="showProfile">
        <div class="social-icon">
          <img
            :src="socials[lead.social].image"
            :alt="socials[lead.social].name"
          />
        </div>
        <div class="avatar mt-1">
          <avatar
            :username="
              lead.data.full_name ||
              lead.data.nickname ||
              lead.data.title ||
              lead.data.username
            "
            :size="32"
            :src="leadPictureUrl"
          >
          </avatar>
        </div>
        <div class="flex-column w-100 ml-2">
          <div class="profile-name-container d-flex align-items-baseline">
            <div class="profile-name">
              {{
                lead.data.full_name ||
                lead.data.nickname ||
                lead.data.title ||
                lead.data.username
              }}
            </div>
            <div class="profile-username ml-2">@{{ lead.username }}</div>
          </div>
          <div class="profile-bio">
            {{
              lead.data.biography ||
              lead.data.signature ||
              lead.data.description
            }}
          </div>
        </div>
      </div>
      <slot name="button"> </slot>
    </div>
    <div class="profile-website" v-if="lead.data.website">
      <i class="fas fa-globe"></i>
      <a :href="lead.data.website" :alt="lead.data.website" target="_blank">{{
        lead.data.website
      }}</a>
    </div>
    <div
      class="profile-emails"
      v-if="lead.data.emails && lead.data.emails.length"
    >
      <i class="far fa-envelope"></i>

      {{ leadEmailsList }}
    </div>
    <div
      v-if="lead.data.followers || lead.data.posts || lead.data.following"
      class="d-flex flex-wrap justify-content-between"
    >
      <div v-if="lead.data.followers" class="profile-stats">
        <div class="main-stats">
          <div class="value">
            {{ lead.data.followers | BigNumber }}
          </div>
          <div class="label">followers</div>
        </div>
        <div v-if="lead.data.posts" class="main-stats">
          <div class="value">
            {{ lead.data.posts | BigNumber }}
          </div>
          <div class="label">posts</div>
        </div>
        <div v-if="lead.data.following" class="main-stats">
          <div class="value">
            {{ lead.data.following | BigNumber }}
          </div>
          <div class="label">following</div>
        </div>
      </div>
      <div v-if="lead.data.engagement_rate" class="profle-engagement_rate">
        <div class="label mr-1">Engagement rate:</div>
        <div class="engagement-bar flex-column align-items-center mb-1">
          <div class="flex-middle">
            <div class="label">
              {{ lead.data.engagement_rate | Percent }}
            </div>
          </div>
          <div class="w-100 ml-2">
            <base-progress
              type="primary"
              :show-percentage="false"
              :class="'pt-0 pt-' + getEngagementRateClass(lead.data)"
              :value="
                engagementRateChartData[getEngagementRateClass(lead.data)]
                  .percent
              "
            />
          </div>
        </div>
      </div>
    </div>

    <profile-panel
      :show="showSingleProfile"
      :campaignsList="campaignsList"
      @close="showSingleProfile = false"
      :social="lead.social"
      :username="lead.username"
      :id="profileId"
      :animationDuration="500"
      :canAddToCampaign="false"
    />
  </div>
</template>
<script>
import { getEngagementRateClass } from "@/utils";
import { SOCIALS, ENGAGEMENT_CHART_DATA } from "@/constants";
import Avatar from "vue-avatar";
const ProfilePanel = () => import("../ProfilePanel");

export default {
  name: "lead",
  components: {
    Avatar,
    ProfilePanel
  },
  props: {
    lead: {
      type: Object
    }
  },
  computed: {
    leadPictureUrl() {
      return this.lead.profile_pic;
    },
    leadEmailsList: {
      get() {
        if (typeof this.lead.data.emails === "string")
          return this.lead.data.emails;

        return this.lead.data.emails.join(" ; ");
      }
    },
    profileId: {
      get() {
        // sh.sergey - workaround. Currently we have different id values for search results
        // and editing lead inside campaign. So, for Youtube leads id should be channel id
        // and not lead id.
        let profileId =
          this.lead.social === SOCIALS.youtube.id
            ? this.lead.username || this.lead.id
            : this.lead.id;
        return profileId;
      }
    }
  },
  data() {
    return {
      socials: SOCIALS,
      //leadPictureUrl: null,
      engagementRateChartData: ENGAGEMENT_CHART_DATA,
      getEngagementRateClass: getEngagementRateClass,

      showSingleProfile: false,
      campaignsList: [],

      social: "",
      username: "",
      id: 0
    };
  },
  mounted() {
    this.getLeadPictureUrl();
  },
  methods: {
    getLeadPictureUrl() {
      let img = new Image();
      img.onload = () => {
        this.leadPictureUrl = this.lead.profile_pic;
      };
      img.src = this.lead.profile_pic;
    },
    showProfile() {
      this.showSingleProfile = true;
    }
  }
};
</script>
<style lang="scss">
@import "../../assets/scss/custom/functions";
@import "../../assets/scss/custom/variables";
@import "../../assets/scss/custom/mixins";
.profile-avatar {
  width: calc(100% - 400px);
  display: flex;
  margin-bottom: 10px;
  position: relative;
  min-width: 400px;

  cursor: pointer;

  .social-icon {
    position: absolute;
    left: 18px;
    top: 23px;
    background: white;
    width: 16px;
    height: 16px;
    border: 0.5px solid $gray-200;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;

    img {
      width: 10px;
      height: 10px;
    }
  }

  .avatar {
    min-width: 32px;
    min-height: 32px;
    max-width: 32px;
    max-height: 32px;
  }

  .profile-name {
    font-size: 0.95rem;
    font-weight: $font-weight-bold;
    color: $body-color;
    text-align: left;
    overflow: hidden;
    height: 1.3rem;
    max-width: 50%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .profile-username {
    font-size: 0.8rem;
    font-weight: $font-weight-light;
    color: $gray-500;
    text-align: left;
  }

  .email-provider {
    font-size: 1rem;
  }

  .email-receiver {
    font-size: 1rem;
    color: $gray-400;
  }

  .email-date {
    color: $gray-400;
    font-size: 0.9rem;
  }
  .profile-name-container {
    display: flex;
  }
  .profile-bio {
    font-size: 0.9rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
  }
}
</style>
