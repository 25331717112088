<template>
  <div
    class="tab-pane fade set-plans-panel"
    :class="[{ 'modal-mini': type === 'mini' }]"
    v-bind:id="tabId"
  >
    <div class="main-content">
      <div class="modal-body p-0">
        <div v-if="currentPlan" class="setting-label px-4">
          CURRENT SUBSCRIPTION
        </div>
        <ul v-if="currentPlan" class="main-nav">
          <li class="p-3 my-1 flex-middle-between">
            <span>{{ currentPlan.name }}</span>
          </li>
        </ul>
        <div class="w-100 text-center">
          <a class="btn-link" @click="changePlan">Change plan...</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "set-plans-panel",
  props: {
    show: Boolean,
    type: {
      type: String,
      default: "",
      validator(value) {
        let acceptedValues = ["", "notice", "mini"];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Modal type (notice|mini|"") ',
    },
    animationDuration: {
      type: Number,
      default: 500,
      description: "Modal transition duration",
    },
    username: {
      type: String,
    },
    tabId: String,
  },
  data() {
    return {
      currentPlan: null,
      loading: {
        currentPlan: false,
      },
      localError: false,
    };
  },
  mounted() {
    this.$nextTick(function () {
      this.getCurrentPlan();
    });
  },
  methods: {
    closePanel() {
      /* slide out panel */
      document.querySelector(
        ".set-plans-panel .modal-dialog"
      ).style.right = `-100%`;
      /* reset panel */
      setTimeout(() => {
        this.$emit("update:show", false);
        this.$emit("close");
      }, this.animationDuration / 2);
    },
    changePlan() {
      this.$emit("changePlan");
    },
    async getCurrentPlan() {
      this.loading.currentPlan = true;
      try {
        const { data } = await this.$store.dispatch("user/getCurrentPlan");
        if (data) {
          this.currentPlan = data.plan; // need to check
          this.loading.currentPlan = false;
        } else {
          this.closePanel();
          this.changePlan();
        }
      } catch {
        this.loading.currentPlan = false;
        this.localError = "Getting the current plan failed";
      }
    },
  },

  watch: {
    show(val) {
      const documentClasses = document.body.classList;
      if (val) {
        this.getCurrentPlan();
        /* slide in panel*/
        documentClasses.add("modal-open");
        setTimeout(() => {
          document.querySelector(
            ".set-plans-panel .modal-dialog"
          ).style.right = `0px`;
        }, 0);
      } else {
        /* reset panel */
        documentClasses.remove("modal-open");
        document.querySelector(".set-plans-panel").scrollTop = 0;
      }
    },
  },
};
</script>
<style>
.modal.show {
  background-color: rgba(255, 255, 255, 0.7);
}
</style>
