<template>
  <ul class="filter-nav my-2 p-3">
    <li>
      <influencers-search-switch v-model="instagramSearchType">
      </influencers-search-switch>
    </li>
    <li v-if="instagramSearchType == 'username'" class="flex-column mt-3">
      <!-- <div class="search-tip-filtering mb-2">@Username</div> -->
      <div class="flex-between filter-item search-form" ref="search-button">
        <base-input
          id="input_search--instagram"
          class="input-group-alternative search-input w-100"
          placeholder="Search Instagram User Name..."
          autofocus
          v-on:keyup="searchUserNameChanged($event)"
          v-model="searchUserName"
        ></base-input>
        <base-button
          type="primary"
          class="search-button input-group-alternative ml-0 mb-4"
          @click="searchByUserName(true)"
        >
          <i class="fas fa-search"></i>
        </base-button>
        <!-- <div class="search-message"></div>
        <div class="search-message">@Username for specific users or Keyword for general search</div> -->
      </div>
    </li>
    <li v-if="instagramSearchType == 'keyword'" class="flex-column  mt-3">
      <!-- <div class="search-tip-filtering mb-2">Keyword</div> -->
      <div class="flex-between filter-item search-form" ref="search-button">
        <base-input
          id="input_search--instagram"
          class="input-group-alternative search-input w-100"
          name="searchInput"
          placeholder="Search Instagram bio..."
          autofocus
          v-on:keyup="searchInputChanged($event)"
          v-model="searchInput"
        >
        </base-input>
        <base-button
          type="primary"
          class="search-button input-group-alternative ml-0 mb-4"
          @click="search(true)"
        >
          <i class="fas fa-search"></i>
        </base-button>
        <!-- <div class="search-message"></div>
        <div class="search-message">@Username for specific users or Keyword for general search</div> -->
      </div>
    </li>

    <li class="flex-column filter-item mt-2">
      <div class="categories-filter mb-2">
        <span class="nav-link-text">Hashtags</span>
        <toggle-button
          v-model="hashtagsAndOr"
          :width="65"
          :height="30"
          :value="true"
          :sync="true"
          :labels="{ checked: 'AND', unchecked: 'OR' }"
          class="mb-3 ml-3 switch-category"
        />
      </div>
      <div class="flex-column">
        <div class="mb-3 filter-item-wrapper">
          <multiselect
            placeholder="Add one or more hashtags"
            tagPlaceholder="Press enter to insert"
            :multiple="true"
            :taggable="true"
            :options="hashtags"
            label="label"
            track-by="hashtag"
            @tag="addHashtag"
            @search-change="getHashtagsList"
            @open="hashtags = []"
            :loading="loading.hashtags"
            :internal-search="false"
            selectLabel
            deselectLabel="✕"
            :hideSelected="true"
            v-model="currentHashtags"
            ref="hashtagsMultiselect"
          >
            <span slot="noOptions">Type at least two characters.</span>
            <span slot="noResult">No matching hashtags.</span>
          </multiselect>
          <a
            v-if="hashtags"
            class="btn-select-reset"
            @click="resetFilter('hashtags')"
          >
            <i class="fas fa-times-circle" v-if="currentHashtags.length"></i>
          </a>
        </div>
      </div>
    </li>

    <li class="flex-column filter-item mt-3">
      <div class="mb-2">
        <span class="nav-link-text">Contact details</span>
      </div>
      <div class="filter-item-wrapper">
        <multiselect
          placeholder="Contact details"
          :searchable="true"
          :allow-empty="false"
          :hide-selected="true"
          selectLabel
          selectedLabel="✓"
          deselectLabel="✕"
          label="label"
          track-by="id"
          v-model="hasEmails"
          :options="profileTypes.contact"
        />
        <a
          v-if="hasEmails && hasEmails.id !== profileTypes.contact[1].id"
          class="btn-select-reset btn-select-reset--contact"
          @click="resetFilter('has_emails')"
        >
          <i class="fas fa-times-circle"></i>
        </a>
      </div>
    </li>

    <li class="flex-column filter-item mt-4">
      <div class="mb-2">
        <span class="nav-link-text">Engagement Rate (%)</span>
      </div>
    </li>
    <li class="flex-column mt-3">
      <base-slider
        v-model="sliderEng"
        :pips="pips"
        :step="10"
        :range="{ min: sliderEng[0], max: sliderEng[1] }"
        :value="[sliderEng[0], sliderEng[1]]"
      ></base-slider>
    </li>
    <li>
      <div class="flex-middle-between filter-item-wrapper">
        <base-input
          class="input-group-alternative mb-3"
          placeholder="Min"
          type="number"
          :step="INPUT_ENGAGEMENT_RATE_STEP"
          :min="INPUT_ENGAGEMENT_RATE_MIN"
          :max="
            engagementRateLTE == null
              ? INPUT_ENGAGEMENT_RATE_MAX
              : Math.min(INPUT_ENGAGEMENT_RATE_MAX, engagementRateLTE)
          "
          v-on:blur="validateEngagementRateGTE"
          v-on:keyup.enter="validateEngagementRateGTE"
          v-model="engagementRateGTE"
        ></base-input>
        <base-input
          class="input-group-alternative mb-3 ml-3"
          placeholder="Max"
          type="number"
          :step="INPUT_ENGAGEMENT_RATE_STEP"
          :min="Math.max(INPUT_ENGAGEMENT_RATE_MIN, engagementRateGTE)"
          :max="INPUT_ENGAGEMENT_RATE_MAX"
          v-on:blur="validateEngagementRateLTE"
          v-on:keyup.enter="validateEngagementRateLTE"
          v-model="engagementRateLTE"
        ></base-input>
        <a
          v-if="engagementRateGTE || engagementRateLTE"
          class="btn-select-reset"
          @click="resetFilter('engagement_rate')"
        >
          <i class="fas fa-times-circle"></i>
        </a>
      </div>
    </li>
    <li>
      <div class="mb-2">
        <span class="nav-link-text">Followers</span>
      </div>
    </li>
    <li class="flex-column mt-3">
      <base-slider
        v-model="sliderFollower"
        :pips="pips"
        :step="1000"
        :range="rangesliderFollower"
        :value="[sliderFollower[0], sliderFollower[1]]"
      ></base-slider>
    </li>
    <li>
      <div class="flex-middle-between filter-item-wrapper">
        <base-input
          class="input-group-alternative mb-3"
          placeholder="Min"
          type="number"
          :step="INPUT_FOLLOWERS_STEP"
          :min="INPUT_FOLLOWERS_MIN"
          :max="
            followersLTE == null
              ? INPUT_FOLLOWERS_MAX
              : Math.min(INPUT_FOLLOWERS_MAX, followersLTE)
          "
          v-on:blur="validateFollowersGTE"
          v-on:keyup.enter="validateFollowersGTE"
          v-model="followersGTE"
        >
        </base-input>
        <base-input
          class="input-group-alternative mb-3 ml-3"
          placeholder="Max"
          type="number"
          :step="INPUT_FOLLOWERS_STEP"
          :min="Math.max(INPUT_FOLLOWERS_MIN, followersGTE)"
          :max="INPUT_FOLLOWERS_MAX"
          v-on:blur="validateFollowersLTE"
          v-on:keyup.enter="validateFollowersLTE"
          v-model="followersLTE"
        ></base-input>
        <a
          v-if="followersGTE || followersLTE"
          class="btn-select-reset"
          @click="resetFilter('followers')"
        >
          <i class="fas fa-times-circle"></i>
        </a>
      </div>
    </li>

    <li class="flex-column filter-item mt-3">
      <div class="categories-filter mb-2">
        <span class="nav-link-text">Categories</span>
        <toggle-button
          v-model="categoryAndOr"
          :width="65"
          :height="30"
          :value="true"
          :sync="true"
          :labels="{ checked: 'AND', unchecked: 'OR' }"
          class="mb-3 ml-3 switch-category"
        />
      </div>
      <div class="flex-column">
        <div class="mt-2 flex-center"></div>
        <div class="mb-3 filter-item-wrapper">
          <multiselect
            placeholder="Select Category"
            :multiple="true"
            :taggable="true"
            selectLabel
            deselectLabel="✕"
            label="category"
            track-by="category"
            :hideSelected="true"
            :options="categories"
            tagPlaceholder="Press enter to select"
            v-model="currentCategories"
          />
          <a
            v-if="currentCategories"
            class="btn-select-reset"
            @click="resetFilter('categories')"
          >
            <i class="fas fa-times-circle" v-if="currentCategories.length"></i>
          </a>
        </div>
      </div>
    </li>
    <li class="flex-column filter-item mt-3">
      <div class="mb-2">
        <span class="nav-link-text">Location</span>
      </div>
      <div class="mb-3 filter-item-wrapper">
        <multiselect
          placeholder="Location"
          :searchable="true"
          :taggable="true"
          :multiple="true"
          :limit="5"
          selectLabel
          track-by="value"
          label="value"
          noOptions="Please enter 2 or more characters"
          @search-change="searchLocation"
          v-model="currentlocations"
          :options="locations"
        >
        </multiselect>

        <a
          v-if="currentlocations != null"
          class="btn-select-reset btn-select-reset--location"
          @click="resetFilter('currentlocations')"
        >
          <i class="fas fa-times-circle"></i>
        </a>
      </div>
    </li>
    <li class="flex-column filter-item mt-3">
      <div class="mb-2">
        <span class="nav-link-text">Gender</span>
      </div>
      <div class="mb-3 filter-item-wrapper">
        <multiselect
          placeholder="Gender"
          :searchable="true"
          :allow-empty="false"
          :hide-selected="true"
          selectLabel
          selectedLabel="✓"
          deselectLabel="✕"
          label="label"
          track-by="id"
          v-model="gender"
          :options="GENDERS"
        />
        <a
          v-if="gender && gender.id !== 'any'"
          class="btn-select-reset btn-select-reset--contact"
          @click="resetFilter('gender')"
        >
          <i class="fas fa-times-circle"></i>
        </a>
      </div>
    </li>

    <li class="flex-column filter-item mt-3">
      <div class="mb-2">
        <span class="nav-link-text">Profile type</span>
      </div>
      <div class="mb-3 filter-item-wrapper">
        <label class="sub-label">Business/Personal</label>
        <multiselect
          placeholder="Business/Personal"
          :searchable="true"
          :allow-empty="false"
          :hide-selected="true"
          selectLabel
          selectedLabel="✓"
          deselectLabel="✕"
          label="label"
          track-by="id"
          v-model="isBusiness"
          :options="profileTypes.business"
        />
        <a
          v-if="isBusiness && isBusiness.id !== 'all'"
          class="btn-select-reset btn-select-reset--profile"
          @click="resetFilter('is_business')"
        >
          <i class="fas fa-times-circle"></i>
        </a>
      </div>
      <div class="mb-3 filter-item-wrapper">
        <label class="sub-label">Verified/Unverified</label>
        <multiselect
          placeholder="Verified/Unverified"
          :searchable="true"
          :allow-empty="false"
          :hide-selected="true"
          selectLabel
          selectedLabel="✓"
          deselectLabel="✕"
          label="label"
          track-by="id"
          v-model="isVerified"
          :options="profileTypes.verified"
        />
        <a
          v-if="isVerified && isVerified.id !== 'all'"
          class="btn-select-reset btn-select-reset--profile"
          @click="resetFilter('is_verified')"
        >
          <i class="fas fa-times-circle"></i>
        </a>
      </div>
    </li>

    <li class="flex-column mt-3 mb-5">
      <div class="mb-3 flex-center">
        <a class="btn-reset-all" @click="resetFilter()">
          <span class="nav-link-text ml-2">Reset filters</span>
          <i class="fas fa-sync"></i>
        </a>
      </div>
    </li>
  </ul>
</template>
<script>
import { mapState } from "vuex";

import Popper from "popper.js";
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false;

/* components */
import { ToggleButton } from "vue-js-toggle-button";
const InfluencersSearchSwitch = () => import("./Influencers/InfluencersSearchSwitch");

import Multiselect from "vue-multiselect";
import {
  MESSAGES,
  SOCIALS,
  GENDERS,
  PROFILE_TYPES,
  INSTAGRAM_LOCATIONS,
  INPUT_ENGAGEMENT_RATE_MAX,
  INPUT_ENGAGEMENT_RATE_MIN,
  INPUT_ENGAGEMENT_RATE_STEP,
  INPUT_FOLLOWERS_MAX,
  INPUT_FOLLOWERS_MIN,
  INPUT_FOLLOWERS_STEP
} from "@/constants";

export default {
  name: "instagram-search-filter",
  components: {
    ToggleButton,
    Multiselect,
    InfluencersSearchSwitch
  },
  props: {
    logo: {
      type: String,
      default: "/app/img/brand/green.png",
      description: "filterbar app logo"
    },
    autoClose: {
      type: Boolean,
      default: true,
      description:
        "Whether filterbar should autoclose on mobile when clicking an item"
    }
  },
  provide() {
    return {
      autoClose: this.autoClose
    };
  },
  computed: {
    ...mapState({
      filter: (state) => state.influencer.filter[SOCIALS.instagram.id],
      exportList: (state) => state.influencer.exportList
    }),
    searchInput: {
      set(value) {
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.instagram.id,
          name: "keyword",
          value: value
        });
      },
      get() {
        return this.filter.keyword;
      }
    },
    searchUserName: {
      set(value) {
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.instagram.id,
          name: "username",
          value: value
        });
      },
      get() {
        return this.filter.username;
      }
    },
    engagementRateLTE: {
      set(value) {
        const realValue = value
          ? Math.min(INPUT_ENGAGEMENT_RATE_MAX, value)
          : value;
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.instagram.id,
          name: "engagement_rate_lte",
          value: realValue
        });
      },
      get() {
        return this.filter.engagement_rate_lte;
      }
    },
    engagementRateGTE: {
      set(value) {
        const realValue = value
          ? Math.max(INPUT_ENGAGEMENT_RATE_MIN, value)
          : value;
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.instagram.id,
          name: "engagement_rate_gte",
          value: realValue
        });
      },
      get() {
        return this.filter.engagement_rate_gte;
      }
    },
    followersLTE: {
      set(value) {
        const realValue = value ? Math.min(INPUT_FOLLOWERS_MAX, value) : value;
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.instagram.id,
          name: "followers_lte",
          value: realValue
        });
      },
      get() {
        return this.filter.followers_lte;
      }
    },
    followersGTE: {
      set(value) {
        const realValue = value ? Math.max(INPUT_FOLLOWERS_MIN, value) : value;
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.instagram.id,
          name: "followers_gte",
          value: realValue
        });
      },
      get() {
        return this.filter.followers_gte;
      }
    },
    categoryAndOr: {
      set(value) {
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.instagram.id,
          name: "category_and_or",
          value: value
        });
      },
      get() {
        return this.filter.category_and_or;
      }
    },
    hashtagsAndOr: {
      set(value) {
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.instagram.id,
          name: "hashtags_and_or",
          value: value
        });
      },
      get() {
        return this.filter.hashtags_and_or;
      }
    },
    currentCategories: {
      set(value) {
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.instagram.id,
          name: "categories",
          value: value
        });
      },
      get() {
        return this.filter.categories;
      }
    },
    currentHashtags: {
      set(value) {
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.instagram.id,
          name: "hashtags",
          value: value
        });
      },
      get() {
        return this.filter.hashtags;
      }
    },
    gender: {
      set(value) {
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.instagram.id,
          name: "gender",
          value: GENDERS.map((e) => e.id).indexOf(value.id)
        });
      },
      get() {
        return GENDERS[this.filter.gender];
      }
    },
    location: {
      set(value) {
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.instagram.id,
          name: "location_country",
          value: value.country
        });
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.instagram.id,
          name: "location_region",
          value: value.region
        });
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.instagram.id,
          name: "location_city",
          value: value.city
        });
      },
      get() {
        return this.filter.location_country
          ? {
              country: this.filter.location_country,
              region: this.filter.location_region,
              city: this.filter.location_city,
              value: `${
                this.filter.location_city
                  ? this.filter.location_city + ", "
                  : ""
              }${
                this.filter.location_region
                  ? this.filter.location_region + ", "
                  : ""
              }${this.filter.location_country}`
            }
          : null;
      }
    },
    hasEmails: {
      set(value) {
        let filterValue = value.id === "all" ? null : value.id === "has_emails";

        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.instagram.id,
          name: "has_emails",
          value: filterValue
        });
      },
      get() {
        return this.filter.has_emails
          ? PROFILE_TYPES.contact[0]
          : PROFILE_TYPES.contact[1];
      }
    },
    isVerified: {
      set(value) {
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.instagram.id,
          name: "is_verified",
          value:
            value.id === "all"
              ? null
              : value.id === "is_verified"
              ? true
              : false
        });
      },
      get() {
        return this.filter.is_verified === null
          ? PROFILE_TYPES.verified[0]
          : this.filter.is_verified === true
          ? PROFILE_TYPES.verified[1]
          : PROFILE_TYPES.verified[2];
      }
    },
    isBusiness: {
      set(value) {
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.instagram.id,
          name: "is_business",
          value:
            value.id === "all"
              ? null
              : value.id === "is_business_account"
              ? true
              : false
        });
      },
      get() {
        return this.filter.is_business === null
          ? PROFILE_TYPES.business[0]
          : this.filter.is_business === true
          ? PROFILE_TYPES.business[1]
          : PROFILE_TYPES.business[2];
      }
    }
  },
  watch: {
    searchInput: function () {
      this.$emit("searchInputChanged", this.searchInput);
    },
    // searchInput: function (value) {
    //   this.$emit("change", {
    //     social: SOCIALS.instagram.id,
    //     name: "keyword",
    //     value: value,
    //     query: this.buildQuery(),
    //   });
    // },

    // searchUserName: function (value) {
    //   this.$emit("change", {
    //     social: SOCIALS.instagram.id,
    //     name: "username",
    //     value: value,
    //     query: this.buildQuery(),
    //   });
    // },

    engagementRateLTE: function (value) {
      this.sliderEng[1] = value;
      this.$emit("change", {
        social: SOCIALS.instagram.id,
        name: "engagement_rate_lte",
        value: value,
        query: this.buildQuery()
      });
    },
    engagementRateGTE: function (value) {
      this.sliderEng[0] = value;
      this.$emit("change", {
        social: SOCIALS.instagram.id,
        name: "engagement_rate_gte",
        value: value,
        query: this.buildQuery()
      });
    },
    followersLTE: function (value) {
      this.sliderFollower[1] = value;
      this.$emit("change", {
        social: SOCIALS.instagram.id,
        name: "followers_lte",
        value: value,
        query: this.buildQuery()
      });
    },
    followersGTE: function (value) {
      this.sliderFollower[0] = value;
      this.$emit("change", {
        social: SOCIALS.instagram.id,
        name: "followers_gte",
        value: value,
        query: this.buildQuery()
      });
    },
    sliderFollower: function (value) {
      this.followersGTE = value[0];
      this.followersLTE = value[1];
    },
    sliderEng: function (value) {
      this.engagementRateGTE = value[0];
      this.engagementRateLTE = value[1];
    },
    categoryAndOr: function (value) {
      this.$emit("change", {
        social: SOCIALS.instagram.id,
        name: "category_and_or",
        value: value,
        query: this.buildQuery()
      });
    },
    hashtagsAndOr: function (value) {
      this.$emit("change", {
        social: SOCIALS.instagram.id,
        name: "hashtags_and_or",
        value: value,
        query: this.buildQuery()
      });
    },
    gender: function (value) {
      this.$emit("change", {
        social: SOCIALS.instagram.id,
        name: "gender",
        value: value.id,
        query: this.buildQuery()
      });
    },
    currentCategories: function (value) {
      this.$emit("change", {
        social: SOCIALS.instagram.id,
        name: "categories",
        value: value,
        query: this.buildQuery()
      });
    },
    currentHashtags: function () {
      this.search(true);
      // this.$emit("change", {
      //   social: SOCIALS.instagram.id,
      //   name: "hashtags",
      //   value: value,
      //   query: this.buildQuery()
      // });
    },
    isBusiness: function (value) {
      this.$emit("change", {
        social: SOCIALS.instagram.id,
        name: "is_business",
        value:
          value.id === "all"
            ? null
            : value.id === "is_business_account"
            ? true
            : false,
        query: this.buildQuery()
      });
    },
    isVerified: function (value) {
      this.$emit("change", {
        social: SOCIALS.instagram.id,
        name: "is_verified",
        value:
          value.id === "all" ? null : value.id === "is_verified" ? true : false,
        query: this.buildQuery()
      });
    },
    hasEmails: function (value) {
      this.$emit("change", {
        social: SOCIALS.instagram.id,
        name: "has_emails",
        value: value.id === "all" ? null : true,
        query: this.buildQuery()
      });
    },
    location: function (location) {
      this.$emit("change", {
        social: SOCIALS.instagram.id,
        name: "location",
        value: location,
        query: this.buildQuery()
      });
    }
  },

  data() {
    return {
      categories: [],
      hashtags: [],
      locations: [],
      currentlocations: null,
      countries: [],
      regions: [],
      cities: [],
      profileTypes: PROFILE_TYPES,
      INPUT_ENGAGEMENT_RATE_MIN: INPUT_ENGAGEMENT_RATE_MIN,
      INPUT_ENGAGEMENT_RATE_MAX: INPUT_ENGAGEMENT_RATE_MAX,
      INPUT_ENGAGEMENT_RATE_STEP: INPUT_ENGAGEMENT_RATE_STEP,
      INPUT_FOLLOWERS_MIN: INPUT_FOLLOWERS_MIN,
      INPUT_FOLLOWERS_MAX: INPUT_FOLLOWERS_MAX,
      INPUT_FOLLOWERS_STEP: INPUT_FOLLOWERS_STEP,
      GENDERS: GENDERS,
      loading: {
        categories: false,
        countries: false,
        regions: false,
        cities: false,
        hashtags: false
      },
      locationQuery: "",
      sliderFollower: [0, 1000000],
      sliderEng: [0, 100],
      rangesliderFollower: {
        min: [0],
        "10%": [500, 500],
        "50%": [4000, 1000],
        max: [1000000]
      },
      pips: {
        mode: "range",
        density: 3
      },
      instagramSearchType: "keyword"
    };
  },
  async mounted() {
    document.querySelector("#input_search--instagram").focus();
    await this.getCategoriesList();

    this.$nextTick(function () {
      //let popper =
      // new Popper(
      //   this.$refs["search-button"],
      //   this.$refs["search-button-hashtag-popup"],
      //   {
      //     placement: "bottom"
      //   }
      // );
    });
  },
  methods: {
    resetFilter(which) {
      switch (which) {
        case "engagement_rate":
          this.$store.dispatch("influencer/setFilterValue", {
            social: SOCIALS.instagram.id,
            name: "engagement_rate_lte",
            value: null
          });
          this.$store.dispatch("influencer/setFilterValue", {
            social: SOCIALS.instagram.id,
            name: "engagement_rate_gte",
            value: null
          });
          break;
        case "followers":
          this.$store.dispatch("influencer/setFilterValue", {
            social: SOCIALS.instagram.id,
            name: "followers_lte",
            value: null
          });
          this.$store.dispatch("influencer/setFilterValue", {
            social: SOCIALS.instagram.id,
            name: "followers_gte",
            value: null
          });
          break;
        case "location":
          this.$store.dispatch("influencer/setFilterValue", {
            social: SOCIALS.instagram.id,
            name: "location_country",
            value: null
          });
          this.$store.dispatch("influencer/setFilterValue", {
            social: SOCIALS.instagram.id,
            name: "location_region",
            value: null
          });
          this.$store.dispatch("influencer/setFilterValue", {
            social: SOCIALS.instagram.id,
            name: "location_city",
            value: null
          });
          break;
        case "categories":
          this.$store.dispatch("influencer/setFilterValue", {
            social: SOCIALS.instagram.id,
            name: which,
            value: []
          });
          break;
        case "hashtags":
          this.$store.dispatch("influencer/setFilterValue", {
            social: SOCIALS.instagram.id,
            name: which,
            value: []
          });
          break;
        case "gender":
          this.$store.dispatch("influencer/setFilterValue", {
            social: SOCIALS.instagram.id,
            name: "gender",
            value: 2 // 2 - any
          });
          break;
        case "is_business":
          this.$store.dispatch("influencer/setFilterValue", {
            social: SOCIALS.instagram.id,
            name: "is_business",
            value: null
          });
          break;
        case "is_verified":
          this.$store.dispatch("influencer/setFilterValue", {
            social: SOCIALS.instagram.id,
            name: "is_verified",
            value: null
          });
          break;
        case "currentlocations":
          // this.$store.dispatch("influencer/setFilterValue", {
          //   social: SOCIALS.instagram.id,
          //   name: "locations",
          //   value: null,
          // });
          this.currentlocations = null;
          break;
        case "has_emails":
          this.$store.dispatch("influencer/setFilterValue", {
            social: SOCIALS.instagram.id,
            name: "has_emails",
            value: null
          });
          break;

        default:
          [
            "engagement_rate_lte",
            "engagement_rate_gte",
            "followers_lte",
            "followers_gte",
            "categories",
            "is_business",
            "is_verified",
            "has_emails",
            "currentlocations",
            "location_country",
            "location_region",
            "location_city"
          ].forEach((key) => {
            this.$store.dispatch("influencer/setFilterValue", {
              social: SOCIALS.instagram.id,
              name: key,
              value: key === "categories" ? [] : null
            });
          });
      }
    },
    addHashtag(newTag) {
      if (!newTag || newTag.trim() === "#") return;
      const tag = newTag.toLowerCase().trim();
      const tagWithSharp = tag.charAt(0) === "#" ? tag : `#${tag}`;

      const tagObj = {
        hashtag: tag,
        label: tagWithSharp
      };
      this.hashtags.push(tagObj);
      this.currentHashtags = this.currentHashtags.concat([tagObj]);
    },
    async getHashtagsList(tagKey) {
      if (tagKey && tagKey.length > 1) {
        try {
          this.loading.hashtags = true;
          const data = await this.$store.dispatch(
            "influencer/getHashtagsList",
            {
              social: SOCIALS.instagram.id,
              prefix: tagKey.toLowerCase()
            }
          );
          if (data.hits && data.hits.length) {
            this.hashtags = data.hits.map((e) => {
              return {
                hashtag: e,
                label: `#${e}`
              };
            });
          } else {
            this.hashtags = [];
          }
          this.loading.hashtags = false;
        } catch {
          this.loading.hashtags = false;
          if (!this.showAlert) {
            this.$store.dispatch("setAlert", {
              showAlert: true,
              content: MESSAGES["common-500"],
              alertClass: "danger"
            });
          }
        }
      }
    },
    searchInputChanged(e) {
      if (e.keyCode === 13) {
        this.search(true);
      }
    },
    searchUserNameChanged(e) {
      if (e.keyCode === 13) {
        this.searchByUserName(true);
      }
    },
    search() {
      this.$emit("search", {
        social: SOCIALS.instagram.id,
        input: this.searchInput,
        username: "",
        query: this.buildQuery()
      });
    },
    searchByUserName() {
      if (this.searchUserName.charAt(0) != "@") {
        this.searchUserName = "@" + this.searchUserName;
      }

      this.$emit("search", {
        social: SOCIALS.instagram.id,
        input: "",
        username: this.searchUserName,
        query: this.buildQuery()
      });
    },
    focusHastags() {
      this.$refs.hashtagsMultiselect.activate();
    },
    customLocationLabel(value, query) {
      if (value) {
        const index = value.toLowerCase().search(query.toLowerCase());
        const length = query.length;
        const word = value.slice(index, index + length);

        return value.replace(word, `<b>${word}</b>`);
      } else {
        return "";
      }
    },
    searchLocation(query) {
      this.locationQuery = query;
      if (!query || query.length < 2) {
        this.locations = [];
        return;
      }
      let matches = [],
        matches_country = [],
        matches_region = [],
        matches_city = [];
      for (const country in INSTAGRAM_LOCATIONS) {
        const regions = INSTAGRAM_LOCATIONS[country];
        if (country.toLowerCase().includes(query)) {
          matches_country.push({
            country: country,
            region: null,
            city: null,
            value: country,
            label: this.customLocationLabel(country, query)
          });
        }
        for (const region in regions) {
          const cities = regions[region];
          if (region.toLowerCase().includes(query)) {
            matches_region.push({
              country: country,
              region: region,
              city: null,
              value: region + ", " + country,
              label: this.customLocationLabel(region + ", " + country, query)
            });
          }
          cities.forEach((city) => {
            if (city.toLowerCase().includes(query)) {
              matches_city.push({
                country: country,
                region: region,
                city: city,
                value: city + ", " + region + ", " + country,
                label: this.customLocationLabel(
                  city + ", " + region + ", " + country,
                  query
                )
              });
            }
          });
        }
      }
      matches = [...matches_country, ...matches_region, ...matches_city];
      this.locations = matches;
      this.locationQuery = query;
    },
    validateEngagementRateGTE() {
      if (!this.engagementRateGTE) return;
      if (
        this.engagementRateLTE !== null &&
        this.engagementRateLTE < this.engagementRateGTE
      ) {
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.instagram.id,
          name: "engagement_rate_gte",
          value: this.engagementRateLTE
        });
      }
    },
    validateEngagementRateLTE() {
      if (!this.engagementRateLTE) return;
      if (
        this.engagementRateGTE !== null &&
        this.engagementRateLTE < this.engagementRateGTE
      ) {
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.instagram.id,
          name: "engagement_rate_lte",
          value: this.engagementRateGTE
        });
      }
    },
    validateFollowersGTE() {
      if (!this.followersGTE) return;
      if (this.followersLTE !== null && this.followersLTE < this.followersGTE) {
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.instagram.id,
          name: "followers_gte",
          value: this.followersLTE
        });
      }
    },
    validateFollowersLTE() {
      if (!this.followersLTE) return;
      if (this.followersGTE !== null && this.followersLTE < this.followersGTE) {
        this.$store.dispatch("influencer/setFilterValue", {
          social: SOCIALS.instagram.id,
          name: "followers_lte",
          value: this.followersGTE
        });
      }
    },
    buildQuery() {
      /* inputed string */
      let query = `query=${this.searchInput}`;

      /* has_emails */
      if (this.hasEmails.id !== "all") {
        query += `&has_emails=true`;
      }

      /* engagement and followers */
      if (this.engagementRateLTE !== null && this.engagementRateLTE !== "") {
        query += `&engagement_rate_lte=${this.engagementRateLTE / 100}`;
      }
      if (this.engagementRateGTE !== null && this.engagementRateGTE !== "") {
        query += `&engagement_rate_gte=${this.engagementRateGTE / 100}`;
      }
      if (this.followersLTE !== null && this.followersLTE !== "") {
        query += `&followers_lte=${this.followersLTE}`;
      }
      if (this.followersGTE !== null && this.followersGTE !== "") {
        query += `&followers_gte=${this.followersGTE}`;
      }

      /* categories */
      if (this.currentCategories.length) {
        const categoriesStrList = this.currentCategories.map((obj) => {
          return obj.category;
        });
        query += this.categoryAndOr
          ? `&categories_and=${categoriesStrList.toString()}`
          : `&categories_or=${categoriesStrList.toString()}`;
      }

      /* hashtags */
      if (this.currentHashtags.length) {
        const hashtagsList = this.currentHashtags.map((obj) =>
          encodeURIComponent(obj.hashtag)
        );
        query += this.hashtagsAndOr
          ? `&hashtags_and=${hashtagsList.toString()}`
          : `&hashtags_or=${hashtagsList.toString()}`;
      }

      /* gender */
      if (this.gender.id !== "any") {
        query += `&gender=${this.gender.id}`;
      }

      /* profile types */
      if (this.isVerified.id !== "all") {
        query += `&${
          this.isVerified.id === "is_verified"
            ? "is_verified=true"
            : "is_not_verified=true"
        }`;
      }
      if (this.isBusiness.id !== "all") {
        query += `&${
          this.isBusiness.id === "is_business_account"
            ? "is_business_account=true"
            : "is_not_business_account=false"
        }`;
      }

      /* location */
      // if (this.location && this.location.country) {
      //   query += `&location_country=${this.location.country}`;
      // }
      // if (this.location && this.location.region) {
      //   query += `&location_region=${this.location.region}`;
      // }
      // if (this.location && this.location.city) {
      //   query += `&location_city=${this.location.city}`;
      // }
      if (this.currentlocations) {
        query += `&locations=${JSON.stringify(this.currentlocations)}`;
      }

      //query +='&order_by=relevance';

      return query;
    },
    getSearchInput() {
      return this.searchInput;
    },
    async getCountriesList() {
      this.loading.countries = true;
      this.countries = [];
      try {
        const { countries } = await this.$store.dispatch(
          "influencer/getCountriesList",
          {
            social: SOCIALS.instagram.id
          }
        );
        this.countries = countries.sort((a, b) =>
          a.country > b.country ? 1 : -1
        );
        this.loading.countries = false;
      } catch {
        this.loading.countries = false;
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["influencer__get_countries-failed"],
            alertClass: "danger"
          });
        }
      }
    },
    async getRegionsList() {
      this.loading.regions = true;
      this.regions = [];
      if (!this.locationCountry) return;
      try {
        const { regions } = await this.$store.dispatch(
          "influencer/getRegionsList",
          {
            social: SOCIALS.instagram.id,
            country: this.locationCountry.country
          }
        );
        this.regions = regions.sort((a, b) => (a.region > b.region ? 1 : -1));
        this.loading.regions = false;
      } catch {
        this.loading.regions = false;
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["influencer__get_regions-failed"],
            alertClass: "danger"
          });
        }
      }
    },
    async getCitiesList() {
      this.loading.cities = true;
      this.cities = [];
      if (!this.locationCountry || !this.locationRegion) return;
      try {
        const { cities } = await this.$store.dispatch(
          "influencer/getCitiesList",
          {
            social: SOCIALS.instagram.id,
            country: this.locationCountry.country,
            region: this.locationRegion.region
          }
        );
        this.cities = cities.sort((a, b) => (a.city > b.city ? 1 : -1));
        this.loading.cities = false;
      } catch {
        this.loading.cities = false;
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["influencer__get_cities-failed"],
            alertClass: "danger"
          });
        }
      }
    },
    async getCategoriesList() {
      this.loading.categories = true;
      this.categories = [];
      try {
        const { categories } = await this.$store.dispatch(
          "influencer/getCategoriesList",
          {
            social: SOCIALS.instagram.id
          }
        );
        this.categories = categories.sort((a, b) =>
          a.category > b.category ? 1 : -1
        );
        this.loading.categories = false;
      } catch {
        this.loading.categories = false;
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["influencer__get_categories-failed"],
            alertClass: "danger"
          });
        }
      }
    }
  }
};
</script>
<style>
  .multiselect__spinner {
    background: #e0e0e0;
  }
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>