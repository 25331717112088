<template>
  <div
    class="tab-pane fade set-notifications-panel"
    :class="[
      { 'modal-mini': type === 'mini' },
    ]"
    v-bind:id="tabId"
  >
    <div class="main-content">
      <template v-if="Object.keys(this.notifications).length">
        <div class="setting-label mt-3 px-4">
          ACTIVE NOTIFICATION CHANNELS
        </div>
        <ul class="main-nav-notifications">
          <li
            v-for="(channel, key, index) in notifications"
            :key="index"
            class="p-3 my-1 flex-middle-between flex-wrap"
          >
            <div class="flex-middle flex-wrap">
              <div class="card-icon">
                <img :src="availableNotifications[key].image" />
              </div>
              <div class="ml-3">
                {{ channel.target }}
              </div>
            </div>
            <div class="flex-middle ml-auto">
              <div class="ml-2">
                <base-button
                  :disabled="!hasFullAccess('integrations')"
                  :disabledTooltip="getPermissionErrorMessage('integrations:full')"
                  :isBusy="key === selectedId && disconnecting"
                  type="danger"
                  class="w-100 flex-middle"
                  v-on:click="disconnect(key)"
                >
                    Disconnect
                </base-button>
              </div>
            </div>
          </li>
        </ul>
      </template>
      <template
        v-if="
          Object.keys(availableNotifications).length >
            Object.keys(notifications).length
        "
      >
        <div class="setting-label mt-3 px-4">
          AVAILABLE NOTIFICATION CHANNELS
        </div>
        <ul class="main-nav-notifications">
          <template v-for="(channel, id, index) in availableNotifications">
            <li
              v-if="isNotIntegrated(id)"
              :key="index"
              class="p-3 my-1 flex-middle-between"
            >
              <div class="flex-middle">
                <div class="card-icon">
                  <img :src="channel.image" />
                </div>
                <span class="ml-3">{{ channel.name }}</span>
              </div>
              <div>
                <base-button
                  :disabled="!hasFullAccess('integrations')"
                  :disabledTooltip="getPermissionErrorMessage('integrations:full')"
                  type="success"
                  class="w-100 flex-middle"
                  v-on:click="connect(id)"
                  :isBusy="loading.request"
                >
                  Connect
                </base-button>
              </div>
            </li>
          </template>
        </ul>
      </template>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import { getPermissionErrorMessage } from "@/utils";
import { AVAILABLE_NOTIFICATIONS, MESSAGES } from "@/constants";
import configs from "../configs";

export default {
  name: "set-notifications-panel",
  components: {},
  props: {
    show: Boolean,
    type: {
      type: String,
      default: "",
      validator(value) {
        let acceptedValues = ["", "notice", "mini"];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Modal type (notice|mini|"") ',
    },
    animationDuration: {
      type: Number,
      default: 500,
      description: "Modal transition duration",
    },
    disconnecting: {
      type: Boolean,
    },
    username: {
      type: String,
    },
    tabId: String
  },
  data() {
    return {
      availableNotifications: AVAILABLE_NOTIFICATIONS,
      getPermissionErrorMessage: getPermissionErrorMessage,
      selectedId: null,
      localError: false,
      loading: {
        request: false,
      },
    };
  },
  computed: {
    ...mapState({
      showAlert: (state) => state.alert.showAlert,
      notifications: (state) => state.integration.notifications,
    }),
    ...mapGetters({
      hasFullAccess: 'user/hasFullAccess',
      hasViewAccess: 'user/hasViewAccess',
    }),
  },
  methods: {
    closePanel() {
      /* slide out panel */
      document.querySelector(
        ".set-notifications-panel .modal-dialog"
      ).style.right = `-100%`;
      /* reset panel */
      setTimeout(() => {
        this.$emit("update:show", false);
        this.$emit("close");
      }, this.animationDuration / 2);
    },
    isNotIntegrated(channel) {
      return !this.notifications[channel];
    },
    redirectToSite(data) {
      let url;
      if (data.name === AVAILABLE_NOTIFICATIONS.slack.id) {
        url = `https://slack.com/oauth/authorize?client_id=${configs.services.slack.client_id}&scope=incoming-webhook&redirect_uri=${configs.site.url}/api/webhooks/slack/connect&state=${data.nonce}`;
      }
      const win = window.open(url, "_blank");
      if (win == null || typeof(win) == 'undefined') {
        // Turn off the pop-up blocker
        this.$store.dispatch("setAlert", {
          showAlert: true,
          content: MESSAGES["user__popup-failed"],
          alertClass: "danger",
        });
      } else {
        win.focus();
      }
    },
    async connect(id) {
      this.loading.request = true;
      try {
        const data = await this.$store.dispatch(
          "integration/requestNotification",
          {
            name: id,
          }
        );
        this.loading.request = false;
        this.redirectToSite(data);
      } catch (error) {
        this.loading.request = false;
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["common-500"],
            alertClass: "danger",
          });
        }
      }
    },
    disconnect(id) {
      this.selectedId = id;
      this.$emit("disconnect", id);
    },
  },

  watch: {
    show(val) {
      const documentClasses = document.body.classList;
      if (val) {
        this.localError = false;
        /* slide in panel*/
        documentClasses.add("modal-open");
        setTimeout(() => {
          document.querySelector(
            ".set-notifications-panel .modal-dialog"
          ).style.right = `0px`;
        }, 0);
      } else {
        /* reset panel */
        documentClasses.remove("modal-open");
        document.querySelector(".set-notifications-panel").scrollTop = 0;
      }
    },
  },
};
</script>
<style>
.modal.show {
  background-color: rgba(255, 255, 255, 0.7);
}
</style>
