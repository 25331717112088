<template>
  <div v-if="campaign" class="campaign-pipeline d-flex flex-column">
    <div class="topbar navbar-mobile flex-between d-md-none">
      <div class="d-flex flex-row w-100">
        <router-link
          :to="'../../campaigns'"
          class="topbar-menu topbar-link flex-middle-center mr-3"
        >
          <i class="fas fa-chevron-left"></i>
        </router-link>
        <a
          class="flex-middle cursor-pointer"
          v-b-tooltip.hover
          :title="
            hasFullAccess('campaigns')
              ? campaign.active
                ? 'Click to deactivate'
                : 'Click to activate'
              : undefined
          "
          @click="hasFullAccess('campaigns') ? toggleCampaign() : undefined"
        >
          <div
            class="topbar-info flex-middle"
            :style="{ color: campaign.color }"
          >
            {{ campaign.name }}
          </div>
          <h5 class="mt-2 ml-2">
            <span v-if="campaign.active" class="badge badge-success"
              >Active</span
            >
            <span v-else class="badge badge-danger">Inactive</span>
          </h5>
        </a>
      </div>
      <div class="d-flex">
        <template v-if="hasFullAccess('campaigns')">
          <a
            class="
              topbar-button
              tracking-button
              flex-middle-center
              topbar-button-wide
              p-2
            "
            @click="toggleCampaign"
          >
            <span v-if="campaign.active">Pause tracking</span>
            <span v-else>Start tracking</span>
          </a>
          <a
            class="
              topbar-button
              edit-button
              flex-middle-center
              topbar-button-wide
              p-2
            "
            @click="showEditCampaign = true"
            >Edit</a
          >
        </template>
        <template v-else>
          <a
            class="
              topbar-button topbar-button-disabled
              tracking-button
              flex-middle-center
              topbar-button-wide
              p-2
            "
            v-b-tooltip.hover
            :title="getPermissionErrorMessage('campaigns:full')"
          >
            <span v-if="campaign.active">Pause tracking</span>
            <span v-else>Start tracking</span>
          </a>
          <a
            class="
              topbar-button topbar-button-disabled
              edit-button
              flex-middle-center
              topbar-button-wide
              p-2
            "
            v-b-tooltip.hover
            :title="getPermissionErrorMessage('campaigns:full')"
            >Edit</a
          >
        </template>
      </div>
    </div>

    <!-- Top menu header -->
    <campaign-nav :id="id"></campaign-nav>

    <!-- Page basic content -->
    <div class="campaign-pipeline-content flex-fill d-flex flex-column">
      <!-- Campaign header + management -->
      <div class="campaign-title-block flex-between flex-wrap">
        <div class="title-container d-md-flex flex-row my-3 align-items-center">
          <!-- <a
            class="flex-middle cursor-pointer mr-3"
            v-b-tooltip.hover
            :title="
              hasFullAccess('campaigns') ? campaign.active ? 'Click to deactivate' : 'Click to activate' : undefined
            "
            @click="hasFullAccess('campaigns') ? toggleCampaign() : undefined"
          >-->
          <h1 class="mr-2 mb-0">{{ campaign.name }}</h1>
          <!-- <h4 class="mb-0">
              <span v-if="campaign.active" class="badge badge-success">Active</span>
              <span v-else class="badge badge-danger">Inactive</span>
          </h4>-->
          <!-- </a> -->
          <!-- <base-button
            :type="campaign.active ? 'danger' : 'success'"
            class="mx-2"
            :isBusy="loading.active"
            :disabled="!hasFullAccess('campaigns')"
            :disabledTooltip="getPermissionErrorMessage('campaigns:full')"
            @click="toggleCampaign"
          >{{ campaign.active ? 'Pause tracking' : 'Start tracking' }}</base-button>-->
          <div class="toggle-active">
            <toggle-button
              v-model="campaign.active"
              :width="110"
              :height="35"
              :value="true"
              :font-size="16"
              @change="toggleCampaignSwitch"
              :labels="{ checked: 'Active', unchecked: 'Inactive' }"
              :color="{
                checked: '#54daa1',
                unchecked: '#f5365c',
                disabled: '#CCCCCC'
              }"
              class="switch-category"
            />
          </div>
        </div>

        <!-- Controls on the right (fitering, edit props, etc) -->
        <div class="d-md-flex flex-row my-3 align-items-center">
          <base-input
            class="input-group-alternative search-input mr-3 mb-0"
            placeholder="Filter influencers"
            v-model="filterText"
            type="search"
          />
          <div v-if="hasFullAccess('campaigns')" class="mr-3">
            <base-input
              v-if="showStageNewNameInput"
              id="input-stage_new_name"
              class="input-group-alternative mb-0"
              placeholder="Stage Name"
              v-on:keyup.enter="addStage()"
            ></base-input>
            <button
              v-else
              class="btn btn-light"
              @click="setFocusStageNewNameInput()"
            >
              Add Column...
            </button>
          </div>
          <div v-else class>
            <a
              class="add-column"
              v-b-tooltip.hover
              :title="getPermissionErrorMessage('campaigns:full')"
              >Add Column...</a
            >
          </div>
          <base-button
            :disabled="!hasFullAccess('campaigns')"
            :disabledTooltip="getPermissionErrorMessage('campaigns:full')"
            type="primary"
            class="btn-black"
            @click="showEditCampaign = true"
            icon="fas fa-pen"
            >Edit properties</base-button
          >
        </div>
      </div>
      <!-- Campaign content -->
      <div class="card-scene">
        <base-input
          class="filter-input input-group-alternative d-none mb-0 mt-3"
          placeholder="Filter influencers"
          v-model="filterText"
          type="search"
        />
        <Container
          orientation="horizontal"
          @drop="reorderCampaignStages($event)"
          drag-handle-selector=".column-drag-handle"
          @drag-start="dragStart"
          :drop-placeholder="upperDropPlaceholderOptions"
        >
          <Draggable v-for="(column, index) in scene.columns" :key="column.id">
            <div class="card-container d-flex flex-column px-2">
              <div class="card-column-header">
                <base-input
                  v-if="showStageNameInput[index]"
                  :id="'input-stage_name-' + column.id"
                  class="input-group-alternative mb-0"
                  placeholder="Stage Name"
                  :value="column.name"
                  v-on:keyup.enter="renameStage(index)"
                ></base-input>

                <base-dropdown v-else position="right">
                  <span
                    class="column-drag-handle"
                    slot="title"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ column.name }}
                    ({{
                      column.cards.filter((card) => isFiltered(card)).length
                    }})
                    <i class="fas fa-angle-down"></i>
                  </span>
                  <a
                    class="dropdown-item px-3 py-1"
                    @click="
                      hasFullAccess('campaigns')
                        ? showRenameInput(index)
                        : undefined
                    "
                    v-b-tooltip.hover
                    :title="
                      hasFullAccess('campaigns')
                        ? undefined
                        : getPermissionErrorMessage('campaigns:full')
                    "
                    >Rename</a
                  >
                  <div
                    v-if="scene.columns.length > 1"
                    class="dropdown-divider"
                  ></div>
                  <a
                    v-if="scene.columns.length > 1"
                    class="dropdown-item dropdown-item--red px-3 py-1"
                    @click="
                      hasFullAccess('campaigns')
                        ? confirmRemoveStage(index)
                        : undefined
                    "
                    v-b-tooltip.hover
                    :title="
                      hasFullAccess('campaigns')
                        ? undefined
                        : getPermissionErrorMessage('campaigns:full')
                    "
                    >Remove</a
                  >
                </base-dropdown>
              </div>
              <div class="card-column-content flex-fill">
                <!-- @drag-start="(e) => log('drag start', e)"
                @drag-end="(e) => log('drag end', e)"-->
                <Container
                  group-name="col"
                  @drop="(e) => changeLeadStage(column.id, e)"
                  :get-child-payload="getCardPayload(column.id)"
                  drag-class="card-ghost"
                  drop-class="card-ghost-drop"
                  :drop-placeholder="dropPlaceholderOptions"
                >
                  <template v-for="(card, index) in column.cards">
                    <Draggable v-show="isFiltered(card)" :key="column + index">
                      <!-- instagram -->
                      <template v-if="card.social == socials.instagram.id">
                        <div
                          class="
                            card
                            d-flex
                            name-row
                            flex-row
                            align-items-start
                            p-2
                          "
                          v-bind:class="[column.name]"
                          @click="onSelectLead(card)"
                        >
                          <div
                            v-if="card.unread_messages"
                            class="lead__badge"
                            v-b-tooltip.hover
                            title="You have unread messages"
                          >
                            <i class="fas fa-envelope"></i>
                          </div>
                          <div class="social-icon">
                            <img
                              :src="socials.instagram.image"
                              :alt="socials.instagram.name"
                            />
                          </div>
                          <div class="avatar ml-2 mt-1">
                            <avatar
                              :username="
                                card.data.full_name
                                  ? card.data.full_name
                                  : card.username
                              "
                              :size="32"
                              :src="card.profile_pic"
                            ></avatar>
                          </div>
                          <div class="flex-column ml-2">
                            <div class="profile-name">
                              {{ card.data.full_name }}
                            </div>
                            <div class="profile-username">
                              @{{ card.username }}
                            </div>
                            <div class="profile-followers">
                              <span>
                                {{ card.data.followers | BigNumber }}
                                followers
                              </span>
                            </div>
                          </div>
                        </div>
                      </template>

                      <!-- tiktok -->
                      <template v-if="card.social == socials.tiktok.id">
                        <div
                          class="
                            card
                            d-flex
                            name-row
                            flex-row
                            align-items-start
                            p-2
                          "
                          @click="onSelectLead(card)"
                        >
                          <div
                            v-if="card.unread_messages"
                            class="lead__badge"
                            v-b-tooltip.hover
                            title="You have unread messages"
                          >
                            <i class="fas fa-envelope"></i>
                          </div>
                          <div class="social-icon">
                            <img
                              :src="socials.tiktok.image"
                              :alt="socials.tiktok.name"
                            />
                          </div>
                          <div class="avatar ml-2 mt-1">
                            <avatar
                              :username="
                                card.data.nickname
                                  ? card.data.nickname
                                  : card.username
                              "
                              :size="32"
                              :src="getCardPictureUrl(card)"
                            ></avatar>
                          </div>
                          <div class="flex-column ml-2">
                            <div class="profile-name">
                              {{ card.data.nickname }}
                            </div>
                            <div class="profile-username">
                              @{{ card.username }}
                            </div>
                            <div class="profile-followers">
                              <span>
                                {{ card.data.fans | BigNumber }}
                                fans
                              </span>
                            </div>
                          </div>
                        </div>
                      </template>

                      <!-- youtube -->
                      <template v-if="card.social == socials.youtube.id">
                        <div
                          class="
                            card
                            d-flex
                            name-row
                            flex-row
                            align-items-start
                            p-2
                          "
                          @click="onSelectLead(card)"
                        >
                          <div
                            v-if="card.unread_messages"
                            class="lead__badge"
                            v-b-tooltip.hover
                            title="You have unread messages"
                          >
                            <i class="fas fa-envelope"></i>
                          </div>
                          <div class="social-icon">
                            <img
                              :src="socials.youtube.image"
                              :alt="socials.youtube.name"
                            />
                          </div>
                          <div class="avatar ml-2 mt-1">
                            <avatar
                              :username="
                                card.data.title
                                  ? card.data.title
                                  : card.username
                              "
                              :size="32"
                              :src="getCardPictureUrl(card)"
                            ></avatar>
                          </div>
                          <div class="flex-column ml-2">
                            <div class="profile-name">
                              {{ card.data.title }}
                            </div>
                            <div class="profile-username">
                              @{{ card.username }}
                            </div>
                            <div class="profile-followers">
                              <span>
                                {{ card.data.subscribers | BigNumber }}
                                subscribers
                              </span>
                            </div>
                          </div>
                        </div>
                      </template>
                    </Draggable>
                  </template>
                </Container>
              </div>
            </div>
          </Draggable>
        </Container>
      </div>
      <!-- Empty screen -->
      <div
        v-if="!campaign.leads.length && !loading.leads"
        class="no-data flex-fill"
      >
        <h5>
          You can add influencers from the
          <span>
            <router-link to="/app/influencers">Search</router-link>
          </span>
          interface.
        </h5>
      </div>
    </div>

    <!-- Modals and panels -->
    <edit-lead-panel
      :show="showEditLead"
      @close="showEditLead = false"
      @selectCard="selectCardonPanel"
      @moveToStage="moveToStage"
      @startNewConversation="startNewConversation"
      @editLead="showEditLeadDetail = true"
      @removeLead="showConfirmLeadRemove = true"
      @invalidEmailToken="openEmailReconnectionModal"
      :disabled="loading.removeLead"
      :leadId="selectedLead.id"
      :campaign="campaign"
      :animationDuration="500"
      ref="singleLead"
    />

    <edit-lead-email-panel
      :show="showEditLeadEmail"
      :threadId="selectedThreadId"
      @close="showEditLeadEmail = false"
      @reply="replyEmail"
      @forward="forwardEmail"
      @invalidEmailToken="openEmailReconnectionModal"
      :campaignId="id"
      :animationDuration="500"
    />
    <EditLeadEmailConversationPanel
      :show="showEditLeadEmailConversation"
      @close="showEditLeadEmailConversation = false"
      @invalidEmailToken="openEmailReconnectionModal"
      :campaignId="id"
      :animationDuration="500"
    />
    <edit-lead-contract-panel
      :show="showEditLeadContract"
      @close="showEditLeadContract = false"
      :lead="selectedLead"
      :campaignId="id"
      :animationDuration="500"
    />
    <edit-lead-notes-panel
      :show="showEditLeadNotes"
      @close="
        showEditLeadNotes = false;
        updateSingleLead();
      "
      :lead="selectedLead"
      :campaignId="id"
      :note="cardParam"
      :animationDuration="500"
    />
    <edit-lead-tracking-panel
      :show="showEditLeadTracking"
      @close="showEditLeadTracking = false"
      :lead="selectedLead"
      :campaign="campaign"
      :animationDuration="500"
    />
    <edit-lead-pricing-panel
      :show="showEditLeadPricing"
      @close="showEditLeadPricing = false"
      :lead="selectedLead"
      :campaignId="id"
      :animationDuration="500"
    />
    <edit-lead-coupon-panel
      :show="showEditLeadCoupon"
      @close="showEditLeadCoupon = false"
      :lead="selectedLead"
      :campaign="campaign"
      :animationDuration="500"
      ref="editLeadCoupon"
    />
    <edit-lead-detail-panel
      :show="showEditLeadDetail"
      @close="showEditLeadDetail = false"
      @change="onChangeLead"
      :animationDuration="500"
    />
    <set-plans-update-panel
      :show="showSetPlansUpdate"
      @close="showSetPlansUpdate = false"
      :animationDuration="500"
    />
    <edit-campaign-panel
      :show="showEditCampaign"
      :currenciesList="currencies"
      :campaign="campaign"
      :loading="loading.save"
      @close="showEditCampaign = false"
      @save="saveCampaign"
      :animationDuration="500"
    />
    <modal
      :show="showConfirmStageRemove"
      @close="showConfirmStageRemove = false"
    >
      <h3 slot="header">Removing stage</h3>
      <div v-if="scene.columns.length > 1">
        This stage contains some influencers. Would you like to remove them from
        the campaign or move them to the first remaining stage?
      </div>
      <div v-else>
        This stage contains some influencers and there are no other stages
        available. If you delete this stage, these influencers will be removed
        from the campaign. Do you confirm you want to delete the stage and the
        influencers inside it?
      </div>
      <div slot="footer" class="d-flex">
        <base-button
          v-if="scene.columns.length > 1"
          type="danger"
          @click="removeStage('delete')"
          >Remove</base-button
        >
        <base-button
          v-if="scene.columns.length > 1"
          type="primary"
          @click="removeStage('move_to_first')"
          >Move to first</base-button
        >
      </div>
    </modal>
    <modal :show="showConfirmLeadRemove" @close="showConfirmLeadRemove = false">
      <h3 slot="header">Remove influencer from the campaign</h3>
      <p>
        All tracking stats, links, coupons and notes attached to this influencer
        will be deleted. Do you want to proceed?
      </p>
      <div slot="footer" class="d-flex">
        <base-button
          v-if="scene.columns.length > 1"
          type="danger"
          :disabled="loading.removeLead"
          @click="removeLead()"
          >Yes</base-button
        >
        <base-button
          v-if="scene.columns.length > 1"
          type="primary"
          @click="showConfirmLeadRemove = false"
          >No</base-button
        >
      </div>
    </modal>

    <modal
      :show="showConfirmReconnectEmail"
      @close="showConfirmReconnectEmail = false"
    >
      <h3 slot="header">Reconnection required</h3>
      <p>
        It looks like your email account{{
          selectedEmail ? ` ${selectedEmail.address}` : ""
        }}
        got disconnected! Do you want to reconnect it?
      </p>
      <div slot="footer" class="d-flex">
        <base-button type="primary" @click="reconnectEmail">Yes</base-button>
        <base-button type="danger" @click="showConfirmReconnectEmail = false"
          >No</base-button
        >
      </div>
    </modal>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import { applyDrag, resizedataUrl, getPermissionErrorMessage } from "@/utils";
import { CAMPAIGN_LEAD_CONTENT, SOCIALS, MESSAGES } from "@/constants";
import Configs from "../configs";

/* components */
import EditLeadPanel from "../components/EditLeadPanel";
const EditCampaignPanel = () => import("../components/EditCampaignPanel");
const EditLeadEmailPanel = () => import("../components/EditLeadEmailPanel");
const EditLeadContractPanel = () =>
  import("../components/EditLeadContractPanel");
const EditLeadNotesPanel = () => import("../components/EditLeadNotesPanel");
const EditLeadTrackingPanel = () =>
  import("../components/EditLeadTrackingPanel");
const EditLeadPricingPanel = () => import("../components/EditLeadPricingPanel");
const EditLeadCouponPanel = () => import("../components/EditLeadCouponPanel");
const EditLeadDetailPanel = () => import("../components/EditLeadDetailPanel");
const EditLeadEmailConversationPanel = () =>
  import("../components/EditLeadEmailConversationPanel");
const SetPlansUpdatePanel = () => import("../components/SetPlansUpdatePanel");
import CampaignNav from "../components/CampaignNav";
import Avatar from "vue-avatar";
import { Container, Draggable } from "vue-smooth-dnd";
import { ToggleButton } from "vue-js-toggle-button";

export default {
  components: {
    ToggleButton,
    Container,
    Draggable,
    EditLeadPanel,
    EditLeadEmailPanel,
    EditLeadEmailConversationPanel,
    EditLeadContractPanel,
    EditLeadNotesPanel,
    EditLeadTrackingPanel,
    EditLeadPricingPanel,
    EditLeadCouponPanel,
    SetPlansUpdatePanel,
    EditCampaignPanel,
    EditLeadDetailPanel,
    Avatar,
    CampaignNav
  },
  data() {
    return {
      filterbarBackground: "vue", //vue|blue|orange|green|red|primary
      getPermissionErrorMessage: getPermissionErrorMessage,
      loading: {
        campaign: false,
        save: false,
        active: false,
        leads: false,
        removeLead: false
      },
      socials: SOCIALS,
      campaign: null,
      filterText: "",
      selectedLead: {},
      selectedEmail: null,
      selectedThreadId: null,
      selectedStageIndex: null,
      cardParam: null,
      cardPictureUrl: {},
      showEditCampaign: false,
      showEditLead: false,
      showEditLeadEmail: false,
      showEditLeadEmailConversation: false,
      showEditLeadContract: false,
      showEditLeadNotes: false,
      showEditLeadTracking: false,
      showEditLeadCoupon: false,
      showEditLeadPricing: false,
      showEditLeadDetail: false,
      showSetPlansUpdate: false,

      showConfirmStageRemove: false,
      showConfirmLeadRemove: false,
      showConfirmReconnectEmail: false,
      upperDropPlaceholderOptions: {
        className: "cards-drop-preview",
        animationDuration: "150",
        showOnTop: true
      },
      dropPlaceholderOptions: {
        className: "drop-preview",
        animationDuration: "150",
        showOnTop: true
      },
      headerHeight: 0,
      scene: { columns: [] },
      showStageNameInput: [],
      showStageNewNameInput: false,
      resizeHandler: null,
      platformOffsetY: 0
    };
  },
  props: {
    id: {
      type: String,
      description: "Campaign ID"
    },
    lead_id: {
      type: String,
      description: "Lead ID"
    }
  },
  watch: {
    $route: function (to) {
      this.id = to.params.id;
      this.getSingleCampaign();
      // to reload userpilot
      this.$store.dispatch("user/setRoute", to.path);
    },
    showAlert: function (show) {
      if (!show) return;
      if (this.alert.content === MESSAGES["campaigns__max-active-reached"]) {
        if (!this.hasFullAccess("billing")) {
          setTimeout(() => {
            this.$store.dispatch("setAlert", {
              showAlert: true,
              content: getPermissionErrorMessage("billing:full"),
              alertClass: "danger"
            });
          }, 1500);
          return;
        }
        this.showSetPlansUpdate = true;
      }
    },
    showEditLead: function () {
      this.setRouter();
    }
  },
  computed: {
    ...mapState({
      currencies: (state) => state.user.currencies,
      filter: (state) => state.campaign.filter,
      alert: (state) => state.alert,
      showAlert: (state) => state.alert.showAlert
    }),
    ...mapGetters({
      hasFullAccess: "user/hasFullAccess",
      hasViewAccess: "user/hasViewAccess"
    }),
    contentHeight: {
      get() {
        const offsetV = 3 + this.platformOffsetY;
        return `calc(100vh - ${this.headerHeight + offsetV}px)`;
      }
    }
  },
  async mounted() {
    // to reload userpilot
    this.$store.dispatch("user/setRoute", this.$route.path);

    if (!this.resizeHandler) {
      this.resizeHandler = window.addEventListener("resize", this.resizeWindow);
    }
    await this.getSingleCampaign();
    await this.getCurrenciesList();
    await this.hideAllRenameInputs();
    if (this.lead_id) {
      const index = this.campaign.leads
        .map((e) => e.id)
        .indexOf(parseInt(this.lead_id));
      if (index > -1) {
        this.onSelectLead(this.campaign.leads[index]);
      }
    }
  },
  beforeDestroy() {
    if (this.resizeHandler) {
      window.removeEventListener("resize", this.resizeWindow);
      this.resizeHandler = null;
    }
  },
  methods: {
    resizeWindow() {
      const isMobileSafari =
        navigator.userAgent.match(/(iPod|iPhone|iPad)/) &&
        navigator.userAgent.match(/AppleWebKit/);
      if (isMobileSafari) {
        this.platformOffsetY = 114;
      }
    },
    isFiltered(card) {
      const keyText = this.filterText.toLowerCase().trim();
      if (
        !keyText.trim().length ||
        (card.username && card.username.toLowerCase().includes(keyText))
      )
        return true;
      switch (card.social) {
        case SOCIALS.instagram.id:
          if (
            card.data.full_name &&
            card.data.full_name.toLowerCase().includes(keyText)
          )
            return true;
          if (
            card.data.biography &&
            card.data.biography.toLowerCase().includes(keyText)
          )
            return true;
          break;
        case SOCIALS.tiktok.id:
          if (
            card.data.nickname &&
            card.data.nickname.toLowerCase().includes(keyText)
          )
            return true;
          if (
            card.data.signature &&
            card.data.signature.toLowerCase().includes(keyText)
          )
            return true;

          break;
        case SOCIALS.youtube.id:
          if (
            card.data.title &&
            card.data.title.toLowerCase().includes(keyText)
          )
            return true;
          if (
            card.data.description &&
            card.data.description.toLowerCase().includes(keyText)
          )
            return true;
          break;
        default:
      }
      return false;
    },
    toggleCampaign() {
      if (this.campaign.active) {
        this.setCampaignInactive();
      } else {
        this.setCampaignActive();
      }
    },
    toggleCampaignSwitch(switchValue) {
      if (!switchValue.value) {
        this.setCampaignInactive();
      } else {
        this.setCampaignActive();
      }
    },
    setFocusStageNewNameInput() {
      this.showStageNewNameInput = true;
      setTimeout(() => {
        document.querySelector("#input-stage_new_name").focus();
      }, 100);
    },
    async changeLeadStage(columnId, dropResult) {
      if (!this.hasFullAccess("campaigns")) {
        this.$store.dispatch("setAlert", {
          showAlert: true,
          content: this.getPermissionErrorMessage("campaigns:full"),
          alertClass: "danger"
        });
        this.buildPipeline();
        return;
      }
      this.hideAllRenameInputs();
      if (dropResult.removedIndex !== null || dropResult.addedIndex !== null) {
        const scene = Object.assign({}, this.scene);
        const column = scene.columns.filter((p) => p.id === columnId)[0];
        const columnIndex = scene.columns.indexOf(column);
        const newColumn = Object.assign({}, column);
        newColumn.cards = applyDrag(newColumn.cards, dropResult);
        scene.columns.splice(columnIndex, 1, newColumn);
        this.scene = scene;
        const leadId = dropResult.payload.id;
        const leadIndexOfStage = this.scene.columns[columnIndex].cards
          .map((e) => e.id)
          .indexOf(leadId);
        if (leadIndexOfStage < 0) return;
        try {
          const { data } = await this.$store.dispatch(
            "campaign/changeLeadStage",
            {
              campaign_id: this.id,
              lead_id: dropResult.payload.id,
              stage_id: newColumn.id
            }
          );
          if (data) {
            const index = this.campaign.leads
              .map((e) => e.id)
              .indexOf(data.lead_id);
            this.campaign.leads[index].stage = data.stage_id;
          }
        } catch {
          if (!this.showAlert) {
            this.$store.dispatch("setAlert", {
              showAlert: true,
              content: MESSAGES["common-500"],
              alertClass: "danger"
            });
          }
        }
      }
    },
    onChangeLead(lead) {
      const index = this.campaign.leads.map((e) => e.id).indexOf(lead.id);
      this.campaign.leads[index] = { ...lead };
      this.selectedLead = { ...lead };
      this.showEditLead = false;
      this.buildPipeline();
    },
    selectCardonPanel(e) {
      this.hideAllRenameInputs();
      this.cardParam = null;
      if (e.type === CAMPAIGN_LEAD_CONTENT.email) {
        this.showEditLeadEmail = true;
        this.selectedThreadId = e.param;
      } else if (e.type === CAMPAIGN_LEAD_CONTENT.contract) {
        this.showEditLeadContract = true;
      } else if (e.type === CAMPAIGN_LEAD_CONTENT.notes) {
        this.cardParam = e.param;
        this.showEditLeadNotes = true;
      } else if (e.type === CAMPAIGN_LEAD_CONTENT.tracking) {
        this.showEditLeadTracking = true;
      } else if (e.type === CAMPAIGN_LEAD_CONTENT.coupon) {
        this.showEditLeadCoupon = true;
      } else if (e.type === CAMPAIGN_LEAD_CONTENT.pricing) {
        this.showEditLeadPricing = true;
      }
    },

    getCardPayload(columnId) {
      return (index) => {
        return this.scene.columns.filter((p) => p.id === columnId)[0].cards[
          index
        ];
      };
    },
    dragStart() {
      this.hideAllRenameInputs();
    },
    async onSelectLead(lead) {
      this.hideAllRenameInputs();
      this.selectedLead = lead;
      this.showEditLead = true;
      if (!lead.unread_messages) {
        try {
          const { data } = await this.$store.dispatch(
            "campaign/markReadLead",
            lead
          );
          if (
            data &&
            this.campaign &&
            this.campaign.leads &&
            this.campaign.leads.length
          ) {
            const index = this.campaign.leads.map((e) => e.id).indexOf(lead.id);
            if (index > -1 && this.campaign.leads[index]) {
              this.campaign.leads[index].unread_messages = 0;
            }
          }
        } catch (err) {
          if (!this.showAlert) {
            this.$store.dispatch("setAlert", {
              showAlert: true,
              content: MESSAGES["common-500"],
              alertClass: "danger"
            });
          }
        }
      }
    },
    setRouter() {
      if (this.showEditLead) {
        this.$router.push({
          path: `/app/campaigns/${this.id}/pipeline/leads/${this.selectedLead.id}`
        });
      } else {
        this.$router.push({
          path: `/app/campaigns/${this.id}/pipeline`
        });
      }
    },
    moveToStage(moveInfo) {
      if (moveInfo) {
        this.getSingleCampaign();
      }
    },
    startNewConversation() {
      this.selectedThreadId = null;
      this.showEditLeadEmailConversation = true;
    },
    async setCampaignActive() {
      if (!this.hasFullAccess("campaigns")) {
        this.$store.dispatch("setAlert", {
          showAlert: true,
          content: this.getPermissionErrorMessage("campaigns:full"),
          alertClass: "danger"
        });
        return;
      }
      this.loading.active = true;
      try {
        const result = await this.$store.dispatch(
          "campaign/setCampaignActive",
          {
            campaign_id: this.id
          }
        );
        this.loading.active = false;
        if (result) {
          this.campaign.active = true;
        }
      } catch {
        this.loading.active = false;
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["common-500"],
            alertClass: "danger"
          });
        }
      }
    },
    async setCampaignInactive() {
      if (!this.hasFullAccess("campaigns")) {
        this.$store.dispatch("setAlert", {
          showAlert: true,
          content: this.getPermissionErrorMessage("campaigns:full"),
          alertClass: "danger"
        });
        return;
      }
      this.loading.active = true;
      try {
        const result = await this.$store.dispatch(
          "campaign/setCampaignInactive",
          {
            campaign_id: this.id
          }
        );
        this.loading.active = false;
        if (result) {
          this.campaign.active = false;
        }
      } catch {
        this.loading.active = false;
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["common-500"],
            alertClass: "danger"
          });
        }
      }
    },

    async reorderCampaignStages(dropResult) {
      if (!this.hasFullAccess("campaigns")) {
        this.$store.dispatch("setAlert", {
          showAlert: true,
          content: this.getPermissionErrorMessage("campaigns:full"),
          alertClass: "danger"
        });
        this.buildPipeline();
        return;
      }
      this.hideAllRenameInputs();
      const scene = Object.assign({}, this.scene);
      scene.columns = applyDrag(scene.columns, dropResult);
      this.scene = scene;
      this.campaign.stages_order = [];

      this.scene.columns.forEach((stage) => {
        this.campaign.stages_order.push(stage.id);
      });

      this.loading.campaign = true;
      try {
        const { data } = await this.$store.dispatch(
          "campaign/reorderCampaignStages",
          {
            campaign_id: this.id,
            stage_ids: this.campaign.stages_order
          }
        );

        if (data) {
          // this.buildPipeline();
        } else {
          this.getSingleCampaign();
        }
        this.loading.campaign = false;
      } catch {
        this.loading.campaign = false;
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["common-500"],
            alertClass: "danger"
          });
        }
      }
    },

    async getSingleCampaign() {
      this.loading.campaign = true;
      try {
        const { data } = await this.$store.dispatch(
          "campaign/getSingleCampaign",
          this.id
        );
        this.campaign = data;
        await this.getCampaignLeadsPreview(this.campaign);
        this.loading.campaign = false;
      } catch {
        this.campaign = null;
        this.loading.campaign = false;
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["campaign__get_single_campaign-failed"],
            alertClass: "danger"
          });
        }
      }
    },
    async getCurrenciesList() {
      if (this.currencies.length > 0) return;
      this.loading.currencies = true;
      try {
        await this.$store.dispatch("user/getCurrenciesList");
        this.loading.currencies = false;
      } catch {
        this.loading.currencies = false;
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["user__get_currencies-failed"],
            alertClass: "danger"
          });
        }
      }
    },
    async saveCampaign(campaign) {
      if (!this.hasFullAccess("campaigns")) {
        this.$store.dispatch("setAlert", {
          showAlert: true,
          content: this.getPermissionErrorMessage("campaigns:full"),
          alertClass: "danger"
        });
        return;
      }
      this.loading.save = true;
      try {
        const result = await this.$store.dispatch("campaign/saveCampaign", {
          id: this.campaign.id,
          name: campaign.name,
          imv_coef: campaign.imv_coef,
          budget: campaign.budget,
          currency: campaign.currency,
          color: campaign.color
        });
        this.loading.save = false;
        if (result.status === 201) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["campaign__update_campaign-success"],
            alertClass: "success"
          });
          this.getSingleCampaign();
          this.showEditCampaign = false;
        }
      } catch {
        this.loading.save = false;
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["campaign__update_campaign-failed"],
            alertClass: "danger"
          });
        }
      }
    },
    buildPipeline() {
      this.scene = { columns: [] };
      if (this.campaign) {
        this.campaign.stages_order.forEach((stage_id) => {
          const stage_name = this.campaign.stage_names[stage_id];
          this.scene.columns.push({
            id: stage_id,
            name: stage_name,
            cards: []
          });
        });
        this.campaign.leads.forEach((lead) => {
          if (this.scene.columns.length > 0) {
            const index = this.scene.columns
              .map((e) => e.id)
              .indexOf(lead.stage + "");
            this.scene.columns[index].cards.push(lead);
          }
        });

        this.scene.columns.forEach((column) => {
          column.cards.sort((lead1, lead2) => {
            const name1 = this.getLeadNameBySocial(lead1);
            const name2 = this.getLeadNameBySocial(lead2);
            return name1.localeCompare(name2);
          });
        });
        setTimeout(() => {
          this.resizeWindow();
        }, 500);
      }
    },
    getLeadNameBySocial(lead) {
      if (lead.social === SOCIALS.instagram.id && lead.data.full_name) {
        return lead.data.full_name;
      } else if (lead.social === SOCIALS.tiktok.id && lead.data.nickname) {
        return lead.data.nickname;
      } else if (lead.social === SOCIALS.youtube.id && lead.data.title) {
        return lead.data.title;
      }
      return "-" + Math.random().toString(36).substring(2, 15);
    },
    hideAllRenameInputs() {
      this.showStageNameInput = [];
    },
    showRenameInput(index) {
      this.hideAllRenameInputs();
      this.showStageNameInput[index] = true;
    },
    updateSingleLead() {
      this.$refs.singleLead.getSingleLead();
    },

    onEditLeadDetail() {
      this.showEditLeadDetail = true;
    },
    openEmailReconnectionModal(email) {
      this.selectedEmail = email;
      this.showConfirmReconnectEmail = true;
    },
    async reconnectEmail() {
      if (!this.hasFullAccess("integrations")) {
        this.$store.dispatch("setAlert", {
          showAlert: true,
          content: this.getPermissionErrorMessage("integrations:full"),
          alertClass: "danger"
        });
        return;
      }
      this.showConfirmReconnectEmail = false;
      this.loading.request = true;
      try {
        const data = await this.$store.dispatch(
          "integration/requestEmail",
          this.selectedEmail
        );
        this.loading.request = false;
        this.redirectToSite(data);
      } catch {
        this.loading.request = false;
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["common-500"],
            alertClass: "danger"
          });
        }
      }
    },
    redirectToSite(data) {
      const { name, nonce } = data;
      if (name === "outlook") {
        const scopes = [
          "offline_access",
          "https://graph.microsoft.com/Mail.ReadWrite",
          "https://graph.microsoft.com/Mail.Send",
          "https://graph.microsoft.com/User.Read"
        ].join(" ");
        const url = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${Configs.services.outlook.client_id}&response_type=code&redirect_uri=${Configs.site.url}/api/webhooks/outlook/connect&response_mode=form_post&scope=${scopes}&state=${nonce}`;
        const win = window.open(url, "_blank");
        if (win == null || typeof win == "undefined") {
          // Turn off the pop-up blocker
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["user__email_popup-failed"],
            alertClass: "danger"
          });
        } else {
          win.focus();
        }
      }
    },
    async getCampaignLeadsPreview() {
      this.loading.leads = true;
      this.campaign.leads = [];
      try {
        const data = await this.$store.dispatch(
          "campaign/getCampaignLeadsPreview",
          {
            campaign_id: this.campaign.id
          }
        );
        if (data) {
          this.campaign.leads = data;
          this.setCardPictureUrl(data);
          await this.buildPipeline();
        }
        this.loading.leads = false;
      } catch {
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["campaign__get_leads_preview-failed"],
            alertClass: "danger"
          });
        }
        this.loading.leads = false;
      }
      this.buildPipeline();
    },
    setCardPictureUrl(data) {
      data.forEach(async (lead) => {
        const cardId = `${lead.campaign_id}-${lead.id}`;
        this.$set(
          this.cardPictureUrl,
          cardId,
          await resizedataUrl(lead.profile_pic, 32, 32)
        );
      });
    },
    getCardPictureUrl(card) {
      const cardId = `${card.campaign_id}-${card.id}`;
      return this.cardPictureUrl[cardId] ? this.cardPictureUrl[cardId] : null;
    },
    async renameStage(index) {
      if (!this.hasFullAccess("campaigns")) {
        this.$store.dispatch("setAlert", {
          showAlert: true,
          content: this.getPermissionErrorMessage("campaigns:full"),
          alertClass: "danger"
        });
        return;
      }
      const stageName = document.getElementById(
        "input-stage_name-" + this.scene.columns[index].id
      ).value;
      // need to improve the name validation on frontend
      if (!stageName || stageName === "") return;

      try {
        await this.$store.dispatch("campaign/renameCampaignStage", {
          campaign_id: this.id,
          stage_id: this.scene.columns[index].id,
          stage_name: stageName
        });
        this.scene.columns[index].name = stageName;
        this.campaign.stage_names[this.scene.columns[index].id] = stageName;
        this.loading.campaign = false;
      } catch {
        this.loading.campaign = false;

        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["campaign__rename_stage-failed"],
            alertClass: "danger"
          });
        }
      }

      this.hideAllRenameInputs();
    },
    async addStage() {
      if (!this.hasFullAccess("campaigns")) {
        this.$store.dispatch("setAlert", {
          showAlert: true,
          content: this.getPermissionErrorMessage("campaigns:full"),
          alertClass: "danger"
        });
        return;
      }
      const stageName = document.getElementById("input-stage_new_name").value;
      // need to improve the name validation on frontend
      if (!stageName || stageName === "") return;

      this.loading.campaign = true;
      try {
        const { data } = await this.$store.dispatch(
          "campaign/addCampaignStage",
          {
            campaign_id: this.id,
            stage_name: stageName
          }
        );

        this.campaign.stages_order.push(data.stage_id);
        this.campaign.stage_names[data.stage_id] = data.stage_name;
        this.buildPipeline();
        this.loading.campaign = false;
      } catch {
        this.loading.campaign = false;
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["campaign__add_stage-failed"],
            alertClass: "danger"
          });
        }
      }
      this.showStageNewNameInput = false;
    },
    async removeStage(cascade) {
      if (!this.hasFullAccess("campaigns")) {
        this.$store.dispatch("setAlert", {
          showAlert: true,
          content: this.getPermissionErrorMessage("campaigns:full"),
          alertClass: "danger"
        });
        return;
      }
      const index = this.selectedStageIndex;
      this.showConfirmStageRemove = false;
      try {
        await this.$store.dispatch("campaign/removeCampaignStage", {
          campaign_id: this.id,
          stage_id: this.scene.columns[index].id,
          cascade: cascade
        });
        this.getSingleCampaign();
        this.loading.campaign = false;
      } catch {
        this.loading.campaign = false;
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["campaign__delete_stage-failed"],
            alertClass: "danger"
          });
        }
      }
    },
    async confirmRemoveStage(index) {
      this.selectedStageIndex = index;
      this.loading.campaign = true;
      if (this.scene.columns[index].cards.length) {
        this.showConfirmStageRemove = true;
      } else {
        this.removeStage("delete");
      }
    },
    async removeLead() {
      this.loading.removeLead = true;
      const result = await this.$store.dispatch(
        "campaign/removeLeadFromCampaign",
        this.selectedLead
      );
      this.loading.removeLead = false;
      if (result) {
        this.getSingleCampaign();
        this.showEditLead = false;
      }
      this.showConfirmLeadRemove = false;
    },

    replyEmail() {
      this.showEditLeadEmailConversation = true;
    },
    forwardEmail() {
      this.showEditLeadEmailConversation = true;
    }
  }
};
</script>

<style lang="scss">
@import "../assets/scss/pages/campaigns";
</style>
