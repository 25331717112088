<template>
  <div class="app-content">
    <div class="left-side-menu">
      <div class="menu-logo">
        <router-link slot="brand" to="/">
          <img src="/app/img/icons/left-menu/ninjainfluence-logo.svg" class />
        </router-link>
      </div>
      <div class="menu-items-list">
        <div class="menu-item-icon" v-b-tooltip.hover.right title="Home">
          <router-link class to="/app/home">
            <img src="/app/img/icons/left-menu/home-icon.svg" class />
          </router-link>
        </div>
        <div class="menu-item-icon" v-b-tooltip.hover.right title="Search">
          <router-link class to="/app/influencers">
            <img src="/app/img/icons/left-menu/search-icon.svg" class />
          </router-link>
        </div>
        <div
          v-if="hasAccess('campaigns:view')"
          class="menu-item-icon"
          v-b-tooltip.hover.right
          title="Campaigns"
        >
          <router-link class to="/app/campaigns">
            <img src="/app/img/icons/left-menu/campaign-icon.svg" class />
          </router-link>
        </div>
        <div
          v-if="hasAccess('campaigns:view') && hasAccess('emails:view')"
          class="menu-item-icon"
          v-b-tooltip.hover.right
          title="Inbox"
        >
          <router-link class to="/app/inbox">
            <img src="/app/img/icons/left-menu/message-icon.svg" class />
          </router-link>
        </div>
        <div class="menu-item-icon" v-b-tooltip.hover.right title="Workspace">
          <router-link class to="/app/workspace">
            <img
              src="/app/img/icons/left-menu/work-outline-white-24dp.svg"
              class
            />
          </router-link>
        </div>
        <div class="menu-item-fill">&nbsp;</div>
        <template v-if="authUser.is_admin">
          <template v-if="emulatingUser && emulatingUser.email">
            <div
              class="menu-item-link"
              v-b-tooltip.hover.right
              :title="`Currently emulating ${emulatingUser.email}`"
            >
              <a href="#" class @click="stopEmulatingUser">Stop emulate</a>
            </div>
          </template>

          <div
            class="menu-item-link"
            v-else
            v-b-tooltip.hover.right
            title="Admin page"
          >
            <router-link class to="/app/admin">Admin</router-link>
          </div>
        </template>
        <div class="menu-item-icon" v-b-tooltip.hover.right title="Help">
          <router-link class to="/app/help">
            <span class="material-icons-outlined md-24"> help_outline </span>
          </router-link>
        </div>
        <div
          class="menu-item-icon logout-icon"
          v-b-tooltip.hover.right
          title="Logout"
          @click="logOut"
        >
          <img src="/app/img/icons/left-menu/logout-icon.svg" />
        </div>
        <div
          class="menu-item-settings"
          v-b-tooltip.hover.right
          title="Settings"
        >
          <div class="profile-logo">
            <router-link class to="/app/settings">
              <img src="/app/img/icons/left-menu/settings-icon.svg" class />
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div
      class="main-content"
      :class="{
        'fixed-left': $route.meta.requiresFilterbar,
        'main-content--inbox': $route.name == 'inbox'
      }"
    >
      <!-- Page content -->
      <div class="page-content" :class="isMobileSafari ? 'mobile-safari' : ''">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Configs from "../configs";

export default {
  name: "dashboard-layout",
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.user.isLoggedIn,
      exportList: (state) => state.influencer.exportList,
      unreads: (state) => state.inbox.unreads,
      authUser: (state) => state.user.authUser,
      emulatingUser: (state) => state.user.emulatingUser,
      alert: (state) => state.alert,
      showAlert: (state) => state.alert.showAlert
    }),
    ...mapGetters({
      hasAccess: "user/hasAccess"
    })
  },
  watch: {
    showAlert: function (show) {
      if (show) {
        this.$notifications.setOptions({ type: this.alert.alertClass });
        // passing alert obj to account for customButton if included in obj
        this.$notifications.notify(this.alert);
      }
      if (!this.isLoggedIn) {
        this.$router.push("/app/login");
      }
    }
  },
  data() {
    return {
      year: new Date().getFullYear(),
      configs: Configs,
      showMenu: false,
      timer: null,
      loading: false,
      isMobileSafari: false
    };
  },
  beforeMount() {
    if (this.isLoggedIn) {
      this.checkAuthMe();
    }
    this.isMobileSafari =
      navigator.userAgent.match(/(iPod|iPhone|iPad)/) &&
      navigator.userAgent.match(/AppleWebKit/);
  },
  mounted() {
    if (!this.timer) {
      this.getUnreads();
      this.timer = setInterval(
        this.getUnreads,
        Configs.site.inbox.refresh_timeout * 1000
      );
    }

    this.$nextTick(function () {});
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },

  methods: {
    toggleFilterbar() {
      this.$filterbar.toggleFilterbar();
    },
    logOut() {
      this.$store.dispatch("user/signOut");
      setTimeout(() => {
        window.location.href = "/app";
      }, 100);
    },
    stopEmulatingUser() {
      this.$store.dispatch("user/stopEmulatingUser");
      this.$router.push("/app/admin");
    },
    exportProfiles() {
      document.querySelector("#btn_export_profiles").click();
    },
    async checkAuthMe() {
      this.loading = true;
      try {
        const result = await this.$store.dispatch("user/checkAuthMe");
        this.loading = false;
        if (!result) {
          this.logOut();
        }
      } catch {
        this.loading = false;
        this.logOut();
      }
    },
    async getUnreads() {
      try {
        await this.$store.dispatch("inbox/getUnreads");
      } catch {
        return;
      }
    }
  }
};
</script>
<style>
</style>
