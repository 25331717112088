<template>
  <div
    class="tab-pane fade set-credit-usage-panel"
    :class="[
      { 'modal-mini': type === 'mini' },
    ]"
    v-bind:id="tabId"
  >
    <div class="main-content">
      <div class="flex-middle-end">
        <div class="date-picker">
          <label class="mr-2" for="dt-picker">
            <i class="fas fa-calendar-alt"></i>
          </label>
          <VueFlatPicker
            id="dt-picker"
            :config="flatPickerConfig"
            v-model="dateRange.str"
            @on-close="onChangeRange"
          />
        </div>
      </div>
      <div class="data-container">
        <VueScrollableTable
          v-if="data && data.length"
          class="credit-usage-table"
          :scroll-horizontal="true"
          :scroll-vertical="true"
          :sync-header-scroll="true"
          :dead-area-color="'transparent'"
          :class="{ freezeFirstColumn: true }"
        >
          <template slot="thead">
            <tr>
              <th>Date</th>
              <th>Type</th>
              <th>Credits</th>
            </tr>
          </template>
          <template slot="tbody">
            <tr
              v-for="(item, index) in data"
              :key="index"
              class="d-flex"
            >
              <td class="flex-middle">
                {{item.dt | FullDate(true)}}
              </td>
              <td class="flex-middle">
                <router-link v-if="item.campaign_id && item.type == 'tracking'" :to="`/app/campaigns/${item.campaign_id}`">
                  {{creditUsageTypes[item.type]}}
                </router-link>
                <template v-else>
                  {{creditUsageTypes[item.type]}}
                </template>
              </td>
              <td class="flex-middle">
                {{item.cost / 100 | NormalNumber(2)}}
              </td>
            </tr>
          </template>
        </VueScrollableTable>
        <div
          v-if="loading"
          class="data-spinner"
        >
          <base-spinner></base-spinner>
          <span class="ml-2">Loading...</span>
        </div>
        <div v-else-if="!data || !data.length" class="data-no">
          <h5>No data yet</h5>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import { mapState } from "vuex";
import { MESSAGES, CREDIT_USAGE_TYPES } from "@/constants";

const VueScrollableTable = () => import("vue-scrolling-table");
const VueFlatPicker = () => import("vue-flatpickr-component");
import 'flatpickr/dist/flatpickr.css';

export default {
  name: "set-credit-usage-panel",
  components: {
    VueScrollableTable,
    VueFlatPicker,
  },
  props: {
    currenciesList: {
      type: Array,
    },
    show: Boolean,
    type: {
      type: String,
      default: "",
      validator(value) {
        let acceptedValues = ["", "notice", "mini"];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Modal type (notice|mini|"") ',
    },
    animationDuration: {
      type: Number,
      default: 500,
      description: "Modal transition duration",
    },
    disconnecting: {
      type: Boolean,
      default: false,
    },
    tabId: String
  },
  data() {
    return {
      creditUsageTypes: CREDIT_USAGE_TYPES,
      flatPickerConfig: {
        mode: 'range',
        dateFormat: "MMM. D, YYYY",
        parseDate: (datestr, format) => {
          return moment(datestr, format, true).toDate();
        },
        // eslint-disable-next-line no-unused-vars
        formatDate: (date, format, locale) => {
          // locale can also be used
          return moment(date).format(format);
        }
      },
      data: [],
      dateRange: {
        str: '',
        start: '',
        end: '',
      },
      loading: false,
      localError: false,
    };
  },
  computed: {
    ...mapState({
      showAlert: (state) => state.alert.showAlert,
    }),
  },
  methods: {
    closePanel() {
      /* slide out panel */
      document.querySelector(
        ".set-credit-usage-panel .modal-dialog"
      ).style.right = `-100%`;
      /* reset panel */
      setTimeout(() => {
        this.$emit("update:show", false);
        this.$emit("close");
      }, this.animationDuration / 2);
    },
    onChangeRange(e) {
      this.dateRange.start = moment(e[0]).format("YYYY-MM-DD");
      this.dateRange.end = moment(e[1]).format("YYYY-MM-DD");
      this.getCreditUsageList();
    },
    async getCreditUsageList() {
      this.loading = true;
      try {
        const { start, end } = this.dateRange;
        this.data = await this.$store.dispatch("user/getCreditUsage", {
          start,
          end
        });
        this.loading = false;
      } catch {
        this.loading = false;
        this.localError = MESSAGES['common-500'];
      }
    },
    initComponent() {
      const documentClasses = document.body.classList;
      const intitialStartDate = moment().subtract(2, 'days');
      const intitialEndDate = moment();

      this.dateRange = {
        start: moment(intitialStartDate).format("YYYY-MM-DD"),
        end: moment(intitialEndDate).format("YYYY-MM-DD"),
        str: `${intitialStartDate.format(
          this.flatPickerConfig.dateFormat)
          } to ${intitialEndDate.format(
          this.flatPickerConfig.dateFormat)}`
      };

      this.getCreditUsageList();
      this.localError = false;
      /* slide in panel*/
      documentClasses.add("modal-open");
      setTimeout(() => {
        this.imvCoef = this.defaultImvCoefficient;
      }, 0);
    },
    resetComponent() {
      const documentClasses = document.body.classList;
      documentClasses.remove("modal-open");
      document.querySelector(".set-credit-usage-panel").scrollTop = 0;
    }
  },
  async mounted() {
    this.$nextTick(function() {
      this.initComponent();
    });
  },
  watch: {
    show(val) {
      if (val) {
        this.initComponent
      } else {
        /* reset panel */
        this.resetComponent()
      }
    },
  },
};
</script>
<style>
.modal.show {
  background-color: rgba(255, 255, 255, 0.7);
}
</style>
