<template>
  <div
    class="modal set-billing-package-panel right-sliding-panel"
    :class="[
      { 'show d-block': show },
      { 'd-none': !show },
      { 'modal-mini': type === 'mini' },
      { 'right-sliding-panel-xs': showStripeForm || showSelectPaymentMethod }
    ]"
    v-show="show"
    tabindex="-1"
    role="dialog"
    :aria-hidden="!show"
  >
    <div class="modal-dialog mb-0">
      <div class="modal-content">
        <modal-topbar>
          <a v-if="showSelectPaymentMethod" slot="left" @click="back">Back</a>
          <a v-else slot="left" @click="closePanel">Cancel</a>
          <div slot="center">Select credit package</div>
        </modal-topbar>

        <div
          class="modal-body"
          :class="!showStripeForm & showSelectPaymentMethod ? 'px-0' : ''"
        >
          <stripe-form
            v-if="showStripeForm"
            :package="currentPackage"
            :plan="null"
            :paymentMethod="currentPaymentMethod"
            @boughtCredits="boughtCredits"
            @addedNewPaymentMethod="addedNewPaymentMethod"
            @removedPaymentMethod="removedPaymentMethod"
          ></stripe-form>
          <div v-else-if="showSelectPaymentMethod" class="mt-5">
            <div v-if="paymentMethods.length" class="setting-label px-4">
              Payment Methods
            </div>
            <ul class="main-nav">
              <li
                v-for="method in paymentMethods"
                :key="method.card_id"
                class="p-3 my-1 flex-middle-between"
                @click="setCard(method)"
              >
                <div class="d-flex">
                  <div class="card-icon">
                    <svg
                      class="ccicon"
                      width="750"
                      height="471"
                      viewBox="0 0 750 471"
                      version="1.1"
                      v-html="getCardIcon(method.brand)"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                    />
                  </div>
                  <span class="ml-3 mt-2">{{ method.last4 }}</span>
                </div>
              </li>
            </ul>
            <div class="w-100 text-center mt-3">
              <a class="btn-link" @click="setCard(null)"
                >Pay with another card</a
              >
            </div>
          </div>
          <div v-else class="row">
            <div
              v-for="pack in packages"
              :key="pack.id"
              class="col-lg-4 col-md-6 col-sm-12"
              @click="selectCreditPackage(pack)"
            >
              <card :hover="true" class="mt-5">
                <h3 class="m-0" slot="header">{{ pack.name }} credits</h3>
                <div class="text-center">
                  <h4>
                    {{pack.cost / pack.amount | NormalNumber(2) | Currency}}
                    / credit
                  </h4>
                  <p>{{ pack.description }}</p>
                </div>
                <base-button type="primary" class="w-100 primary"
                  >Buy for ${{ pack.cost / 100 }}</base-button
                >
              </card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import * as ICONS from "../constants/icons";
import { MESSAGES } from "@/constants";

/* components */
import StripeForm from "./StripeForm";

export default {
  name: "set-billing-package-panel",
  components: {
    StripeForm
  },
  props: {
    show: Boolean,
    type: {
      type: String,
      default: "",
      validator(value) {
        let acceptedValues = ["", "notice", "mini"];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Modal type (notice|mini|"") '
    },
    selectedPaymentMethod: {
      type: Object,
      default: null
    },
    addingNewPaymentMethod: {
      type: Boolean,
      default: false
    },
    animationDuration: {
      type: Number,
      default: 500,
      description: "Modal transition duration"
    }
  },
  computed: {
    ...mapState({
      showAlert: state => state.alert.showAlert
    })
  },
  data() {
    return {
      icons: ICONS,
      loading: {
        profile: false,
        payments: false
      },
      paymentMethods: [],
      packages: [],
      currentPackage: {},
      currentPaymentMethod: null,
      showSelectPaymentMethod: false,
      showStripeForm: false,
      localError: false
    };
  },
  methods: {
    closePanel() {
      /* slide out panel */
      document.querySelector(
        ".set-billing-package-panel .modal-dialog"
      ).style.right = `-100%`;
      /* reset panel */
      setTimeout(() => {
        this.$emit("update:show", false);
        this.$emit("close");
      }, this.animationDuration / 2);
    },
    getCardIcon(brand) {
      if (brand) {
        return this.icons[brand] ? this.icons[brand] : this.icons["undefined"];
      }
      return this.icons["unknown"];
    },
    selectCreditPackage(pack) {
      this.showSelectPaymentMethod = this.selectedPaymentMethod ? false : true;
      this.showStripeForm = this.selectedPaymentMethod ? true : false;
      this.currentPackage = pack;
      this.currentPaymentMethod = this.selectedPaymentMethod;
      if (!this.paymentMethods.length) {
        this.setCard(null);
      }
    },
    addedNewPaymentMethod(result) {
      if (result) {
        this.showSelectPaymentMethod = false;
        this.showStripeForm = false;
        this.closePanel();
        this.$emit("addedNewPaymentMethod", result);
      }
    },
    boughtCredits(result) {
      if (result) {
        this.showSelectPaymentMethod = false;
        this.showStripeForm = false;
        this.closePanel();
        this.$emit("boughtCredits", result);
      }
    },
    setCard(paymentMethod) {
      this.showStripeForm = true;
      this.currentPaymentMethod = paymentMethod;
    },
    back() {
      if (this.showStripeForm) {
        this.showStripeForm = false;
        this.showSelectPaymentMethod = !this.selectedPaymentMethod;
      } else {
        this.showSelectPaymentMethod = false;
      }
    },
    removedPaymentMethod(cardId) {
      const index = this.paymentMethods.map(e => e.card_id).indexOf(cardId);
      if (index >= 0) {
        this.paymentMethods.splice(index, 1);
      }
      this.showStripeForm = false;
      this.$emit("removedPaymentMethod", cardId);
    },
    async getCreditPackages() {
      this.loading.profile = true;
      try {
        const { data } = await this.$store.dispatch("billing/getCreditPackages");
        this.packages = data;
      } catch {
        this.loading.profile = false;
        this.$store.dispatch("setAlert", {
          showAlert: true,
          content: MESSAGES["common-500"],
          alertClass: "danger"
        });
      }
    },
    async getPaymentMethods() {
      this.loading.payments = true;
      try {
        const { data } = await this.$store.dispatch("billing/getPaymentMethods");
        this.paymentMethods = data;
        this.loading.payments = false;
      } catch {
        this.loading.payments = false;
        this.$store.dispatch("setAlert", {
          showAlert: true,
          content: MESSAGES["common-500"],
          alertClass: "danger"
        });
      }
    }
  },

  watch: {
    show(val) {
      this.showSelectPaymentMethod = false;
      this.showStripeForm = false;
      const documentClasses = document.body.classList;

      if (val) {
        if (this.addingNewPaymentMethod) {
          this.showSelectPaymentMethod = false;
          this.currentPaymentMethod = null;
          this.currentPackage = {};
          this.showStripeForm = true;
        } else {
          this.getCreditPackages();
          this.getPaymentMethods();
        }

        /* slide in panel*/
        documentClasses.add("modal-open");
        setTimeout(() => {
          document.querySelector(
            ".set-billing-package-panel .modal-dialog"
          ).style.right = `0px`;
        }, 0);
      } else {
        /* reset panel */
        documentClasses.remove("modal-open");
        document.querySelector(".set-billing-package-panel").scrollTop = 0;
      }
    }
  }
};
</script>
<style>
.modal.show {
  background-color: rgba(255, 255, 255, 0.7);
}
</style>
