<template>
  <div class="settings">
    <!-- <div class="topbar navbar-mobile flex-between d-md-none">
      <div class="d-flex flex-row w-100">
        <a @click="showFilterbar(true)" class="topbar-menu flex-middle-center mr-3">
          <i class="fas fa-bars"></i>
        </a>
        <div class="topbar-info flex-middle w-100" @click="showFilterbar(true)">Settings</div>
      </div>
    </div> -->
    <!-- <p class="text-right mb-0 mt-3 px-4" v-if="emulatingUser.email">
        <b>{{emulatingUser.email || authUser.email}}</b>
    </p>-->
    <div class="avatar-container">
      <div class="avatar-logo">
        <div class="user-avatar">
          <img
            class="avatar avatar-126"
            src="/app/img/avatar/ninjainfluence-default.png"
            alt="Logo"
          />
        </div>
      </div>
      <div class="avatar-user-name">
        {{ authUser.email }}
      </div>
    </div>
    <div class="main-nav-wrapper">
      <!-- Tabs -->
      <ul class="main-nav-tabs nav nav-tabs">
        <li class="pt-2">
          <a href="#myAccountTab" class="nav-link active" data-toggle="tab">
            <span>{{ menu.my_account.label }}</span>
          </a>
        </li>
        <li v-if="hasAccess(menu.team.scope)" class="pt-2">
          <a href="#teamTab" class="nav-link" data-toggle="tab">
            <span>{{ menu.team.label }}</span>
          </a>
        </li>
        <!-- <li v-if="hasAccess(menu.billing.scope)" class="pt-2">
          <a href="#billingTab" class="nav-link" data-toggle="tab">
            <span>{{ menu.billing.label }}</span>
          </a>
        </li>
        <li v-if="hasAccess(menu.plans.scope)" class="pt-2">
          <a href="#plansTab" class="nav-link" data-toggle="tab">
            <span>{{ menu.plans.label }}</span>
          </a>
        </li> -->
        <!-- <li v-if="hasAccess(menu.credit_usage.scope)" class="pt-2">
          <a href="#creditUsageTab" class="nav-link" data-toggle="tab">
            <span>{{ menu.credit_usage.label }}</span>
          </a>
        </li> -->
        <li
          v-if="hasAccess(menu.customization.scope)"
          class="pt-2"
          @click="showSetVariables = true"
        >
          <a href="#customizationTab" class="nav-link" data-toggle="tab">
            <span>{{ menu.customization.label }}</span>
          </a>
        </li>
        <li v-if="hasAccess(menu.integrations.scope)" class="pt-2">
          <a href="#integrationsTab" class="nav-link" data-toggle="tab">
            <span>{{ menu.integrations.label }}</span>
          </a>
        </li>
        <li v-if="hasAccess(menu.emails.scope)" class="pt-2 tab-icon">
          <a href="#emailsTab" class="nav-link" data-toggle="tab">
            <span> {{ menu.emails.label }}</span>
          </a>
        </li>
        <li v-if="hasAccess(menu.notifications.scope)" class="pt-2">
          <a href="#notificationsTab" class="nav-link" data-toggle="tab">
            <span>{{ menu.notifications.label }}</span>
          </a>
        </li>
        <li class="pt-2">
          <a href="#setPasswordTab" class="nav-link" data-toggle="tab">
            <span>{{ menu.update_password.label }}</span>
          </a>
        </li>
      </ul>
      <!-- Tabs content -->
      <div class="main-tab-content tab-content">
        <set-account-panel
          :tabId="'myAccountTab'"
          :show="true"
          @close="showSetAccount = false"
          :animationDuration="500"
        />
        <set-team-panel
          :tabId="'teamTab'"
          :show="true"
          @close="showSetTeam = false"
          @inviteTeamMember="inviteTeamMember"
          @editTeamMember="editTeamMember"
          :animationDuration="500"
        />
        <set-billing-panel
          :show="true"
          :tabId="'billingTab'"
          @close="showSetBilling = false"
          @buyCredits="buyCredits"
          @addNewPaymentMethod="addNewPaymentMethod"
          :animationDuration="500"
        />
        <set-billing-package-panel
          :show="showSetBillingPackage"
          :selectedPaymentMethod="selectedPaymentMethod"
          :addingNewPaymentMethod="addingNewPaymentMethod"
          @close="showSetBillingPackage = false"
          @addedNewPaymentMethod="addedNewPaymentMethod"
          @removedPaymentMethod="removedPaymentMethod"
        />
        <set-plans-panel
          :show="true"
          :tabId="'plansTab'"
          @close="showSetPlans = false"
          @changePlan="changePlan"
          :animationDuration="500"
        />
        <!-- <set-credit-usage-panel
          :show="true"
          :tabId="'creditUsageTab'"
          @close="showSetCreditUsage = false"
          :animationDuration="500"
        /> -->
        <set-plans-update-panel
          :show="showSetPlansUpdate"
          @close="showSetPlansUpdate = false"
          :animationDuration="500"
        />

        <set-team-invite-panel
          :show="showSetTeamInvite"
          :selected="selectedTeamMember"
          @close="showSetTeamInvite = false"
          :animationDuration="500"
        />
        <set-variables-panel
          :show="showSetVariables"
          :currenciesList="currencies"
          @close="showSetVariables = false"
          :animationDuration="500"
        />
        <set-emails-panel
          ref="emailPanel"
          :show="true"
          :tabId="'emailsTab'"
          @close="showSetEmails = false"
          :disconnecting="loading.delete"
          @disconnectEmail="confirmDisconnectEmail"
        />
        <set-integrations-panel
          :show="true"
          :tabId="'integrationsTab'"
          :integrations="integrations"
          :disconnecting="loading.delete"
          @addNewIntegration="addNewIntegration"
          @close="showSetIntegrations = false"
          @disconnect="removingIntegration"
          :animationDuration="500"
        />
        <!-- the user clicks a button (Ecwid, Big Cartel) -->
        <set-integrations-add-panel-button
          :show="
            showSetIntegrationsAdd &&
            availableIntegrations[selectedIntegrationType] &&
            !availableIntegrations[selectedIntegrationType].type
          "
          :selectedIntegrationId="selectedIntegrationType"
          :shop="shop"
          :state="state"
          @close="showSetIntegrationsAdd = false"
          :animationDuration="500"
        />
        <!-- the user inputs a URL, then clicks a button (WooCommerce, 3dcart, BigCommerce) -->
        <set-integrations-add-panel-url
          :show="
            showSetIntegrationsAdd &&
            availableIntegrations[selectedIntegrationType] &&
            availableIntegrations[selectedIntegrationType].type === 1
          "
          :selectedIntegrationId="selectedIntegrationType"
          :shop="shop"
          :state="state"
          @close="showSetIntegrationsAdd = false"
          :animationDuration="500"
        />
        <!-- the user inputs some values (Magento, ?) -->
        <set-integrations-add-panel-values1
          :show="
            showSetIntegrationsAdd &&
            availableIntegrations[selectedIntegrationType] &&
            availableIntegrations[selectedIntegrationType].type === 2
          "
          :selectedIntegrationId="selectedIntegrationType"
          :shop="shop"
          :state="state"
          @close="showSetIntegrationsAdd = false"
          :animationDuration="500"
        />
        <!-- the user inputs some values (Magento) -->
        <set-integrations-add-panel-values2
          :show="
            showSetIntegrationsAdd &&
            availableIntegrations[selectedIntegrationType] &&
            availableIntegrations[selectedIntegrationType].type === 3
          "
          :selectedIntegrationId="selectedIntegrationType"
          :shop="shop"
          :state="state"
          @close="showSetIntegrationsAdd = false"
          :animationDuration="500"
        />
        <set-notifications-panel
          :show="true"
          :tabId="'notificationsTab'"
          :disconnecting="loading.delete"
          @close="showSetNotifications = false"
          @disconnect="removingNotification"
          :animationDuration="500"
        />
        <set-password-panel
          :tabId="'setPasswordTab'"
          :show="showSetPassword"
          @close="showSetPassword = false"
          :animationDuration="500"
        />
      </div>
    </div>
    <filter-bar
      :background-color="filterbarBackground"
      :short-title="configs.site.name"
      :title="configs.site.name"
      class="d-block d-md-none"
    >
      <left-nav slot="filters" />
    </filter-bar>

    <modal
      v-if="selectedNotificationId"
      :show="showConfirmRemoveNotification"
      @close="showConfirmRemoveNotification = false"
    >
      <h3 slot="header">
        Disconnecting {{ availableNotifications[selectedNotificationId].name }}
      </h3>
      <p>Are you sure you want to disconnect this notification channel?</p>
      <div slot="footer" class="d-flex">
        <base-button type="danger" @click="removeNotification()"
          >Remove</base-button
        >
        <base-button
          type="primary"
          @click="showConfirmRemoveNotification = false"
          >Cancel</base-button
        >
      </div>
    </modal>
    <modal
      v-if="selectedIntegrationId"
      :show="showConfirmRemoveInteraction"
      @close="showConfirmRemoveInteraction = false"
    >
      <h3 slot="header">
        Disconnect
        {{ integrationById && integrationById.url | Domain }}
      </h3>
      <p>
        If you disconnect your integrations, the campaigns will stop receiving
        data from your e-commerce platform immediately. Are you sure you want to
        disconnect this integration?
      </p>
      <div slot="footer" class="d-flex">
        <base-button type="danger" @click="removeIntegration()"
          >Remove</base-button
        >
        <base-button
          type="primary"
          @click="showConfirmRemoveInteraction = false"
          >Cancel</base-button
        >
      </div>
    </modal>
    <modal
      v-if="selectedEmail"
      :show="showConfirmDisconnectEmail"
      @close="showConfirmDisconnectEmail = false"
    >
      <h3 slot="header">Disconnecting {{ selectedEmail.name }}</h3>
      <p>
        If you disconnect your email, Ninja Influence will stop receiving your
        email data. Are you sure you want to disconnect this address?
      </p>
      <div slot="footer" class="d-flex">
        <base-button type="danger" @click="disconnectEmail()"
          >Remove</base-button
        >
        <base-button type="primary" @click="showConfirmDisconnectEmail = false"
          >Cancel</base-button
        >
      </div>
    </modal>

    <modal
      :show="showConfirmReconnectEmail"
      @close="showConfirmReconnectEmail = false"
    >
      <h3 slot="header">Reconnection required</h3>
      <p>
        It looks like your email account{{
          selectedEmail ? ` ${selectedEmail.address}` : ""
        }}
        got disconnected! Do you want to reconnect it?
      </p>
      <div slot="footer" class="d-flex">
        <base-button type="primary" @click="reconnectEmail">Yes</base-button>
        <base-button type="danger" @click="showConfirmReconnectEmail = false"
          >No</base-button
        >
      </div>
    </modal>
  </div>
</template>

<script>
import Configs from "../configs";
import { mapState, mapGetters } from "vuex";
import {
  MESSAGES,
  SETTINGS_ITEMS,
  AVAILABLE_INTEGRATIONS,
  AVAILABLE_NOTIFICATIONS
} from "@/constants";

import "bootstrap";

/* components */
import SetAccountPanel from "../components/SetAccountPanel";
import SetBillingPanel from "../components/SetBillingPanel";
import SetBillingPackagePanel from "../components/SetBillingPackagePanel";
import SetPlansPanel from "../components/SetPlansPanel";
import SetPlansUpdatePanel from "../components/SetPlansUpdatePanel";
import SetCreditUsagePanel from "../components/SetCreditUsagePanel";
import SetTeamPanel from "../components/SetTeamPanel";
import SetTeamInvitePanel from "../components/SetTeamInvitePanel";
import SetVariablesPanel from "../components/SetVariablesPanel";
import SetIntegrationsPanel from "../components/SetIntegrationsPanel";
import SetIntegrationsAddPanelButton from "../components/SetIntegrationsAddPanel-Button";
import SetIntegrationsAddPanelUrl from "../components/SetIntegrationsAddPanel-Url";
import SetIntegrationsAddPanelValues1 from "../components/SetIntegrationsAddPanel-Values1";
import SetIntegrationsAddPanelValues2 from "../components/SetIntegrationsAddPanel-Values2";
import SetEmailsPanel from "../components/SetEmailsPanel";
import SetNotificationsPanel from "../components/SetNotificationsPanel";
import SetPasswordPanel from "../components/SetPasswordPanel";

export default {
  components: {
    SetAccountPanel,
    SetBillingPanel,
    SetBillingPackagePanel,
    SetPlansPanel,
    SetCreditUsagePanel,
    SetPlansUpdatePanel,
    SetTeamPanel,
    SetTeamInvitePanel,
    SetVariablesPanel,
    SetEmailsPanel,
    SetIntegrationsPanel,
    SetIntegrationsAddPanelButton,
    SetIntegrationsAddPanelUrl,
    SetIntegrationsAddPanelValues1,
    SetIntegrationsAddPanelValues2,
    SetNotificationsPanel,
    SetPasswordPanel
  },
  data() {
    return {
      filterbarBackground: "vue", //vue|blue|orange|green|red|primary
      availableIntegrations: AVAILABLE_INTEGRATIONS,
      availableNotifications: AVAILABLE_NOTIFICATIONS,
      configs: Configs,
      menu: SETTINGS_ITEMS,
      headerHeight: 0,
      showSetAccount: false,
      showSetBilling: false,
      showSetPlans: false,
      showSetCreditUsage: false,
      showSetPlansUpdate: false,
      showSetTeam: false,
      showSetTeamInvite: false,
      showSetVariables: false,
      showSetBillingPackage: false,
      showSetIntegrations: false,
      showSetIntegrationsAdd: false,
      showSetPassword: false,
      showConfirmRemoveInteraction: false,
      showConfirmRemoveNotification: false,
      showConfirmDisconnectEmail: false,
      showConfirmReconnectEmail: false,
      showSetNotifications: false,
      showSetEmails: false,
      selectedPaymentMethod: null,
      selectedIntegrationId: null,
      selectedIntegrationType: null,
      selectedNotificationId: null,
      selectedEmail: null,
      selectedTeamMember: null,
      addingNewPaymentMethod: false,
      shop: null,
      state: null,
      loading: {
        currencies: false,
        integrations: false,
        notifications: false,
        emails: false
      },
      localError: null
    };
  },
  props: {
    primary: {
      type: String,
      description: "primary url"
    },
    secondary: {
      type: String,
      description: "secondary url"
    }
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.user.isLoggedIn,
      currencies: (state) => state.user.currencies,
      integrations: (state) => state.integration.integrations,
      notifications: (state) => state.integration.notifications,
      isMobileSafari: (state) => state.user.isMobileSafari,
      authUser: (state) => state.user.authUser,
      emulatingUser: (state) => state.user.emulatingUser
    }),
    ...mapGetters({
      hasAccess: "user/hasAccess"
    }),
    integrationById() {
      return this.integrations.find((obj) => {
        return obj.id === this.selectedIntegrationId;
      });
    }
  },
  watch: {
    $route: function (to) {
      if (to.params.primary === SETTINGS_ITEMS.integrations.value) {
        this.showSetIntegrations = true;
      }
      if (to.params.primary === SETTINGS_ITEMS.notifications.value) {
        this.showSetNotifications = true;
      }
      if (to.params.primary === SETTINGS_ITEMS.emails.value) {
        this.showSetEmails = true;
      }
      // to reload userpilot
      this.$store.dispatch("user/setRoute", to.path);
    },
    showSetBilling: function () {
      this.setRouter();
    },
    showSetPlans: function () {
      this.setRouter();
    },
    showSetCreditUsage: function () {
      this.setRouter();
    },
    showSetVariables: function () {
      this.setRouter();
    },
    showSetIntegrations: function () {
      this.setRouter();
    },
    showSetIntegrationsAdd: function (val) {
      if (!val) {
        this.getIntegraions();
      }
      this.setRouter();
    },
    showSetNotifications: function () {
      this.setRouter();
    },
    showSetBillingPackage: function () {
      this.setRouter();
    },
    showSetEmails: function () {
      this.setRouter();
    },
    showSetTeam: function () {
      this.setRouter();
    },
    showSetTeamInvite: function () {
      if (!this.selectedTeamMember) {
        this.setRouter();
      }
    },
    showSetPlansUpdate: function () {
      this.setRouter();
    },
    addingNewPaymentMethod: function () {
      this.setRouter();
    },
    localError: function (msg) {
      if (msg) {
        this.$store.dispatch("setAlert", {
          showAlert: true,
          content: msg,
          alertClass: "danger"
        });
        this.localError = null;
      }
    }
  },

  async mounted() {
    // to reload userpilot
    this.$store.dispatch("user/setRoute", this.$route.path);

    if (!this.resizeHandler) {
      this.resizeHandler = window.addEventListener("resize", this.resizeWindow);
    }
    this.shop = this.$route.query.shop || this.authUser.shop;
    this.state = this.$route.query.state || this.authUser.state;
    this.$filterbar.displayFilterbar(false);

    await this.getCurrenciesList();
    await this.getIntegraions();
    await this.getNotifications();

    if (this.secondary) {
      switch (this.secondary) {
        case SETTINGS_ITEMS.add_card.value:
          this.addNewPaymentMethod();
          break;
        case SETTINGS_ITEMS.buy_credits.value:
          this.buyCredits();
          break;
        case SETTINGS_ITEMS.set_plan.value:
          this.showSetPlansUpdate = true;
          break;
        case SETTINGS_ITEMS.invite_team.value:
          this.showSetTeamInvite = true;
          break;
        default:
          if (this.primary === SETTINGS_ITEMS.integrations.value) {
            if (
              Object.keys(AVAILABLE_INTEGRATIONS).indexOf(this.secondary) >
                -1 &&
              Object.keys(this.integrations).indexOf(this.secondary) < 0
            ) {
              this.selectedIntegrationId = this.secondary;
              this.showSetIntegrationsAdd = true;
            } else {
              this.showSetIntegrations = true;
            }
          }
      }
    } else {
      switch (this.primary) {
        case SETTINGS_ITEMS.billing.value:
          this.showSetBilling = true;
          break;
        case SETTINGS_ITEMS.plans.value:
          this.showSetPlans = true;
          break;
        case SETTINGS_ITEMS.credit_usage.value:
          this.showSetCreditUsage = true;
          break;
        case SETTINGS_ITEMS.customization.value:
          this.showSetVariables = true;
          break;
        case SETTINGS_ITEMS.integrations.value:
          this.showSetIntegrations = true;
          break;
        case SETTINGS_ITEMS.notifications.value:
          this.showSetNotifications = true;
          break;
        case SETTINGS_ITEMS.emails.value:
          this.showSetEmails = true;
          break;
        case SETTINGS_ITEMS.team.value:
          this.showSetTeam = true;
          break;
        default:
      }
    }
  },
  beforeDestroy() {},
  methods: {
    filterChanged(milliseconds = 500) {
      if (this.searchThread) {
        clearTimeout(this.searchThread);
        this.searchThread = null;
      }
      this.searchThread = setTimeout(() => {
        this.getCampaigns();
      }, milliseconds);
      this.isQuery = false;
    },
    hideFilterbar() {
      if (this.$filterbar.showFilterbar) {
        this.$filterbar.displayFilterbar(false);
      }
    },
    showFilterbar() {
      setTimeout(() => {
        this.$filterbar.displayFilterbar(true);
      }, 0);
    },
    setRouter() {
      if (this.showSetBillingPackage) {
        if (this.addingNewPaymentMethod) {
          this.$router.push({
            path: `/app/settings/${SETTINGS_ITEMS.billing.value}/${SETTINGS_ITEMS.add_card.value}`
          });
        } else {
          this.$router.push({
            path: `/app/settings/${SETTINGS_ITEMS.billing.value}/${SETTINGS_ITEMS.buy_credits.value}`
          });
        }
      } else if (this.showSetPlansUpdate) {
        this.$router.push({
          path: `/app/settings/${SETTINGS_ITEMS.plans.value}/${SETTINGS_ITEMS.set_plan.value}`
        });
      } else if (this.showSetIntegrationsAdd) {
        if (this.authUser.shopify_success) {
          this.showSetIntegrationsAdd = false;
          this.showSetIntegrations = true;
        } else {
          let path = `/app/settings/${SETTINGS_ITEMS.integrations.value}/${this.selectedIntegrationId}`;
          if (this.shop) {
            path +=
              `?shop=${this.shop}` + (this.state ? `&state=${this.state}` : "");
          }
          this.$router.push({
            path: path
          });
        }
      } else if (this.showSetTeamInvite) {
        this.$router.push({
          path: `/app/settings/${SETTINGS_ITEMS.team.value}/${SETTINGS_ITEMS.invite_team.value}`
        });
      } else if (this.showSetBilling) {
        this.$router.push({
          path: `/app/settings/${SETTINGS_ITEMS.billing.value}`
        });
      } else if (this.showSetPlans) {
        this.$router.push({
          path: `/app/settings/${SETTINGS_ITEMS.plans.value}`
        });
      } else if (this.showSetCreditUsage) {
        this.$router.push({
          path: `/app/settings/${SETTINGS_ITEMS.credit_usage.value}`
        });
      } else if (this.showSetVariables) {
        this.$router.push({
          path: `/app/settings/${SETTINGS_ITEMS.customization.value}`
        });
      } else if (this.showSetIntegrations) {
        this.$router.push({
          path: `/app/settings/${SETTINGS_ITEMS.integrations.value}`
        });
      } else if (this.showSetNotifications) {
        this.$router.push({
          path: `/app/settings/${SETTINGS_ITEMS.notifications.value}`
        });
      } else if (this.showSetEmails) {
        this.$router.push({
          path: `/app/settings/${SETTINGS_ITEMS.emails.value}`
        });
      } else if (this.showSetTeam) {
        this.$router.push({
          path: `/app/settings/${SETTINGS_ITEMS.team.value}`
        });
      } else {
        this.$router.push({ name: "settings" });
      }
    },
    async getCurrenciesList() {
      if (this.currencies.length > 0) return;
      this.loading.currencies = true;
      try {
        await this.$store.dispatch("user/getCurrenciesList");
        this.loading.currencies = false;
      } catch {
        this.loading.currencies = false;
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["user__get_currencies-failed"],
            alertClass: "danger"
          });
        }
      }
    },

    reconnectEmail() {
      this.$refs.emailPanel.reconnectEmail(this.selectedEmail);
    },
    confirmDisconnectEmail(emailObj) {
      this.selectedEmail = emailObj;
      this.showConfirmDisconnectEmail = true;
    },
    async disconnectEmail() {
      this.showConfirmDisconnectEmail = false;
      this.$set(this.loading, "delete", true);
      try {
        await this.$store.dispatch(
          "integration/disconnectEmail",
          this.selectedEmail
        );
        this.$set(this.loading, "delete", false);
      } catch {
        this.$set(this.loading, "delete", false);
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["common-500"],
            alertClass: "danger"
          });
        }
      }
    },
    removingNotification(id) {
      // eslint-disable-next-line no-console
      console.log(id);
      this.selectedNotificationId = id;
      this.showConfirmRemoveNotification = true;
    },
    async removeNotification() {
      this.showConfirmRemoveInteraction = false;
      if (!this.selectedNotificationId) return;
      this.$set(this.loading, "delete", true);
      try {
        await this.$store.dispatch(
          "integration/removeNotification",
          this.selectedNotificationId
        );
        this.$set(this.loading, "delete", false);

        this.selectedNotificationId = null;
      } catch {
        this.selectedNotificationId = null;
        this.$set(this.loading, "delete", false);
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["common-500"],
            alertClass: "danger"
          });
        }
      }
    },
    removingIntegration(id) {
      this.selectedIntegrationId = id;
      this.showConfirmRemoveInteraction = true;
    },
    async removeIntegration() {
      this.showConfirmRemoveInteraction = false;
      if (!this.selectedIntegrationId) return;
      this.$set(this.loading, "delete", true);
      try {
        await this.$store.dispatch(
          "integration/removeIntegration",
          this.selectedIntegrationId
        );
        this.$set(this.loading, "delete", false);

        this.selectedIntegrationId = null;
      } catch {
        this.selectedIntegrationId = null;
        this.$set(this.loading, "delete", false);
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["integration__delete_integration-failed"],
            alertClass: "danger"
          });
        }
      }
    },
    async getIntegraions() {
      this.loading.integrations = true;
      try {
        await this.$store.dispatch("integration/getIntegrations");
        this.loading.integrations = false;
      } catch {
        this.loading.integrations = false;
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["integration__get_integrations-failed"],
            alertClass: "danger"
          });
        }
      }
    },
    async getNotifications() {
      this.loading.notifications = true;
      try {
        await this.$store.dispatch("integration/getNotifications");
        this.loading.notifications = false;
      } catch {
        this.loading.notifications = false;
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["common-500"],
            alertClass: "danger"
          });
        }
      }
    },
    inviteTeamMember() {
      this.selectedTeamMember = null;
      this.showSetTeamInvite = true;
    },
    editTeamMember(teamMember) {
      this.selectedTeamMember = teamMember;
      this.showSetTeamInvite = true;
    },
    buyCredits(paymentMethod) {
      this.showSetBillingPackage = true;
      this.addingNewPaymentMethod = false;
      this.showSetBilling = false;
      this.selectedPaymentMethod = paymentMethod;
    },
    addNewIntegration(type) {
      this.selectedIntegrationType = type;
      this.showSetIntegrationsAdd = true;
    },
    addNewPaymentMethod() {
      this.selectedPaymentMethod = null;
      this.addingNewPaymentMethod = true;
      this.showSetBillingPackage = true;
    },
    addedNewPaymentMethod() {
      this.showSetBilling = false;
    },
    removedPaymentMethod() {
      this.showSetBillingPackage = false;
      this.showSetBilling = false;
    },
    changePlan() {
      this.showSetPlansUpdate = true;
    },
    logOut() {
      this.$store.dispatch("user/signOut");
      setTimeout(() => {
        window.location.href = "/app";
      }, 100);
    }
  }
};
</script>

<style lang="scss">
@import "../assets/scss/pages/settings";
</style>
