<template>
  <div
    class="modal lead-panel right-sliding-panel"
    :class="[
      { 'show d-block': show },
      { 'd-none': !show },
      { 'modal-mini': type === 'mini' }
    ]"
    @click.self="closePanel"
    v-show="show"
    tabindex="-1"
    role="dialog"
    :aria-hidden="!show"
  >
    <div class="modal-dialog mb-0">
      <div class="modal-content lead-panel-content">
        <div class="profile-topbar">
          <div class="profile-topbar-left">
            <a slot="left" @click="closePanel" class="back-btn">
              <i class="fas fa-times"></i>
            </a>
          </div>
          <div class="profile-topbar-right">
            <div class="social-links">
              <a class="btn btn-light" :href="getProfileLink()" target="_blank"
                >Visit Profile</a
              >
            </div>
          </div>
        </div>

        <!-- Profile header for instagram/youtube/tiktok -->
        <template v-if="currentProfile">
          <div class="lead-header">
            <lead-profile-header
              :social="social"
              :currentProfile="currentProfile"
            >
            </lead-profile-header>
          </div>

          <div class="lead-tabs">
            <tabs
              :pills="false"
              :centered="true"
              tabComponentClasses="black-tabs"
              tabNavWrapperClasses="black-tab-nav-wrapper"
              tabNavClasses="black-tab-nav"
              tabContentClasses="black-tab-content"
              ref="tabs"
              @setActiveTab="setActiveTab"
            >
              <tab-pane title="Credit payment">
                <div class="credit-payment-tab">
                  <h2>Credit Payment</h2>
                  <div>
                    <label for="">Payment Amount</label>
                    <div class="d-flex">
                      <base-input v-model="paymentAmount" placeholder="Amount">
                      </base-input>
                      <div class="currency-multiselect">
                        <multiselect
                          placeholder="Currency"
                          v-model="selectedCurrency"
                          selectLabel
                          deselectLabel
                          selectedLabel
                          :allowEmpty="false"
                          label="description"
                          track-by="code"
                          :options="currenciesListForDropdown"
                        >
                        </multiselect>
                      </div>

                      <div class="payment-actions">
                        <base-button class="btn-black" @click="doPayment">
                          Pay
                        </base-button>
                      </div>
                    </div>
                  </div>
                  <bridgerpay-modal
                    :show="bridgerPayActive"
                    :paymentAmount="paymentAmount"
                    :paymentCurrency="paymentCurrency"
                    :userCountry="userCountry"
                    :userState="userState"
                    :leadId="profileRequestData.id"
                    :campaignId="campaignId"
                    @close="bridgerPayActive = false"
                  >
                  </bridgerpay-modal>
                </div>
              </tab-pane>
              <tab-pane title="Cryptocurrency">
                <div class="cryptocurrency-tab">
                  <h2>Cryptocurrency</h2>

                  <h3>Comming soon!</h3>
                </div>
              </tab-pane>
            </tabs>
          </div>
        </template>

        <div v-if="!currentProfile" class="my-4 modal-body flex-middle-center">
          <base-spinner v-if="loading.profile"></base-spinner>
          <h3 v-if="loading.profile" class="p-2 mt-2">Loading...</h3>
          <h3 v-else>Woophs, something was wrong!</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import { defineComponent } from '@vue/composition-api'

// export default defineComponent({
//     setup() {

//     },
// })
//
</script>

<script>
import { mapState } from "vuex";
import Multiselect from "vue-multiselect";

import { SOCIALS } from "@/constants";

import { ProfilePanelRequestData } from "../models/leadProfile";

import LeadProfileHeader from "./Profiles/LeadProfileHeader.vue";
import BridgerpayModal from "./Payments/BridgerpayModal.vue";

export default {
  name: "lead-panel",
  components: {
    LeadProfileHeader,
    Multiselect,
    BridgerpayModal
  },
  props: {
    show: Boolean,
    type: {
      type: String,
      default: "",
      validator(value) {
        if (value && value.length) {
          let acceptedValues = ["", "notice", "mini"];
          return acceptedValues.indexOf(value) !== -1;
        }
        return -1;
      },
      description: 'Modal type (notice|mini|"") '
    },
    //#region Main props for filling panel with data
    profileRequestData: {
      type: ProfilePanelRequestData
    },
    campaignId: Number,
    //#endregion
    animationDuration: {
      type: Number,
      default: 500,
      description: "Modal transition duration"
    }
  },
  data() {
    return {
      lead: null,
      social: null,
      currentProfile: null,
      loading: {
        profile: false
      },
      bridgerPayActive: false,
      paymentAmount: 100,
      currenciesListForDropdown: [
        { code: "USD", description: "USD" + " - " + "United states dollar" },
        { code: "EUR", description: "EUR" + " - " + "Euro" },
        { code: "GBP", description: "GBP" + " - " + "Pound sterling" }
      ],
      selectedCurrency: null
    };
  },
  methods: {
    closePanel() {
      /* slide out panel */
      document.querySelector(".lead-panel .modal-dialog").style.right = `-100%`;

      /* reset panel */
      setTimeout(() => {
        this.$emit("update:show", false);
        this.$emit("close");
      }, this.animationDuration / 2);
    },
    getProfileLink() {},

    async getSingleProfile() {
      this.loading.profile = true;
      this.currentProfile = null;

      console.log("Profile request data: ", this.profileRequestData);

      // sh.sergey - workaround

      // sh.sergey - workaround. Currently we have different id values for search results
      // and editing lead inside campaign. So, for Youtube leads id should be channel id
      // and not lead id.
      let profileId =
        this.profileRequestData.social === SOCIALS.youtube.id
          ? this.profileRequestData.username || this.profileRequestData.id
          : this.profileRequestData.id;

      let leadPanelRequestData = new ProfilePanelRequestData(
        this.profileRequestData.social,
        this.profileRequestData.username,
        profileId
      );

      const data = await this.$store.dispatch(
        "influencer/getSingleProfile",
        leadPanelRequestData
      );

      this.social = this.profileRequestData.social;
      this.currentProfile = data.profile || data;

      this.loading.profile = false;
    },
    setActiveTab(e) {},
    async doPayment() {
      this.bridgerPayActive = false;

      await this.$store.dispatch("user/getAccount");

      if (!this.userCountry) {
        this.$store.dispatch("setAlert", {
          showAlert: true,
          content:
            "Hi there, in order to use the payment system - country of residence should be filled under -> settings/My account tab",
          alertClass: "warning"
        });
        return;
      }

      this.bridgerPayActive = true;
    }
  },
  computed: {
    ...mapState({
      userCountry: (state) => state.user.authUser.userAccount.country,
      userState: (state) => state.user.authUser.userAccount.state
    }),
    paymentCurrency: {
      get() {
        return this.selectedCurrency && this.selectedCurrency.code;
      }
    }
  },
  watch: {
    async show(val) {
      const documentClasses = document.body.classList;
      if (val) {
        documentClasses.add("modal-open");

        setTimeout(() => {
          document.querySelector(
            ".lead-panel .modal-dialog"
          ).style.right = `0px`;
        }, 100);

        await this.getSingleProfile();

        this.paymentAmount = 100;
        this.selectedCurrency = this.currenciesListForDropdown[0];
      } else {
        documentClasses.remove("modal-open");

        this.currentProfile = null;
        this.bridgerPayActive = false;
      }
    }
  }
};
</script>

<style lang="scss">
@import "../assets/scss/custom/functions";
@import "../assets/scss/custom/variables";

.lead-panel {
  .modal-dialog {
    height: 100%;
  }


  .lead-panel-content {
    display: flex;
    flex-direction: column;
    height: 100%;

    .profile-topbar {
      flex: 0 1 auto;

      background-color: #eceff1;
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      align-content: center;
      padding: 10px 30px;

      .profile-topbar-left {
        display: flex;

        .back-btn {
          cursor: pointer;
        }
      }

      .profile-topbar-right {
        display: flex;
      }
    }

    .lead-header {
      flex: 0 1 auto;
    }

    .lead-tabs {
      flex: 1 1 auto;
    }
  }
}

// Credit paymen tab
.credit-payment-tab {
  padding: 10px 50px;

  .currency-multiselect {
    margin-left: 10px;
    max-width: 250px;
  }
}

//Cryptocurrency tab
.cryptocurrency-tab {
  padding: 10px 50px;
}

.multiselect {
  //max-width: 250px;
  // margin-right: 5px;
  // margin: 5px;

  &__element > span,
  &__single {
    display: flex;
    align-items: center;

    .option__image {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
  }
}

.payment-actions {
  margin-left: 10px;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>