<template>
  <div id="chart">
    <apexchart
      width="250"
      height="100"
      type="bar"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import chart from "vue-apexcharts";

export default {
  name: "orders-returns",
  components: { apexchart: chart },
  props: {
    graphOptions: {
      type: Object,
      default: () => ({})
    }
  },

  data: function() {
    return {
      series: [
        {
          name: "Returns",
          data: this.graphOptions.allData[3],
        },
        {
          name: "Orders",
            data: this.graphOptions.allData[1],
        },
      ],
      chartOptions: {
        chart: {
          type: "bar",
          height: 180,
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        colors: ["#9C27B0", "#FF6F00"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],

        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },

        xaxis: {
          
           categories: this.graphOptions.chartData.labels,
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            show: false,
          },
        },
        yaxis: {
          type: "datetime",
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            show: false,
          },
        },

        grid: {
          row: {
            colors: ["transparent"],
          },
          
        },
      },
    };
  },
  mounted(){
    console.log('props- order returns',this.graphOptions)
  }
};
</script>
<style></style>
