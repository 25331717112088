<template>
  <div
    class="modal edit-lead-panel right-sliding-panel"
    :class="[
      { 'show d-block': show },
      { 'd-none': !show },
      { 'modal-mini': type === 'mini' }
    ]"
    @click.self="closePanel"
    v-show="show"
    tabindex="-1"
    role="dialog"
    :aria-hidden="!show"
  >
    <div class="modal-dialog mb-0">
      <div class="modal-content">
        <modal-topbar>
          <a slot="left" @click="closePanel">
            <i class="fas fa-chevron-left mr-1"></i>
            <span>Back</span>
          </a>
          <div v-if="lead && lead.data" slot="center" class="title">
            {{
              lead.social == socials.instagram.id
                ? lead.data.full_name
                : lead.data.nickname
            }}
          </div>
        </modal-topbar>

        <div
          v-if="lead"
          class="modal-topbar--profile d-flex flex-wrap flex-column p-3"
        >
          <LeadProfile :lead="lead">
            <div class="d-flex" slot="button">
              <base-button
                :disabled="!hasFullAccess('campaigns')"
                :disabledTooltip="getPermissionErrorMessage('campaigns:full')"
                class="btn-edit-lead"
                type="primary"
                @click="editLead(lead)"
                >Edit influencer</base-button
              >
              <base-button
                :disabled="!hasFullAccess('campaigns')"
                :disabledTooltip="getPermissionErrorMessage('campaigns:full')"
                class="btn-remove-lead"
                type="danger"
                @click="removeLead(lead)"
                >Remove from campaign</base-button
              >
            </div>
          </LeadProfile>

          <div class="buttons-container my-2 flex-center w-100">
            <base-button
              :disabled="!stageIndex || !hasFullAccess('campaigns')"
              :disabledTooltip="
                !hasFullAccess('campaigns')
                  ? getPermissionErrorMessage('campaigns:full')
                  : undefined
              "
              type="danger"
              class="mx-3"
              @click="moveLeadToStage('backward')"
              >Move Back</base-button
            >
            <base-button
              :disabled="
                stageIndex > campaign.stages_order.length - 1 ||
                !hasFullAccess('campaigns')
              "
              :disabledTooltip="
                !hasFullAccess('campaigns')
                  ? getPermissionErrorMessage('campaigns:full')
                  : undefined
              "
              type="success"
              class="mx-3"
              @click="moveLeadToStage('forward')"
              >Move Forward</base-button
            >
          </div>
        </div>

        <!-- Displaying lead stages and current stage -->
        <div v-if="lead && lead.stage >= 0" class="pipeline-progress">
          <ul
            class="pipeline-progress__labels"
            :style="{ left: `calc(50% - 50px - ${100 * stageIndex}px)` }"
          >
            <li
              v-for="stageId in campaign.stages_order"
              :key="stageId"
              :class="`pipeline-progress__labels__elem ${
                stageId == lead.stage.toString() ? 'active' : ''
              }`"
            >
              {{ campaign.stage_names[stageId] }}
            </li>
          </ul>
          <ul class="pipeline-progress__bars">
            <li
              v-for="(stageId, index) in campaign.stages_order"
              :key="stageId"
              :style="{
                width: `${100 / campaign.stages_order.length}%`
              }"
              :class="index <= stageIndex ? 'active' : ''"
            >
              <a></a>
            </li>
          </ul>
        </div>

        <!-- Tabs for lead profile (for now Communication and Tracking) -->
        <div v-if="lead" class="modal-body main-content pt-0">
          <tabs :pills="false" ref="tabs" @setActiveTab="setActiveTab">
            <tab-pane
              id="communication"
              title="Communication"
              :isHidden="!hasViewAccess('emails')"
            >
              <template v-if="leadEmails.length">
                <template
                  v-if="
                    emails && (emails.gmail || emails.outlook) && selectedEmail
                  "
                >
                  <div class="row">
                    <div class="col flex-middle flex-wrap">
                      <div class="flex-middle">
                        <label class="mr-3 mb-0">Influencer email</label>
                        <base-dropdown class="nav-item mr-3" position="left">
                          <a
                            slot="title"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            class="
                              cursor-pointer
                              flex-middle-center
                              text-overflow-ellipsis
                              py-2
                            "
                          >
                            {{ selectedEmail.lead_email
                            }}<i class="fas fa-angle-down ml-2"></i>
                          </a>
                          <li
                            v-for="(emailAddress, index) in leadEmails"
                            :key="index"
                            class="dropdown-item cursor-pointer px-3 py-3"
                            @click="
                              selectedEmail = {
                                ...selectedEmail,
                                lead_email: emailAddress
                              }
                            "
                          >
                            {{ emailAddress }}
                          </li>
                        </base-dropdown>
                      </div>
                      <div class="flex-middle">
                        <label class="mr-3 mb-0">Your email</label>
                        <base-dropdown class="nav-item" position="right">
                          <a
                            slot="title"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            class="
                              cursor-pointer
                              flex-middle-center
                              text-overflow-ellipsis
                              py-2
                            "
                          >
                            <img
                              class="icon-size16 mr-2"
                              :src="availableEmails[selectedEmail.name].image"
                              :alt="availableEmails[selectedEmail.name].name"
                            />
                            {{ selectedEmail.address
                            }}<i class="fas fa-angle-down ml-2"></i>
                          </a>
                          <template v-for="(item, id) in emails">
                            <template v-for="(email, index) in emails[id]">
                              <li
                                :key="id + index"
                                class="dropdown-item cursor-pointer px-3 py-3"
                                @click="
                                  selectedEmail = {
                                    ...selectedEmail,
                                    ...email,
                                    name: id
                                  }
                                "
                              >
                                <img
                                  class="icon-size16 mr-2"
                                  :src="availableEmails[id].image"
                                  :alt="availableEmails[id].name"
                                />
                                {{ email.address }}
                              </li>
                            </template>
                          </template>
                        </base-dropdown>
                      </div>
                      <base-button
                        v-if="
                          hasFullAccess('emails') &&
                          !loading.threads &&
                          threads &&
                          Object.keys(threads) &&
                          Object.keys(threads).length
                        "
                        class="btn-start-new-conversation"
                        type="primary"
                        @click="startNewConversation"
                      >
                        Start new conversation
                      </base-button>
                    </div>
                  </div>

                  <div
                    v-if="
                      threads &&
                      Object.keys(threads) &&
                      Object.keys(threads).length
                    "
                    class="thread-container mt-2"
                  >
                    <a
                      v-for="(thread, id) in threads"
                      :key="id"
                      @click="selectCard(campaignLeadContent.email, id)"
                    >
                      <card class="thread mb-2">
                        <div>
                          {{ thread[thread.length - 1].subject }}
                        </div>
                        <div class="card-time">
                          {{ thread[thread.length - 1].modified_at | FullDate }}
                        </div>
                      </card>
                    </a>
                  </div>
                  <div
                    v-else-if="loading.threads"
                    class="flex-middle-center py-5"
                  >
                    <base-spinner></base-spinner>
                    <span class="ml-2">Loading...</span>
                  </div>
                </template>
                <p class="py-4 w-100 text-center" v-else-if="!loading.emails">
                  <router-link
                    v-if="hasFullAccess('integrations')"
                    to="/app/settings/email"
                    >Connect</router-link
                  >
                  <a
                    v-else
                    v-b-tooltip.hover
                    href="javascript:;"
                    :title="getPermissionErrorMessage('campaigns:full')"
                    >Connect</a
                  >
                  your email address to manage your outreach from
                  <strong>Ninja Influence</strong>.
                </p>
              </template>
              <p v-else class="py-4 w-100 text-center">
                This influencer has no emails. You can add them manually by
                editing the influencer data
                <a
                  v-if="hasFullAccess('campaigns')"
                  href="#"
                  @click.prevent="editLead(lead)"
                  >here</a
                >
                <a
                  v-else
                  href="javascript:;"
                  v-b-tooltip.hover
                  :title="getPermissionErrorMessage('campaigns:full')"
                  >here</a
                >
                .
              </p>

              <div
                v-show="
                  hasViewAccess('emails') &&
                  (!threads ||
                    !Object.keys(threads) ||
                    !Object.keys(threads).length) &&
                  !loading.threads &&
                  emails &&
                  (emails.gmail || emails.outlook) &&
                  selectedEmail &&
                  leadEmails.length
                "
                class="mt-3 w-100"
              >
                <div class="mt-2 flex-middle tags">
                  <label class="mr-2 mb-0">To</label>
                  <multiselect
                    v-if="lead"
                    placeholder="Input influencer email(s)"
                    v-model="to"
                    :multiple="true"
                    :taggable="true"
                    track-by="address"
                    label="address"
                    :options="toOptions"
                    :disabled="true"
                    tagPlaceholder="Press enter to select"
                  />
                </div>
                <div class="mt-2 flex-middle tags">
                  <label class="mr-3 mb-0">Cc</label>
                  <multiselect
                    v-if="lead"
                    placeholder="Input Cc email(s)"
                    v-model="cc"
                    :multiple="true"
                    :taggable="true"
                    track-by="address"
                    label="address"
                    :options="ccOptions"
                    @tag="addCCOption"
                    tagPlaceholder="Press enter to select"
                  />
                </div>
                <div class="my-2 flex-middle">
                  <label class="mr-3 mb-0">Subject</label>
                  <base-input class="mb-0 w-100" v-model="subject"></base-input>
                </div>
                <quill-editor
                  ref="emailQuillEditor"
                  v-model="content"
                  :option="editorOption"
                ></quill-editor>
                <div class="flex-center mt-2">
                  <base-button
                    class="btn-send btn-w-25 flex-middle-center"
                    type="primary"
                    @click="send"
                    :isBusy="loading.send"
                  >
                    Send
                  </base-button>
                </div>
              </div>
            </tab-pane>

            <tab-pane id="tracking" title="Tracking">
              <h4 class="mt-4">Coupons</h4>
              <p v-if="!Object.keys(integrations).length">
                You can connect your e-commerce platform and start tracking
                revenue and sales conversion rate.
                <!-- TODO: REMOVE IT -->
                <router-link
                  v-if="hasFullAccess('integrations')"
                  to="/app/settings/integrations"
                >
                  Connect
                </router-link>
                <a
                  v-else
                  v-b-tooltip.hover
                  href="javascript:;"
                  :title="getPermissionErrorMessage('campaigns:full')"
                  >Connect</a
                >
              </p>
              <template v-else>
                <template v-if="coupons.length">
                  <template v-for="(coupon, index) in coupons">
                    <card class="mb-2" :key="index">
                      <div
                        class="card-content"
                        @click="
                          selectCard(campaignLeadContent.coupon, coupon.code)
                        "
                      >
                        <div class="flex-between">
                          <div class="d-flex">
                            <img
                              class="integration-icon"
                              :src="
                                availableIntegrations[coupon.integration_name]
                                  .image
                              "
                              :alt="
                                availableIntegrations[coupon.integration_name]
                                  .name
                              "
                            />
                            <div class="coupon-name">
                              <span
                                >{{ coupon.code }} ({{
                                  coupon.integration_url | Domain
                                }})</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-time">{{ coupon.updated_at }}</div>
                      <a
                        class="btn-delete"
                        v-on:click="deleteCoupon(coupon)"
                        title="Delete this coupon"
                        alt="Delete this coupon"
                      >
                        <i class="fas fa-times-circle"></i>
                      </a>
                    </card>
                  </template>
                </template>
                <p v-else>
                  You can add coupon from the
                  <i class="fas fa-plus"></i>
                  button below.
                </p>
              </template>
              <h4 class="mt-4">Tracking Links</h4>
              <card v-for="link in trakingLinks" :key="link.id" class="mb-2">
                <div
                  class="card-content"
                  @click="selectCard(campaignLeadContent.tracking, link.id)"
                >
                  {{ link.url }}
                </div>
                <div class="card-cost">
                  {{ link.cost }} {{ campaign.currency }}
                </div>
                <a
                  class="btn-delete"
                  v-on:click="deleteContentPiece(link.id)"
                  title="Delete this link"
                  alt="Delete this link"
                >
                  <i class="fas fa-times-circle"></i>
                </a>
              </card>
              <p v-if="!trakingLinks.length">
                You can add content that will be tracked automatically from the
                <i class="fas fa-plus"></i>
                button below.
              </p>
              <h4 class="mt-4">Notes</h4>
              <card v-for="note in notesList" :key="note.id" class="mb-2">
                <div
                  class="card-content"
                  v-html="note.content"
                  @click="selectCard(campaignLeadContent.notes, note)"
                ></div>
                <div class="card-time">
                  {{ note.updated_at | FullDate }}
                </div>
              </card>
              <p v-if="!notesList.length">
                You can save additional details about this influencer by
                creating notes with the <i class="fas fa-plus"></i> button
                below.
              </p>
            </tab-pane>
          </tabs>

          <div v-if="selectedContentTabIndex" class="floating-menu">
            <base-dropdown
              v-if="hasFullAccess('campaigns')"
              class="nav-item"
              position="left"
              direction="up"
            >
              <a
                slot="title"
                class="nav-link nav-link-icon"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div class="floating-menu__button">
                  <i class="fas fa-plus"></i>
                </div>
              </a>
              <template v-if="Object.keys(integrations).length">
                <a
                  class="dropdown-item px-3 py-1"
                  @click="selectCard(campaignLeadContent.coupon, null)"
                  >Coupon</a
                >
                <div class="dropdown-divider"></div>
              </template>
              <a
                class="dropdown-item px-3 py-1"
                @click="selectCard(campaignLeadContent.tracking, null)"
                >Tracking Links</a
              >
              <div class="dropdown-divider"></div>
              <a
                class="dropdown-item px-3 py-1"
                @click="selectCard(campaignLeadContent.notes, null)"
                >Note</a
              >
            </base-dropdown>
            <a
              v-else
              v-b-tooltip.hover
              :title="getPermissionErrorMessage('campaigns:full')"
              class="nav-link nav-link-icon"
            >
              <div class="floating-menu__button">
                <i class="fas fa-plus"></i>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getEngagementRateClass, getPermissionErrorMessage } from "@/utils";
import {
  CAMPAIGN_LEAD_CONTENT,
  MESSAGES,
  SOCIALS,
  ENGAGEMENT_CHART_DATA,
  AVAILABLE_INTEGRATIONS,
  AVAILABLE_EMAILS
} from "@/constants";
import { mapState, mapGetters } from "vuex";
/* components */
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import Multiselect from "vue-multiselect";
import LeadProfile from "@/components/Profiles/ProfileLead";
/* components */

export default {
  name: "edit-lead-panel",
  components: {
    quillEditor,
    Multiselect,
    LeadProfile
  },
  props: {
    show: Boolean,
    type: {
      type: String,
      default: "",
      validator(value) {
        if (value && value.length) {
          let acceptedValues = ["", "notice", "mini"];
          return acceptedValues.indexOf(value) !== -1;
        }
        return -1;
      },
      description: 'Modal type (notice|mini|"") '
    },
    disabled: Boolean,
    animationDuration: {
      type: Number,
      default: 500,
      description: "Modal transition duration"
    },
    leadId: {
      type: Number,
      description: "Selected lead id"
    },
    campaign: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      lead: (state) => state.campaign.lead,
      trakingLinks: (state) => state.campaign.content_pieces,
      coupons: (state) => state.campaign.coupons,
      integrations: (state) => state.integration.integrations,
      emails: (state) => state.integration.emails,
      threads: (state) => state.campaign.threads,
      notesList: (state) => {
        var notesList = JSON.parse(JSON.stringify(state.campaign.notes));
        return notesList.sort((a, b) => {
          var dateA = new Date(a.updated_at);
          var dateB = new Date(b.updated_at);
          return dateA < dateB ? 1 : -1;
        });
      },
      leadEmails: (state) => {
        if (
          state.campaign.lead &&
          state.campaign.lead.data &&
          state.campaign.lead.data.emails &&
          state.campaign.lead.data.emails.length
        ) {
          return state.campaign.lead.data.emails;
        } else {
          return [];
        }
      },
      showAlert: (state) => state.alert.showAlert
    }),
    ...mapGetters({
      hasFullAccess: "user/hasFullAccess",
      hasViewAccess: "user/hasViewAccess"
    }),
    stageIndex: {
      get() {
        return this.campaign && this.lead.stage
          ? this.campaign.stages_order.indexOf(this.lead.stage.toString())
          : 0;
      }
    },
    editor() {
      return this.$refs.emailQuillEditor.quill;
    }
  },
  mounted() {},
  beforeDestroy() {},
  data() {
    return {
      getEngagementRateClass: getEngagementRateClass,
      getPermissionErrorMessage: getPermissionErrorMessage,
      socials: SOCIALS,
      availableIntegrations: AVAILABLE_INTEGRATIONS,
      availableEmails: AVAILABLE_EMAILS,
      selectedEmail: null,
      cc: [],
      ccOptions: [],
      to: [],
      toOptions: [],
      content: "",
      editorOption: {
        theme: "snow",
        placeholder: "Your new placeholder!",
        modules: {}
      },
      subject: "",
      loading: {
        lead: false,
        moveLead: false,
        integrations: false,
        coupons: false,
        emails: false,
        threads: false,
        send: false
      },
      leadPictureUrl: null,
      selectedContentTabIndex: 0,
      campaignLeadContent: CAMPAIGN_LEAD_CONTENT,
      engagementRateChartData: ENGAGEMENT_CHART_DATA
    };
  },
  methods: {
    closePanel() {
      /* slide out panel */
      document.querySelector(
        ".edit-lead-panel .modal-dialog"
      ).style.right = `-100%`;
      /* reset panel */
      setTimeout(() => {
        this.$emit("update:show", false);
        this.$emit("close");
      }, this.animationDuration / 2);
    },
    async send() {
      this.loading.send = true;
      try {
        await this.$store.dispatch("campaign/sendEmail", {
          name: this.selectedEmail.name,
          address: this.selectedEmail.address,
          lead_id: this.lead.id,
          send_meta: {
            subject: this.subject,
            body: this.content,
            to_recipients: [
              {
                address: this.selectedEmail.lead_email,
                name: this.selectedEmail.lead_email
              }
            ],
            cc_recipients: this.cc
          }
        });
        this.loading.send = false;

        this.subject = "";
        this.content = "";

        this.$store.dispatch("setAlert", {
          showAlert: true,
          content: "Message sent!",
          alertClass: "success"
        });
      } catch (error) {
        this.loading.send = false;
        if (
          error.response.status === 409 &&
          error.response.data.hasOwnProperty("user_message_id")
        ) {
          const email = error.response.data
            ? error.response.data.metadata
            : null;
          if (
            error.response.data.user_message_id[1] === "email__invalid-auth"
          ) {
            this.$emit("invalidEmailToken", email);
          } else if (!this.showAlert) {
            const message = (
              MESSAGES[error.response.data.user_message_id[1]] ||
              MESSAGES["common-500"]
            ).replace(
              "{{email_provider}}",
              email ? AVAILABLE_EMAILS[email.name].name : "The email provider"
            );
            this.$store.dispatch("setAlert", {
              showAlert: true,
              content: message,
              alertClass: "danger"
            });
          }
        } else if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["common-500"],
            alertClass: "danger"
          });
        }
      }
    },
    addCCOption(newEmail) {
      this.ccOptions.push({ address: newEmail, name: newEmail });
      this.cc.push({ address: newEmail, name: newEmail });
    },
    selectCard(type, param) {
      this.$emit("selectCard", { type, param });
    },
    editLead(lead) {
      this.$emit("editLead", lead);
    },
    removeLead(lead) {
      this.$emit("removeLead", lead);
    },
    getLeadPictureUrl(lead) {
      let img = new Image();
      var self = this;
      img.onload = () => {
        self.leadPictureUrl = lead.profile_pic;
      };
      img.src = lead.profile_pic;
    },
    async getSingleLead() {
      this.loading.lead = true;
      try {
        await this.$store.dispatch("campaign/getSingleLead", {
          campaign_id: this.campaign.id,
          lead_id: this.leadId
        });
        this.loading.lead = false;
      } catch {
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["campaign__get_single_campaign-failed"],
            alertClass: "danger"
          });
        }
        this.loading.lead = false;
      }
    },
    async deleteContentPiece(contentId) {
      try {
        await this.$store.dispatch("campaign/deleteLeadContentPiece", {
          campaign_id: this.campaign.id,
          lead_id: this.leadId,
          content_id: contentId
        });
        this.$store.dispatch("setAlert", {
          showAlert: true,
          content: MESSAGES["campaign__delete_lead_content_piece-success"],
          alertClass: "success"
        });
      } catch {
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["campaign__delete_lead_content_piece-failed"],
            alertClass: "danger"
          });
        }
      }
    },
    async deleteCoupon(coupon) {
      const { integration_id, code } = coupon;
      this.loading.coupons = true;
      try {
        await this.$store.dispatch("campaign/deleteCoupon", {
          campaign_id: this.campaign.id,
          lead_id: this.leadId,
          integration_id: integration_id,
          code: code
        });
        this.isChanged = true;
        this.loading.coupons = false;
      } catch {
        this.loading.coupons = false;
        this.$store.dispatch("setAlert", {
          showAlert: true,
          content: MESSAGES["common-500"],
          alertClass: "danger"
        });
      }
    },
    async moveLeadToStage(action) {
      this.loading.moveLead = true;
      try {
        const { data } = await this.$store.dispatch(
          "campaign/moveLeadToStage",
          {
            campaign_id: this.campaign.id,
            lead_id: this.leadId,
            action: action
          }
        );
        this.$store.dispatch("campaign/setLeadStage", data.stage_id);
        this.$emit("moveToStage", data);
        this.loading.moveLead = false;
      } catch {
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["common-500"],
            alertClass: "danger"
          });
        }
        this.loading.moveLead = false;
      }
    },
    async getIntegrations() {
      this.loading.integrations = true;
      try {
        await this.$store.dispatch("integration/getIntegrations");
        this.loading.integrations = false;
      } catch {
        this.loading.integrations = false;
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["integration__get_integrations-failed"],
            alertClass: "danger"
          });
        }
      }
    },
    async getConnectedEmails() {
      this.loading.emails = true;
      try {
        await this.$store.dispatch("integration/getConnectedEmails");
        this.loading.emails = false;
      } catch (error) {
        this.loading.emails = false;
        if (error.response.status === 409) {
          this.$emit(
            "invalidEmailToken",
            error.response.data ? error.response.data.metadata : null
          );
        } else {
          if (!this.showAlert) {
            this.$store.dispatch("setAlert", {
              showAlert: true,
              content: MESSAGES["common-500"],
              alertClass: "danger"
            });
          }
        }
      }
    },
    startNewConversation() {
      this.$store.dispatch("campaign/setConversation", {
        from: {
          address: this.selectedEmail.address,
          name: this.selectedEmail.name
        },
        to: [
          {
            address: this.selectedEmail.lead_email,
            name: this.selectedEmail.lead_email
          }
        ],
        cc: [],
        subject: "",
        thread_id: null,
        conversation_index: null,
        is_reply: false
      });
      this.$emit("startNewConversation");
    },
    async getThreads() {
      this.loading.threads = true;
      try {
        this.$store.dispatch("campaign/setConversation", {
          from: {
            address: this.selectedEmail.address,
            name: this.selectedEmail.name
          },
          to: [
            {
              address: this.selectedEmail.lead_email,
              name: this.lead.username
            }
          ],
          cc: [],
          subject: "",
          thread_id: null,
          conversation_index: null,
          is_reply: false
        });
        this.$store.dispatch("campaign/setThreads", null);
        await this.$store.dispatch("campaign/getThreads", {
          name: this.selectedEmail.name,
          address: this.selectedEmail.address,
          lead_email: this.selectedEmail.lead_email
        });
        this.loading.threads = false;
      } catch (error) {
        this.loading.threads = false;
        if (error.response.status === 409) {
          this.$emit(
            "invalidEmailToken",
            error.response.data ? error.response.data.metadata : null
          );
        } else {
          if (!this.showAlert) {
            this.$store.dispatch("setAlert", {
              showAlert: true,
              content: MESSAGES["common-500"],
              alertClass: "danger"
            });
          }
        }
      }
    },
    setActiveTab(e) {
      this.selectedContentTabIndex = e;
    }
  },

  watch: {
    show(val) {
      const documentClasses = document.body.classList;
      if (val) {
        if (typeof this.leadEmails === "string") this.ccOptions = [];
        else
          this.ccOptions = this.leadEmails.map((e) => {
            return { address: e, name: e };
          });
        this.cc = [];
        this.leadPictureUrl = null;
        this.selectedEmail = null;
        this.getSingleLead();
        this.getIntegrations();
        if (this.hasViewAccess("emails")) {
          this.getConnectedEmails();
        } else {
          this.$refs.tabs.findAndActivateTab("Tracking");
        }
        /* slide in panel*/
        documentClasses.add("modal-open");
        setTimeout(() => {
          this.editor.root.setAttribute("data-placeholder", "");
          if (document.querySelector(".edit-lead-panel button.ql-image")) {
            document.querySelector(
              ".edit-lead-panel button.ql-image"
            ).style.display = "none";
            document.querySelector(
              ".edit-lead-panel button.ql-video"
            ).style.display = "none";
          }
          document.querySelector(
            ".edit-lead-panel .modal-dialog"
          ).style.right = `0px`;
        }, 100);
      } else {
        /* reset panel */
        documentClasses.remove("modal-open");
        document.querySelector(".edit-lead-panel").scrollTop = 0;
      }
    },
    lead(val) {
      this.getLeadPictureUrl(val);
    },
    leadEmails(val) {
      this.selectedEmail = {
        ...(this.selectedEmail || {}),
        lead_email: val[0]
      };
    },
    emails(val) {
      if (val) {
        const selectedEmailId =
          val.gmail && val.gmail.length
            ? AVAILABLE_EMAILS.gmail.id
            : AVAILABLE_EMAILS.outlook.id;
        this.selectedEmail = val[selectedEmailId]
          ? {
              ...(this.selectedEmail || {}),
              ...val[selectedEmailId][0],
              name: selectedEmailId
            }
          : null;
      }
    },
    selectedEmailAddress(val) {
      if (val && this.selectedEmail) {
        this.getThreads();
      }
    },
    selectedEmail(val) {
      if (val && val.address && val.name && val.lead_email) {
        this.getThreads();
        this.to = [{ address: val.lead_email }];
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.modal.show {
  background-color: rgba(255, 255, 255, 0.7);
}
.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select {
  background: transparent !important;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
