<template>
  <div class="inbox-filter">
    <div class="flex-column filter-item px-3 pt-3">
      <div class="mb-2 filter-item-wrapper">
        <label class="mb-0">Campaign</label>
        <multiselect
          placeholder="Campaigns"
          :searchable="true"
          selectLabel
          selectedLabel="✓"
          deselectLabel=""
          label="name"
          :allowEmpty="false"
          v-model="currentCampaign"
          :options="allCampaigns"
        />
      </div>
    </div>
    <div
      v-if="allStages && allStages.length"
      class="flex-column filter-item px-3"
    >
      <div class="mb-2 filter-item-wrapper">
        <label class="mb-0">Stage</label>
        <multiselect
          placeholder="Stage"
          :searchable="true"
          selectLabel
          selectedLabel="✓"
          deselectLabel=""
          label="label"
          :allowEmpty="false"
          v-model="currentStage"
          :options="allStages"
        />
      </div>
    </div>
    <div class="flex-column filter-item px-3">
      <div class="mb-2 filter-item-wrapper">
        <label class="mb-0">Status</label>
        <multiselect
          placeholder="Status"
          :searchable="true"
          :allowEmpty="false"
          selectLabel
          selectedLabel="✓"
          deselectLabel="✕"
          label="label"
          track-by="id"
          v-model="currentStatus"
          :options="allStatus"
        />
      </div>
    </div>
    <div class="flex-column filter-item px-3">
      <div class="mb-2 filter-item-wrapper">
        <label class="mb-0">Email Account</label>
        <multiselect
          placeholder="Email Accounts"
          :searchable="true"
          selectLabel
          selectedLabel="✓"
          deselectLabel=""
          label="email"
          :allowEmpty="false"
          v-model="currentEmailAccount"
          :options="allEmailAccounts"
        />
      </div>
    </div>
    <div class="flex-column filter-item px-3 mt-3">
      <div class="mb-2 filter-item-wrapper">
        <base-checkbox v-model="hasEmail" class="custom-control-alternative"
          >Has email address</base-checkbox
        >
      </div>
    </div>

    <div
      v-if="leads && leads.length"
      class="flex-column filter-item leads-container"
      v-on:scroll="scrollLeads"
    >
      <div ref="cardContainer">
        <card
          v-for="lead in leads"
          :key="lead.id"
          :class="
            (selectedLead && selectedLead.id == lead.id ? 'selected' : '') +
            (lead.unread_messages ? ' unread' : '')
          "
        >
          <!-- instagram -->
          <template v-if="lead.social == socials.instagram.id">
            <div class="social-icon">
              <img
                :src="socials[lead.social].image"
                :alt="socials.instagram.name"
              />
            </div>
            <div class="d-flex w-100" @click="onSelectLead(lead)">
              <div class="avatar ml-2 mt-1">
                <avatar
                  :username="
                    lead.data.full_name ? lead.data.full_name : lead.username
                  "
                  :size="32"
                  :src="lead.profile_pic"
                >
                </avatar>
              </div>
              <div class="flex-column ml-2">
                <div class="profile-name">{{ lead.data.full_name }}</div>
                <div class="profile-username">@{{ lead.username }}</div>
                <div class="profile-followers">
                  <span>{{ lead.data.followers | BigNumber }} followers</span>
                </div>
              </div>
            </div>
          </template>
          <!-- youtube -->
          <template v-else-if="lead.social == socials.youtube.id">
            <div class="social-icon">
              <img
                :src="socials[lead.social].image"
                :alt="socials.youtube.name"
              />
            </div>
            <div class="d-flex w-100" @click="onSelectLead(lead)">
              <div class="avatar ml-2 mt-1">
                <avatar
                  :username="lead.data.title ? lead.data.title : lead.username"
                  :size="32"
                  :src="lead.profile_pic"
                >
                </avatar>
              </div>
              <div class="flex-column ml-2">
                <div class="profile-name">{{ lead.data.title }}</div>
                <div class="profile-username">@{{ lead.username }}</div>
                <div class="profile-followers">
                  <span
                    >{{ lead.data.subscribers | BigNumber }} subscribers</span
                  >
                </div>
              </div>
            </div>
          </template>

          <!-- tiktok -->
          <template v-else-if="lead.social == socials.tiktok.id">
            <div class="social-icon">
              <img
                :src="socials[lead.social].image"
                :alt="socials.tiktok.name"
              />
            </div>
            <div class="d-flex w-100" @click="onSelectLead(lead)">
              <div class="avatar ml-2 mt-1">
                <avatar
                  :username="
                    lead.data.nickname ? lead.data.nickname : lead.username
                  "
                  :size="32"
                  :src="lead.profile_pic"
                >
                </avatar>
              </div>
              <div class="flex-column ml-2">
                <div class="profile-name">{{ lead.data.nickname }}</div>
                <div class="profile-username">@{{ lead.username }}</div>
                <div class="profile-followers">
                  <span>{{ lead.data.fans | BigNumber }} fans</span>
                </div>
              </div>
            </div>
          </template>
          <div
            v-if="lead.unread_messages"
            class="lead__badge"
            v-b-tooltip.hover
            title="You have unread messages"
          >
            <i class="fas fa-envelope"></i>
          </div>
        </card>

        <div v-if="loading.leads" class="text-center py-2">Loading...</div>
      </div>
    </div>
    <div v-else-if="!loading.leads" class="flex-column filter-item px-3">
      <p class="w-100 text-center py-4">
        There are no leads matching the filters.
      </p>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { MESSAGES, SOCIALS } from "@/constants";

/* components */
import Multiselect from "vue-multiselect";
import Avatar from "vue-avatar";

export default {
  name: "inbox-filter",
  components: {
    Multiselect,
    Avatar
  },
  props: {
    initialQuery: Object
  },
  provide() {
    return {
      autoClose: this.autoClose
    };
  },
  computed: {
    ...mapState({
      inbox: (state) => state.inbox,
      inboxFilter: (state) => state.inboxFilter,
      allCampaigns: (state) => {
        return [{ name: "Any" }, ...state.inbox.campaigns];
      },
      allEmailAccounts: (state) => {
        return [{ email: "Any" }, ...state.integration.emailAccounts];
      },
      leads: (state) => state.inbox.leads
      //messages: (state) => state.inbox.messages
    }),
    selectedLead: {
      set(value) {
        this.$store.dispatch("inbox/setLead", value);
      },
      get() {
        return this.inbox.currentLead;
      }
    },
    currentStage: {
      set(value) {
        this.$store.dispatch("inbox/setStage", value);
      },
      get() {
        return this.inbox.currentStage;
      }
    },
    currentStatus: {
      set(value) {
        this.$store.dispatch("inbox/setStatus", value);
      },
      get() {
        return this.inbox.currentStatus;
      }
    },
    currentCampaign: {
      set(value) {
        this.$store.dispatch("inbox/setCampaign", value);
      },
      get() {
        return this.inbox.currentCampaign;
      }
    },
    currentEmailAccount: {
      set(value) {
        this.$store.dispatch("inbox/setEmailAccount", value);
      },
      get() {
        return this.inbox.currentEmailAccount;
      }
    },
    hasEmail: {
      set(value) {
        this.$store.dispatch("inbox/setHasEmail", value);
      },
      get() {
        return this.inbox.hasEmail;
      }
    }
  },
  data() {
    return {
      socials: SOCIALS,
      allStatus: [
        { label: "Any", id: undefined },
        { label: "Unread", id: "unread" },
        { label: "Read", id: "read" }
      ],
      allStages: null,
      reachedEnd: false,
      filtersReady: false,

      thread: null,
      conversation: null,

      currentFilter: {},

      page: 0,
      mounted: false,
      loading: {
        campaigns: false,
        leads: false,
        messagess: false
      }
    };
  },
  async mounted() {
    this.$nextTick(async function () {
      await this.getCampaigns();

      const index = this.allStatus
        .map((e) => e.id)
        .indexOf(this.initialQuery.status);
      this.currentStatus = this.allStatus[index > -1 ? index : 0];
      await this.$store.dispatch("inbox/setStatus", this.currentStatus);

      if (Object.keys(this.initialQuery).length) {
        this.hasEmail = this.initialQuery.has_emails ? true : false;
      } else {
        this.hasEmail = true;
      }
      await this.$store.dispatch("inbox/setHasEmail", this.hasEmail);

      this.mounted = true;

      await this.getLeads(true);
    });
  },
  beforeDestroy() {},
  methods: {
    /* need to confirm the right respose from api for lazy loading */
    // eslint-disable-next-line no-unused-vars
    scrollLeads(event) {
      const element = event.target;
      const scrollTop = element.scrollTop;
      const scrollHeight = this.$refs.cardContainer.clientHeight;
      const scrollTriger = 1500;
      if (scrollHeight - scrollTop < scrollTriger && !this.loading.leads) {
        this.getLeads(false);
      }
    },
    async getCampaigns() {
      this.loading.campaigns = true;
      try {
        await this.$store.dispatch("inbox/getCampaigns");
        this.loading.campaigns = false;
      } catch {
        this.loading.campaigns = false;
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["common-500"],
            alertClass: "danger"
          });
        }
      }
    },
    async getLeads(isNew) {
      console.log("getLeads executed");

      this.reachedEnd = isNew ? false : this.reachedEnd;
      if (this.reachedEnd) return;
      const newPage = isNew ? 1 : this.page + 1;
      this.loading.leads = true;

      try {
        const data = await this.$store.dispatch("inbox/getLeads", {
          campaign_id: (this.currentCampaign && this.currentCampaign.id) || "",
          stage_id: (this.currentStage && this.currentStage.id) || "",
          unread: this.currentStatus && this.currentStatus.id,
          has_emails: this.hasEmail,
          email:
            (this.currentEmailAccount && this.currentEmailAccount.email) || "",
          page: newPage
        });
        this.loading.leads = false;
        if (data) {
          this.$store.dispatch("inbox/setLeads", {
            page: newPage,
            data: data
          });
          this.page = data.length ? newPage : this.page;
          this.reachedEnd = data.length ? false : true;
        }
      } catch {
        this.loading.leads = false;
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["common-500"],
            alertClass: "danger"
          });
        }
      }
    },
    onSelectLead(lead) {
      this.$store.dispatch("inbox/selectLead", lead);
    }
  },
  watch: {
    async allCampaigns(val) {
      if (val && val.length) {
        const index = val
          .map((e) => e.id + "")
          .indexOf(this.initialQuery.campaign);
        this.currentCampaign = val[index === -1 ? 0 : index];
        await this.$store.dispatch("inbox/setCampaign", this.currentCampaign);
      }
    },
    async allEmailAccounts(val) {
      if (val && val.length) {
        const index = val
          .map((e) => e.id + "")
          .indexOf(this.initialQuery.userEmail);
        this.currentEmailAccount = val[index === -1 ? 0 : index];
        await this.$store.dispatch(
          "inbox/setEmailAccount",
          this.currentEmailAccount
        );
      }
    },
    //#region Watching filters values
    currentCampaign(val) {
      console.log("currentCampaign changed");

      if (!this.mounted) 
        return;
      if (val && val.stage_names && val.stage_names[0]) {
        this.allStages = [{ name: "Any", size: 0, label: "Any (0)" }];

        val.stages_order.forEach((stageId) => {
          this.allStages.push({
            id: stageId,
            name: val.stage_names[stageId],
            size: val.stage_sizes[stageId],
            label: `${val.stage_names[stageId]} (${val.stage_sizes[stageId]})`
          });
          this.allStages[0].size += val.stage_sizes[stageId] * 1;
        });

        this.allStages[0].label = `${this.allStages[0].name} (${this.allStages[0].size})`;

        const index = this.allStages
          .map((e) => e.id + "")
          .indexOf(this.initialQuery.stage);
        this.currentStage = this.allStages[index === -1 ? 0 : index];
      } else {
        this.allStages = null;
        this.currentStage = null;
      }
      this.getLeads(true);
    },
    currentStage() {
      console.log("currentStage changed");
      if (this.mounted) this.getLeads(true);
    },
    currentEmailAccount() {
      console.log("currentEmailAccount changed");
      if (this.mounted) this.getLeads(true);
    },
    currentStatus() {
      console.log("currentStatus changed");
      if (this.mounted) this.getLeads(true);
    },
    hasEmail() {
      console.log("hasEmail changed");
      if (this.mounted) this.getLeads(true);
    },
    //#endregion
    leads(val) {
      if (val && val.length && this.initialQuery.lead) {
        const index = val.map((e) => e.id + "").indexOf(this.initialQuery.lead);
        if (index > -1) {
          this.onSelectLead(val[index]);
        } else {
          // need to additional processing
        }
      }
    }
  }
};
</script>

<style lang="scss">
.inbox-filter {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .custom-checkbox {
    padding-left: 2rem;
  }

  .custom-control-alternative .custom-control-label::before {
    border-radius: 4px;
    left: -2rem;
  }

  .custom-control-alternative .custom-control-label::after {
    left: -2rem;
  }

  .custom-control-label {
    color: inherit;
    text-transform: inherit;
  }

  .leads-container {
    flex: 1 1 auto;
  }
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
